import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { ThresholdUnit } from '~/client/graph'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  value: number
  unit: ThresholdUnit
  inputLabel: string
  numberUnitLabel?: string
  percentUnitLabel?: string
  hideUnitSelector?: boolean
  onValueChange(newValue: number): void
  onUnitChange?(newUnit: ThresholdUnit): void
}

@observer
export default class ThresholdsSelector extends React.Component<IProps> {
  @observable private value: string = '0'
  @observable private unit: ThresholdUnit = ThresholdUnit.Number

  public UNSAFE_componentWillMount() {
    this.value = String(this.props.value || 0)
    this.unit = this.props.unit || ThresholdUnit.Number
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    this.value = String(newProps.value || 0)
    this.unit = newProps.unit || ThresholdUnit.Number
  }

  public render() {
    const {
      inputLabel,
      numberUnitLabel = Localization.translator.number,
      percentUnitLabel = Localization.translator.percent,
      hideUnitSelector,
    } = this.props
    return (
      <div className="thresholds-selector">
        <span className="text extra-large inline-block mr10">{inputLabel}</span>
        <span className="value-input mr15">
          <StruxhubInput
            type="number"
            isMinimalisticMode={true}
            value={this.value}
            onChange={this.onValueChange}
            customRightIcon={
              this.unit === ThresholdUnit.Percent && (
                <span className="percent-mark">%</span>
              )
            }
          />
        </span>
        {!hideUnitSelector && (
          <RadioGroup
            className="theme-radio-group inline"
            onChange={this.onUnitChange}
            selectedValue={this.unit}
          >
            <Radio value={ThresholdUnit.Number} label={numberUnitLabel} />
            <Radio value={ThresholdUnit.Percent} label={percentUnitLabel} />
          </RadioGroup>
        )}
      </div>
    )
  }

  private onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.value = event.target.value
    if (!isNaN(event.target.valueAsNumber)) {
      this.props.onValueChange(event.target.valueAsNumber)
    }
  }

  private onUnitChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.unit = event.currentTarget.value as any as ThresholdUnit
    this.props.onUnitChange(this.unit)
  }
}
