import * as React from 'react'

import {
  IPopoverProps,
  Menu,
  MenuItem,
  PopoverPosition,
  Radio,
} from '@blueprintjs/core'
import {
  IItemListRendererProps,
  IItemRendererProps,
  Select,
} from '@blueprintjs/select'
import { observer } from 'mobx-react'

import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NOOP } from '~/client/src/shared/utils/noop'

import BaseGroupingOptionsStore, {
  EMPTY_OPTION,
  ICustomBandOption,
  INDENT_OPTION_MAIN_ID,
} from '../../BaseGroupingOptions.store'

import '../../BaseGroupingOptions.scss'

// localization: translated

interface IProps {
  store: BaseGroupingOptionsStore
}

const popoverProps: IPopoverProps = {
  position: PopoverPosition.BOTTOM,
  targetClassName: 'mw300',
  usePortal: true,
  modifiers: {
    computeStyle: {
      gpuAcceleration: false,
    },
    flip: {
      enabled: true,
    },
    preventOverflow: {
      enabled: true,
      boundariesElement: 'viewport',
    },
  },
  minimal: true,
}

@observer
export default class CustomBandCreation extends React.Component<IProps> {
  public render() {
    return (
      <>
        <div className="text bold large pb5 mb10 bb-light-grey">
          {Localization.translator.customGroupCreation}
        </div>
        <div className="relative col scrollable mb15 min-height130">
          {this.renderBandRow()}
        </div>
      </>
    )
  }

  private renderBandRow(index: number = 0) {
    const { groupLevels, options, maxCustomBandsLevel } = this.props.store
    if (index + 1 > maxCustomBandsLevel || index >= groupLevels.length) {
      return
    }

    const level = groupLevels[index]
    const items = options.filter(
      ({ name }) => !name || level === name || !groupLevels.includes(name),
    )
    const selectedItem = options.find(o => o.name === level)
    const selectedValue = (selectedItem?.name && selectedItem?.caption) || ''

    return (
      <div key={index} className="row y-start pr10 y-stretch mt5">
        <div className="activity-log-entry-left-col col no-grow mr10 mt5">
          <div className="band-left-column-dot brada10 mb5 ml8" />
          <div className="relative-block thread-line-centred">
            <div className="text center absolute-block" />
            <div className="thread-vertical-line" />
          </div>
        </div>
        <div className="col">
          <>
            <div className="ml5 text">
              {Localization.translator.bandX(index + 1)}
            </div>
            <Select
              className="mw300"
              items={items}
              itemRenderer={this.renderGroupingItem}
              itemListRenderer={this.itemsListRenderer}
              onItemSelect={this.setLevel.bind(this, index)}
              activeItem={selectedItem}
              popoverProps={popoverProps}
              filterable={false}
              scrollToActiveItem={true}
            >
              <StruxhubTextValueSelector
                isMinimalisticMode={true}
                value={selectedValue}
              />
            </Select>
          </>
          {this.renderBandRow(index + 1)}
        </div>
      </div>
    )
  }

  private setLevel(levelIndex: number, item: ICustomBandOption) {
    const { store } = this.props
    const { name } = item

    switch (true) {
      case levelIndex === store.groupLevels.length - 1:
        store.groupLevels[levelIndex] = name
        store.groupLevels.push(EMPTY_OPTION)
        return
      case !name && !levelIndex:
        store.groupLevels = [EMPTY_OPTION]
        return
      case !name && !!levelIndex:
        store.groupLevels.splice(
          levelIndex,
          store.groupLevels.length - levelIndex - 1,
        )
      // eslint-disable-next-line no-fallthrough
      default:
        store.groupLevels[levelIndex] = name
        return
    }
  }

  private renderGroupingItem = (
    option: ICustomBandOption,
    { handleClick, index, modifiers }: IItemRendererProps,
  ): JSX.Element => {
    if (option.name === INDENT_OPTION_MAIN_ID) {
      return <div key={option.name + index} className="indent-option pt20" />
    }

    const onClick = modifiers.active ? NOOP : handleClick

    return (
      <MenuItem
        active={modifiers.active}
        key={option.name + index}
        text={
          <Radio
            readOnly={true}
            checked={modifiers.active}
            className="small"
            onClick={onClick}
          >
            {option.caption}
          </Radio>
        }
      />
    )
  }

  private itemsListRenderer = ({
    items,
    renderItem,
    itemsParentRef,
  }: IItemListRendererProps<ICustomBandOption>): JSX.Element => {
    return (
      <Menu
        ulRef={itemsParentRef}
        className="theme-radio-group custom-grouping-band-container px20 py10"
      >
        {items.map(renderItem)}
      </Menu>
    )
  }
}
