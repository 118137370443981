import * as React from 'react'

import { observer } from 'mobx-react'

import PhotosPageStore, { PhotoFilterTypes } from '../PhotosPageStore.store'
import PhotosFilter from './PhotosFilter'

interface IProps {
  photosStore: PhotosPageStore
}

@observer
export default class PhotosSecondaryFilters extends React.Component<IProps> {
  public render() {
    const { photosStore } = this.props
    if (!photosStore.isDeliveriesModeActive) {
      return <div />
    }
    const {
      reportersOptions,
      companiesOptions,
      suppliersOptions,
      selectedReporters,
      selectedSuppliers,
      selectedCompanies,
    } = photosStore
    const { reporter, company, supplier } = PhotoFilterTypes
    return (
      <div className="row secondary-filters">
        <PhotosFilter
          options={reportersOptions}
          store={photosStore}
          photoFilterType={reporter}
          values={selectedReporters}
        />
        <PhotosFilter
          options={companiesOptions}
          store={photosStore}
          photoFilterType={company}
          values={selectedCompanies}
        />
        <PhotosFilter
          options={suppliersOptions}
          store={photosStore}
          photoFilterType={supplier}
          values={selectedSuppliers}
        />
      </div>
    )
  }
}
