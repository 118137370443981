import * as React from 'react'

import { classList } from 'react-classlist-helper'

const NO_COMPANY_TITLE = 'Add Co.'
const MAX_LABEL_CHARACTERS = 10
const OFFSET_FOR_SINGLE_COMPANY_NAME = 5
const OFFSET_TO_LEFT = -120
const OFFSET_TO_RIGHT = 80

interface IProps {
  companyNames: string[]
  shouldShowTooltipOnLeft?: boolean
}

export default class CompanyBubble extends React.PureComponent<IProps> {
  public render() {
    const { companyNames, shouldShowTooltipOnLeft } = this.props
    const letOffset = shouldShowTooltipOnLeft ? OFFSET_TO_LEFT : OFFSET_TO_RIGHT
    let label
    let otherCompaniesCount
    let topOffset
    if (!companyNames || !companyNames.length) {
      label = NO_COMPANY_TITLE
    } else {
      label = this.retrieveCompanyLabel(companyNames)
      otherCompaniesCount =
        companyNames.length > 1 ? ` + ${companyNames.length - 1}` : ''
      topOffset =
        '-' + companyNames.length * OFFSET_FOR_SINGLE_COMPANY_NAME + 'px'
    }

    return (
      <div className="company-bubble">
        {otherCompaniesCount && (
          <div className="companies-list">
            <div
              style={{ top: topOffset, left: letOffset }}
              className="companies-list-holder pa5 ba-grey"
            >
              {companyNames.map((company, index) => {
                return (
                  <span
                    className="companies-list-holder-company-label"
                    key={index}
                  >
                    {company}
                  </span>
                )
              })}
            </div>
          </div>
        )}
        <div className="row mr5 company-bubble text">
          <div
            className={classList({
              'company-label': true,
              noname: !companyNames.length,
            })}
          >
            {label}
          </div>
          {otherCompaniesCount}
        </div>
      </div>
    )
  }

  private retrieveCompanyLabel(companies: string[]): string {
    const company = companies[0]
    if (companies.length === 1) {
      return company.split(' ', 1)[0]
    }
    return company.slice(0, MAX_LABEL_CHARACTERS)
  }
}
