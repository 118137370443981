import { InviteStatus } from '~/client/graph'

import User from '../models/User'
import UserProject from '../models/UserProject'
import UserProjectsStore from '../stores/domain/UserProjects.store'

export default function canApproveUser(
  user: User,
  userProjectsStore: UserProjectsStore,
) {
  const userProject = userProjectsStore?.getByUser(user)

  // TODO: Replace all conditions below by `Requested` status alone
  // if/when added on backend
  return (
    userProject?.inviteStatus === InviteStatus.NotInvited &&
    user.phoneNumber &&
    !user.setUpFinished &&
    user.accountPosition === 'field' &&
    !UserProject.isAccepted(user, userProjectsStore)
  )
}
