import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import StatusUpdateThread from '~/client/src/shared/components/StatusUpdateEntry/StatusUpdateThread'
import StatusUpdateMessagesActionBar from '~/client/src/shared/components/StatusUpdateMessagesActionBar'
import StatusUpdateMessagesViewBase from '~/client/src/shared/components/StatusUpdateMessagesViewBase'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

interface IProps {
  goBack: () => void
  statusUpdateId: string
  state?: DesktopInitialState
  statusUpdatesStore?: StatusUpdatesStore
  activitiesStore?: ActivitiesStore
  slackBarStore?: SlackBarStore
}

// eslint-disable-next-line zoomin/no-unused-mobx-injections
@inject('state', 'statusUpdatesStore', 'activitiesStore', 'slackBarStore')
@observer
export default class DesktopStatusUpdateMessagesView extends StatusUpdateMessagesViewBase<IProps> {
  public constructor(props: IProps) {
    super(props)

    this.statusUpdateId = this.props.statusUpdateId
  }

  public render() {
    const { userActiveProjectSettings } = this.props.state
    return (
      <View>
        <Header>{this.header}</Header>
        {this.imagePreview}
        <Content scrollable={true}>{this.content}</Content>
        <Footer
          className={toggleClass(
            'inactive-element',
            userActiveProjectSettings?.isPresentationUser,
          )}
        >
          {this.footer}
        </Footer>
      </View>
    )
  }

  public get content() {
    const currEntryDate = new Date(this.statusUpdate.createdAt)

    return (
      <div className="activity-log">
        <DaySeparator date={currEntryDate} />
        <StatusUpdateThread
          statusUpdate={this.statusUpdate}
          isStatusUpdateViewDisplayed={true}
          hideButtons={true}
          FileInputType={DesktopFileInput}
        />
      </div>
    )
  }

  public get footer() {
    const { selectedCompany } = this.props.state.activityList
    return (
      <StatusUpdateMessagesActionBar
        statusUpdate={this.statusUpdate}
        activity={this.activity}
        selectedCompany={selectedCompany}
        goBack={this.goBack}
      />
    )
  }

  @action.bound
  public goBack() {
    this.props.goBack()
  }

  @action.bound
  public goToActivityDetails() {
    this.props.goBack()
  }
}
