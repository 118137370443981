import User from '../models/User'
import UserProject from '../models/UserProject'
import UserProjectsStore from '../stores/domain/UserProjects.store'
import canApproveUser from './canApproveUser'

export default function canSendInviteToUser(
  user: User,
  userProjectsStore: UserProjectsStore,
): boolean {
  return (
    (user.email || user.phoneNumber) &&
    !UserProject.isAccepted(user, userProjectsStore) &&
    !canApproveUser(user, userProjectsStore)
  )
}
