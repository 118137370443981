import React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

interface IParametersBarProps {
  onTimeWindow: number
  setOnTimeWindow: (onTimeWindow: number) => void
  loadRequiredData?: (value: boolean) => void
  additionalContentStyle?: string
  shouldHideTitle?: boolean
  shouldHideBorderUnderTitle?: boolean
}

const doneTypingInterval = 2000

@observer
export default class ParametersBar extends React.Component<IParametersBarProps> {
  public render() {
    const {
      additionalContentStyle,
      shouldHideTitle,
      shouldHideBorderUnderTitle,
      onTimeWindow,
    } = this.props
    const contentClassname = 'col pt10 ' + additionalContentStyle
    return (
      <div
        className={classList({
          'parameters-bar py10': true,
          'bt-light-grey': !shouldHideBorderUnderTitle,
        })}
      >
        {!shouldHideTitle && (
          <div
            className={classList({
              'text extra-large bold': true,
              'bb-light-grey': shouldHideBorderUnderTitle,
            })}
          >
            {Localization.translator.parameters}
          </div>
        )}
        <div className={contentClassname}>
          <StruxhubInput
            label={Localization.translator.onTimeWindowHr}
            isRequiredTextHidden={true}
            type="number"
            step={0.1}
            value={onTimeWindow.toString()}
            onChange={this.setOnTimeWindow}
            negativeOrDecimal={true}
            withDelay={true}
            inputDelay={doneTypingInterval}
          />
        </div>
      </div>
    )
  }

  private setOnTimeWindow = (event: React.ChangeEvent<HTMLInputElement>) => {
    const onTimeWindow = event.target.value && Number(event.target.value)
    const { setOnTimeWindow, loadRequiredData } = this.props
    setOnTimeWindow(onTimeWindow)
    loadRequiredData?.(true)
  }
}
