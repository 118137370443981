import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ActivityStatus, CategoryName } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import { getActivityStatusDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'

import CategoryFilterStore from '../../CategoryFilter.store'
import StatusFilterStore from './StatusFilter.store'

// localization: translated

interface ICategoryOption {
  id: CategoryName
  displayName: string
  icon: JSX.Element
  activitiesCount: number
  statusFilterStore: StatusFilterStore
  categoryFilterStore: CategoryFilterStore
}

@observer
export default class StatusFilter extends React.Component<ICategoryOption> {
  private get store(): StatusFilterStore {
    return this.props.statusFilterStore
  }

  public render() {
    const {
      id,
      displayName,
      icon,
      activitiesCount,
      categoryFilterStore: { onCheckboxClick, selectedCategories },
    } = this.props

    return (
      <div key={id} className="option-row my10 row">
        <Checkbox
          isChecked={selectedCategories.includes(id)}
          onClick={onCheckboxClick.bind(this, id)}
        />
        <div className="row">
          <div className="icon-cell col x-center pt5">{icon}</div>
          <div className="status-name-cell nowrap text large light">
            {displayName}
          </div>
          <div className="selector-cell text large light row">
            <div
              className="selector no-grow title px5 ml10 ba-light-grey brada2 text large primary-blue"
              onClick={this.store.toggleStatusSelector}
            >
              <div className="row x-between selector">
                {getActivityStatusDisplayName(this.store.selectedMode)}
                {this.renderToggle()}
              </div>
              {this.store.shouldExpandStatusSelector && (
                <div className="bg-white text large light date-selector-options-holder">
                  {this.renderOptions()}
                </div>
              )}
            </div>
          </div>
          <div className="amount-cell text large primary-blue right row x-end">
            {activitiesCount}
          </div>
        </div>
      </div>
    )
  }

  private renderOptions(): JSX.Element[] {
    return Object.values(ActivityStatus).map((mode, index) => {
      return (
        <span
          key={index}
          className={classList({
            'ba-light-grey option row px5 pointer': true,
            'text large primary-blue': this.store.isModeSelected(mode),
          })}
          onClick={this.store.setSelectedSelectorStatusMode.bind(this, mode)}
        >
          {getActivityStatusDisplayName(mode)}
        </span>
      )
    })
  }

  private renderToggle() {
    const { CHEVRON_DOWN, CHEVRON_UP } = IconNames
    return (
      <Icon
        icon={this.store.shouldExpandStatusSelector ? CHEVRON_UP : CHEVRON_DOWN}
        className="text primary-blue"
      />
    )
  }
}
