import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ViewTypes, { viewTypesList } from '~/client/src/shared/enums/ViewTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'

import ConfiguratorHeader from '../Utils/ConfiguratorHeader/ConfiguratorHeader'
import ViewConfigurationRow from './components/ViewConfigurationRow/ViewConfigurationRow'

import '../Utils/CommonConfigurator.scss'

interface IHierarchyModeForViewsConfigurator {
  hierarchyConfigurationStore?: HierarchyConfigurationStore
}

const disabledMap = {
  [ViewTypes.webGantt]: false,
  [ViewTypes.webSitemap]: true,
  [ViewTypes.mobile]: true,
  [ViewTypes.tablet]: false,
}

@inject('hierarchyConfigurationStore')
@observer
export default class HierarchyModeForViewsConfigurator extends React.Component<IHierarchyModeForViewsConfigurator> {
  public render() {
    const { handleHierarchyModesForViewChange, viewsToHierarchyModesMap } =
      this.props.hierarchyConfigurationStore

    return (
      <div className="col hierarchy-configurator px50">
        <ConfiguratorHeader
          columnTitles={[
            Localization.translator.view_noun,
            Localization.translator.trackingHierarchy,
            Localization.translator.wbs,
          ]}
        />
        {viewTypesList.map((viewType, index) => (
          <ViewConfigurationRow
            key={index}
            viewType={viewType}
            configuration={viewsToHierarchyModesMap}
            onChange={handleHierarchyModesForViewChange}
            isDisabled={disabledMap[viewType]}
          />
        ))}
      </div>
    )
  }
}
