import * as React from 'react'

import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'

import User from '~/client/src/shared/models/User'

import DeliveryRequestSetUpStore from '../../../DeliveryRequestSetUp.store'
import MemberSearchList from '../../MemberSearchList/MemberSearchList'

interface IProps {
  store: DeliveryRequestSetUpStore
}

@observer
export default class DeliveryInspectorsEditor extends React.Component<IProps> {
  @observable private searchQuery: string = ''

  public render() {
    const { inspectors, promoteToInspector, revokeFromInspector } =
      this.props.store

    return (
      <MemberSearchList
        value={this.searchQuery}
        membersList={this.filterResult}
        appliedMembers={inspectors}
        onClear={this.handleClear}
        onChange={this.handleChange}
        onApply={promoteToInspector}
        onCancel={revokeFromInspector}
      />
    )
  }

  @computed
  private get filterResult(): User[] {
    if (!this.searchQuery) {
      return []
    }
    const { projectMembers } = this.props.store
    const keyWord = this.searchQuery.toLowerCase().trim()

    return projectMembers.filter(user => {
      const email = (user.email || '').toLowerCase()
      const firstName = (user.firstName || '').toLowerCase()
      const lastName = (user.lastName || '').toLowerCase()
      return `${email} ${firstName} ${lastName}`.includes(keyWord)
    })
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.searchQuery = event.target.value || ''
  }

  private handleClear = () => {
    this.searchQuery = ''
  }
}
