import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { NotificationType } from '~/client/graph'
import SitePermitCreationFormStore from '~/client/src/shared/components/SitePermitCreationForm/SitePermitCreationForm.store'
import WorkflowViewFormWrapper from '~/client/src/shared/components/SitePermitCreationForm/WorkflowViewFormWrapper'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import PermitInspectionChangesStore from '~/client/src/shared/stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '~/client/src/shared/stores/domain/PermitInspections.store'
import PermitStatusChangesStore from '~/client/src/shared/stores/domain/PermitStatusChanges.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DesktopFileInput from '../../../components/FileInput/DesktopFileInput'
import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import NotificationDetailsBase from './NotificationDetailsBase/NotificationDetailsBase'

interface IProps {
  permitId: string
  notificationType: NotificationType
  backClicked: () => void
  eventsStore?: DesktopEventStore
  projectDateStore?: ProjectDateStore
  sitePermitsStore?: SitePermitsStore
  fileUploadingStore?: FileUploadingStore
  permitTypesStore?: PermitTypesStore
  permitStatusChangesStore?: PermitStatusChangesStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  companiesStore?: CompaniesStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
  tagsStore?: TagsStore
  constraintsStore?: ConstraintsStore
}

@inject(
  'eventsStore',
  'projectDateStore',
  'sitePermitsStore',
  'fileUploadingStore',
  'permitTypesStore',
  'permitStatusChangesStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
  'userProjectsStore',
  'projectMembersStore',
  'companiesStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
  'tagsStore',
  'constraintsStore',
)
@observer
export default class NotificationDetailsPermit extends NotificationDetailsBase<IProps> {
  private readonly permitStore: SitePermitCreationFormStore

  public constructor(props: IProps) {
    super(props)

    this.permitStore = new SitePermitCreationFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.fileUploadingStore,
      props.permitTypesStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
      props.tagsStore,
      props.constraintsStore,
      props.sitePermitsStore,
      props.permitStatusChangesStore,
      props.permitInspectionsStore,
      props.permitInspectionChangesStore,
    )
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    if (this.props.permitId !== newProps.permitId) {
      this.permitStore.setDefaultSitePermit(newProps.permitId)
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { permitId } = this.props

    if (permitId !== prevProps.permitId) {
      this.permitStore.scrollToBottom()
    }
  }

  public UNSAFE_componentWillMount() {
    this.permitStore.setDefaultSitePermit(this.props.permitId)
  }

  protected get title() {
    return null
  }

  protected get content() {
    return (
      <WorkflowViewFormWrapper
        workflowToShow={this.permitStore.editablePermit}
        onClose={this.props.backClicked}
        FileInputType={DesktopFileInput}
      />
    )
  }

  protected get footer() {
    return null
  }
}
