import * as React from 'react'

import { IReactionDisposer, action, computed, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Route } from 'react-router'

import { PresentationScreenKey } from '~/client/graph'
import NavBar from '~/client/src/desktop/components/NavBar/NavBar'
import TwoMonthsDatePickerUnsafe from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker'
import DeliveryViewPeriodMode from '~/client/src/desktop/enums/DeliveryViewPeriodMode'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import MapBoxViewerStore from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import BaseMapViewSetUpStore from '~/client/src/shared/components/SitemapHelpers/BaseMapViewSetUp.store'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GlobeViewControlStore from '~/client/src/shared/stores/GlobeViewControl.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConcreteDirectIntegrationStore from '~/client/src/shared/stores/domain/ConcreteDirectStores/ConcreteDirectIntegration.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import DeliverySitemapPinStore from '~/client/src/shared/stores/ui/DeliverySitemapPin.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import desktopRoutes from '../../constants/desktopRoutes'
import DesktopCommonStore from '../../stores/ui/DesktopCommon.store'
import DeliveriesCalendar from './components/DeliveriesCalendar/DeliveriesCalendar'
import DeliveriesHeaderBar from './components/DeliveriesHeaderBar/DeliveriesHeaderBar'
import DeliveriesListUnsafe from './components/DeliveriesList/DeliveriesList'
import DeliveriesListStore from './components/DeliveriesList/DeliveriesList.store'
import DeliveriesMapUnsafe from './components/DeliveriesMap/DeliveriesMap'
import DeliveriesMapStore from './components/DeliveriesMap/DeliveriesMap.store'
import DeliveryActionSideWrapperUnsafe from './components/DeliveryActionSideWrapper/DeliveryActionSideWrapper'

import './Deliveries.scss'

// localization: translated

const DeliveryActionSideWrapper = withErrorBoundary(
  DeliveryActionSideWrapperUnsafe,
)
const TwoMonthsDatePicker = withErrorBoundary(TwoMonthsDatePickerUnsafe)
const DeliveriesList = withErrorBoundary(DeliveriesListUnsafe)
const DeliveriesMap = withErrorBoundary(DeliveriesMapUnsafe)

interface IProps {
  eventsStore?: DesktopEventStore
  deliveryDetailsStore?: DeliveryDetailsStore
  deliveriesStore?: DeliveriesStore
  locationAttributesStore?: LocationAttributesStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  calendarPlaceholderStore?: CalendarPlaceholderStore
  projectDateStore?: ProjectDateStore
  materialsStore?: MaterialsStore
  sitemapsStore?: SitemapsStore
  common?: DesktopCommonStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  materialCategoryStore?: MaterialCategoriesStore
  companiesStore?: CompaniesStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  concreteDirectIntegrationStore?: ConcreteDirectIntegrationStore
  globeViewsStore?: GlobeViewsStore
  tilesetsStore?: TilesetsStore
}

@inject(
  'eventsStore',
  'deliveryDetailsStore',
  'deliveriesStore',
  'deliveryVehicleTypesStore',
  'calendarPlaceholderStore',
  'materialsStore',
  'projectDateStore',
  'materialsStore',
  'sitemapsStore',
  'common',
  'deliveryAssignmentsStore',
  'deliveryFollowingsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'materialCategoryStore',
  'companiesStore',
  'projectMembersStore',
  'userProjectsStore',
  'concreteDirectIntegrationStore',
  'locationAttributesStore',
  'globeViewsStore',
  'tilesetsStore',
)
@observer
export default class Deliveries extends React.Component<IProps> {
  private disposePresentationReaction: IReactionDisposer
  private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore
  private readonly deliveriesListStore: DeliveriesListStore
  private readonly deliveriesMapStore: DeliveriesMapStore
  private readonly baseSitemapSetUpStore: BaseMapViewSetUpStore
  private readonly mapBoxViewerStore: MapBoxViewerStore = null
  private readonly globeViewControlStore: GlobeViewControlStore
  private readonly deliverySitemapPinStore: DeliverySitemapPinStore

  private readonly clearPostEventCallback: () => void = NOOP
  public constructor(props: IProps) {
    super(props)

    const {
      eventsStore,
      sitemapItemsStore,
      sitemapsStore,
      locationAttributesStore,
      concreteDirectIntegrationStore,
    } = props

    this.desktopDeliveryViewStore = new DesktopDeliveryViewStore(
      props.deliveryDetailsStore,
      eventsStore,
      props.deliveriesStore,
      locationAttributesStore,
      props.deliveryVehicleTypesStore,
      props.materialCategoryStore,
      props.materialsStore,
      props.projectDateStore,
      props.deliveryAssignmentsStore,
      props.deliveryFollowingsStore,
      props.companiesStore,
      props.common,
      concreteDirectIntegrationStore,
      props.calendarPlaceholderStore,
    )

    this.deliveriesListStore = new DeliveriesListStore(
      props.eventsStore,
      props.projectDateStore,
      props.deliveryAssignmentsStore,
      props.deliveryFollowingsStore,
      this.desktopDeliveryViewStore,
      props.materialCategoryStore,
      props.companiesStore,
      props.projectMembersStore,
      props.userProjectsStore,
    )

    this.baseSitemapSetUpStore = new BaseMapViewSetUpStore(
      props.sitemapsStore,
      props.globeViewsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
    )

    this.globeViewControlStore = new GlobeViewControlStore(
      props.eventsStore,
      props.globeViewsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )

    this.mapBoxViewerStore = new MapBoxViewerStore(
      sitemapsStore,
      props.tilesetsStore,
      locationAttributesStore,
      eventsStore.appState,
      this.globeViewControlStore,
      props.basemapsStore,
      null,
      false,
      () => this.getSelectedMapViewItem(),
      this.selectSitemapItem,
    )

    this.deliverySitemapPinStore = new DeliverySitemapPinStore(
      eventsStore.appState,
    )

    this.deliveriesMapStore = new DeliveriesMapStore(
      props.eventsStore,
      props.projectDateStore,
      props.deliveryAssignmentsStore,
      this.desktopDeliveryViewStore,
      this.baseSitemapSetUpStore,
      props.companiesStore,
      props.projectMembersStore,
      props.materialCategoryStore,
      props.userProjectsStore,
      this.globeViewControlStore,
      this.mapBoxViewerStore,
      props.globeViewsStore,
      this.deliverySitemapPinStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onEvent,
    )

    this.ensureCorrectViewMode()
  }

  private selectSitemapItem = (item: MapViewItemBase) => {
    const { isSitemapItemSupported, selectAttr } = this.deliveriesMapStore
    if (!!item && isSitemapItemSupported(item)) {
      selectAttr(item.id)
    } else {
      selectAttr(null)
    }
  }

  private getSelectedMapViewItem = () => {
    return this.globeViewControlStore.selectedGlobeViewItems.find(
      i => i.id === this.deliveriesMapStore.selectedAttrId,
    )
  }

  public UNSAFE_componentWillMount() {
    if (!this.appState.userActiveProjectSettings?.isPresentationUser) {
      return
    }

    this.disposePresentationReaction = reaction(
      () => this.appState.currentPresentationPage,
      page => {
        if (page && page.type === PresentationScreenKey.Deliveries) {
          this.desktopDeliveryViewStore.setCalendarMode(
            DeliveryViewPeriodMode.WeekPeriod,
          )
        }
      },
      { fireImmediately: true },
    )
  }

  public componentWillUnmount() {
    this.disposePresentationReaction?.()
    this.clearPostEventCallback()
  }

  public render() {
    const { datePickerStore, isDataPrepared, isCalendarViewMode } =
      this.desktopDeliveryViewStore

    if (!isDataPrepared) {
      return (
        <Loader hint={Localization.translator.loadingDeliveriesData + '...'} />
      )
    }

    return (
      <Layout.View className="desktop-deliveries overflow-hidden">
        <Layout.Header>
          <NavBar className="layout-navigation" />
          <DeliveriesHeaderBar
            desktopDeliveryViewStore={this.desktopDeliveryViewStore}
            deliveriesListStore={this.deliveriesListStore}
            deliveriesMapStore={this.deliveriesMapStore}
            isMapViewDisabled={this.isMapViewDisabled}
          />
        </Layout.Header>
        <Layout.Content>
          <div className="relative-block">
            <div className="relative-block overflow-hidden multi-grid-container">
              <TwoMonthsDatePicker
                store={datePickerStore}
                className="desktop-deliveries-date-picker"
              />
              <div
                className={classList({
                  'full-height': true,
                  row: isCalendarViewMode,
                })}
              >
                {this.renderView()}
                <Route path={desktopRoutes.DELIVERY_DETAILS}>
                  <DeliveryActionSideWrapper
                    state={this.appState}
                    deliveriesListStore={this.deliveriesListStore}
                    desktopDeliveryViewStore={this.desktopDeliveryViewStore}
                  />
                </Route>
              </div>
            </div>
          </div>
        </Layout.Content>
        <Layout.Footer>
          <div />
        </Layout.Footer>
      </Layout.View>
    )
  }

  private renderView() {
    switch (this.viewMode) {
      case ViewModes.Calendar:
        return (
          <DeliveriesCalendar
            state={this.appState}
            store={this.desktopDeliveryViewStore}
          />
        )
      case ViewModes.List:
        return <DeliveriesList store={this.deliveriesListStore} />

      case ViewModes.Map:
        return (
          <DeliveriesMap
            store={this.deliveriesMapStore}
            activeActionType={this.desktopDeliveryViewStore.activeActionType}
            onNewDeliveryCreate={
              this.desktopDeliveryViewStore.onNewDeliveryCreate
            }
          />
        )
    }
  }

  private get appState() {
    return this.props.eventsStore.appState
  }

  private get viewMode() {
    return this.desktopDeliveryViewStore.viewMode
  }

  @computed
  public get isMapViewDisabled(): boolean {
    // logic is easier than on the Logistics page because the mapView is not default
    return (
      !this.deliveriesMapStore.deliveriesGlobes?.length &&
      !this.deliveriesMapStore.deliveriesSitemaps?.length
    )
  }

  @action.bound
  public ensureCorrectViewMode(): void {
    if (this.viewMode === ViewModes.Map && this.isMapViewDisabled) {
      this.appState.deliveriesList.viewMode = ViewModes.Calendar
    }
  }

  @action.bound
  public onEvent(eventContext: EventContext): void {
    const [eventType] = eventContext.event

    switch (eventType) {
      case e.PROJECT_RECEIVED:
        const { setCalendarMode, calendarMode } = this.desktopDeliveryViewStore
        return setCalendarMode(calendarMode)
      case e.SITEMAPS_RECEIVED:
      case e.SITEMAP_UPDATED:
      case e.DELIVERY_CONFIGURATIONS_RECEIVED:
      case e.DELIVERY_CONFIGURATIONS_UPDATED:
        this.ensureCorrectViewMode()
    }
  }
}
