import { action, computed } from 'mobx'

import {
  IPermitTypeField,
  PermitFieldType,
  WorkflowStepType,
} from '~/client/graph'
import FormReportGroupingOption from '~/client/src/desktop/enums/FormReportGroupingOption'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  BasicDataKeys,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import {
  companyPermitFieldTypes,
  locationPermitFieldTypes,
  userPermitFieldTypes,
} from '~/client/src/shared/constants/PermitFieldTypeConstants'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getFormStatusDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import SitePermit from '~/client/src/shared/models/Permit'
import PermitField from '~/client/src/shared/models/PermitField'
import PermitType from '~/client/src/shared/models/PermitType'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitInspectionChangesStore from '~/client/src/shared/stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '~/client/src/shared/stores/domain/PermitInspections.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import { ITreeNodeObj } from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { groupingCommonMapper } from '~/client/src/shared/utils/mappers'
import {
  EMPTY_STRING,
  LOCATION_SEPARATOR,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'
import { copyObjectDeep } from '~/client/src/shared/utils/util'

import ReportsStore from '../../../Reports.store'

enum SpecificDataKeys {
  NAME = 'name',
  RECEIPT = 'receipt',
  STATUS = 'status',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  LOCATION = 'location',
  COMPANY = 'company',
  REQUESTER = 'requester',
  SUMMARIZED = 'summarized',
}

const DEFAULT_SORT_KEY = 'name'

export const DataKeys = { ...BasicDataKeys, ...SpecificDataKeys }
const FILTER_KEYS = ['id']
const DEFAULT_ROW_HEIGHT = 48
const MULTILINE_ROW_HEIGHT = 35
const CATEGORY_ROW_HEIGHT = 32
const SMALL_DYNAMIC_COLUMN_MIN_LENGTH = 50
const SMALL_DYNAMIC_COLUMN_WIDTH = 200
const BIG_DYNAMIC_COLUMN_WIDTH_MULTIPLIER = 150

enum AcceptableFormFilterTypes {
  COMPANY = 'company',
  STATUS = 'status',
  LOCATION = 'location',
}

interface DynamicColumn {
  label: string
  dataKey: string
  checkListItemId?: string
  isSummarized?: boolean
}

const unassignedValues = [UNASSIGNED, NO_SPECIFIED, EMPTY_STRING, NO_VALUE]
const checklistFormFieldTypes = [
  PermitFieldType.Question,
  PermitFieldType.Checklist,
]

export default class FormReportListStore extends BaseMultiBandListStore<SitePermit> {
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly reportsStore: ReportsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly permitInspectionsStore: PermitInspectionsStore,
    private readonly permitInspectionChangesStore: PermitInspectionChangesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly locationAttributesStore: LocationAttributesStore,
  ) {
    super(
      state.formReportFilters,
      () => reportsStore.allFormReportPermits,
      FILTER_KEYS,
    )
  }

  @computed
  public get activeFormType(): PermitType {
    return this.permitTypesStore.actualTypes.find(
      actualType => actualType.id === this.reportsStore.activeReportView?.id,
    )
  }

  @computed
  public get activeFormTypeFieldsMap(): Map<string, IPermitTypeField> {
    const map = new Map()
    this.activeFormType.workflowSteps.forEach(workflowStep => {
      workflowStep.fields.forEach(f => map.set(f.id, f))
      workflowStep.conditionalFields.forEach(condField => {
        condField.values.forEach(f => map.set(f.id, f))
      })
    })

    return map
  }

  public getRowHeight = ({ index }): number => {
    const row = this.rows[index]

    if (row?.category) return CATEGORY_ROW_HEIGHT

    const requesterIds = row.data && row.data[DataKeys.REQUESTER]

    if (requesterIds?.length <= 1) {
      return DEFAULT_ROW_HEIGHT
    }

    return requesterIds?.length * MULTILINE_ROW_HEIGHT
  }

  public columnsWidthState = new Map<string, number>([
    [DataKeys.CHECKBOX, 60],
    [DataKeys.NAME, 200],
    [DataKeys.RECEIPT, 150],
    [DataKeys.STATUS, 200],
    [DataKeys.LOCATION, 250],
    [DataKeys.START_DATE, 250],
    [DataKeys.END_DATE, 250],
    [DataKeys.COMPANY, 250],
    [DataKeys.REQUESTER, 250],
  ])

  @computed
  public get columns(): ILWFCColumn[] {
    const columns = [
      {
        dataKey: DataKeys.CHECKBOX,
      },
      {
        label: Localization.translator.name,
        dataKey: DataKeys.NAME,
      },
      {
        label: Localization.translator.receipt,
        dataKey: DataKeys.RECEIPT,
      },
      {
        label: Localization.translator.status,
        dataKey: DataKeys.STATUS,
      },
      {
        label: Localization.translator.location,
        dataKey: DataKeys.LOCATION,
      },
      {
        label: Localization.translator.startDate,
        dataKey: DataKeys.START_DATE,
      },
      {
        label: Localization.translator.finishDate,
        dataKey: DataKeys.END_DATE,
      },
      {
        label: Localization.translator.company,
        dataKey: DataKeys.COMPANY,
      },
      {
        label: Localization.translator.requester,
        dataKey: DataKeys.REQUESTER,
      },
      ...this.dynamicColumns.map(dynamicColumn => ({
        label: dynamicColumn.label,
        dataKey: dynamicColumn.dataKey,
      })),
    ]

    return columns.map(column => ({
      ...column,
      width: this.getColumnWidth(column),
    }))
  }

  private getColumnWidth(column: any): number {
    if (this.columnsWidthState.has(column.dataKey)) {
      return this.columnsWidthState.get(column.dataKey)
    }

    if (column?.label) {
      return column.label.length > SMALL_DYNAMIC_COLUMN_MIN_LENGTH
        ? Math.round(column.label.length / SMALL_DYNAMIC_COLUMN_MIN_LENGTH) *
            BIG_DYNAMIC_COLUMN_WIDTH_MULTIPLIER
        : SMALL_DYNAMIC_COLUMN_WIDTH
    }
    return null
  }

  public get dynamicColumns(): DynamicColumn[] {
    const columns = []
    const { permitTypeFields, summaryFields } =
      this.reportsStore.activeReportView || {}

    if (permitTypeFields?.length) {
      permitTypeFields.forEach(permitTypeField => {
        const field = this.activeFormTypeFieldsMap.get(
          permitTypeField.permitTypeFieldId,
        )

        if (!field) return

        const label = permitTypeField.checkListItemId
          ? field.checklist.list.find(
              item => item.id === permitTypeField.checkListItemId,
            )?.text
          : field.caption

        columns.push({
          label,
          dataKey: field.id,
          checkListItemId: permitTypeField.checkListItemId,
        })
      })
    }

    if (summaryFields?.length) {
      summaryFields.forEach(summaryField => {
        columns.push({
          label: summaryField.name,
          dataKey: `${DataKeys.SUMMARIZED}_${summaryField.fieldId}`,
          isSummarized: true,
        })
      })
    }

    return columns
  }

  @action.bound
  public onRowClick(rowData: LWFCRowData, columnKey: string) {
    const id = rowData[DataKeys.ID]

    switch (columnKey) {
      case DataKeys.CHECKBOX:
        this.toggleInstance(id)
        return
      default:
        const item: SitePermit = this.reportsStore.allFormReportPermits.find(
          form => form.id === id,
        )
        this.reportsStore.showFormReports(item)
    }
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { groupingKey } = this.state.formReportFilters

    const keys = []
    keys.push(groupingKey)

    const bandRows = this.toBandTreeNodeRows(
      keys,
      null,
      '',
      0,
      false,
      this.sortTreeObjs,
    )

    return this.hasDynamicRecurringField
      ? this.populateWithInspections(bandRows)
      : bandRows
  }

  private sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) {
        return 1
      }
      if (unassignedValues.includes(b.id)) {
        return -1
      }

      return this.collator.compare(a.name, b.name)
    })
  }

  private get hasDynamicRecurringField() {
    const recurringStep = this.activeFormType.workflowSteps.find(
      ws => ws.type === WorkflowStepType.RecurringInspection,
    )

    if (!recurringStep) return

    const recurringFields = [
      ...recurringStep.fields,
      ...recurringStep.conditionalFields.flatMap(cf => cf.values),
    ]

    return this.dynamicColumns.some(column =>
      recurringFields.find(f => f.id === column.dataKey),
    )
  }

  private populateWithInspections = (rows: ILWFCRow[]) => {
    const { getWeekdayMonthDayYearAndTimeToDisplay } = this.projectDateStore
    const permitWithInspections = []

    rows.forEach(row => {
      if (row.category) {
        permitWithInspections.push(row)
        return
      }

      const permitId = row.data[DataKeys.ID]

      permitWithInspections.push(row)

      const inspections =
        this.permitInspectionsStore.getInspectionsForPermit(permitId)

      if (!inspections?.length) return

      inspections.forEach((insp, i) => {
        // I'm using it to skip that inspections which doesn't any of our dynamic fields
        let inspectionHaveDynamicField = false

        const latestChange =
          this.permitInspectionChangesStore.getLatestChangeForInspection(
            insp.id,
          )

        const inspection = latestChange
          ? latestChange.toInspection(permitId)
          : insp

        const data = {
          [DataKeys.ID]: row.data[DataKeys.ID],
          [DataKeys.CHECKBOX]: row.data[DataKeys.CHECKBOX],
          [DataKeys.RECEIPT]: Localization.translator.log,
          [DataKeys.STATUS]: row.data[DataKeys.STATUS],
          [DataKeys.COMPANY]: row.data[DataKeys.COMPANY],
          [DataKeys.LOCATION]: row.data[DataKeys.LOCATION],
          [DataKeys.REQUESTER]: row.data[DataKeys.REQUESTER],
          [DataKeys.NAME]: {
            isInspection: true,
            value: row.data[DataKeys.NAME].replace(':', `.${i + 1}:`),
          },
          [DataKeys.START_DATE]: getWeekdayMonthDayYearAndTimeToDisplay(
            inspection.inspectionDate,
          ),
          [DataKeys.END_DATE]: getWeekdayMonthDayYearAndTimeToDisplay(
            inspection.inspectionDate,
          ),
        }

        this.dynamicColumns.forEach(dynamicColumn => {
          if (dynamicColumn.isSummarized) return

          let field = inspection.fieldsByIdMap[dynamicColumn.dataKey]
          if (!field) return

          inspectionHaveDynamicField = true
          field = copyObjectDeep(field)
          field.values = this.getFormattedFieldValues(
            field,
            dynamicColumn,
            getWeekdayMonthDayYearAndTimeToDisplay,
          )

          data[dynamicColumn.dataKey] = field
        })
        if (inspectionHaveDynamicField) {
          permitWithInspections.push({
            data,
          })
        }
      })
    })

    return permitWithInspections
  }

  protected get instancesInPeriodInterval(): SitePermit[] {
    return this.reportsStore.formReportPermitsInPeriodInterval
  }

  protected searchFiltering = (form: SitePermit) => {
    const key = this.filter.searchKey.toLowerCase()
    const formLocationNames = form.locations.reduce((set, loc) => {
      const formLocation = this.locationAttributesStore.getById(loc.id)
      if (formLocation?.name) {
        set.add(formLocation?.name)
      }
      return set
    }, new Set<string>())

    const entityValues: string[] = [
      ...Array.from(formLocationNames),
      form.getCaption(this.permitTypesStore),
      this.projectDateStore.getWeekdayMonthDayAndTimeToDisplay(form.startDate),
      this.projectDateStore.getWeekdayMonthDayAndTimeToDisplay(form.endDate),
      form.status,
      getFormStatusDisplayName(form.status, form.workflowStepLevel),
      ...this.projectMembersStore
        .getByIds(form.requesterIds)
        .map(u => u.fullName),
      ...form.companyIds.map(id => this.getCompanyName(id)),
    ]
    return entityValues.some(value => value?.toLowerCase().includes(key))
  }

  @computed
  protected get categoryToInstancesMap() {
    const map = {}

    this.filteredCollection.forEach(pair => {
      const categoryIds = this.getCategoryIds(pair, this.groupingKey) || [
        UNASSIGNED,
      ]

      categoryIds.forEach(id => {
        const formattedCategoryId = id || UNASSIGNED

        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(pair)
        } else {
          map[formattedCategoryId] = [pair]
        }
      })
    })

    return map
  }

  public getCategoryIds(form: SitePermit, band: string): string[] {
    const startDate = form?.startDate
    const { getWeekRange, getMonthRange, getMonthDayAndYearToDisplay } =
      this.projectDateStore
    switch (band) {
      case FormReportGroupingOption.COMPANY:
        return form.companyIds?.length ? form.companyIds : [UNASSIGNED]
      case FormReportGroupingOption.DAY:
        return [getMonthDayAndYearToDisplay(startDate) || UNASSIGNED]
      case FormReportGroupingOption.WEEK:
        return [getWeekRange(startDate) || UNASSIGNED]
      case FormReportGroupingOption.MONTH:
        return [getMonthRange(startDate) || UNASSIGNED]
      case FormReportGroupingOption.NONE:
        return [FormReportGroupingOption.NONE]
      default:
        return []
    }
  }

  public get reportInfo() {
    const {
      formReportFilters: { fieldsMap },
      activeProject: { dateTimeFormat, code, materialsUploadId },
      user: { id: userId },
    } = this.state
    const { order, columnKey } = this.sortState
    const { startDate, endDate } = this.reportsStore

    const filters = this.getPermitFilters(fieldsMap)
    return {
      userId,
      materialsUploadId,
      projectTimeZone: this.projectDateStore.getClientTimezoneId(),
      dateTimeFormat,
      permitsFrom: startDate.getTime(),
      permitsTo: endDate.getTime(),
      filteredIds: {
        permits: this.filteredCollection.map(form => form.id),
      },
      filters,
      grouping: {
        permits: this.groupingKey,
      },
      sorting: {
        permits: [
          {
            ascending: order !== SortOrder.DESC,
            key: columnKey || DEFAULT_SORT_KEY,
          },
        ],
      },
      qrCodeLinks: this.filteredCollection.map(form =>
        SitePermit.getDirectLinkToInstance(code, form.id),
      ),
      dynamicColumns: this.dynamicColumns,
    }
  }

  private getPermitFilters(fieldsMap: { [filterType: string]: UIFilterInfo }): {
    [filterType: string]: string[]
  } {
    const result: { [filterType: string]: string[] } = {}

    Object.entries(fieldsMap).forEach(([filterType, filter]) => {
      switch (filterType) {
        case AcceptableFormFilterTypes.COMPANY:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).map(id => this.getCompanyName(id))
          break
        case AcceptableFormFilterTypes.STATUS:
          result[filterType] = Array.from(filter.selectedFilterOptions.keys())
          break
        case AcceptableFormFilterTypes.LOCATION:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).reduce((list, key) => {
            if (key === UNASSIGNED) {
              return list
            }

            const attributeId = key.split(LOCATION_SEPARATOR)[1]
            const location = this.locationAttributesStore.getById(attributeId)
            if (location?.name) list.push(location.name)

            return list
          }, [] as string[])
          break
      }
    })

    return result
  }

  @action.bound
  public selectRow(objectId: string) {
    this.resetSelection()
    this.selection.set(objectId, true)
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getSortingValue(aRowData, columnKey)
    const v2 = this.getSortingValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      if (unassignedValues.includes(v1)) {
        return 1
      }
      if (unassignedValues.includes(v2)) {
        return -1
      }
      return this.collator.compare(v1, v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  private getSortingValue(rowData: LWFCRowData, columnKey: string) {
    const isDynamicColumn = this.dynamicColumns.find(
      c => c.dataKey.replace(`${DataKeys.SUMMARIZED}_`, '') === columnKey,
    )

    if (isDynamicColumn) {
      return this.getDynamicColumnSortingValue(rowData[columnKey])
    }

    const form = this.filteredCollection.find(
      f => f.id === rowData[DataKeys.ID],
    )
    switch (columnKey) {
      case DataKeys.START_DATE:
        return form.startDate
      case DataKeys.END_DATE:
        return form.endDate
      case DataKeys.STATUS:
        return getFormStatusDisplayName(form.status, form.workflowStepLevel)
      case DataKeys.LOCATION:
        const locations = rowData[columnKey]
        return locations?.length > 1
          ? locations.length.toString()
          : locations?.[0]?.name || EMPTY_STRING
      case DataKeys.COMPANY:
        const companiesData = rowData[columnKey]
        return companiesData?.length > 1
          ? companiesData.length.toString()
          : companiesData?.[0]?.name || EMPTY_STRING
      case DataKeys.REQUESTER:
        const usersData = rowData[columnKey]
        return usersData?.length > 1
          ? usersData.length.toString()
          : usersData?.[0]?.fullName || EMPTY_STRING

      default:
        const value = rowData[columnKey]
        return value === NO_SPECIFIED ? EMPTY_STRING : value
    }
  }

  private getDynamicColumnSortingValue(field: any): string {
    if (!field) {
      return EMPTY_STRING
    }

    switch (true) {
      case locationPermitFieldTypes.includes(field.type):
      case companyPermitFieldTypes.includes(field.type):
        return field.values?.length > 1
          ? field.values.length.toString()
          : field.values?.[0]?.name || EMPTY_STRING
      case userPermitFieldTypes.includes(field.type):
        return field.values?.length > 1
          ? field.values.length.toString()
          : field.values?.[0]?.fullName || EMPTY_STRING

      default:
        return field.values?.join(' ') || EMPTY_STRING
    }
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndYearToDisplay, getWeekRange, getMonthRange } =
      this.projectDateStore

    switch (currentBand) {
      case FormReportGroupingOption.NONE:
        return [groupingCommonMapper(FormReportGroupingOption.NONE)]
      case FormReportGroupingOption.DAY:
        return this.filteredCollection
          .map(
            form => getMonthDayAndYearToDisplay(form.startDate) || UNASSIGNED,
          )
          .map(groupingCommonMapper)
      case FormReportGroupingOption.WEEK:
        return this.filteredCollection
          .map(form => getWeekRange(form.startDate) || UNASSIGNED)
          .map(groupingCommonMapper)
      case FormReportGroupingOption.MONTH:
        return this.filteredCollection
          .map(form => getMonthRange(form.startDate) || UNASSIGNED)
          .map(groupingCommonMapper)
      case FormReportGroupingOption.COMPANY:
        return [
          ...this.filteredCollection
            .flatMap(form => form.companyIds)
            .map(companyId => {
              const company = this.companiesStore.getCompanyById(companyId)
              return {
                name: company?.formattedName || UNASSIGNED,
                id: company?.id || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
      default:
        return []
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: SitePermit[] }
  } {
    const map = {}

    const groupingOptions = Object.values(FormReportGroupingOption)

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(report => {
        const groupNames: string[] = this.getCategoryIds(report, band) || [
          UNASSIGNED,
        ]

        groupNames.forEach(group => {
          const groupName = group ? group : UNASSIGNED

          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }
          bandMap[groupName].push(report)
        })
      })
    })

    return map
  }

  protected toRows(reports: SitePermit[]) {
    const { getWeekdayMonthDayYearAndTimeToDisplay } = this.projectDateStore

    const hasSummaryColumn = this.dynamicColumns.some(
      column => column.isSummarized,
    )

    return reports.map(form => {
      const data = {
        [DataKeys.ID]: form.id,
        [DataKeys.CHECKBOX]: this.selection.get(form.id),
        [DataKeys.RECEIPT]: hasSummaryColumn
          ? Localization.translator.summary
          : NO_VALUE,
        [DataKeys.NAME]: form.getCaption(this.permitTypesStore),
        [DataKeys.STATUS]: {
          status: form.status,
          workflowStepLevel: form.workflowStepLevel,
          isLate: form.isLate,
        },
        [DataKeys.START_DATE]: getWeekdayMonthDayYearAndTimeToDisplay(
          form.startDate,
        ),
        [DataKeys.END_DATE]: getWeekdayMonthDayYearAndTimeToDisplay(
          form.endDate,
        ),
        [DataKeys.COMPANY]: form.companyIds
          ?.map(id => this.companiesStore.getCompanyById(id))
          .filter(c => c),
        [DataKeys.LOCATION]:
          form.locations.reduce((list, loc) => {
            const formLocation = this.locationAttributesStore.getById(loc.id)
            if (formLocation) {
              list.push(formLocation)
            }
            return list
          }, []) || [],
        [DataKeys.REQUESTER]: this.projectMembersStore.getByIds(
          form.requesterIds,
        ),
      }

      const inspections = this.permitInspectionsStore.getInspectionsForPermit(
        form.id,
      )

      const formInspectionFields = inspections
        .flatMap(insp => {
          const latestChange =
            this.permitInspectionChangesStore.getLatestChangeForInspection(
              insp.id,
            )

          const inspection = latestChange
            ? latestChange.toInspection(form.id)
            : insp

          return inspection.fields
        })
        .reduce((acc, f) => {
          const field = acc.find(accField => accField.fieldId === f.fieldId)
          if (field) {
            field.values.push(...f.values)
          } else {
            acc.push(copyObjectDeep(f))
          }
          return acc
        }, [] as PermitField[])

      this.dynamicColumns.forEach(dynamicColumn => {
        const fields: PermitField[] = dynamicColumn.isSummarized
          ? [...form.fields, ...formInspectionFields]
          : form.fields

        let field = fields.find(
          f =>
            f.fieldId ===
            dynamicColumn.dataKey.replace(`${DataKeys.SUMMARIZED}_`, ''),
        )

        if (!field) return

        field = copyObjectDeep(field)
        field.values = this.getFormattedFieldValues(
          field,
          dynamicColumn,
          getWeekdayMonthDayYearAndTimeToDisplay,
        )

        if (dynamicColumn.isSummarized && field?.values) {
          field.values = [
            field.values.reduce(
              (acc, value) => (acc += parseInt(value, 10)),
              0,
            ),
          ]
        }

        data[dynamicColumn.dataKey] = field
      })

      return { data }
    })
  }

  private getCompanyName = (companyId: string): string => {
    return this.companiesStore.getCompanyNameById(companyId)
  }

  private getFormattedFieldValues = (
    field: PermitField,
    dynamicColumn: DynamicColumn,
    dateFormatter: (date: Date | number) => string,
  ): any[] => {
    if (locationPermitFieldTypes.includes(field.type)) {
      return field.values?.reduce((list, loc) => {
        const formLocation = this.locationAttributesStore.getById(loc.id)
        if (formLocation) {
          list.push(formLocation)
        }
        return list
      }, [])
    }
    if (userPermitFieldTypes.includes(field.type)) {
      return this.projectMembersStore.getByIds(field.values)
    }
    if (companyPermitFieldTypes.includes(field.type)) {
      return field.values
        .map(id => this.companiesStore.getCompanyById(id))
        .filter(c => c)
    }
    if (field.type === PermitFieldType.AdditionalDate) {
      return field.values.map(value => dateFormatter(value))
    }
    if (checklistFormFieldTypes.includes(field.type)) {
      return field.values
        ?.filter(v => v.checklistItemId === dynamicColumn.checkListItemId)
        .map(v => {
          switch (true) {
            case v.answer === undefined:
              return NO_VALUE
            case v.answer === null:
              return Localization.translator.NA
            case v.answer:
              return Localization.translator.yes
            case !v.answer:
              return Localization.translator.no
          }
        })
    }
    return field.values
  }
}
