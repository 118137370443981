import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'

import PermitWorkflowDiagram from '~/client/src/desktop/components/WorkflowDiagrams/PermitWorkflowDiagram/PermitWorkflowDiagram'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import PermitsSetUpStore from '../PermitsSetUp.store'

const createNewForm = 'Create a new form'
const enterTypeName = 'Enter form name'
const basedOn = 'Based on form'
const submit = 'Submit'
const workflow = 'Workflow'

interface IProps {
  store: PermitsSetUpStore
}

@observer
export default class NewFormTypeDialog extends React.Component<IProps> {
  @observable private typeName: string = EMPTY_STRING
  @observable private basedOn: string = EMPTY_STRING
  @observable private categoryId: string

  public componentDidMount() {
    this.basedOn = this.availableTypes[0].type
  }

  public render() {
    const { store } = this.props
    const { activeCategories, hideNewFormTypeDialog: hideNewTypeForm } = store

    const bodyClassName = `${Classes.DIALOG_BODY} overflow-auto relative px16`
    const footerClassName = `${Classes.DIALOG_FOOTER} ma0 py10 px30 bt-light-input-border row x-center`

    return (
      <Dialog
        className="new-permit-type-form bg-white ba-light-input-border"
        isOpen={true}
        title={createNewForm}
        onClose={hideNewTypeForm}
        canEscapeKeyClose={false}
      >
        <div className={bodyClassName}>
          <div className="row x-center">
            <div className="col new-type-inputs">
              <StruxhubInput
                label={enterTypeName}
                isRequired={true}
                value={this.typeName}
                onChange={this.onNameChanged}
                onValueReset={this.onNameReset}
              />
              <StruxhubSelect
                label={Localization.translator.formCategory}
                isRequired
                value={this.categoryId}
                onChange={this.onCategoryChanged}
              >
                <option></option>
                {activeCategories.map(category => (
                  <option
                    key={category.id}
                    value={category.id}
                    className="text large"
                  >
                    {category.name}
                  </option>
                ))}
              </StruxhubSelect>
              <StruxhubSelect
                label={basedOn}
                isRequired={true}
                value={this.basedOn}
                onChange={this.onBasedChanged}
              >
                {this.availableTypes.map((type, index) => (
                  <option key={index} value={type.type} className="text large">
                    {type.name}
                  </option>
                ))}
              </StruxhubSelect>
            </div>
          </div>
          {this.workflowDiagram}
        </div>
        <div className={footerClassName}>
          <BaseActionButton
            isEnabled={!!this.typeName && !!this.categoryId && !!this.basedOn}
            isGrow
            className="text extra-large scale-blue-theme submit-form-btn"
            title={submit}
            onClick={this.onDialogSubmit}
          />
        </div>
      </Dialog>
    )
  }

  private get workflowDiagram(): JSX.Element {
    if (!this.selectedBasedType) {
      return null
    }

    const {
      inspectionFrequency,
      inspectionOptions,
      workflowSteps,
      isAutoActivationEnabled,
    } = this.selectedBasedType

    return (
      <>
        <div className="col x-center my12">
          <span className="text extra-large bold mb10">{workflow}</span>
        </div>
        <div className="col mb20">
          <div className="pb10 row overflow-auto">
            <div className="row x-center">
              <PermitWorkflowDiagram
                workflowSteps={workflowSteps}
                isAutoActivationEnabled={isAutoActivationEnabled}
                inspectionFrequency={inspectionFrequency}
                inspectionFrequencyType={
                  inspectionOptions?.inspectionFrequencyType
                }
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  @action.bound
  private onDialogSubmit() {
    this.props.store.createCustomPermitType(
      this.typeName,
      this.categoryId,
      this.selectedBasedType,
    )
  }

  @action.bound
  private onNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.typeName = event.target.value
  }

  @action.bound
  private onNameReset() {
    this.typeName = EMPTY_STRING
  }

  @action.bound
  private onCategoryChanged(event: React.ChangeEvent<HTMLSelectElement>) {
    this.categoryId = event.target.value
  }

  @action.bound
  private onBasedChanged(event: React.ChangeEvent<HTMLSelectElement>) {
    this.basedOn = event.target.value
  }

  @computed
  private get selectedBasedType(): PermitType {
    return this.availableTypes.find(({ type }) => this.basedOn === type)
  }

  @computed
  private get availableTypes(): PermitType[] {
    return this.props.store.permitTypes.filter(t => !t.isMaterialTransfer) || []
  }
}
