import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import { IXerProjectInfo } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'

interface IProps {
  xerProject: IXerProjectInfo
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class XerProjectRow extends React.Component<IProps> {
  public render() {
    const { name, activitiesCount } = this.props.xerProject
    return (
      <div className="row mb5 pointer" onClick={this.onCheckboxClick}>
        <div className="row y-center no-flex-children">
          <Checkbox isChecked={this.isProjectChecked} />
          {name}
        </div>
        <div className="text large light">
          {Localization.translator.xActivities(activitiesCount)}
        </div>
      </div>
    )
  }

  private get isProjectChecked(): boolean {
    const { chosenXerProjectId } = this.props.projectScheduleUploadStore

    return chosenXerProjectId === this.props.xerProject.id
  }

  @action.bound
  private onCheckboxClick() {
    const { projectScheduleUploadStore, xerProject } = this.props

    projectScheduleUploadStore.chooseXerProjectId(xerProject.id)
  }
}
