import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import { HGap } from '~/client/src/shared/components/Layout'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { CalendarEventEntityType } from '~/client/src/shared/models/CalendarEvent'
import {
  MINUTES_IN_HOUR,
  ONE_MINUTE_HEIGHT_PX,
} from '~/client/src/shared/stores/ui/CalendarView.store'

import DesktopCalendarStore from '../DesktopCalendar.store'
import DesktopCalendarTotalBarItem from '../DesktopCalendarTotalBarItem'

const HOUR_HEIGHT_PX = ONE_MINUTE_HEIGHT_PX * MINUTES_IN_HOUR

// localization: translated

interface IProps {
  store: DesktopCalendarStore
  setVerticalTotalBarRef(ref: HTMLDivElement): void
  entityType?: CalendarEventEntityType
}

@observer
export default class VerticalTotalBar extends React.Component<IProps> {
  public render() {
    const { shouldShowFullOffloadingEquipmentBar } = this.props.store
    return (
      <div
        className={classList({
          collapsed: !shouldShowFullOffloadingEquipmentBar,
          'desktop-calendar-view-vertical-total-bar no-grow bl-light-grey':
            true,
        })}
      >
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    )
  }

  private renderHeader() {
    const {
      visibleTotalBarItems,
      shouldShowFullOffloadingEquipmentBar,
      toggleOffloadingEquipmentBar,
      events,
    } = this.props.store

    const title =
      this.props.entityType === CalendarEventEntityType.Delivery
        ? Localization.translator.deliveriesEnEquipmentNeedsPerHour
        : Localization.translator.forms

    return (
      <div
        className={classList({
          'desktop-calendar-view-vertical-total-bar-header': true,
          'bb-light-grey col y-between px5': true,
        })}
      >
        <div className="collapse-menu">
          {shouldShowFullOffloadingEquipmentBar ? (
            <Icons.OffloadingEquipmentMenuRight
              onClick={toggleOffloadingEquipmentBar}
            />
          ) : (
            <Icons.OffloadingEquipmentMenuLeft
              onClick={toggleOffloadingEquipmentBar}
            />
          )}
        </div>
        <div className="text center light large">
          {shouldShowFullOffloadingEquipmentBar && title}
        </div>

        <div className="row pb5 y-end">
          <div className="total-item-width no-grow">
            <div className="text bold large center">
              {Localization.translator.totalX(events.length)}
            </div>
          </div>
          {shouldShowFullOffloadingEquipmentBar && (
            <>
              {this.renderLeftArrow()}
              {visibleTotalBarItems.map((item, idx) => (
                <div key={idx} className="total-item-width no-grow">
                  <div className="text bold large center">{item.title}</div>
                </div>
              ))}
              {this.renderRightArrow()}
            </>
          )}
        </div>
      </div>
    )
  }

  private renderLeftArrow() {
    const { currentTotalBarPage, setPrevTotalPage } = this.props.store
    if (currentTotalBarPage <= 0) {
      return <HGap size="16" />
    }

    return (
      <div className="no-grow">
        <Icon
          className="arrow-icon"
          icon={IconNames.CHEVRON_LEFT}
          onClick={setPrevTotalPage}
        />
      </div>
    )
  }

  private renderRightArrow() {
    const { currentTotalBarPage, setNextTotalPage, maxTotalPage } =
      this.props.store
    if (currentTotalBarPage >= maxTotalPage) {
      return <HGap size="16" />
    }

    return (
      <div className="no-grow">
        <Icon
          className="arrow-icon"
          icon={IconNames.CHEVRON_RIGHT}
          onClick={setNextTotalPage}
        />
      </div>
    )
  }

  private renderContent() {
    const { setVerticalTotalBarRef, store } = this.props
    const hours = store.dayHours.filter(h => h < 24)

    return (
      <div
        className="desktop-calendar-view-vertical-total-bar-content px5"
        ref={setVerticalTotalBarRef}
      >
        {hours.map(this.renderTotalRow)}
      </div>
    )
  }

  private renderTotalRow = (hour: number, rowIndex: number) => {
    const { visibleTotalBarItems, shouldShowFullOffloadingEquipmentBar } =
      this.props.store

    const totalHourDeliveriesQty = this.getEventsQtyByFilters(hour)
    const isHighlighted =
      totalHourDeliveriesQty > 0 &&
      this.maxHourDeliveryQty === totalHourDeliveriesQty

    return (
      <div
        style={{ height: HOUR_HEIGHT_PX }}
        className="bb-light-grey row y-center"
        key={rowIndex}
      >
        <div className="total-item-width no-grow">
          <div className="text center large bold">
            <span
              className={classList({
                highlighted: isHighlighted,
              })}
            >
              {totalHourDeliveriesQty}
            </span>
          </div>
        </div>

        <HGap size="16" />

        {shouldShowFullOffloadingEquipmentBar &&
          visibleTotalBarItems.map((item, idx) => (
            <div key={idx} className="total-item-width no-grow">
              <div className="text center large">
                {this.getEventsQtyByFilters(hour, item)}
              </div>
            </div>
          ))}

        <HGap size="16" />
      </div>
    )
  }

  private getEventsQtyByFilters(
    hour: number,
    totalBarItem?: DesktopCalendarTotalBarItem,
  ) {
    const { events, startDate } = this.props.store
    return events.filter(event => {
      return (
        event.isInHour(startDate, hour) &&
        (!totalBarItem || totalBarItem.isEventRelatedTo(event))
      )
    }).length
  }

  @computed
  private get maxHourDeliveryQty() {
    const { dayHours } = this.props.store
    const displayedDayHours = dayHours.filter(hour => hour < 24)
    return Math.max(
      ...displayedDayHours.map(hour => this.getEventsQtyByFilters(hour)),
    )
  }
}
