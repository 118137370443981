import commonRoutes, {
  bareRoutes,
  projectBased,
} from '../../shared/constants/commonRoutes'

const desktopRoutes = {
  ...commonRoutes,

  PROJECT_SETUP: projectBased(bareRoutes.PROJECT_SETUP),
  USER_PROFILE: projectBased('/projectSetup?page=edit-profile'),
  MAP_SETUP: projectBased('/projectSetup?page=map-setup'),
  MATERIALS: projectBased('/materials'),
  PHOTOS: projectBased('/photos'),
  ANALYTICS: projectBased('/analytics'),
  NOTIFICATIONS_DETAILS: projectBased('/notifications/:notificationId'),
}

export default desktopRoutes
