import { action, computed, observable } from 'mobx'

import {
  IHistoryItem,
  ILastUpdatedItem,
  IProjectHistory,
  IProjectInput,
} from '~/client/graph'
import { ISaveProjectHistoryMutation } from '~/client/graph/operations/generated/ProjectHistory.generated'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import setUpPagesNames from '~/client/src/desktop/utils/setUpPagesNames'
import ProjectSetUpPage from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPages'
import ISetUpStep from '~/client/src/desktop/views/ProjectSetUp/components/SetUpSteps/ISetUpStep'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import { IntegrationAppName } from '~/client/src/shared/enums/IntegrationAppName'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Project from '~/client/src/shared/models/Project'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import Guard from '~/client/src/shared/utils/Guard'

import desktopRoutes from '../../constants/desktopRoutes'
import ISaveProjectSuccessOptions from '../../interfaces/ISaveProjectSuccessOptions'

const {
  DEFAULT,

  EDIT_PROFILE,
  NOTIFICATION_SETTINGS,

  USER_DIRECTORY,
  COMPANY_DIRECTORY,
  USER_TAGS,

  PROJECT_DETAILS,
  PROJECT_CREATION,
  MAP_SETUP,
  LOCATION_CONTROLS,
  PRESENTATION_MODE,
  INTEGRATIONS,
  ANALYTICS,
  MATERIALS_UPLOAD,

  FORM_WORKFLOWS,
  FORM_NOTIFICATIONS,

  DELIVERY_WORKFLOWS,
  DELIVERY_LOCATIONS,
  DELIVERY_CARDS,
  DELIVERY_NOTIFICATIONS,

  SCHEDULE_UPLOAD,
  SCHEDULE_COLUMNS,

  MOBILE_CONFIGURATION,
  ON_TIME_THRESHOLDS,
  QR_SCANNERS_SETUP,

  TEAM_ASSIGNMENT,
  APPS_CONNECTION,
} = ProjectSetUpPage
const setUpSteps = new Set([
  PROJECT_DETAILS,
  PROJECT_CREATION,
  SCHEDULE_UPLOAD,
  SCHEDULE_COLUMNS,
  MOBILE_CONFIGURATION,
  USER_DIRECTORY,
  TEAM_ASSIGNMENT,
  PRESENTATION_MODE,
  DELIVERY_WORKFLOWS,
  MAP_SETUP,
  ON_TIME_THRESHOLDS,
  QR_SCANNERS_SETUP,
  APPS_CONNECTION,
  LOCATION_CONTROLS,
  DELIVERY_NOTIFICATIONS,
  FORM_WORKFLOWS,
  FORM_NOTIFICATIONS,
  COMPANY_DIRECTORY,
  USER_TAGS,
  DELIVERY_LOCATIONS,
  DELIVERY_CARDS,
  INTEGRATIONS,
  ANALYTICS,
  MATERIALS_UPLOAD,
])

const shouldHideLeftPanelSteps = new Set([
  MAP_SETUP,
  PROJECT_CREATION,
  FORM_WORKFLOWS,
])

export default class ProjectSetUpPageStore {
  @observable public isSetUpPagesPanelHidden: boolean = false

  public get isHistoryLoading() {
    return this.state.loading.get(e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY)
  }

  private get projectHistory() {
    return this.state.projectHistory
  }

  public readonly usersSettingsPages: ISetUpStep[]
  public readonly projectSettingsPages: ISetUpStep[]
  public readonly analyticsSettingsPages: ISetUpStep[]
  public readonly scheduleSettingsPages: ISetUpStep[]
  public readonly deliveriesSettingsPages: ISetUpStep[]
  public readonly formsSettingsPages: ISetUpStep[]
  public readonly scheduleLabsSettingsPages: ISetUpStep[]
  public readonly scannerSettingsPages: ISetUpStep[]
  public readonly integrationSettingsPages: ISetUpStep[]
  public readonly materialsSettingsPages: ISetUpStep[]

  @observable private _page: ProjectSetUpPage = DEFAULT

  public readonly accountSettingsPages: ISetUpStep[]
  public constructor(
    private eventsStore: DesktopEventStore,
    private common: DesktopCommonStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {
    Guard.againstUndefined(eventsStore, 'eventsStore')
    Guard.againstUndefined(common, 'common')

    this.integrationSettingsPages = [
      {
        page: INTEGRATIONS,
        getTitle: () => Localization.translator.integrations,
        getIcon: Icons.Integrations,
      },
    ]

    this.projectSettingsPages = [
      {
        page: PROJECT_DETAILS,
        getTitle: () => Localization.translator.projectDetails,
        getIcon: Icons.ProjectDetailsSettings,
      },
      {
        page: MAP_SETUP,
        getTitle: () => Localization.translator.mapSetup,
        getIcon: Icons.Sitemap,
      },
      {
        page: LOCATION_CONTROLS,
        getTitle: () => Localization.translator.locationControls,
        getIcon: Icons.Location,
      },
      {
        page: PRESENTATION_MODE,
        getTitle: () => Localization.translator.presentationMode,
        getIcon: Icons.Presentation,
      },
    ]
    this.materialsSettingsPages = [
      {
        page: MATERIALS_UPLOAD,
        getTitle: () => Localization.translator.materialsUpload,
        getIcon: Icons.Upload,
      },
    ]
    this.formsSettingsPages = [
      {
        page: FORM_WORKFLOWS,
        getTitle: () => Localization.translator.formWorkflows,
        getIcon: Icons.GeneralForm,
      },
      {
        page: FORM_NOTIFICATIONS,
        getTitle: () => Localization.translator.formNotifications,
        getIcon: Icons.Bell,
      },
    ]
    this.usersSettingsPages = [
      {
        page: USER_DIRECTORY,
        getTitle: () => Localization.translator.userDirectory,
        getIcon: TagIcon.User,
      },
      {
        page: COMPANY_DIRECTORY,
        getTitle: () => Localization.translator.companyDirectory,
        getIcon: Icons.CompanyCompact,
      },
      {
        page: USER_TAGS,
        getTitle: () => Localization.translator.userTags,
        getIcon: Icons.Tag,
      },
    ]
    this.scheduleSettingsPages = [
      {
        page: SCHEDULE_UPLOAD,
        getTitle: () => Localization.translator.scheduleUpload,
        getIcon: Icons.Upload,
        isActivityUploadRelated: true,
      },
      {
        page: SCHEDULE_COLUMNS,
        getTitle: () => Localization.translator.scheduleColumns,
        getIcon: Icons.Gantt,
        isActivityUploadRelated: true,
      },
    ]
    this.deliveriesSettingsPages = [
      {
        page: DELIVERY_WORKFLOWS,
        getTitle: () => Localization.translator.deliveryWorkflows,
        getIcon: Icons.Truck,
      },
      {
        page: DELIVERY_LOCATIONS,
        getTitle: () => Localization.translator.deliveryLocations,
        getIcon: TagIcon.Zone,
      },
      {
        page: DELIVERY_CARDS,
        getTitle: () => Localization.translator.deliveryCards,
        getIcon: Icons.HorizontalListLines,
      },
      {
        page: DELIVERY_NOTIFICATIONS,
        getTitle: () => Localization.translator.deliveryNotifications,
        getIcon: Icons.Bell,
      },
    ]
    this.accountSettingsPages = [
      {
        page: EDIT_PROFILE,
        getTitle: () => Localization.translator.editProfile,
        getIcon: Icons.StarFilledRounded,
      },
      {
        page: NOTIFICATION_SETTINGS,
        getTitle: () => Localization.translator.notificationPreferences,
        getIcon: Icons.Bell,
      },
    ]
    this.scheduleLabsSettingsPages = [
      {
        page: MOBILE_CONFIGURATION,
        getTitle: () => Localization.translator.mobileConfiguration,
        getIcon: Icons.Mobile,
        isActivityUploadRelated: true,
      },
      {
        page: ON_TIME_THRESHOLDS,
        getTitle: () => Localization.translator.onTimeThresholds,
        getIcon: Icons.Clock,
        isActivityUploadRelated: true,
      },
    ]
    this.scannerSettingsPages = [
      {
        page: QR_SCANNERS_SETUP,
        getTitle: () => Localization.translator.qrScannersSetup,
        getIcon: Icons.UserSetupPrimary,
      },
    ]
    this.analyticsSettingsPages = [
      {
        page: ANALYTICS,
        getTitle: () => Localization.translator.analyticsSetting,
        getIcon: Icons.Home,
      },
    ]
  }

  public async saveCurrentProjectHistory() {
    this.state.projectHistory = await this.saveProjectHistory(
      this.projectHistory,
    )
  }

  public async saveProjectHistory(
    projectHistory: IProjectHistory,
    shouldCompleteRequest?: boolean,
  ): Promise<IProjectHistory> {
    const input = JSON.parse(JSON.stringify(projectHistory)) as IProjectHistory
    input.lastUpdated?.forEach(lu => delete lu.updateInfo.schedule)
    input.scheduleHistory?.forEach(h => delete h.schedule)
    input.materialsHistory?.forEach(h => delete h.schedule)

    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        e.SAVE_PROJECT_HISTORY,
        input,
        (data: ISaveProjectHistoryMutation) =>
          resolve(data.saveProjectHistory as IProjectHistory),
        reject,
        shouldCompleteRequest,
      )
    })
  }

  public setPageLastUpdated(lastUpdatedItem: ILastUpdatedItem) {
    const newValue = this.lastUpdated.filter(
      i => i.pageName !== lastUpdatedItem.pageName,
    )
    if (lastUpdatedItem.updateInfo) {
      newValue.push(lastUpdatedItem)
    }
    this.projectHistory.lastUpdated = newValue
  }

  public get state(): DesktopInitialState {
    return this.eventsStore.appState
  }

  public get currentPage(): ProjectSetUpPage {
    return this._page
  }

  public set currentPage(value: ProjectSetUpPage) {
    if (Object.values(ProjectSetUpPage).includes(value)) {
      this._page = value
      return
    }
    this._page = DEFAULT
  }

  @action.bound
  public toggleSetUpPagesPanel() {
    this.isSetUpPagesPanelHidden = !this.isSetUpPagesPanelHidden
  }

  public applyPageFromQueryParams(search: string) {
    const queryParams = new URLSearchParams(search)
    this.currentPage = queryParams.get('page') as ProjectSetUpPage
    this.isSetUpPagesPanelHidden = shouldHideLeftPanelSteps.has(
      this.currentPage,
    )
    this.redirectIfNotAllowed()
  }

  public get nextPage(): ProjectSetUpPage {
    if (!this.isSetUpPage(this.currentPage)) {
      return this.currentPage
    }
    const nextPage = this.allProjectSettingsList.find(
      page => +page.page > +this.currentPage,
    )
    return nextPage ? nextPage.page : this.currentPage
  }

  @computed
  private get allProjectSettingsList(): ISetUpStep[] {
    const allProjectSettingsList = [
      ...this.projectSettingsPages,
      ...this.usersSettingsPages,
    ]
    if (this.isIntegrationsEnabled) {
      allProjectSettingsList.push(...this.integrationSettingsPages)
    }
    if (!this.isMaterialsDisabled) {
      allProjectSettingsList.push(...this.materialsSettingsPages)
    }
    if (!this.isFormsDisabled) {
      allProjectSettingsList.push(...this.formsSettingsPages)
    }
    if (!this.isTrackerDisabled) {
      allProjectSettingsList.push(
        ...this.scheduleSettingsPages,
        ...this.scheduleLabsSettingsPages,
      )
    }
    if (this.hasScanAccess) {
      allProjectSettingsList.push(...this.scannerSettingsPages)
    }
    if (!this.isDeliveriesDisabled) {
      allProjectSettingsList.push(...this.deliveriesSettingsPages)
    }
    if (!this.isAnalyticsDisabled) {
      allProjectSettingsList.push(...this.analyticsSettingsPages)
    }

    return allProjectSettingsList
  }

  public get currentUser() {
    return this.state.user
  }

  public get hasScanAccess(): boolean {
    return this.state.userActiveProjectSettings?.hasScanAccess
  }

  public get currentProject(): Project {
    return this.state.activeProject
  }

  public get isAdmin(): boolean {
    return this.state.userActiveProjectSettings?.isAdmin
  }

  public get isScanMaster(): boolean {
    return this.isAdmin || this.state.userActiveProjectSettings?.isScanMaster
  }

  public get lastUpdated(): ILastUpdatedItem[] {
    return this.projectHistory.lastUpdated || []
  }

  public getLastPageUpdate(pageName: string): IHistoryItem {
    return this.lastUpdated.find(u => u.pageName === pageName)?.updateInfo
  }

  public get scheduleHistory(): IHistoryItem[] {
    return this.projectHistory.scheduleHistory || []
  }

  public get materialsHistory(): IHistoryItem[] {
    return this.projectHistory.materialsHistory || []
  }

  public get projectMembersQuantity(): number {
    return this.projectMembersStore.list.length
  }

  public redirectIfNotAllowed() {
    if (
      !this.isSetUpPage(this.currentPage) ||
      this.isAllowedToNavigate(this.currentPage)
    ) {
      return
    }

    let pageIndex = +this.currentPage

    // TODO: rewrite it
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (isNaN(pageIndex)) {
        return this.navigateTo(ProjectSetUpPage.EDIT_PROFILE)
      }

      const page = String(--pageIndex) as ProjectSetUpPage

      if (this.isAllowedToNavigate(page)) {
        return this.navigateTo(page)
      }
    }
  }

  public navigateTo(
    pageToNavigateTo: ProjectSetUpPage,
    additionalParams?: { [key: string]: string },
  ) {
    if (
      pageToNavigateTo !== this.currentPage &&
      (pageToNavigateTo === ProjectSetUpPage.DEFAULT ||
        this.isAllowedToNavigate(pageToNavigateTo))
    ) {
      const searchParams = new URLSearchParams(
        Object.assign(
          { page: pageToNavigateTo },
          additionalParams || {},
        ) as any,
      )

      this.common._displayView(
        `${desktopRoutes.PROJECT_SETUP}?${searchParams.toString()}`,
      )
    }
  }

  public navigateToNextStep() {
    this.navigateTo(this.nextPage)
  }

  public isAllowedToNavigate(page: ProjectSetUpPage): boolean {
    if (
      page === ProjectSetUpPage.EDIT_PROFILE ||
      page === ProjectSetUpPage.NOTIFICATION_SETTINGS ||
      page === ProjectSetUpPage.PROJECT_CREATION
    ) {
      return true
    }

    const dependencies = this.pagesDependencies[page] || []
    const areDependenciesCompleted = dependencies.every(dependency =>
      this.isPageCompleted(dependency),
    )

    const isPageAllowed =
      this.isSetUpPage(page) &&
      this.allowedProjectSettingsList.some(item => item.page === page)
    return areDependenciesCompleted && isPageAllowed
  }

  private get allowedProjectSettingsList(): ISetUpStep[] {
    return this.allProjectSettingsList.filter(
      setting =>
        this.isActivityUploadAvailable || !setting.isActivityUploadRelated,
    )
  }

  public getProjectSetUpPageName(page: ProjectSetUpPage): string {
    if (!Object.keys(setUpPagesNames).includes(page)) {
      return page as string
    }
    return setUpPagesNames[page]
  }

  public isSetUpPage(page: ProjectSetUpPage): boolean {
    return setUpSteps.has(page)
  }

  public isPageCompleted(page: ProjectSetUpPage): boolean {
    const pageName = this.getProjectSetUpPageName(page)
    const { user, activeProject } = this.state

    const accountSettingsCompleted = user?.setUpFinished || {}
    const projectSettingsCompleted = activeProject.setUpFinished
    return (
      !!accountSettingsCompleted[pageName] ||
      projectSettingsCompleted?.includes(pageName)
    )
  }

  public setCurrentPageAsCompletedOnProject(
    filename?: string,
    scheduleId?: string,
  ): Promise<any> {
    if (!setUpSteps.has(this.currentPage)) {
      return Promise.resolve()
    }

    const pageName = this.getProjectSetUpPageName(this.currentPage)

    const setUpFinished = this.currentProject.setUpFinished || []
    if (!setUpFinished.includes(pageName)) {
      setUpFinished.push(pageName)
    }
    this.currentProject.setUpFinished = setUpFinished

    const { id, email, firstName = null, lastName = null } = this.state.user

    this.setPageLastUpdated({
      pageName,
      updateInfo: {
        updatedAt: Date.now(),
        fileName: filename || null,
        scheduleId: scheduleId || null,
        by: { id, email, firstName, lastName },
      },
    })

    return this.saveCurrentProject().then(() =>
      this.saveCurrentProjectHistory(),
    )
  }

  public async saveCurrentProject(projectInput?: IProjectInput) {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        e.SAVE_PROJECT,
        projectInput || this.currentProject,
        { onSuccessCb: resolve } as ISaveProjectSuccessOptions,
        reject,
      )
    })
  }

  // if page doesn't have dependings list it is available always
  private get pagesDependencies() {
    return {
      [SCHEDULE_UPLOAD]: [PROJECT_DETAILS],
      [SCHEDULE_COLUMNS]: [SCHEDULE_UPLOAD],
      [MOBILE_CONFIGURATION]: [SCHEDULE_UPLOAD],
      [USER_DIRECTORY]: [PROJECT_DETAILS],
      [COMPANY_DIRECTORY]: [PROJECT_DETAILS],
      [USER_TAGS]: [PROJECT_DETAILS],
      [PRESENTATION_MODE]: [PROJECT_DETAILS],
      [DELIVERY_WORKFLOWS]: [PROJECT_DETAILS],
      [DELIVERY_LOCATIONS]: [PROJECT_DETAILS],
      [DELIVERY_CARDS]: [PROJECT_DETAILS],
      [MAP_SETUP]: [PROJECT_DETAILS],
      [DELIVERY_NOTIFICATIONS]: [PROJECT_DETAILS],
      [LOCATION_CONTROLS]: [PROJECT_DETAILS],
    }
  }

  public get isTrackerDisabled(): boolean {
    return this.state.isTrackerDisabled
  }

  public get isDeliveriesDisabled(): boolean {
    return this.state.isDeliveriesDisabled
  }

  public get isAnalyticsDisabled(): boolean {
    return this.state.isAnalyticsDisabled
  }

  public get isIntegrationsEnabled(): boolean {
    return (
      this.eventsStore.appState.isIntegrationEnabled(
        IntegrationAppName.MATURIX,
      ) ||
      this.eventsStore.appState.isIntegrationEnabled(
        IntegrationAppName.CONCRETE_DIRECT,
      )
    )
  }

  public get isActivityUploadAvailable(): boolean {
    return this.state.userActiveProjectSettings?.isActivityUploadAvailable
  }

  public get isFormsDisabled(): boolean {
    return this.state.isFormsDisabled
  }

  public get isMaterialsDisabled(): boolean {
    return this.state.isMaterialsDisabled
  }
}
