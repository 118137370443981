/* eslint-disable zoomin/scss-import-last */
import 'core-js/es'
import '~/client/src/shared/styles.scss'
import '~/client/src/shared/tones.scss'

import * as React from 'react'

import * as Sentry from '@sentry/browser'
import { initializeApp } from 'firebase/app'
import { render } from 'react-dom'

import Container from '~/client/src/desktop/container'
import DesktopRoutes from '~/client/src/desktop/desktop.routes'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import App from '../shared/App'
import Config from '../shared/Config'
import AppProvider from '../shared/components/AppProvider'
import Localization from '../shared/localization/LocalizationManager'
import { beforeSend } from '../shared/utils/Sentry'
import ThemeMode from '../shared/utils/ThemeModeManager'

const eventsToHideInLog = [e.COLLECT_TELEMETRY, e.NO_EFFECT]

function startApp() {
  if (Config.ENVIRONMENT.toLowerCase() !== 'development') {
    Sentry.init({
      beforeSend,
      dsn: 'https://c4509968fb074001bfefc1902752cee6@sentry.io/1819303',
      environment: Config.ENVIRONMENT,
      release: `StruxHub-Desktop@${Config.VERSION}`,
      attachStacktrace: true,
    })
  }

  Localization.init()

  new App(Config).configure()

  const app = initializeApp(Config.FIREBASE_CONFIG)
  const isTestEnv = ['qa', 'development'].includes(
    Config.ENVIRONMENT.toLowerCase(),
  )
  const container = new Container(app, isTestEnv)
  const { rootStore } = container
  const { eventsStore } = rootStore

  ThemeMode.init()

  if (isTestEnv) {
    window.stores = rootStore
    window.eventsLog = []

    eventsStore.addPostEventCallback(({ event }: EventContext) => {
      const [type, ...args] = event
      if (eventsToHideInLog.includes(type)) {
        return
      }
      window.eventsLog.push({ type, args })

      console.groupCollapsed(type)

      console.log(...args)

      console.groupEnd()
    })
  }

  function renderUi() {
    render(
      <AppProvider rootStore={rootStore}>
        <DesktopRoutes />
      </AppProvider>,
      document.getElementById('app'),
    )
  }

  eventsStore.dispatchN([
    [e.START_ALL, renderUi],
    [e.LISTEN_TO_DEPLOYMENT_VERSION],
  ])
}

Config.READY.then(startApp)
