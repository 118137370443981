import * as React from 'react'

import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'

// localization: no display text to translate

interface IProps {
  attribute: LocationAttributeBase
}

export default class ZoneTitle extends React.Component<IProps> {
  public render() {
    const { color, name } = this.props.attribute

    return (
      <div className="text extra-large uppercase center" style={{ color }}>
        {name}
      </div>
    )
  }
}
