import React from 'react'

import { inject, observer } from 'mobx-react'

import DeliveryNotificationActions from '~/client/src/desktop/components/DeliveryNotificationActions/DeliveryNotificationActions'
import NotificationEntityName from '~/client/src/shared/components/NotificationEntityName'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Flag from '~/client/src/shared/models/Flag'
import BaseNotification from '~/client/src/shared/models/Notification'
import Rfi from '~/client/src/shared/models/Rfi'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import {
  isActivityType,
  isDeleteType,
  isDeliveryType,
  isFlagType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'

// translated

interface IProps {
  notification: BaseNotification
  activitiesStore?: ActivitiesStore
  deliveriesStore?: DeliveriesStore
  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  messagesStore?: MessagesStore
  scheduleCommentsStore?: ScheduleCommentsStore
  zonesStore?: ZonesStore
  gatesStore?: GatesStore
  statusUpdatesStore?: StatusUpdatesStore
  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  companiesStore?: CompaniesStore
}

@inject(
  'flagsStore',
  'rfisStore',
  'scheduleCommentsStore',
  'activitiesStore',
  'deliveriesStore',
  'zonesStore',
  'gatesStore',
  'statusUpdatesStore',
  'offloadingEquipmentsStore',
  'deliveryVehicleTypesStore',
  'messagesStore',
  'companiesStore',
)
@observer
export default class NotificationDetailedInformation extends React.Component<IProps> {
  public render() {
    return (
      <div className="col full-height y-between">
        {this.renderNotificationDetailsHeader()}
        {this.renderNotificationDetailsContent()}
      </div>
    )
  }

  private renderNotificationDetailsHeader() {
    const {
      activitiesStore,
      notification,
      deliveriesStore,
      zonesStore,
      gatesStore,
      deliveryVehicleTypesStore,
      offloadingEquipmentsStore,
      companiesStore,
    } = this.props
    const { type, activityObjectId, entityId } = notification

    switch (true) {
      case isDeleteType(type):
        return <div />
      case isDeliveryType(type):
        const delivery = deliveriesStore.byId.get(entityId)
        if (!delivery) {
          return <div />
        }
        const {
          zone: zoneId,
          gate: gateId,
          truckSize,
          offloadingEquipments,
          isDone,
          company,
        } = delivery
        const zone = zonesStore.getString(zoneId, isDone)
        const gate = gatesStore.getString(gateId, isDone)
        const companyName = companiesStore.getCompanyNameById(company)
        const truck = deliveryVehicleTypesStore.getAttributeString(
          truckSize,
          isDone,
        )
        const equipment = offloadingEquipmentsStore.getString(
          offloadingEquipments,
          isDone,
        )

        return (
          <div className="row text bold large">
            <div
              className="large ellipsis text no-grow bold mr30 w-max-content"
              style={{ maxWidth: '120px' }}
            >
              {companyName}
            </div>
            <div className="large text bold">
              {zone}, {gate}, {truck}, {equipment}
            </div>
          </div>
        )
      case isActivityType(type):
        const activity = activitiesStore.byId.get(activityObjectId)
        const sequenceNumber =
          activitiesStore.listItemIdToNumberMap[activityObjectId]
        const activityId = activity?.formattedId(sequenceNumber)

        return (
          <div className="row text bold large">
            {activity && `${activity.name} ${activityId}`}
          </div>
        )
      default:
        return
    }
  }

  private renderNotificationDetailsContent() {
    const {
      notification,
      flagsStore,
      rfisStore,
      scheduleCommentsStore,
      messagesStore,
    } = this.props
    const { type, entityId } = notification

    switch (true) {
      case isDeleteType(type):
        return <div />

      case isDeliveryType(type):
        return this.renderDeliveryNotificationActions()

      case isStatusUpdateType(type):
        return this.renderStatusUpdateLabels()

      case isFlagType(type):
        const flag = flagsStore.byId.get(entityId) || Flag.none
        const flagMessage = messagesStore.list.find(
          m => m.threadId === flag.threadId && !!m.text,
        )
        const flagCaption = Flag.getDisplayType(flag.type)
        return (
          <>
            <div className="text capitalize bold large">
              {flagCaption && flagCaption + ':'}
            </div>
            <div className="text no-bold ellipsis large">
              {flagMessage && flagMessage.text}
            </div>
          </>
        )

      case isRFIType(type):
        const rfi = rfisStore.byId.get(entityId) || Rfi.none
        const rfiMessage = messagesStore.list.find(
          m => m.threadId === rfi.threadId && !!m.text,
        )
        const rfiCaption = Rfi.getDisplayType(rfi.type)
        return (
          <>
            <div className="text large capitalize bold">
              {rfiCaption && rfiCaption + ':'}
            </div>
            <div className="text large no-bold ellipsis">
              {rfiMessage && rfiMessage.text}
            </div>
          </>
        )

      case isScheduleCommentType(type):
        const scheduleComment =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        const messageScheduleComment = messagesStore.list.find(
          m => m.threadId === scheduleComment.threadId && !!m.text,
        )
        return (
          <div className="text large no-bold ellipsis">
            {messageScheduleComment && messageScheduleComment.text}
          </div>
        )

      default:
        return <div />
    }
  }

  private renderDeliveryNotificationActions() {
    const { notification, messagesStore, deliveriesStore } = this.props
    const { type, entityId } = notification
    const delivery = deliveriesStore.byId.get(entityId)
    if (!delivery) {
      return <div />
    }

    const messages = messagesStore.list.filter(
      m => m.threadId === delivery.threadId && !!m.text,
    )

    // get most up-to-date message per thread
    const [message] = messages.sort((m1, m2) => m2.createdAt - m1.createdAt)

    if (isDeliveryType(type) && !isDeleteType(type)) {
      return (
        <>
          <div className="text large no-bold ellipsis">
            {message && message.text}
          </div>
          {this.renderDeliveryActions()}
          <NotificationEntityName notification={notification} />
        </>
      )
    }
    return <div />
  }

  private renderDeliveryActions() {
    const { notification } = this.props

    return (
      <div className="row y-start x-start mb5 delivery-states-line">
        <DeliveryNotificationActions
          notification={notification}
          isViewButtonHidden={true}
        />
      </div>
    )
  }

  private renderStatusUpdateLabels() {
    const { notification, statusUpdatesStore } = this.props
    const { entityId } = notification
    const statusUpdate =
      statusUpdatesStore.byId.get(entityId) || StatusUpdate.none

    const { manpower, percentComplete, plannedPercentComplete } = statusUpdate

    return (
      <>
        <div className="row text no-bold large">
          <div className="mr10 mw100 bold text large">
            {Localization.translator.manpower}
          </div>
          {this.statusUpdateColumn(Localization.translator.actual, manpower)}
        </div>
        <div className="row text no-bold large">
          <div className="mr10 mw100 bold text large no-white-space-wrap">
            {Localization.translator.PComplete}
          </div>
          {this.statusUpdateColumn(
            Localization.translator.planned,
            plannedPercentComplete,
          )}
          {this.statusUpdateColumn(
            Localization.translator.actual,
            percentComplete,
          )}
        </div>
      </>
    )
  }

  private statusUpdateColumn(title: string, value: number) {
    return (
      <div className="row mw100 mr20">
        <div>{title}</div>
        <div className="row x-end">{value}</div>
      </div>
    )
  }
}
