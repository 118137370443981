import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import FileInputBase from '~/client/src/shared/components/FileInput/FileInput'
import IFileInputProps from '~/client/src/shared/components/FileInput/IFileInputProps'
import FileType from '~/client/src/shared/enums/FileType'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'

import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'

import '~/client/src/shared/components/FileInput/FileInput.scss'

// localization: no display text to translate

interface IProps extends IFileInputProps {
  eventsStore?: DesktopEventStore
}

@inject('eventsStore')
@observer
export default class DesktopFileInput extends FileInputBase<IProps> {
  @action.bound
  protected showFullscreenPreview(event) {
    this.stopPropagation(event)
    const { value, isReadonly, eventsStore, name } = this.props

    if (value) {
      const file = { fileUrl: value, fileType: FileType.Image, fileName: name }
      eventsStore.dispatch(SHOW_FULLSCREEN_PREVIEW, [file], 0)
    } else if (!isReadonly) {
      this.promptForFile()
    }
  }
}
