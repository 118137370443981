import React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopperModifiers,
  Position,
  Radio,
  Switch,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { AvatarSize } from '~/client/src/shared/components/Avatar/Avatar'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import SearchBar from '~/client/src/shared/components/UsersDirectory/components/SearchBar/SearchBar'
import { ISavedViewSettings } from '~/client/src/shared/enums/ProjectSpecificUserProps'
import { SavedViewEditableFields } from '~/client/src/shared/enums/SavedViewEditableFields'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CustomWorkflowsFilter from '~/client/src/shared/types/CustomWorkflowFilter'
import { NOOP } from '~/client/src/shared/utils/noop'

import DesktopCustomWorkflowsFiltersStore from '../../CustomWorkflowsFilters/DesktopCustomWorkflowsFilters.store'
import SavedViewFilters from './SavedViewFilters'

import '../SavedViews.scss'

interface ISavedViewsProps {
  className?: string

  desktopCustomWorkflowsFiltersStore?: DesktopCustomWorkflowsFiltersStore
}

enum SavedViewsSections {
  Shown = 'shown',
  Hidden = 'hidden',
}

enum SavedViewActions {
  duplicate = 'duplicate',
  delete = 'delete',
}

enum SavedViewsPopups {
  none = 'none',
  filters = 'filters',
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

const SAVED_VIEWS_LIST_PRESET_ITEM_ID_PREFIX = 'saved-views-list-item-preset'
const PRESET_SAVED_VIEWS_COUNT = 1

const SortableItem = SortableElement(({ item }) => (
  <div className="sortable-item vertical-align-top h40 px6">{item}</div>
))

const SortableList = SortableContainer(
  ({ items, setHoveringSection, isSortDisabled }) => {
    const { presetItems, shownItems, hiddenItems } = items

    return (
      <div className="col">
        {/* Shown items */}
        <div
          onMouseEnter={setHoveringSection.bind(null, SavedViewsSections.Shown)}
        >
          <div className="row text large bold h40 mx16">
            {Localization.translator.listSections.shown}
          </div>
          <div className="col">
            {presetItems.map((item, index) => (
              <SortableItem
                key={`preset-saved-view-${index}`}
                index={index}
                disabled={true}
                item={item}
              />
            ))}
          </div>
          <div className="col">
            {shownItems.map((item, index) => (
              <SortableItem
                key={`saved-view-${index}`}
                index={presetItems?.length + index}
                disabled={isSortDisabled}
                item={item}
              />
            ))}
          </div>
        </div>
        {/* Hidden items */}
        <div
          className="col"
          onMouseEnter={setHoveringSection.bind(
            null,
            SavedViewsSections.Hidden,
          )}
        >
          <div className="row text large bold h40 mx16">
            {Localization.translator.listSections.hidden}
          </div>
          <div className="col">
            {hiddenItems.map((item, index) => (
              <SortableItem
                key={`hidden-saved-view-${index}`}
                index={presetItems?.length + shownItems?.length + index}
                item={item}
              />
            ))}
          </div>
        </div>
      </div>
    )
  },
)

@observer
export default class SavedViewsDialog extends React.Component<ISavedViewsProps> {
  @observable private searchKey: string = ''
  @observable private hoveringSection: SavedViewsSections =
    SavedViewsSections.Shown
  @observable private openPopupSavedViewId: string = null
  @observable private openPopup: SavedViewsPopups = SavedViewsPopups.none

  private get store(): DesktopCustomWorkflowsFiltersStore {
    return this.props.desktopCustomWorkflowsFiltersStore
  }

  public render() {
    const { className } = this.props
    const {
      toggleAddSavedViewDialog,
      closeSavedFilters,
      getFiltersMapByCustomFilterId,
      getSelectedFilterCountByCustomFilterId,
      getGroupByCaptionByCustomFilterId,
      getSavedViewById,
    } = this.store

    const isFiltersPopupOpen =
      this.openPopupSavedViewId && this.openPopup === SavedViewsPopups.filters

    if (isFiltersPopupOpen) {
      const item = getSavedViewById(this.openPopupSavedViewId)
      return (
        <div
          className={classList({
            'view-dialog saved-views-dialog view-dialog-details': true,
            [className]: !!className,
          })}
        >
          <div className="row header bb-light-cool-grey">
            <div className="row pl4">
              <span className="col align-start y-center w48 h48 pa8 no-grow">
                <Icons.SavedFilterRight
                  className="row pointer"
                  onClick={this.closeSavedViewFiltersCount}
                />
              </span>
              <div className="inline-block text large bold line-40 ellipsis center pl8 mr50">
                {item?.name}
              </div>
            </div>
          </div>
          <SavedViewFilters
            groupBy={getGroupByCaptionByCustomFilterId(
              this.openPopupSavedViewId,
            )}
            filtersMap={getFiltersMapByCustomFilterId(
              this.openPopupSavedViewId,
            )}
            filtersCount={getSelectedFilterCountByCustomFilterId(
              this.openPopupSavedViewId,
            )}
            className="saved-views-details overflow-hidden-scrollbar overflow-y-auto"
          />
        </div>
      )
    }

    return (
      <div
        className={classList({
          'view-dialog saved-views-dialog': true,
          [className]: !!className,
        })}
      >
        <div className="row header">
          <div className="row pl4">
            <span className="col align-start y-center w48 h48 pa8">
              <Icons.Cross
                className="row pointer"
                onClick={closeSavedFilters}
              />
            </span>
            <div className="text bold extra-large center">
              {Localization.translator.savedViews}
            </div>
            <div className="col text blue large x-end px12 py10">
              <span className="pointer" onClick={toggleAddSavedViewDialog}>
                {Localization.translator.createNewView}
              </span>
            </div>
          </div>
        </div>
        {this.renderSearchBar()}
        {this.renderColumnNames()}
        <div
          className="view-dialog-content"
          onScroll={this.closeSavedViewsPopup.bind(this)}
        >
          {this.renderSavedViewsList()}
        </div>
      </div>
    )
  }

  private renderColumnNames(): JSX.Element {
    return (
      <div className="saved-views-list-header row vertical-align-top h40 mx6">
        <div className="inline-block text large bold light line-40 ellipsis mw160 no-grow pl8 ml80">
          {Localization.translator.savedViewsDialog.columns.viewName}
        </div>
        <div className="inline-block text large bold light line-40 ellipsis mw100 no-grow pl8">
          {Localization.translator.savedViewsDialog.columns.filterCount}
        </div>
        <div className="inline-block text large bold light line-40 ellipsis mw160 no-grow pl8">
          {Localization.translator.savedViewsDialog.columns.access}
        </div>
        <div className="inline-block text large bold light line-40 ellipsis mw200 no-grow pl8 mr80">
          {Localization.translator.savedViewsDialog.columns.creator}
        </div>
      </div>
    )
  }

  private renderSearchBar(): JSX.Element {
    return (
      <SearchBar
        className="mx16 my8 ba-light-grey"
        value={this.searchKey}
        onChange={this.onChangeSearchKey}
        onReset={this.onChangeSearchKey.bind(this, '')}
        onTagClick={NOOP}
        customPlaceholder={Localization.translator.savedViewsDialog.searchHint}
      />
    )
  }

  private onChangeSearchKey = (newSearchKey: string) => {
    this.searchKey = newSearchKey
  }

  private setHoveringSection = (section: SavedViewsSections) => {
    this.hoveringSection = section
  }

  private renderSavedViewsList(): JSX.Element {
    return (
      <div className="col pb12 saved-views-list text large grey-10">
        <div className="col full-height sortable-list-holder">
          <SortableList
            items={this.items}
            onSortEnd={this.onSortEnd}
            axis="y"
            setHoveringSection={this.setHoveringSection}
            isSortDisabled={this.isSortDisabled}
            distance={2}
          />
        </div>
      </div>
    )
  }

  @computed
  private get isSortDisabled(): boolean {
    return !!this.searchKey
  }

  @computed
  private get items() {
    const {
      getSavedViewById,
      customFilters: savedViews = [],
      savedViewsSettings,
    } = this.store
    let items = {
      hiddenItems: [],
      shownItems: [],
      presetItems: [],
    }

    if (
      Localization.translator.all_items
        .toLowerCase()
        .includes(this.searchKey.toLowerCase())
    ) {
      items.presetItems.push(
        new CustomWorkflowsFilter(
          null,
          Localization.translator.all_items,
          true,
          null,
          null,
          null,
          null,
          null,
        ),
      )
    }

    if (savedViewsSettings?.length) {
      items = savedViewsSettings.reduce((map, savedViewSettings) => {
        const savedView = getSavedViewById(
          savedViewSettings.workflowCustomFilterId,
        )

        if (
          !savedView ||
          !savedView.name.toLowerCase().includes(this.searchKey.toLowerCase())
        ) {
          return map
        }

        switch (true) {
          case savedViewSettings.isHidden:
            map.hiddenItems.push(savedView)
            break

          default:
            map.shownItems.push(savedView)
            break
        }

        return map
      }, items)
    } else {
      items = savedViews
        .slice()
        .filter(savedView =>
          savedView.name.toLowerCase().includes(this.searchKey.toLowerCase()),
        )
        .reduce((map, item) => {
          map.shownItems.push(item)

          return map
        }, items)
    }

    return {
      hiddenItems: this.renderItems(
        items.hiddenItems,
        SavedViewsSections.Hidden,
      ),
      shownItems: this.renderItems(items.shownItems, SavedViewsSections.Shown),
      presetItems: this.renderItems(
        items.presetItems,
        SavedViewsSections.Shown,
        true,
      ),
    }
  }

  private onSelectSavedView = (savedViewId: string) => {
    const {
      isEditingSavedView,
      selectCustomFilterById,
      applyCustomFilter,
      resetAllWithoutClose,
    } = this.store

    if (isEditingSavedView) {
      return
    }

    resetAllWithoutClose(!!savedViewId)

    if (savedViewId) {
      selectCustomFilterById(savedViewId)
      applyCustomFilter()
    }
  }

  private onSavedViewNameClick(id: string) {
    return () => {
      const { isEditingSavedView, startSavedViewEditing } = this.store

      if (isEditingSavedView) {
        return
      }

      startSavedViewEditing(id, SavedViewEditableFields.Name)
    }
  }

  private onSavedViewNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.onSavedViewNameChange(event)
  }

  private onSavedViewNameInputBlur = () => {
    this.store.finishSavedViewEditing()
  }

  private onChangeSavedViewAccess = (id: string) => {
    const { isEditingSavedView, startSavedViewEditing } = this.store

    if (isEditingSavedView) {
      return
    }

    startSavedViewEditing(id, SavedViewEditableFields.IsPublic)
    this.store.onSavedViewIsPublicChange(!this.store.editableSavedViewIsPublic)

    this.closeSavedViewAccessMenu()
  }

  private closeSavedViewAccessMenu = () => {
    this.store.finishSavedViewEditing()
  }

  private handleSavedViewDuplication = async (savedViewId: string) => {
    const { duplicateCustomFilterById, startSavedViewEditing } = this.store
    const duplicateId = await duplicateCustomFilterById(savedViewId)

    startSavedViewEditing(duplicateId, SavedViewEditableFields.Name)
  }

  private onSavedViewFiltersCountClick(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      event?.stopPropagation()

      if (this.openPopupSavedViewId === id) {
        return
      }

      this.openSavedViewsPopup(id, SavedViewsPopups.filters)
    }
  }

  @action.bound
  private closeSavedViewFiltersCount() {
    this.openPopupSavedViewId = null
  }

  private openSavedViewsPopup = (
    savedViewId: string,
    popup: SavedViewsPopups,
  ) => {
    this.openPopupSavedViewId = savedViewId
    this.openPopup = popup
  }

  private closeSavedViewsPopup = (event: Event) => {
    // TODO: Check if this is necessary
    event?.stopPropagation()

    this.openPopupSavedViewId = null
    this.openPopup = SavedViewsPopups.none
  }

  private renderItems(
    items: CustomWorkflowsFilter[],
    section: SavedViewsSections,
    isPresetSavedView: boolean = false,
  ) {
    const {
      appliedCustomFilterId,
      isEditingSavedView,
      editableSavedViewId,
      editableSavedViewField,
      editableSavedViewName,
      isActiveUserAdmin,
    } = this.store

    return items.map(item => {
      const isPopupOpen = this.openPopupSavedViewId === item.id

      const isEditingItemName =
        isEditingSavedView &&
        editableSavedViewId === item.id &&
        editableSavedViewField === SavedViewEditableFields.Name

      const isSharingDisabled =
        !isActiveUserAdmin ||
        (!isEditingItemName &&
          isEditingSavedView &&
          editableSavedViewId === item.id &&
          editableSavedViewField === SavedViewEditableFields.IsPublic)

      const shouldHighlightItem =
        isPopupOpen && this.openPopup !== SavedViewsPopups.none

      return (
        <div
          key={
            item.id || `${SAVED_VIEWS_LIST_PRESET_ITEM_ID_PREFIX}-${item.name}`
          }
          className={classList({
            'saved-views-list-item row': true,
            highlight: shouldHighlightItem,
          })}
        >
          <Icon
            className={classList({
              'dragging-trigger no-grow pa10': true,
              'not-visible': this.isSortDisabled || isPresetSavedView,
              'grab-cursor': !isPresetSavedView,
            })}
            icon={IconNames.DRAG_HANDLE_VERTICAL}
            size={20}
          />
          <Radio
            readOnly={true}
            checked={item.id === appliedCustomFilterId}
            className="small no-grow"
            value={item.id}
            onClick={this.onSelectSavedView.bind(this, item.id)}
            disabled={section === SavedViewsSections.Hidden}
          />
          <div
            className={classList({
              'saved-views-list-item-name inline-block ellipsis mw160 no-grow pl8':
                true,
              'text-cursor editable': !isPresetSavedView,
              editing: isEditingItemName,
            })}
            onClick={
              isPresetSavedView ? NOOP : this.onSavedViewNameClick(item.id)
            }
          >
            {isEditingItemName ? (
              <StruxhubInput
                value={editableSavedViewName}
                isRequiredTextHidden={true}
                isLabelHidden={true}
                isHelperTextHidden={true}
                autoFocus={true}
                onChange={this.onSavedViewNameChange}
                onBlur={this.onSavedViewNameInputBlur}
              />
            ) : (
              <span className="saved-views-list-item-name-text inline-block ellipsis">
                {item.name}
              </span>
            )}
          </div>
          <div
            className={classList({
              'saved-views-list-item-filters-count inline-block mw100 no-grow pl8 static':
                true,
              pointer: !isPresetSavedView,
            })}
            onClick={this.onSavedViewFiltersCountClick(item.id)}
          >
            <span className="saved-views-list-item-filters-count-text">
              {this.store.getSelectedFilterCountByCustomFilterId(item.id)}
            </span>
          </div>
          <div
            className={classList({
              'saved-views-list-item-access inline-block mw160 h40 no-grow pl8 relative':
                true,
              'pointer editable': !isPresetSavedView,
              'default-cursor': isPresetSavedView,
            })}
          >
            <span className="saved-views-list-item-access-content">
              <Switch
                className="primary-blue-switch no-outline-container"
                checked={item.isPublic}
                onChange={this.onChangeSavedViewAccess.bind(null, item.id)}
                disabled={isPresetSavedView || isSharingDisabled}
              />
            </span>
          </div>
          <div className="inline-block ellipsis mw200 no-grow pl8">
            <UserProfilePreview
              user={this.store.getUserForCustomFilter(item)}
              isUserNameClickable={false}
              isBriefInfoHidden={true}
              withEmployeeId={false}
              showBoldName={false}
              avatarSize={AvatarSize.Tiny}
              className="inline-flex vertical-align-middle"
              contentContainerClassName="text grey-10 mw160"
            />
          </div>
          <div
            className={classList({
              'inline-block mw40 h40 no-grow pa10': true,
              pointer: !isPresetSavedView,
              'not-allowed': isPresetSavedView,
            })}
            onClick={
              isPresetSavedView
                ? NOOP
                : this.toggleItemVisibility.bind(null, item.id)
            }
          >
            {isPresetSavedView && (
              <Icons.EyeViewNoFill
                className="icon-grey opacity4"
                disabled={true}
              />
            )}
            {!isPresetSavedView && section === SavedViewsSections.Shown && (
              <Icons.EyeViewNoFill
                className="icon-grey show-hide-icon"
                disabled={isPresetSavedView}
              />
            )}
            {!isPresetSavedView && section === SavedViewsSections.Hidden && (
              <Icons.EyeHide className="icon-grey opacity6 show-hide-icon" />
            )}
          </div>
          <Popover
            autoFocus={false}
            enforceFocus={false}
            position={Position.BOTTOM_RIGHT}
            modifiers={popoverPopperModifiers}
            usePortal={true}
            content={this.renderViewActions(item.id)}
          >
            <div
              className={classList({
                'inline-block mw40 h40 no-grow pa10 relative': true,
                pointer: !isPresetSavedView,
                'not-allowed': isPresetSavedView,
              })}
            >
              <Icons.MoreHorizontal
                className={classList({
                  'icon-grey': true,
                  opacity3: isPresetSavedView,
                })}
                disabled={isPresetSavedView}
              />
            </div>
          </Popover>
        </div>
      )
    })
  }

  private renderViewActions = (itemId: string) => {
    return (
      <div className="saved-views-popup saved-views-popup-list">
        {Object.values(SavedViewActions).map(savedViewAction => {
          return (
            <div
              key={savedViewAction}
              className={`saved-views-popup-list-item pointer row x-start y-center ${Classes.POPOVER_DISMISS}`}
              onClick={this.performSavedViewAction.bind(
                this,
                savedViewAction,
                itemId,
              )}
            >
              {this.getSavedViewActionCaption(savedViewAction)}
            </div>
          )
        })}
      </div>
    )
  }

  private performSavedViewAction = (
    action: SavedViewActions,
    savedViewId: string,
  ) => {
    switch (action) {
      case SavedViewActions.delete:
        this.store.setDeletableCustomFilterId(savedViewId)
        return

      case SavedViewActions.duplicate:
        this.handleSavedViewDuplication(savedViewId)
        return
    }
  }

  private toggleItemVisibility = (savedViewId: string) => {
    const { savedViewsSettings, reorderSavedViewSettings } = this.store

    const collection = savedViewsSettings.slice()

    let firstHiddenSavedViewIndex = collection.findIndex(
      savedViewSettings => savedViewSettings.isHidden,
    )
    const savedViewCurrentIndex = collection.findIndex(
      savedViewSettings =>
        savedViewSettings.workflowCustomFilterId === savedViewId,
    )

    if (firstHiddenSavedViewIndex === -1) {
      // There are no hidden elements in the collection
      // So, this adjustment will make savedViewCurrentIndex < firstHiddenSavedViewIndex
      // to get savedViewNewIndex = last index of the collection during next code instruction
      firstHiddenSavedViewIndex = collection.length
    }

    const savedViewNewIndex =
      savedViewCurrentIndex < firstHiddenSavedViewIndex
        ? firstHiddenSavedViewIndex - 1
        : firstHiddenSavedViewIndex

    const newSavedViewSettings: ISavedViewSettings = {
      ...collection[savedViewCurrentIndex],
    }
    newSavedViewSettings.isHidden = !newSavedViewSettings.isHidden

    reorderSavedViewSettings(
      collection,
      savedViewCurrentIndex,
      savedViewNewIndex,
      newSavedViewSettings,
    )
  }

  private getSavedViewActionCaption = (action: SavedViewActions): string => {
    switch (action) {
      case SavedViewActions.delete:
        return Localization.translator.delete

      case SavedViewActions.duplicate:
        return Localization.translator.duplicate

      default:
        throw new Error(`${action} is unhandled`)
    }
  }

  private onSortEnd = ({ oldIndex, newIndex }) => {
    const { savedViewsSettings, reorderSavedViewSettings } = this.store

    const collection = savedViewsSettings.slice()

    oldIndex = oldIndex - PRESET_SAVED_VIEWS_COUNT
    newIndex = newIndex - PRESET_SAVED_VIEWS_COUNT

    const newSavedViewSettings: ISavedViewSettings = { ...collection[oldIndex] }

    switch (this.hoveringSection) {
      case SavedViewsSections.Hidden:
        newSavedViewSettings.isHidden = true
        break

      case SavedViewsSections.Shown:
        newSavedViewSettings.isHidden = false
        break
    }

    reorderSavedViewSettings(
      collection,
      oldIndex,
      newIndex,
      newSavedViewSettings,
    )
  }
}
