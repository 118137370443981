import * as React from 'react'

import { Button, Classes, Dialog } from '@blueprintjs/core'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'
import SheetRow from './SheetRow'

import './ChooseSheetDialog.scss'

interface IProps {
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class ChooseSheetDialog extends React.Component<IProps> {
  public render() {
    const store = this.props.projectScheduleUploadStore
    const {
      isChooseSheetDialogShown,
      resetInitialValue,
      uploadedSheets,
      chosenSheetName,
      mergeWithExistingData,
      applyChooseSheetDialog,
    } = store

    return (
      <Dialog
        isOpen={isChooseSheetDialogShown}
        title={Localization.translator.chooseSheet}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className="choose-sheet-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="text large light mb20">
            {Localization.translator.uploadScheduleDescriptions.chooseSheet}
          </div>
          {uploadedSheets.map(sheet => (
            <SheetRow
              key={sheet.name}
              sheet={sheet}
              projectScheduleUploadStore={store}
            />
          ))}
          <div className="text large light mb20" />
          <div
            className="row mb5 pointer"
            onClick={this.onRemoveExistingDataClick}
          >
            <div className="row y-center no-flex-children">
              <Checkbox isChecked={mergeWithExistingData} />
              {Localization.translator.uploadScheduleDescriptions.mergeData}
            </div>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <Button
            text={Localization.translator.cancelUpload}
            className="choose-project-dialog-btn white-btn"
            onClick={resetInitialValue}
          />
          <Button
            text={Localization.translator.done}
            className={classList({
              'choose-project-dialog-btn blue-btn': true,
              disabled: !chosenSheetName,
            })}
            onClick={applyChooseSheetDialog}
          />
        </div>
      </Dialog>
    )
  }

  @action.bound
  private onRemoveExistingDataClick() {
    const { projectScheduleUploadStore } = this.props
    projectScheduleUploadStore.setMergeWithExistingData(
      !projectScheduleUploadStore.mergeWithExistingData,
    )
  }
}
