import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import FilterLabel from '~/client/src/desktop/components/Filters/SavedFilters/components/FilterLabel'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'

import '../../SavedFilters.scss'

// localization: no display text to translate

interface IProps {
  customFilter: CustomActivityListFilter | CustomDeliveryListFilter
  getEntitiesCountByCustomFilter: (
    filter: CustomActivityListFilter | CustomDeliveryListFilter,
  ) => number
  selectCustomFilterById: (id: string) => void
  editCustomFilter: () => void
}

@observer
export default class SavedFilterLabel extends React.Component<IProps> {
  @observable private activityCountByCustomFilter: number = 0

  public constructor(props: IProps) {
    super(props)

    this.activityCountByCustomFilter = props.getEntitiesCountByCustomFilter(
      props.customFilter,
    )
  }

  public render() {
    const { customFilter } = this.props
    return (
      <div className="label">
        <div className="name-cell nowrap">
          <FilterLabel customFilter={customFilter} />
        </div>
        <div className="row count-cell">{this.activityCountByCustomFilter}</div>
        <div className="row">
          <Icon
            className="arrow-icon"
            icon={IconNames.CHEVRON_RIGHT}
            onClick={this.editClicked}
          />
        </div>
      </div>
    )
  }

  @action.bound
  private editClicked() {
    const {
      customFilter: { id },
      selectCustomFilterById,
      editCustomFilter,
    } = this.props
    selectCustomFilterById(id)
    editCustomFilter()
  }
}
