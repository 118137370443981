import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Events, scroller } from 'react-scroll'

import AnnouncementParsedContent from '~/client/src/shared/components/AnnouncementEditionForm/components/AnnouncementParsedContent'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import FileAttachment from '~/client/src/shared/components/WorkflowCard/FileAttachment'
import WorkflowCardLocationLabel from '~/client/src/shared/components/WorkflowCard/LocationLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Announcement from '~/client/src/shared/models/Announcement'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './AnnouncementViewDialog.scss'

const siteAnnouncement = 'Site announcement'
const noText = 'No text'
const secondMs = 1000

interface IProps {
  announcements: Announcement[]
  displayedAnnouncement: Announcement
  onClose: () => void
  onDelete?: (announcement: Announcement) => void
  onEdit?: (announcement: Announcement) => void

  projectDateStore?: ProjectDateStore
  state?: InitialState
  shouldScroll?: boolean
  maxScrollTime?: number
}

const CONTAINER_ID = 'ContainerElementID'
const TOP_ELEMENT_ID = 'myScrollToElementTop'
const BOTTOM_ELEMENT_ID = 'myScrollToElement'

@inject('projectDateStore', 'state')
@observer
export default class AnnouncementViewDialog extends React.Component<IProps> {
  @observable private activeAnnouncementIndex: number
  @observable private shouldShowMenu: boolean = false
  @observable private isBeingScrolled: boolean = false
  private containerElement: HTMLDivElement = null

  public constructor(props: IProps) {
    super(props)
    this.activeAnnouncementIndex = props.announcements.findIndex(
      sn => sn.id === props.displayedAnnouncement?.id,
    )
  }

  public componentDidMount(): void {
    if (this.props.shouldScroll) {
      this.isBeingScrolled = false
      this.scrollCycle()
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      prevProps.displayedAnnouncement?.id !==
      this.props.displayedAnnouncement?.id
    ) {
      this.activeAnnouncementIndex = this.props.announcements.findIndex(
        sn => sn.id === this.props.displayedAnnouncement?.id,
      )
      this.isBeingScrolled = false
    }
  }

  private get currentAnnouncement(): Announcement {
    return this.activeAnnouncementIndex !== undefined
      ? this.props.announcements[this.activeAnnouncementIndex]
      : this.props.displayedAnnouncement
  }

  public render(): JSX.Element {
    return (
      <div className="fixed-modal-container dark-background">
        <div className="announcement-dialog fixed-modal-base col">
          {this.renderHeader()}
          <div className="row announcement-dialog-content">{this.content}</div>
        </div>
      </div>
    )
  }

  private onMount = () => {
    this.scrollCycle()
  }

  private renderHeader(): JSX.Element {
    const {
      onClose,
      projectDateStore: { getPronounWithMonthDayAndTimeString },
    } = this.props
    const { startDate, dateToAppear } = this.currentAnnouncement
    return (
      <div className="announcement-dialog-header row pa12">
        <Icons.Cross
          className="cross-icon no-grow pointer mx10"
          onClick={onClose}
        />
        <div className="row x-center no-grow">
          <div className="bold header text no-white-space-wrap mr15">
            {siteAnnouncement}
          </div>
          {this.renderItemsNavigation()}
        </div>
        <div className="row no-grow">
          <div className="header no-white-space-wrap">
            {getPronounWithMonthDayAndTimeString(dateToAppear || startDate)}
          </div>
          {this.isAdmin && (
            <MenuCloser closeMenu={this.closeMenu} isOpen={this.shouldShowMenu}>
              <Icons.MoreHorizontal
                className="header-icon no-grow pointer mx10"
                onClick={this.toggleMenu}
              />
              {this.shouldShowMenu && this.renderActionMenu()}
            </MenuCloser>
          )}
        </div>
      </div>
    )
  }

  private setRef = (ref: HTMLDivElement) => {
    if (ref) {
      this.containerElement = ref
    }
  }

  private get content(): JSX.Element {
    const { attachments, getDisplayedName, location } = this.currentAnnouncement
    const locations = location ? [location] : []

    return (
      <div
        ref={this.setRef}
        id={CONTAINER_ID}
        className="br-palette-brand-lighter full-height overflow-auto pa20"
      >
        <div id={TOP_ELEMENT_ID} className="row">
          <Icons.Announcement className="announcement-title-icon no-grow pr6 col" />
          <div className="col announcement-title">
            {getDisplayedName() || `[${noText}]`}
          </div>
        </div>
        <div className="row no-grow y-start px12 pb12 mt15">
          <AnnouncementParsedContent
            announcement={this.currentAnnouncement}
            isContentExpanded={true}
            onMount={this.onMount}
          />
        </div>
        <div className="mx12 files-n-toolbar-container">
          {attachments.map((attachment, idx) => {
            return (
              <FileAttachment
                key={idx}
                attachment={attachment}
                shouldShowPdfPreview={true}
                isPdfPreviewSmall={true}
              />
            )
          })}
        </div>
        <div id={BOTTOM_ELEMENT_ID} className="pt5 nowrap pl20 row">
          <div className="mr5 no-grow">Location:</div>
          <WorkflowCardLocationLabel
            locations={locations}
            isOneColor={false}
            shouldShowAsTag={true}
          />
        </div>
      </div>
    )
  }

  private toggleMenu = (event?: React.MouseEvent): void => {
    event.stopPropagation()
    this.shouldShowMenu = !this.shouldShowMenu
  }

  private closeMenu = (): void => {
    this.shouldShowMenu = false
  }

  private onDeleteAnnouncement = (event: React.MouseEvent): void => {
    event.stopPropagation()
    this.props.onDelete(this.currentAnnouncement)
  }

  private onEditAnnouncement = (event: React.MouseEvent): void => {
    event.stopPropagation()
    this.props.onEdit(this.currentAnnouncement)
  }

  private renderActionMenu(): JSX.Element {
    return (
      <div className="action-menu absolute col brada10 pa10 bg-white text bold">
        <div className="row pointer" onClick={this.onEditAnnouncement}>
          <Icons.Edit className="no-grow option mr10" />
          <div>{Localization.translator.edit_verb}</div>
        </div>
        <div className="row pointer" onClick={this.onDeleteAnnouncement}>
          <Icons.Delete className="no-grow option mr10" />
          <div>{Localization.translator.delete}</div>
        </div>
      </div>
    )
  }

  private renderItemsNavigation(): JSX.Element {
    if (
      this.activeAnnouncementIndex === -1 &&
      this.props.announcements.length <= 1
    ) {
      return
    }

    return (
      <div className="no-grow no-white-space-wrap navigation-wrapper">
        <Icons.ArrowBack
          className="navigation-icon"
          onClick={this.goToPreviousItem}
        />
        <span className="navigation-title">{this.navigationTitle}</span>
        <Icons.ArrowForward
          className="navigation-icon"
          onClick={this.goToNextItem}
        />
      </div>
    )
  }

  private get navigationTitle(): string {
    return `${this.activeAnnouncementIndex + 1} / ${
      this.props.announcements.length
    }`
  }

  @action.bound
  private goToPreviousItem(): void {
    if (this.activeAnnouncementIndex > 0) {
      this.activeAnnouncementIndex--
    } else {
      this.activeAnnouncementIndex = this.props.announcements.length - 1
    }
  }

  @action.bound
  private goToNextItem(): void {
    if (this.activeAnnouncementIndex + 1 < this.props.announcements.length) {
      this.activeAnnouncementIndex++
    } else {
      this.activeAnnouncementIndex = 0
    }
  }

  private get isAdmin(): boolean {
    return this.props.state.userActiveProjectSettings?.isAdmin
  }

  private scrollCycle = (): void => {
    if (this.isBeingScrolled || !this.containerElement) {
      return
    }
    const { maxScrollTime } = this.props
    const expectedDuration =
      this.containerElement?.children[1].clientHeight * 20
    const duration =
      expectedDuration >= maxScrollTime
        ? maxScrollTime - secondMs
        : expectedDuration

    this.isBeingScrolled = true
    const goToBottom = new Promise<void>(resolve => {
      Events.scrollEvent.register('end', () => {
        resolve()
        Events.scrollEvent.remove('end')
      })

      scroller.scrollTo(BOTTOM_ELEMENT_ID, {
        duration,
        delay: 0,
        smooth: true,
        isDynamic: true,
        ignoreCancelEvent: true,
        containerId: CONTAINER_ID,
      })
    })

    goToBottom.then(() =>
      scroller.scrollTo(TOP_ELEMENT_ID, {
        duration: 0,
        delay: 0,
        smooth: true,
        containerId: CONTAINER_ID,
      }),
    )
  }
}
