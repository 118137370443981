import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { FilterType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import ActivityFiltersViewStore from './ActivityFiltersView.store'
import ActivityFilterSetupDialog from './components/ActivityFilterSetupDialog'
import ActivityFilterSetupDialogStore from './components/ActivityFilterSetupDialog.store'
import FilterTabCaption from './components/FilterTabCaption'
import FilterTabContent from './components/FilterTabContent'
import MappableFilterTabContent from './components/MappableFilterTabContent'

import './ActivityFilters.scss'

interface IProps {
  activityFiltersStore?: ActivityFiltersStore
  projectSetUpPageStore: ProjectSetUpPageStore

  eventsStore?: DesktopEventStore
  activitiesStore?: ActivitiesStore
  activityCodeTypesStore?: ActivityCodeTypesStore
  activityCodesStore?: ActivityCodesStore
  resourcesStore?: ResourcesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

@inject(
  'activityFiltersStore',
  'eventsStore',
  'activitiesStore',
  'activityCodeTypesStore',
  'activityCodesStore',
  'resourcesStore',
  'userProjectsStore',
  'projectMembersStore',
)
@observer
export default class ActivityFilters extends React.Component<IProps> {
  private readonly store: ActivityFiltersViewStore
  private readonly dialogStore: ActivityFilterSetupDialogStore

  private get loader() {
    return (
      <Loader
        className="full-height col x-center y-center m20"
        hint={Localization.translator.loading}
      />
    )
  }

  public constructor(props: IProps) {
    super(props)

    this.dialogStore = new ActivityFilterSetupDialogStore(
      props.activityFiltersStore,
      props.eventsStore,
      props.activitiesStore,
      props.activityCodeTypesStore,
      props.activityCodesStore,
      props.userProjectsStore,
      props.projectMembersStore,
    )

    this.store = new ActivityFiltersViewStore(
      props.activityFiltersStore,
      props.eventsStore,
      this.dialogStore,
      props.userProjectsStore,
      props.resourcesStore,
    )
  }

  public render() {
    const {
      isLoading,
      isConfirmDeleteFilterDialogShown,
      hideConfirmDeleteFilterDialog,
      customFilterCaptionToRemove,
    } = this.store

    return (
      <div className="setup-form px30">
        <ActivityFilterSetupDialog store={this.dialogStore} />
        <ConfirmDialog
          isOpen={isConfirmDeleteFilterDialogShown}
          loading={isLoading}
          title={Localization.translator.confirmDeletion.toUpperCase()}
          question={Localization.translator.activityFiltersDescriptions.confirmDeletionOfX(
            customFilterCaptionToRemove,
          )}
          onCancelClicked={hideConfirmDeleteFilterDialog}
          onDoneClicked={this.removeCustomFilter}
          doneTitle={Localization.translator.delete}
          className="confirm-items-dialog"
        />
        <div className="setup-form-header">
          {Localization.translator.scheduleFilters.toUpperCase()}
        </div>
        <div className="large light pb15 text">
          {Localization.translator.filters}
        </div>
        {isLoading ? this.loader : this.renderContent()}
      </div>
    )
  }

  private renderContent() {
    const { filterTabNames } = this.store

    return (
      <>
        <div className="bb-light-grey row mt15">
          {filterTabNames.map(name => {
            return (
              <FilterTabCaption
                key={name}
                name={name as FilterType}
                store={this.store}
              />
            )
          })}
          {this.renderAddButton()}
        </div>
        {this.renderActiveTab()}
      </>
    )
  }

  private renderActiveTab() {
    const { activeTabFilterName } = this.store

    switch (activeTabFilterName) {
      case FilterType.Company:
      case FilterType.Building:
      case FilterType.Level:
      case FilterType.Zone:
        return (
          <MappableFilterTabContent
            filterName={activeTabFilterName as FilterType}
            store={this.store}
          />
        )
      default:
        return (
          <FilterTabContent
            filterName={activeTabFilterName}
            store={this.store}
          />
        )
    }
  }

  private renderAddButton() {
    return (
      <div
        className="filter-name-tab-caption add-new-filter pl20"
        onClick={this.addCustomFilter}
      >
        <Icon icon={IconNames.ADD} className="blue-icon mr4" />
        {Localization.translator.createNewFilter}
      </div>
    )
  }

  @action.bound
  private addCustomFilter() {
    const newFilterType = this.store.addCustomFilter()
    this.store.activeTabFilterName = newFilterType
  }

  @action.bound
  private removeCustomFilter() {
    const { customFilterTypeToRemove } = this.store
    this.store.removeCustomFilter(customFilterTypeToRemove)
    this.store.hideConfirmDeleteFilterDialog()
  }
}
