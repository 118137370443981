import * as React from 'react'

import { inject, observer } from 'mobx-react'

import NavBar from '~/client/src/desktop/components/NavBar/NavBar'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import BaseAssignmentsStore from '~/client/src/shared/stores/BaseAssignments.store'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import AnnouncementAssignmentsStore from '~/client/src/shared/stores/domain/AnnouncementAssignments.store'
import AnnouncementFollowingsStore from '~/client/src/shared/stores/domain/AnnouncementFollowings.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CastAssignmentsStore from '~/client/src/shared/stores/domain/CastAssignments.store'
import CastFollowingsStore from '~/client/src/shared/stores/domain/CastFollowings.store'
import ClosureAssignmentsStore from '~/client/src/shared/stores/domain/ClosureAssignments.store'
import ClosureFollowingsStore from '~/client/src/shared/stores/domain/ClosureFollowings.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaturixCastsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixCasts.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import SitePermitAssignmentsStore from '~/client/src/shared/stores/domain/SitePermitAssignments.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import LogisticActionTypes from '../../enums/LogisticsActionTypes'
import ViewModes from '../../enums/ViewModes'
import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'
import LogisticsStore from './Logistics.store'
import LogisticsCalendar from './components/LogisticsCalendar/LogisticsCalendar'
import DesktopLogisticsCalendarStore from './components/LogisticsCalendar/LogisticsCalendar.store'
import LogisticsDialogs from './components/LogisticsDialogs'
import LogisticsHeaderBar from './components/LogisticsHeaderBar/LogisticsHeaderBar'
import LogisticsList from './components/LogisticsList/LogisticsList'
import LogisticsListStore from './components/LogisticsList/LogisticsList.store'
import LogisticsMap from './components/LogisticsMap/LogisticsMap'
import LogisticsMapStore from './components/LogisticsMap/LogisticsMap.store'
import LogisticsResponsibilitiesPanel from './components/LogisticsResponsibilitiesPanel/LogisticsResponsibilitiesPanel'

import './Logistics.scss'

interface IProps {
  eventsStore?: DesktopEventStore
  sitemapsStore?: SitemapsStore
  announcementsStore?: AnnouncementsStore
  projectsStore?: ProjectsStore
  projectDateStore?: ProjectDateStore
  sitePermitsStore?: SitePermitsStore
  locationAttributesStore?: LocationAttributesStore
  closuresStore?: ClosuresStore
  permitTypesStore?: PermitTypesStore
  companiesStore?: CompaniesStore
  deliveriesStore?: DeliveriesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  activitiesStore?: ActivitiesStore
  statusUpdatesStore?: StatusUpdatesStore
  projectMembersStore?: ProjectMembersStore
  maturixCastsStore?: MaturixCastsStore
  sitePermitAssignmentsStore?: SitePermitAssignmentsStore
  closureAssignmentsStore?: ClosureAssignmentsStore
  closureFollowingsStore?: ClosureFollowingsStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  activityFollowingsStore?: ActivityFollowingsStore
  announcementFollowingsStore?: AnnouncementFollowingsStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
  castFollowingsStore?: CastFollowingsStore
  userProjectsStore?: UserProjectsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
  castAssignmentsStore?: CastAssignmentsStore
  calendarPlaceholderStore?: CalendarPlaceholderStore
  globeViewsStore?: GlobeViewsStore
  basemapsStore?: BasemapsStore
  formCategoriesStore?: FormCategoriesStore

  isPermitOnly?: boolean
}

@inject(
  'eventsStore',
  'sitemapsStore',
  'announcementsStore',
  'projectsStore',
  'projectDateStore',
  'sitePermitsStore',
  'locationAttributesStore',
  'closuresStore',
  'permitTypesStore',
  'companiesStore',
  'deliveriesStore',
  'deliveryDetailsStore',
  'activitiesStore',
  'statusUpdatesStore',
  'projectMembersStore',
  'maturixCastsStore',
  'sitePermitAssignmentsStore',
  'closureAssignmentsStore',
  'closureFollowingsStore',
  'deliveryFollowingsStore',
  'activityFollowingsStore',
  'announcementFollowingsStore',
  'sitePermitFollowingsStore',
  'castFollowingsStore',
  'userProjectsStore',
  'deliveryAssignmentsStore',
  'activityAssignmentsStore',
  'announcementAssignmentsStore',
  'castAssignmentsStore',
  'calendarPlaceholderStore',
  'globeViewsStore',
  'basemapsStore',
  'formCategoriesStore',
)
@observer
export default class Logistics extends React.Component<IProps> {
  private readonly store: LogisticsStore
  private readonly logisticsListStore: LogisticsListStore = null
  private readonly logisticsMapStore: LogisticsMapStore = null
  private readonly logisticsCalendarStore: DesktopLogisticsCalendarStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new LogisticsStore(
      props.announcementsStore,
      props.sitePermitsStore,
      props.projectDateStore,
      props.deliveriesStore,
      props.activitiesStore,
      props.statusUpdatesStore,
      props.eventsStore,
      props.sitemapsStore,
      props.globeViewsStore,
      props.closuresStore,
      props.maturixCastsStore,
      props.deliveryDetailsStore,
      props.permitTypesStore,
      props.formCategoriesStore,
      props.locationAttributesStore,
      props.deliveryFollowingsStore,
      props.sitePermitFollowingsStore,
      props.activityFollowingsStore,
      props.closureFollowingsStore,
      props.announcementFollowingsStore,
      props.basemapsStore,
      props.calendarPlaceholderStore,
      props.castFollowingsStore,
      props.companiesStore,
      props.isPermitOnly,
    )

    this.logisticsListStore = new LogisticsListStore(
      this.store,
      props.projectDateStore,
      props.sitePermitsStore,
      props.locationAttributesStore,
      props.companiesStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.deliveryFollowingsStore,
      props.activityFollowingsStore,
      props.announcementFollowingsStore,
      props.sitePermitFollowingsStore,
      props.castFollowingsStore,
      props.closureFollowingsStore,
      props.permitTypesStore,
    )

    this.logisticsMapStore = new LogisticsMapStore(
      this.store,
      props.companiesStore,
      props.deliveriesStore,
      props.sitePermitsStore,
      props.activitiesStore,
    )

    this.logisticsCalendarStore = new DesktopLogisticsCalendarStore(
      this.store,
      this.logisticsListStore,
      props.sitePermitsStore,
      props.calendarPlaceholderStore,
      props.projectDateStore,
      props.companiesStore,
      props.permitTypesStore,
      props.locationAttributesStore,
      props.isPermitOnly,
    )

    this.store.initPostEventCallback()
    this.store.loadLogisticsViewPreferences()
    this.store.ensureCorrectViewMode()
  }

  public componentDidMount(): void {
    const {
      eventsStore: { appState },
    } = this.props
    const { openPermitCreationForm, hideAllDialogs } = this.store

    if (appState.preSelectedPermitTypeId) {
      return openPermitCreationForm()
    }

    if (!appState.currentPresentationPage?.entityKey) {
      return hideAllDialogs()
    }

    const announcement =
      this.logisticsMapStore.orderedAnnouncements[this.announcementEntityNumber]
    if (announcement) {
      this.store.showAnnouncementContent(announcement)
    }
  }

  public componentWillUnmount(): void {
    this.store.clearPostEventCallback()
    this.store.hideAllDialogs()
  }

  public render(): JSX.Element {
    const { projectsStore, projectDateStore, isPermitOnly } = this.props
    const { isLoaderShown } = this.store
    const { activeActionType, selectedInstances, resetActionType } =
      this.logisticsListStore

    return (
      <Layout.View className="logistics">
        <Layout.Header className="layout-navigation">
          <NavBar />
        </Layout.Header>
        <Layout.View className="relative overflow-hidden">
          {activeActionType === LogisticActionTypes.ASSIGN && (
            <div className="permit-side-view">
              <LogisticsResponsibilitiesPanel
                selectedEntities={selectedInstances}
                domainsStores={this.logisticAssignmentsStoreByApp}
                onClose={resetActionType}
              />
            </div>
          )}
          {!isLoaderShown && (
            <LogisticsDialogs
              logisticsStore={this.store}
              logisticsCalendarStore={this.logisticsCalendarStore}
            />
          )}
          <Layout.Header>
            {!isLoaderShown && (
              <LogisticsHeaderBar
                logisticsStore={this.store}
                logisticsListStore={this.logisticsListStore}
                logisticsMapStore={this.logisticsMapStore}
                projectsStore={projectsStore}
                projectDateStore={projectDateStore}
                isPermitOnly={isPermitOnly}
                isMapViewDisabled={this.store.isMapViewDisabled}
              />
            )}
          </Layout.Header>
          <Layout.Content>
            <div className="relative col overflow-hidden multi-grid-container">
              {this.renderView()}
            </div>
          </Layout.Content>
        </Layout.View>
        <Layout.Footer>
          <div />
        </Layout.Footer>
      </Layout.View>
    )
  }

  private get loaderText(): string {
    if (this.props.isPermitOnly) {
      return Localization.translator.loadingFormsData
    }

    return Localization.translator.loadingLogisticsData
  }

  private renderView(): JSX.Element {
    if (this.store.isLoaderShown) {
      return <Loader hint={this.loaderText} />
    }

    const { viewMode, state, isCreationAvailable } = this.store

    switch (viewMode) {
      case ViewModes.Calendar:
        return (
          <LogisticsCalendar
            logisticsStore={this.store}
            store={this.logisticsCalendarStore}
          />
        )
      case ViewModes.List:
        return (
          <LogisticsList
            logisticsStore={this.store}
            store={this.logisticsListStore}
          />
        )
      case ViewModes.Map:
        return (
          <LogisticsMap
            store={this.store}
            logisticsMapStore={this.logisticsMapStore}
            entityOrder={this.announcementEntityNumber}
            shouldShowEntity={!!state.currentPresentationPage?.entityKey}
            isPermitOnly={this.props.isPermitOnly}
            isCreationAvailable={isCreationAvailable}
          />
        )
    }
  }

  private get logisticAssignmentsStoreByApp(): {
    [key: string]: BaseAssignmentsStore
  } {
    return {
      [LogisticItemApp.FORM]: this.props.sitePermitAssignmentsStore,
      [LogisticItemApp.DELIVERY]: this.props.deliveryAssignmentsStore,
      [LogisticItemApp.SCHEDULE]: this.props.activityAssignmentsStore,
      [LogisticItemApp.ANNOUNCEMENT]: this.props.announcementAssignmentsStore,
      [LogisticItemApp.SENSOR]: this.props.castAssignmentsStore,
      [LogisticItemApp.CLOSURE]: this.props.closureAssignmentsStore,
    }
  }

  private get announcementEntityNumber(): number {
    return (
      (this.store.state.currentPresentationPage?.entityOrder || 0) %
      this.logisticsMapStore.orderedAnnouncements.length
    )
  }
}
