import * as React from 'react'

import { inject, observer } from 'mobx-react'

import SimpleGanttView from '~/client/src/desktop/views/SimpleGanttView/SimpleGanttView'
import EmailLinkerForm from '~/client/src/shared/components/EmailLinkerForm/EmailLinkerForm'
import LanguageSelector from '~/client/src/shared/components/LanguageSelector/LanguageSelector'
import SavePassword from '~/client/src/shared/components/SavePassword/SavePassword'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SAVE_PASSWORD_SUCCESS } from '~/client/src/shared/stores/EventStore/eventConstants'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import LocalStorageHelper from '~/client/src/shared/utils/LocalStorageHelper'

import Deliveries from '../Deliveries/Deliveries'

interface IProps {
  auth?: AuthenticationStore
  eventsStore?: EventsStore
}

@inject('auth', 'eventsStore')
@observer
export default class SavePasswordView extends React.Component<IProps> {
  public render() {
    const body = this.props.auth.authEmail ? (
      <SavePassword />
    ) : (
      <div className="col no-grow">
        <h1 className="ma0 text header pb24">
          {Localization.translator.userEmailLinking.linkYourEmail}
        </h1>
        <EmailLinkerForm
          onSkip={this.handleEmailLinkingSkip}
          onComplete={this.handleEmailLinkingComplete}
        />
      </div>
    )

    return (
      <>
        <div className="save-password">
          <div className="save-password-popup">
            <>
              {body}
              {Localization.shouldShowLanguageSelector && (
                <LanguageSelector className="extra-large mt20 primary-blue" />
              )}
            </>
          </div>
        </div>

        {this.background}
      </>
    )
  }

  private handleEmailLinkingComplete = () => {
    this.props.eventsStore.dispatch(SAVE_PASSWORD_SUCCESS)
  }

  private handleEmailLinkingSkip = () => {
    LocalStorageHelper.setUserPreferences(this.appState.user.id, {
      [LocalStorageKey.ShouldSkipEmailLinking]: true,
    })
    this.props.eventsStore.dispatch(SAVE_PASSWORD_SUCCESS)
  }

  private get background() {
    const { isTrackerDisabled } = this.appState
    return isTrackerDisabled ? <Deliveries /> : <SimpleGanttView />
  }

  private get appState() {
    return this.props.eventsStore.appState
  }
}
