import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import MaturixStrengthUnit from '~/client/src/shared/enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '~/client/src/shared/enums/MaturixTemperatureUnit'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import MaturixProject from '~/client/src/shared/models/MaturixProject'
import ConcreteDirectIntegrationStore from '~/client/src/shared/stores/domain/ConcreteDirectStores/ConcreteDirectIntegration.store'
import MaturixProjectsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixProjects.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import { Header } from '../SetupPageLayout/SetupPageLayout'
import SubmitButton from '../SubmitButton'
import ConcreteDirectConfigurationForm from './ConcreteDirectConfigurationForm/ConcreteDirectConfigurationForm'
import IntegrationsSetUpStore from './IntegrationsSetUp.store'

const DONE_BUTTON_WIDTH = 75
const MATURIX_API_TOKEN = 'Maturix API Token'
const MATURIX_SECTION_NAME = 'Maturix'
const CONCRETE_DIRECT_SECTION_NAME = 'Concrete Direct'

interface IIntegrationsWorkspaceSetUp {
  projectSetUpPageStore: ProjectSetUpPageStore

  initProjectId: string

  maturixProjectsStore?: MaturixProjectsStore
  concreteDirectIntegrationStore?: ConcreteDirectIntegrationStore
}

@inject('maturixProjectsStore', 'concreteDirectIntegrationStore')
@observer
export default class IntegrationsSetUp extends React.Component<IIntegrationsWorkspaceSetUp> {
  public sectionsToggleState = observable(new Map<string, boolean>())

  private readonly store: IntegrationsSetUpStore

  private readonly clearPostEventCallback: () => void = NOOP
  public constructor(props: IIntegrationsWorkspaceSetUp) {
    super(props)

    this.store = new IntegrationsSetUpStore(props.maturixProjectsStore)
  }

  public componentDidMount() {
    const { loadProjects, isProjectsReceived: isDataReceived } =
      this.props.maturixProjectsStore
    if (
      this.props.maturixProjectsStore.isIntegrationEnabled &&
      !isDataReceived
    ) {
      loadProjects()
    }
    this.store.setIntegrationDataValues()
  }

  public componentDidUpdate(prevProps: Readonly<IIntegrationsWorkspaceSetUp>) {
    if (prevProps.initProjectId !== this.props.initProjectId) {
      this.store.setIntegrationDataValues()
    }
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    return (
      <div className="relative project-details">
        <Header title={Localization.translator.integrations} />
        {this.props.maturixProjectsStore.isIntegrationEnabled &&
          this.renderSection(MATURIX_SECTION_NAME, this.renderMaturixSection)}
        {this.props.concreteDirectIntegrationStore.isIntegrationEnabled &&
          this.renderSection(
            CONCRETE_DIRECT_SECTION_NAME,
            this.renderConcreteDirectSection,
          )}
      </div>
    )
  }

  private renderConcreteDirectSection = (): JSX.Element => {
    return (
      <ConcreteDirectConfigurationForm
        initProjectId={this.props.initProjectId}
      />
    )
  }

  private renderMaturixSection = (): JSX.Element => {
    const {
      projects,
      activeProjectId,
      temperatureUnit,
      strengthUnit,
      maturixToken,
      isProjectsReceived,
    } = this.props.maturixProjectsStore

    return (
      <div className="setup-form-body">
        {this.renderInputBlock(
          this.store.maturixToken,
          Localization.translator.integrationsSetupDescriptions.invalidToken,
          MATURIX_API_TOKEN,
          this.onMaturixTokenChange,
          this.onMaturixTokenReset,
          this.onMaturixTokenSubmit,
          false,
          maturixToken === this.store.maturixToken &&
            isProjectsReceived &&
            projects.length === 0,
        )}
        {maturixToken && (
          <div className="setup-form-parameter px30">
            <StruxhubSelect
              label={Localization.translator.maturixProjectName}
              value={activeProjectId ?? EMPTY_STRING}
              onChange={this.onMaturixProjectChange}
            >
              <option value={EMPTY_STRING}>-</option>
              {projects.map((value: MaturixProject) => {
                return (
                  <option key={value.id} value={value.id}>
                    {value.projectName}
                  </option>
                )
              })}
            </StruxhubSelect>
          </div>
        )}
        {maturixToken && activeProjectId && (
          <>
            <div className="setup-form-parameter px30">
              <StruxhubSelect
                label={Localization.translator.maturixTemperatureUnit}
                value={temperatureUnit}
                onChange={this.onMaturixTemperatureUnitChange}
              >
                {Object.values(MaturixTemperatureUnit).map(
                  (value: MaturixTemperatureUnit) => {
                    return (
                      <option key={value} value={value}>
                        {Localization.translator.temperatureUnit(value)}
                      </option>
                    )
                  },
                )}
              </StruxhubSelect>
            </div>
            <div className="setup-form-parameter px30">
              <StruxhubSelect
                label={Localization.translator.maturixStrengthUnit}
                value={strengthUnit}
                onChange={this.onMaturixStrengthUnitChange}
              >
                {Object.values(MaturixStrengthUnit).map(
                  (value: MaturixStrengthUnit) => {
                    return (
                      <option key={value} value={value}>
                        {Localization.translator.strengthUnit(value)}
                      </option>
                    )
                  },
                )}
              </StruxhubSelect>
            </div>
          </>
        )}
      </div>
    )
  }

  private onMaturixProjectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { maturixProjectsStore } = this.props
    maturixProjectsStore.saveMaturixProjectSelection(e.target.value)
  }

  private onMaturixTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.store.maturixToken = e.target.value
  }

  private onMaturixTokenReset = () => {
    this.store.maturixToken = ''
  }

  private onMaturixTokenSubmit = () => {
    const { maturixProjectsStore } = this.props
    maturixProjectsStore.saveMaturixTokenForProject(this.store.maturixToken)
  }

  private onMaturixTemperatureUnitChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { maturixProjectsStore } = this.props
    maturixProjectsStore.saveMaturixTemperatureUnitSelection(
      e.target.value as MaturixTemperatureUnit,
    )
  }

  private onMaturixStrengthUnitChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { maturixProjectsStore } = this.props
    maturixProjectsStore.saveMaturixStrengthUnitSelection(
      e.target.value as MaturixStrengthUnit,
    )
  }

  private toggleSection(sectionName: string) {
    this.sectionsToggleState.set(
      sectionName,
      !this.sectionsToggleState.get(sectionName),
    )
  }

  private renderSection(sectionName: string, renderContent: () => JSX.Element) {
    const shouldRenderContent = !this.sectionsToggleState.get(sectionName)
    const iconName = this.sectionsToggleState.get(sectionName)
      ? IconNames.CARET_RIGHT
      : IconNames.CARET_DOWN
    return (
      <>
        <div
          className="setup-form-header text uppercase orange pointer"
          onClick={this.toggleSection.bind(this, sectionName)}
        >
          <Icon className="no-grow title-orange-icon" icon={iconName} />
          {sectionName}
        </div>
        {shouldRenderContent && renderContent()}
      </>
    )
  }

  private renderInputBlock(
    text: string,
    errorMessageText: string,
    label: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onValueReset?: () => void,
    onButtonClick?: () => void,
    isHidden?: boolean,
    isErrorVisible?: boolean,
    isReadOnly?: boolean,
  ) {
    return (
      <div className="row y-center setup-form-parameter px30">
        <StruxhubInput
          label={label}
          isValid={!isErrorVisible}
          isRequired={true}
          value={text}
          onChange={onChange}
          onValueReset={onValueReset}
          validationMessage={isErrorVisible && errorMessageText}
          isReadOnly={isReadOnly}
          isDisabled={isReadOnly}
        />
        {this.renderDoneButton(onButtonClick, isHidden, isErrorVisible)}
      </div>
    )
  }

  private renderDoneButton = (
    clickHandler: () => void,
    isHidden?: boolean,
    isDisabled?: boolean,
  ): JSX.Element => {
    return (
      <SubmitButton
        text={Localization.translator.save}
        width={DONE_BUTTON_WIDTH}
        clickHandler={clickHandler}
        isHidden={isHidden}
        disabled={isDisabled}
        className="no-grow setup-form-controls done-button-container"
      />
    )
  }
}
