import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'

import DesktopActivityPresetLabel from './components/ActivityPresetLabel/DesktopActivityPresetLabel'
import DesktopActivityPresetsSelectorHeader from './components/DesktopActivityPresetsSelectorHeader'

// localization: translated

interface IProps {
  selectedPresetId: string
  presets: ActivityPreset[]
  selectActivityPresetById: (id: string) => void
  editActivityPreset: () => void
  onChange: (event: any) => void
  onReset: () => void
}

@observer
export default class DesktopActivityPresetsSelector extends React.Component<IProps> {
  public render() {
    const { onReset } = this.props
    return (
      <div>
        <div className="row no-flex-children x-between y-center bb-light-grey pb10">
          <DesktopActivityPresetsSelectorHeader onReset={onReset} />
        </div>
        <div className="mb20 no-outline-container">{this.content()}</div>
      </div>
    )
  }

  private content() {
    const { presets } = this.props
    if (!presets.length) {
      return Localization.translator.thereAreNoSavedActivityPresets
    }

    const {
      selectedPresetId,
      selectActivityPresetById,
      editActivityPreset,
      onChange,
    } = this.props

    return (
      <RadioGroup
        className="theme-radio-group"
        onChange={onChange}
        selectedValue={selectedPresetId}
      >
        {presets.map(preset => (
          <Radio key={preset.id} value={preset.id}>
            <DesktopActivityPresetLabel
              activityPreset={preset}
              selectActivityPresetById={selectActivityPresetById}
              editActivityPreset={editActivityPreset}
            />
          </Radio>
        ))}
      </RadioGroup>
    )
  }
}
