import { action, computed, observable } from 'mobx'
import { ViewState } from 'react-map-gl'

import {
  GlobeViewMapType,
  IActivitiesConfigurations,
  IAddressBounds,
  IDeliveryConfigurations,
  IFormsConfigurations,
  IGeoJson2DGeographicCoordinates,
  IGeoJson2DGeographicCoordinatesInput,
  IGlobeViewSpecificItemData,
  IGlobeViewStyle,
  IGlobeViewStyleInput,
  IGlobesSetupSettings,
  ILatLng,
  ILogisticsConfigurations,
  IOrderedSitemap,
} from '~/client/graph'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import MapViewItemBase from '../components/SitemapHelpers/models/MapViewItemBase'
import GlobeView from '../models/GlobeView'
import IGeoPosition from '../models/IGeoPosition'
import LocationBase from '../models/LocationObjects/LocationBase'
import EventsStore from './EventStore/Events.store'
import EventTypes from './EventStore/eventTypes'
import GlobeViewControlStore, { DEFAULT_NAME } from './GlobeViewControl.store'
import GlobeViewsStore from './domain/GlobeViews.store'
import LocationAttributesStore from './domain/LocationAttributes.store'
import SitemapItemsStore from './domain/SitemapItems.store'
import TagsStore from './domain/Tags.store'
import UserProjectsStore from './domain/UserProjects.store'

export default class GlobeViewSetupStore extends GlobeViewControlStore {
  @observable public editableGlobeName: string = ''
  @observable public deletableGlobe: GlobeView = null
  @observable public isAssignGlobeDialogShown: boolean = false

  public constructor(
    eventsStore: EventsStore,
    globeViewsStore: GlobeViewsStore,
    private readonly userProjectsStore: UserProjectsStore,
    sitemapItemsStore: SitemapItemsStore,
    locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
  ) {
    super(
      eventsStore,
      globeViewsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )
  }

  public selectInitialGlobe = (globe?: GlobeView): void => {
    this.selectGlobe(
      globe || this.lastEditedGlobe || this.globeViewsStore.list[0],
    )
  }

  @action.bound
  public toggleAssignGlobeDialog(): void {
    this.isAssignGlobeDialogShown = !this.isAssignGlobeDialogShown
  }

  @action.bound
  public hideAssignGlobeDialog(): void {
    this.isAssignGlobeDialogShown = false
  }

  public selectDeletableGlobe = (globe: GlobeView): void => {
    if (this.globeViewsStore.list.length <= 1) {
      throw Error('Last globe can not be deleted')
    }
    this.deletableGlobe = globe
  }

  public copyGlobe = async (globe: GlobeView): Promise<void> => {
    const copy = globe.getFullCopy()
    copy.id = null

    const id = await this.saveGlobeView(copy)
    this.selectGlobeById(id)
  }

  public deselectDeletableGlobe = (): void => {
    this.deletableGlobe = null
  }

  public saveGlobeViewName = async (): Promise<string> => {
    if (
      !this.editableGlobeName ||
      this.selectedGlobeView.isNameEqual(this.editableGlobeName)
    ) {
      return
    }

    const globeToSave = this.selectedGlobeView.getCopy()
    globeToSave.name = this.editableGlobeName
    return new Promise<string>(resolve => {
      this.globeViewsStore.save(
        globeToSave,
        id => {
          this.saveNewCallback(id)
          resolve(id)
        },
        true,
      )
    })
  }

  @action.bound
  public saveGlobeViewStyle(style: IGlobeViewStyleInput) {
    this.globeViewsStore.saveGlobeViewStyle(style, this.selectedGlobeViewId)
  }

  @action.bound
  public async selectGlobe(globe: GlobeView): Promise<void> {
    this.deselectGlobe()
    if (!globe) {
      return
    }

    this.selectedGlobeViewId = globe.id
    this.editableGlobeName = globe.name || DEFAULT_NAME
    this.setLastEditedGlobeView(globe.id)
  }

  @computed
  private get allLocations() {
    return [
      ...this.locationAttributesStore.buildingsStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.zonesStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.gatesStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.routesStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.offloadingEquipmentsStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.levelsStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.areasStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.stagingsStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.interiorDoorsStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.interiorPathsStore.list.filter(
        b => b.assignedGlobes?.length,
      ),
    ]
  }

  @computed
  public get globeToLocation(): {
    [globeId: string]: LocationBase
  } {
    const map = {}

    this.allGlobeViews.forEach(globe => {
      const location = this.allLocations.find(l => l.isGlobeAssigned(globe.id))

      const chains = location?.getHierarchyChainObjs(
        this.tagsStore.tagStoreByTagTypeMap,
      )

      map[globe.id] = location || null

      if (chains?.length) {
        chains.forEach(chain => {
          map[globe.id] = chain || null
        })
      }
    })

    return map
  }

  @computed
  public get deliveriesAssignedGlobes(): { [filterType: string]: boolean } {
    return this.appState.deliveriesMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get siteAssignedGlobes(): { [filterType: string]: boolean } {
    return this.appState.logisticsMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get formsAssignedGlobes(): { [filterType: string]: boolean } {
    return this.appState.formsMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get activitiesAssignedGlobes(): { [filterType: string]: boolean } {
    return this.appState.activitiesMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @action.bound
  public setLastEditedGlobeView(lastEditedGlobeId: string): void {
    const { userActiveProjectSettings } = this.appState
    const userActiveProjectSettingsDto = userActiveProjectSettings.toDto()

    const globeSettings: IGlobesSetupSettings = {
      lastEditedGlobeId,
    }

    userActiveProjectSettingsDto.globesSetupSettings = globeSettings

    this.userProjectsStore.save([userActiveProjectSettingsDto])
  }

  @action.bound
  public changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.editableGlobeName = event.target.value
  }

  @action.bound
  public changeOrientationLock(): void {
    this.selectedGlobeView.isOrientationLocked =
      !this.selectedGlobeView.isOrientationLocked
    this.saveGlobeView()
  }

  public deleteGlobe = (): void => {
    this.deselectGlobe()
    this.globeViewsStore.removeOne(this.deletableGlobe.id)
    this.deselectDeletableGlobe()
    this.selectGlobe(this.globeViewsStore.list[0])
  }

  public get lastEditedGlobe(): GlobeView {
    const { globesSetupSettings } = this.appState.userActiveProjectSettings

    const lastEditedGlobeId = globesSetupSettings?.lastEditedGlobeId

    if (!lastEditedGlobeId) {
      return null
    }

    if (this.globeViewsStore.byId.has(lastEditedGlobeId)) {
      return this.globeViewsStore.byId.get(lastEditedGlobeId)
    }
  }

  public updateGlobeView = async (
    geoposition?: IGeoPosition,
    bounds?: IGeoJson2DGeographicCoordinatesInput[],
    items?,
    sitemaps?,
    tilesets?,
    style?,
    filledImage?: string,
  ): Promise<void> => {
    const newGlobe = this.selectedGlobeView.getCopy()
    if (geoposition) {
      newGlobe.setGeoposition(geoposition, bounds)
    }

    newGlobe.name = this.editableGlobeName
    if (items) {
      newGlobe.items = items
    }
    if (sitemaps) {
      newGlobe.sitemaps = sitemaps
    }
    if (tilesets) {
      newGlobe.tilesets = tilesets
    }
    if (filledImage) {
      newGlobe.filledImage = filledImage
    }
    if (style) {
      newGlobe.style = style
    }

    this.saveGlobeView(newGlobe, filledImage)
  }

  public createNewPlaceholder = async (
    geoCorners: IGeoJson2DGeographicCoordinatesInput[],
    viewport: ViewState,
    bounds?: IAddressBounds,
    items?: IGlobeViewSpecificItemData[],
    style?: IGlobeViewStyle,
    filledImage?: string,
  ): Promise<string> => {
    const {
      projectAddress,
      activeProject: { id },
    } = this.appState
    const newGlobe = new GlobeView(null, DEFAULT_NAME, id)

    newGlobe.sitemaps = []
    newGlobe.items = []
    newGlobe.bounds = geoCorners?.length
      ? {
          ne: { lat: geoCorners[0].latitude, lng: geoCorners[0].longitude },
          sw: { lat: geoCorners[3].latitude, lng: geoCorners[3].longitude },
        }
      : bounds

    newGlobe.center = viewport
      ? {
          lat: viewport.latitude,
          lng: viewport.longitude,
        }
      : projectAddress.center

    if (filledImage) {
      newGlobe.filledImage = filledImage
    }
    newGlobe.altitude = 0
    newGlobe.bearing = viewport?.bearing || 0
    newGlobe.geoCorners = geoCorners
    newGlobe.pitch = viewport?.pitch || 0
    newGlobe.zoom = viewport?.zoom || projectAddress.zoom
    if (items) {
      newGlobe.items = items
    }

    if (style) {
      newGlobe.style = style
    }

    this.selectedGlobeViewId = null
    this.editableGlobeName = DEFAULT_NAME

    return await this.saveGlobeView(newGlobe)
  }

  @action.bound
  public onSiteGlobeSectionClick(globeId: string) {
    const { configurations } = this.appState.logistics
    if (this.siteAssignedGlobes[globeId]) {
      this.removeGlobeFromConfig(
        configurations,
        globeId,
        e.SAVE_LOGISTICS_CONFIGURATIONS,
      )
    } else {
      this.addGlobeToConfig(
        configurations.maps,
        this.appState.activeProject.id,
        globeId,
        e.SAVE_LOGISTICS_CONFIGURATIONS,
      )
    }
  }

  @action.bound
  public onFormsGlobeSectionClick(globeId: string) {
    const { configurations } = this.appState.forms
    if (this.formsAssignedGlobes[globeId]) {
      this.removeGlobeFromConfig(
        configurations,
        globeId,
        e.SAVE_FORMS_CONFIGURATIONS,
      )
    } else {
      this.addGlobeToConfig(
        configurations.maps,
        this.appState.activeProject.id,
        globeId,
        e.SAVE_FORMS_CONFIGURATIONS,
      )
    }
  }

  @action.bound
  public onDeliveryGlobeSectionClick(globeId: string) {
    const { configurations } = this.appState.delivery
    if (this.deliveriesAssignedGlobes[globeId]) {
      this.removeGlobeFromConfig(
        configurations,
        globeId,
        e.SAVE_DELIVERY_CONFIGURATIONS,
      )
    } else {
      this.addGlobeToConfig(
        configurations.maps,
        this.appState.activeProject.id,
        globeId,
        e.SAVE_DELIVERY_CONFIGURATIONS,
      )
    }
  }

  @action.bound
  public onActivitiesSitemapSectionClick(globeId: string) {
    const { configurations } = this.appState.activitiesSettings
    if (this.activitiesAssignedGlobes[globeId]) {
      this.removeGlobeFromConfig(
        configurations,
        globeId,
        e.SAVE_ACTIVITIES_CONFIGURATIONS,
      )
    } else {
      this.addGlobeToConfig(
        configurations.maps,
        this.appState.activeProject.id,
        globeId,
        e.SAVE_ACTIVITIES_CONFIGURATIONS,
      )
    }
  }

  public updateGlobeName = (globe: GlobeView, name: string): void => {
    const newGlobe = globe.getCopy()
    newGlobe.name = name || this.editableGlobeName

    this.saveGlobeView(newGlobe)
  }

  public saveGlobeView = async (
    globeView?: GlobeView,
    filledImage?: string,
  ): Promise<string> => {
    if (this.isLoading) {
      return
    }
    const globeToSave = globeView || this.selectedGlobeView.getCopy()
    if (filledImage) {
      globeToSave.filledImage = filledImage
    }
    return new Promise<string>(resolve => {
      this.globeViewsStore.save(
        globeToSave,
        id => {
          this.saveNewCallback(id)
          resolve(id)
        },
        true,
      )
    })
  }

  @action.bound
  private removeGlobeFromConfig(
    config:
      | IDeliveryConfigurations
      | IActivitiesConfigurations
      | IFormsConfigurations
      | ILogisticsConfigurations,
    globeViewId: string,
    eventType: EventTypes,
  ) {
    if (!globeViewId) {
      return
    }

    config.maps = config.maps.filter(m => m.globeViewId !== globeViewId)

    this.eventsStore.dispatch(eventType, {
      maps: config.maps,
    })
  }

  @action.bound
  private addGlobeToConfig(
    maps: IOrderedSitemap[],
    projectId: string,
    globeViewId: string,
    eventType: EventTypes,
  ) {
    if (!globeViewId) {
      return
    }
    if (!maps.some(s => s.globeViewId === globeViewId)) {
      maps.push({
        globeViewId: globeViewId,
        order: maps.length,
      })

      this.eventsStore.dispatch(eventType, {
        maps,
        projectId,
      })
    }
  }

  public createNewGlobeView = async (
    filledImage?: string,
    bounds?,
    center?: ILatLng,
    zoom?: number,
    altitude?: number,
    bearing?: number,
    pitch?: number,
    geoCorners?: IGeoJson2DGeographicCoordinates[],
    projectId?: string,
  ): Promise<string> => {
    const globe = new GlobeView(
      null,
      DEFAULT_NAME,
      projectId,
      [],
      [],
      [],
      null,
      null,
      bounds,
      center,
      zoom,
      altitude,
      pitch,
      bearing,
      geoCorners,
      true,
      true,
      filledImage,
      {
        mapType: GlobeViewMapType.Street,
        isTerrainEnabled: false,
        isThreeDEnabled: false,
        isTrafficEnabled: false,
        shouldHideLabels: false,
        shouldShowAppProjectMarkers: false,
        shouldUseMapAnimations: false,
      },
    )

    return new Promise<string>(resolve => {
      this.globeViewsStore.save(
        globe,
        id => {
          this.saveNewCallback(id)
          resolve(id)
        },
        true,
      )
    })
  }

  @action.bound
  public assignGlobeToAllApps(globeId: string, projectId: string) {
    this.addGlobeToConfig(
      [],
      projectId,
      globeId,
      e.SAVE_ACTIVITIES_CONFIGURATIONS,
    )
    this.addGlobeToConfig([], projectId, globeId, e.SAVE_FORMS_CONFIGURATIONS)
    this.addGlobeToConfig(
      [],
      projectId,
      globeId,
      e.SAVE_LOGISTICS_CONFIGURATIONS,
    )
    this.addGlobeToConfig(
      [],
      projectId,
      globeId,
      e.SAVE_DELIVERY_CONFIGURATIONS,
    )
  }

  @action.bound
  public deselectGlobe(): void {
    this.selectedGlobeViewId = null
    this.editableGlobeName = ''
  }

  public saveGlobeViewImage = async (
    filledImage: string,
    globeView?: GlobeView,
  ): Promise<string> => {
    const globeToSave = globeView || this.selectedGlobeView.getCopy()
    globeToSave.filledImage = filledImage
    return new Promise<string>(resolve => {
      this.globeViewsStore.save(
        globeToSave,
        id => {
          this.saveNewCallback(id)
          resolve(id)
        },
        true,
      )
    })
  }

  public addItemToGlobeView = async (
    sitemapItemId: string,
    sitemapItemBase?: MapViewItemBase,
  ): Promise<string> => {
    const itemWithIndex = this.selectedGlobeView.displayDataMap[sitemapItemId]
    if (sitemapItemBase) {
      const newItem = {
        ...sitemapItemBase.getDisplayData(true),
        sitemapItemId,
        id: itemWithIndex?.item?.id,
        isHidden: false,
        isReferenced: true,
        globeViewId: this.selectedGlobeView.id,
        projectId: this.selectedGlobeView.projectId,
      } as IGlobeViewSpecificItemData
      return await this.saveGlobeViewItems([newItem])
    } else {
      return await this.saveGlobeViewItems([
        {
          sitemapItemId,
          id: itemWithIndex?.item?.id,
          isHidden: false,
          globeViewId: this.selectedGlobeView.id,
          projectId: this.selectedGlobeView.projectId,
        },
      ])
    }
  }

  public setSitemapToGlobeView = async (sitemapId: string): Promise<string> => {
    const newGlobe = this.selectedGlobeView.getCopy()
    const isAdded = newGlobe.sitemapsMap[sitemapId]
    if (isAdded) {
      newGlobe.sitemaps = newGlobe.sitemaps.filter(id => id !== sitemapId)
    } else {
      newGlobe.sitemaps.push(sitemapId)
    }

    return await this.saveGlobeView(newGlobe)
  }

  public setTilesetToGlobeView = async (tilesetId: string): Promise<string> => {
    const newGlobe = this.selectedGlobeView.getCopy()
    const isAdded = newGlobe.tilesetsMap[tilesetId]
    if (isAdded) {
      newGlobe.tilesets = newGlobe.tilesets.filter(tId => tId !== tilesetId)
    } else {
      newGlobe.tilesets.push(tilesetId)
    }

    return await this.saveGlobeView(newGlobe)
  }

  public async updateItems(globeView: GlobeView): Promise<string> {
    return new Promise<string>(resolve => {
      this.globeViewsStore.save(globeView, id => {
        this.saveNewCallback(id)
        resolve(id)
      })
    })
  }

  public saveNewCallback = (id: string): void => {
    this.selectGlobe(this.globeViewsStore.byId.get(id))
  }

  public saveNewGLobeView = async (
    geoposition?: IGeoPosition,
    bounds?,
    filledImage?: string,
  ): Promise<void> => {
    const globeView = new GlobeView(
      null,
      this.editableGlobeName,
      this.appState.activeProject.id,
    )

    if (geoposition) {
      globeView.setGeoposition(geoposition, bounds)
    }

    const id = await this.saveGlobeView(globeView, filledImage)
    this.selectGlobeById(id)
  }

  public saveGlobeViewItems = async (
    items: IGlobeViewSpecificItemData[],
  ): Promise<string> => {
    return new Promise<string>(resolve => {
      this.globeViewsStore.saveGlobeViewItems(
        items,
        id => resolve(id),
        true,
        false,
      )
    })
  }

  public deleteGlobeViewItem = async (itemId: string): Promise<string> => {
    return new Promise<string>(resolve => {
      this.globeViewsStore.deleteGlobeViewItem(
        itemId,
        id => resolve(id),
        true,
        false,
      )
    })
  }
}
