import * as React from 'react'

import { observer } from 'mobx-react'

import DesktopCalendarUnsafe from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendar'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'

import DesktopDeliveryViewStore from '../../Deliveries.store'

// localization: no display text to translate

const DesktopCalendar = withErrorBoundary(DesktopCalendarUnsafe)

interface IProps {
  store: DesktopDeliveryViewStore
  state: DesktopInitialState
}

@observer
export default class DeliveriesCalendar extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    this.props.store.resetInappropriatePeriodModeIfActive()
  }

  public componentDidMount() {
    this.props.store.prepareCalendar()

    if (navigator.webdriver) {
      window.lhTimeToDeliveryCalendar = performance.now()
    }
  }

  public render() {
    const {
      store,
      state: { userActiveProjectSettings },
    } = this.props

    const {
      areDayTodayCalendarModesActive,
      calendarStore,
      onNewDeliveryCreate,
      onEventClicked,
      onEventDateChange,
      setEditableEvent,
      startDate,
      calendarMode,
      editableCalendarEvent,
      isDeliveryMovable,
    } = store

    return (
      <div className="full-height ml10 full-width">
        <DesktopCalendar
          store={calendarStore}
          multiDayEvents={calendarStore.multiDayEvents}
          isDayCalendarModeActive={areDayTodayCalendarModesActive}
          onNewEventCreate={onNewDeliveryCreate}
          onEventClicked={onEventClicked}
          onEventDateUpdate={onEventDateChange}
          setEditableEvent={setEditableEvent}
          isEventCreatingDisabled={
            userActiveProjectSettings?.isPresentationUser
          }
          startDate={startDate}
          calendarMode={calendarMode}
          editableEvent={editableCalendarEvent}
          isEventMovable={isDeliveryMovable}
        />
      </div>
    )
  }
}
