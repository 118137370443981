import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { classList } from 'react-classlist-helper'

interface IProps {
  isActive: boolean
  isDesc: boolean
  sortKey?: string
  onSort?: (sortKey: string) => void
}

export default class SortingCell extends React.PureComponent<IProps> {
  public render() {
    const { isActive, isDesc, children } = this.props
    const iconName = isDesc ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_UP
    return (
      <div
        onClick={this.sortTable}
        className={classList({
          'row full-width': true,
          sorting: isActive,
        })}
      >
        <div className="full-width">{children}</div>
        {isActive && (
          <div className="col y-start no-grow">
            <Icon className="sorting-arrow-icon" icon={iconName} />
          </div>
        )}
      </div>
    )
  }

  private sortTable = () => {
    const { onSort, sortKey } = this.props
    if (onSort) {
      onSort(sortKey)
    }
  }
}
