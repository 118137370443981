import * as React from 'react'

import { action } from 'mobx'
import { inject } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IProject, IProjectClosedInterval } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  item: IProject
  interval: IProjectClosedInterval
  onRemove: (item: IProject, interval: IProjectClosedInterval) => void
  projectDateStore?: ProjectDateStore
  isLastRecord?: boolean
}

@inject('projectDateStore')
export default class ProjectClosingInterval extends React.Component<IProps> {
  public render() {
    const { isLastRecord } = this.props

    return (
      <div className="row closing-interval-holder relative">
        <div
          className={classList({
            'full-height row pl10 large text bt-light-input-border closing-interval-value':
              true,
            'bb-light-input-border': isLastRecord,
          })}
        >
          <div
            className={classList({
              'row text-ellipsis pr10': true,
            })}
          >
            <span className="pl5 w-fit-content">{this.intervalCaption}</span>
          </div>
        </div>
        <div className="no-grow delete-interval-icon">
          <Icons.Delete className="pointer" onClick={this.removeInterval} />
        </div>
      </div>
    )
  }

  private get intervalCaption() {
    const {
      interval: { startDate, endDate, name },
      projectDateStore: { getWeekdayMonthAndDayToDisplay },
    } = this.props
    const startDateFormated = getWeekdayMonthAndDayToDisplay(startDate)
    const endDateFormated = endDate
      ? getWeekdayMonthAndDayToDisplay(endDate)
      : Localization.translator.forTheFuture
    return `${name}, ${startDateFormated} - ${endDateFormated}`
  }

  @action.bound
  private removeInterval() {
    const { item, interval, onRemove } = this.props
    onRemove(item, interval)
  }
}
