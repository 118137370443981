import * as React from 'react'

import { Popover, PopoverPosition, PopperModifiers } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { AutoSizer, List } from 'react-virtualized'

import CompanyProfilePreview from '~/client/src/shared/components/CompanyProfilePreview/CompanyProfilePreview'
import * as Icons from '~/client/src/shared/components/Icons'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import User from '~/client/src/shared/models/User'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

interface ICellEntity {
  id: string
  name?: string
}

export enum TagCellItemKeyEnum {
  COMPANY = 'Company',
  USER = 'User',
  LOCATION = 'Location',
  EQUIPMENT = 'Equipment',
}

interface IProps {
  items: ICellEntity[]

  isBreadcrumb?: boolean
  renderItemKey?: TagCellItemKeyEnum

  getXItemsLabel?(itemsCount: number): string
  renderItem?(item: ICellEntity, title?: string): JSX.Element
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    padding: 5,
    boundariesElement: 'window',
  },
  flip: {
    boundariesElement: 'window',
  },
  hide: { enabled: true },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

const ROW_HEIGHT = 50
const MODAL_HEIGHT_RATIO = 0.4
const OVERSCAN_ROW_COUNT = 10
const ICON_SIZE = 16

const renderLocationTag = (
  locationObj: LocationBase,
  title?: string,
): JSX.Element => {
  const locationName = title || locationObj.name

  return (
    <SitemapAttributeTag
      dataObject={locationObj}
      shouldShowAsTag
      iconSize={ICON_SIZE}
      contentContainerClassName="text-ellipsis py2"
    >
      <span title={locationName} className="text extra-large">
        {locationName}
      </span>
    </SitemapAttributeTag>
  )
}

const renderCompanyPreview = (company: Company): JSX.Element => (
  <CompanyProfilePreview
    company={company}
    shouldShowCompanyType
    isMenuHidden
    isPhoneHidden
  />
)

const renderUserPreview = (user: User): JSX.Element => (
  <UserProfilePreview user={user} />
)

const defaultItemLabelMap = {
  [TagCellItemKeyEnum.COMPANY]: Localization.translator.xCompanies,
  [TagCellItemKeyEnum.USER]: Localization.translator.xUsers,
  [TagCellItemKeyEnum.LOCATION]: Localization.translator.xLocations,
  [TagCellItemKeyEnum.EQUIPMENT]: (x: number) =>
    `${x} ${Localization.translator.equipment}`,
}
const defaultItemRendererMap = {
  [TagCellItemKeyEnum.COMPANY]: renderCompanyPreview,
  [TagCellItemKeyEnum.USER]: renderUserPreview,
  [TagCellItemKeyEnum.LOCATION]: renderLocationTag,
  [TagCellItemKeyEnum.EQUIPMENT]: renderLocationTag,
}

@observer
export default class ListTagCell extends React.Component<IProps> {
  public render() {
    const { items, isBreadcrumb } = this.props

    if (!items?.length) {
      return <div className="pt20 pb7">{NO_VALUE}</div>
    }

    if (isBreadcrumb || items.length === 1) {
      const itemAsBreadcrumb =
        isBreadcrumb && items.map(l => l?.name).join(' > ')

      return (
        <div className="text-ellipsis">
          {this.renderItem(items[0], itemAsBreadcrumb)}
        </div>
      )
    }

    return (
      <div onClick={this.stopPropagation}>
        <Popover
          className="full-height"
          targetClassName="full-height full-width"
          popoverClassName="mw300 beautiful-shadow overflow-hidden"
          canEscapeKeyClose={false}
          position={PopoverPosition.BOTTOM}
          modifiers={popoverPopperModifiers}
          content={this.modalContent}
        >
          <div className="row primary50-color-hover pointer h48 no-select underline-hover">
            <div className="text-ellipsis">{this.xItemsLabel}</div>
            <Icons.DownArrow
              className="bp3-icon ml5 icon-w11 icon-grey"
              element="span"
            />
          </div>
        </Popover>
      </div>
    )
  }

  private get modalContent(): JSX.Element {
    const { items } = this.props

    return (
      <div className="col no-outline-container">
        <div className="virtualized-list-smart-wrapper">
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                data={items}
                width={width}
                height={Math.min(
                  window.innerHeight * MODAL_HEIGHT_RATIO,
                  ROW_HEIGHT * items.length,
                )}
                rowHeight={ROW_HEIGHT}
                rowCount={items.length}
                rowRenderer={this.renderRow}
                scrollToAlignment="start"
                overscanRowCount={OVERSCAN_ROW_COUNT}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }

  private renderRow = ({ key, style, index }: any): JSX.Element => {
    const item = this.props.items[index]

    if (!item) {
      return null
    }

    return (
      <div key={key} style={style} className="row">
        <div className="ml15 mr10 text large bold no-grow">{index + 1}.</div>
        <div className="row text-ellipsis mr10">{this.renderItem(item)}</div>
      </div>
    )
  }

  private renderItem = (item: ICellEntity, title?: string): JSX.Element => {
    if (!item) {
      return null
    }

    const { renderItem, renderItemKey } = this.props

    if (renderItem) {
      return renderItem(item, title)
    }

    return defaultItemRendererMap[renderItemKey]?.(item as any, title)
  }

  private get xItemsLabel(): string {
    const { items, renderItemKey, getXItemsLabel } = this.props

    if (getXItemsLabel) {
      return getXItemsLabel(items.length)
    }

    return defaultItemLabelMap[renderItemKey](items.length)
  }

  private stopPropagation = (event: React.SyntheticEvent) => {
    event?.stopPropagation()
  }
}
