import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { KonvaEventObject } from 'konva/types/Node'
import { action, computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DeliveriesMapStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesMap/DeliveriesMap.store'
import BaseMapView from '~/client/src/shared/components/BaseMapView/BaseMapView'
import * as Icons from '~/client/src/shared/components/Icons'
import KonvaWorkflowDeliveriesPin from '~/client/src/shared/components/Konva/KonvaWorkflowDeliveriesPin'
import DeliveryMapPopup from '~/client/src/shared/components/MapPopups/DeliveryMapPopup'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapDeliveriesDraggableModal from '~/client/src/shared/components/SitemapDraggableModalWrapper/components/SitemapDeliveriesDraggableModal'
import SitemapElementsWrapper from '~/client/src/shared/components/SitemapElementsWrapper'
import GlobeViewDeliveryMapPin from '~/client/src/shared/components/SitemapHelpers/components/GlobePins/GlobeViewDeliveryMapPin'
import GlobeViewItems from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewItems'
import GlobeViewPlans from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewPlans'
import GlobeViewTilesets from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewTilesets'
import SitemapItems from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryPinLocationOption, {
  getDeliveryPinLocationOptionDisplayName,
} from '~/client/src/shared/enums/DeliveryPinLocationOption'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import GlobeViewControlStore from '~/client/src/shared/stores/GlobeViewControl.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

// localization: translated
interface IProps {
  deliveriesMapStore: DeliveriesMapStore
  textboxesCache: ICanvasImageCache

  state?: DesktopInitialState
  companiesStore?: CompaniesStore

  globeViewControlStore?: GlobeViewControlStore
  basemapsStore?: BasemapsStore

  globe?: GlobeView
  sitemap?: Sitemap
}

interface IShowOnMenuOption {
  name: DeliveryPinLocationOption
  icon: JSX.Element
  fieldId?: FieldIds
}

const showOnMenuOptions: IShowOnMenuOption[] = [
  {
    fieldId: FieldIds.BUILDING,
    name: DeliveryPinLocationOption.building,
    icon: <TagIcon.Building className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.AREA,
    name: DeliveryPinLocationOption.area,
    icon: <TagIcon.Area className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.ZONE,
    name: DeliveryPinLocationOption.zone,
    icon: <TagIcon.Zone className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.GATE,
    name: DeliveryPinLocationOption.gate,
    icon: <TagIcon.Gate className="no-grow mx5" />,
  },
  {
    fieldId: FieldIds.ROUTE,
    name: DeliveryPinLocationOption.route,
    icon: <TagIcon.Route className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.OFFLOADING_EQUIPMENT,
    name: DeliveryPinLocationOption.equipment,
    icon: <TagIcon.Equipment className="no-grow mx5 option-icon" />,
  },
  {
    name: DeliveryPinLocationOption.mostPertinent,
    icon: <Icons.EyeView className="no-grow mx5 option-icon row y-center" />,
  },
  {
    fieldId: FieldIds.LEVEL,
    name: DeliveryPinLocationOption.level,
    icon: <TagIcon.Level className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.STAGING,
    name: DeliveryPinLocationOption.staging,
    icon: <TagIcon.Staging className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.INTERIOR_DOOR,
    name: DeliveryPinLocationOption.interiorDoor,
    icon: <TagIcon.InteriorDoor className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.INTERIOR_PATH,
    name: DeliveryPinLocationOption.interiorPath,
    icon: <TagIcon.InteriorPath className="no-grow mx5 option-icon" />,
  },
]
@inject('state', 'companiesStore', 'basemapsStore')
@observer
export default class DeliveryMapView extends React.Component<IProps> {
  public static contextType = MobXProviderContext
  @observable private isShowOnMenuShown: boolean = false
  @observable private deliverySitemap: BaseMapView

  private containerRef: HTMLElement = null

  public constructor(props: IProps) {
    super(props)

    const { deliveriesMapStore } = props

    deliveriesMapStore.resetAttrSelection()
    deliveriesMapStore.mapBoxViewerStore.setViewportFromAddress()
    deliveriesMapStore.mapBoxViewerStore.setDefaultMapMode()
  }

  public componentDidMount() {
    this.validateShowOnOption()
  }

  public render() {
    const { deliveriesMapStore, basemapsStore, state, globe, sitemap } =
      this.props
    const {
      isGlobeMode,
      selectedSitemapUrl,
      resetAttrSelection,
      resetAllFilters,
      selectDelivery,
      activeDeliveryId,
      attrIdToDeliveriesMap,
      selectedMapViewItem,
      mapBoxViewerStore,
      topOffset,
      leftOffset,
    } = deliveriesMapStore

    const { setViewport } = mapBoxViewerStore

    return (
      <section
        ref={this.setContainerRef}
        className="full-height full-width relative general-map-container"
      >
        <BaseMapView
          globe={globe}
          selectedSitemap={sitemap}
          isDeliveryView={true}
          sitemapUrl={selectedSitemapUrl}
          isDraggable={true}
          onWhiteboardClick={this.handleMouseDownOnContainer}
          onTouch={this.handleMouseDownOnContainer}
          isDraggingMode={false}
          mapBoxViewerStore={deliveriesMapStore.mapBoxViewerStore}
          setViewport={setViewport}
          basemapsStore={basemapsStore}
          projectAddress={state.projectAddress}
          ref={this.setDeliverySitemap}
        >
          {({ width, height }) => {
            if (!this.deliverySitemap) {
              return null
            }
            return (
              <Provider {...this.context}>
                {isGlobeMode
                  ? this.renderGlobeViewChildren()
                  : this.renderSitemapChildren(width, height)}
              </Provider>
            )
          }}
        </BaseMapView>
        {selectedMapViewItem && !isGlobeMode && (
          <SitemapDeliveriesDraggableModal
            onClose={resetAttrSelection}
            item={selectedMapViewItem}
            containerRef={this.containerRef}
            onDeliveryClick={selectDelivery}
            activeDeliveryId={activeDeliveryId}
            onFilterResetClick={resetAllFilters}
            relatedDeliveries={attrIdToDeliveriesMap[selectedMapViewItem.id]}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
        {this.renderShowOnMenu()}
      </section>
    )
  }

  public get viewport() {
    return this.props.deliveriesMapStore.mapBoxViewerStore.viewport
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private renderGlobeViewChildren = () => {
    const { deliveriesMapStore } = this.props

    const { mapBoxViewerStore } = deliveriesMapStore
    const { displayedGlobeViewItems } = mapBoxViewerStore
    return (
      <>
        <GlobeViewPlans
          mapBoxViewerStore={mapBoxViewerStore}
          sitemapWithBasemaps={mapBoxViewerStore.sitemapWithBasemapsOnGlobe}
        />
        <GlobeViewTilesets mapBoxViewerStore={mapBoxViewerStore} />
        <GlobeViewItems
          items={displayedGlobeViewItems || []}
          onSelectItem={this.selectSitemapItem}
          mapBoxViewerStore={mapBoxViewerStore}
          renderMarkerPin={this.renderMarkerPin}
        />
        {this.renderGlobePopups}
      </>
    )
  }

  private get renderGlobePopups(): JSX.Element {
    const { deliveriesMapStore } = this.props
    const {
      resetAttrSelection,
      resetAllFilters,
      selectDelivery,
      activeDeliveryId,
      attrIdToDeliveriesMap,
      selectedMapViewItem,
    } = deliveriesMapStore
    const relatedDeliveries = attrIdToDeliveriesMap[selectedMapViewItem?.id]

    return (
      selectedMapViewItem && (
        <DeliveryMapPopup
          onClose={resetAttrSelection}
          item={selectedMapViewItem}
          onDeliveryClick={selectDelivery}
          activeDeliveryId={activeDeliveryId}
          onFilterResetClick={resetAllFilters}
          relatedDeliveries={relatedDeliveries}
        />
      )
    )
  }

  private renderMarkerPin = (item: MapViewItemBase) => {
    const {
      deliveriesMapStore: {
        isSelectedAttr,
        activeDeliveryId,
        closestAttrIdToDeliveriesMap,
        deliverySitemapPinStore: {
          shouldShowPin,
          shouldShowPinAsDone,
          shouldShowPinAsAssigned,
          shouldShowPinAsCanceled,
        },
      },
      state: { user },
    } = this.props

    const deliveries = closestAttrIdToDeliveriesMap[item.id] || []

    if (!deliveries?.length || !shouldShowPin(item, deliveries)) {
      return null
    }

    const { id: sitemapItemId } = item

    const isPinSelected =
      isSelectedAttr(sitemapItemId) ||
      deliveries?.some(d => d.id === activeDeliveryId)

    return (
      <GlobeViewDeliveryMapPin
        key={sitemapItemId}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, user)}
        isSelected={isPinSelected}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        itemsCount={deliveries.length}
        onClick={this.selectDeliveries.bind(null, item)}
      />
    )
  }

  @action.bound
  private selectDeliveries(item: MapViewItemBase, event) {
    event.stopPropagation()
    this.selectSitemapItem(item)
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
    isMainSitemap?: boolean,
  ) => {
    const { textboxesCache, deliveriesMapStore } = this.props

    const {
      activeDeliveryId,
      closestAttrIdToDeliveriesBoardMap,
      displayedSitemapItems,
    } = deliveriesMapStore

    return (
      <>
        <SitemapItems
          className={classList({ 'unclickable-element': !isMainSitemap })}
          items={displayedSitemapItems || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
          onSelectItem={this.selectSitemapItem}
        />
        <SitemapElementsWrapper>
          {displayedSitemapItems.map(item =>
            this.renderWorkflowPin(
              item,
              width,
              height,
              closestAttrIdToDeliveriesBoardMap,
              activeDeliveryId,
            ),
          )}
        </SitemapElementsWrapper>
      </>
    )
  }

  private renderWorkflowPin(
    item: MapViewItemBase,
    cWidth: number,
    cHeight: number,
    attrIdToDeliveriesMap: { [attrId: string]: Delivery[] },
    activeDeliveryId: string,
  ): JSX.Element {
    const {
      companiesStore,
      deliveriesMapStore: {
        isSelectedAttr,
        deliverySitemapPinStore: {
          getPinLabel,
          shouldShowPin,
          getPinPosition,
          shouldRenderCircle,
          shouldShowPinAsDone,
          shouldShowPinAsAssigned,
          shouldShowPinAsCanceled,
        },
      },
      state: { user },
    } = this.props

    const deliveries = attrIdToDeliveriesMap[item.id] || []

    if (!shouldShowPin(item, deliveries)) {
      return null
    }

    const { id: sitemapItemId } = item
    const { x, y } = getPinPosition(item, cWidth, cHeight)

    const isPinSelected =
      isSelectedAttr(sitemapItemId) ||
      deliveries?.some(d => d.id === activeDeliveryId)

    return (
      <KonvaWorkflowDeliveriesPin
        key={sitemapItemId}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, user)}
        isSelected={isPinSelected}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        text={getPinLabel(deliveries, companiesStore)}
        shouldRenderCircle={shouldRenderCircle(deliveries)}
        x={x}
        y={y}
        onClick={this.onDeliveryPinClick.bind(this, item)}
        onTouchEnd={this.onDeliveryPinTouch.bind(this, item)}
      />
    )
  }

  private onDeliveryPinTouch = (
    item: MapViewItemBase,
    event: KonvaEventObject<TouchEvent>,
  ) => {
    event.cancelBubble = true
    this.selectSitemapItem(item)
  }

  private onDeliveryPinClick = (
    item: MapViewItemBase,
    event: KonvaEventObject<MouseEvent>,
  ) => {
    event.cancelBubble = true
    this.selectSitemapItem(item, event.evt.layerY, event.evt.layerX)
  }

  private selectSitemapItem = (
    item: MapViewItemBase,
    y?: number,
    x?: number,
  ) => {
    const { isSitemapItemSupported, selectAttr } = this.props.deliveriesMapStore

    if (isSitemapItemSupported(item)) {
      selectAttr(item.id, y, x)
    }
  }

  private setContainerRef = (ref: HTMLElement) => {
    this.containerRef = ref
  }

  private handleMouseDownOnContainer = () => {
    const { resetAttrSelection, selectedMapViewItem } =
      this.props.deliveriesMapStore

    if (selectedMapViewItem) {
      resetAttrSelection()
    }
  }

  @computed
  private get locationOptions(): DeliveryPinLocationOption[] {
    const { displayedMapboxItems } = this.props.deliveriesMapStore

    const hasLevelOption = displayedMapboxItems.some(
      x => x?.dataObject?.type === LocationType.Level,
    )

    return Object.values(DeliveryPinLocationOption).filter(o =>
      o === DeliveryPinLocationOption.level ? hasLevelOption : true,
    )
  }

  private validateShowOnOption = () => {
    if (
      !this.locationOptions.includes(
        this.props.deliveriesMapStore.deliverySitemapPinStore.selectedPinOption,
      )
    ) {
      this.props.deliveriesMapStore.deliverySitemapPinStore.selectDefaultShowOnOption()
    }
  }

  private renderShowOnMenu = () => {
    const iconName = this.isShowOnMenuShown
      ? IconNames.CARET_DOWN
      : IconNames.CARET_UP

    const { selectedPinOption } =
      this.props.deliveriesMapStore.deliverySitemapPinStore
    const showOnMenuOption = showOnMenuOptions.find(
      o => o.name === selectedPinOption,
    )

    return (
      <div className="absolute row show-on-menu pointer">
        <div className="show-on-text-box text lp15 small uppercase pa5 br-light-input-border row y-center nowrap no-grow">
          <Icons.RoundedLocation className="shown-on-icon no-grow mr5" />
          {Localization.translator.showOn}
        </div>
        <div
          className={classList({
            'show-on-icons-box row': true,
            selected: this.isShowOnMenuShown,
          })}
          onClick={this.toggleShowOnMenu}
        >
          {showOnMenuOption.icon}
          <span className="large text">
            {this.getOptionName(showOnMenuOption)}
          </span>
          <Icon icon={iconName} className="no-grow mx5 caret-icon" />
        </div>
        {this.isShowOnMenuShown && (
          <MenuCloser className="no-grow" closeMenu={this.toggleShowOnMenu}>
            <div className="absolute col show-on-options brada10">
              {this.locationOptions.map((option, index) => {
                const currentOption = showOnMenuOptions.find(
                  o => o.name === option,
                )
                const isSelected = option === selectedPinOption
                const isLastOption = index === this.locationOptions.length - 1

                return (
                  <div
                    onClick={this.selectShowOnOption.bind(this, option)}
                    key={option}
                    className={classList({
                      'bb-dark-grey-border py5 option-holder row y-center pl10  no-grow pointer':
                        true,
                      selected: isSelected,
                      'first-option': !index,
                      'last-option': isLastOption,
                    })}
                  >
                    {currentOption.icon}
                    <span className="large text">
                      {this.getOptionName(currentOption)}
                    </span>
                    {isSelected && (
                      <Icons.CheckPaletteBlue className="no-grow ml10" />
                    )}
                  </div>
                )
              })}
            </div>
          </MenuCloser>
        )}
      </div>
    )
  }

  private selectShowOnOption = (option: DeliveryPinLocationOption) => {
    this.props.deliveriesMapStore.deliverySitemapPinStore.selectShowOnOption(
      option,
    )

    this.toggleShowOnMenu()
  }

  private toggleShowOnMenu = () => {
    this.isShowOnMenuShown = !this.isShowOnMenuShown
  }

  private getOptionName({ fieldId, name }: IShowOnMenuOption): string {
    const { state } = this.props
    return fieldId
      ? state.getDeliveryFieldName(fieldId)
      : getDeliveryPinLocationOptionDisplayName(name)
  }
}
