import * as React from 'react'

import { classList } from 'react-classlist-helper'

interface IProps {
  isActive: boolean
  icon: React.ReactChild
  label: React.ReactChild
  onClick(): void
}

export default class SectionButton extends React.Component<IProps> {
  public render() {
    const { isActive, icon, onClick, label } = this.props

    return (
      <button
        className={classList({
          'row x-between section pointer brada2 px5 m5': true,
          active: isActive,
        })}
        onClick={onClick}
      >
        {label}
        <span className="no-grow">{icon}</span>
      </button>
    )
  }
}
