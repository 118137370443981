import React, { Component } from 'react'

import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { Frequency, Period } from '../Constants/Enums'
import { chartFilters } from '../Utils/Utils'
import Chart from './Chart/Chart'
import EmptyState from './EmptyState'

import './ChartGroup.scss'

type DashboardDataValue = {
  chartIds: any
  title: string
  baseUrl: string
}

type DashboardData = {
  deliveriesCharts: DashboardDataValue
  averageDaysRequestedBeforeDeliveryCharts: DashboardDataValue
  lateCancellationsCharts: DashboardDataValue
  ResponsibleCompaniesCharts: DashboardDataValue
  NewResponsibleCompaniesCharts: DashboardDataValue
  responsibleChart: DashboardDataValue
}

interface IProps {
  dashboardData: DashboardData
  selectedFrequency: Frequency
  selectedPeriod: Period
  selectedCompany: string
  state?: InitialState
  projectDateStore?: ProjectDateStore
}

@inject('state', 'projectDateStore')
@observer
export default class ChartGroup extends Component<IProps> {
  @observable public chartEmptyState: boolean = false

  @action.bound
  private setChartEmptyState(chartEmptyState: boolean) {
    this.chartEmptyState = chartEmptyState
  }

  private renderResponsibleChartCompanies(
    chartId: string,
    baseUrl: string,
    ProjectName: string,
    selectedCompany?: string,
    selectedPeriod?: Period,
  ) {
    const sdk = new ChartsEmbedSDK({
      baseUrl: baseUrl,
    })

    const filter = chartFilters(
      this.props.projectDateStore,
      selectedPeriod,
      ProjectName,
      selectedCompany,
    )

    const chart = sdk.createChart({
      chartId: chartId,
      width: '600px',
      height: '800px',
      filter: filter,
      showAttribution: false,
    })

    chart
      .render(document.getElementById(chartId))
      .then(() => {
        return chart.getData()
      })
      .then((data: any) => {
        const documentsFound = data?.documents?.length > 0
        this.setChartEmptyState(!documentsFound)
      })
  }

  public componentDidMount() {
    this.renderResponsibleChartCompanies(
      this.props.dashboardData.responsibleChart.chartIds.entireProject,
      this.props.dashboardData.responsibleChart.baseUrl,
      this.props.state?.activeProject.name,
      this.props.selectedCompany,
      this.props.selectedPeriod,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.selectedPeriod === this.props.selectedPeriod &&
      prevProps.selectedCompany === this.props.selectedCompany
    ) {
      return
    }
    this.renderResponsibleChartCompanies(
      this.props.dashboardData.responsibleChart.chartIds.entireProject,
      this.props.dashboardData.responsibleChart.baseUrl,
      this.props.state?.activeProject.name,
      this.props.selectedCompany,
      this.props.selectedPeriod,
    )

    this.setChartEmptyState(false)
  }

  public render() {
    const {
      dashboardData,
      selectedFrequency,
      selectedPeriod,
      selectedCompany,
    } = this.props

    if (!dashboardData) {
      return null
    }

    const renderChart = (chartData: DashboardDataValue) => {
      if (!chartData.chartIds) {
        console.warn('Missing chartIds for', chartData.title)
        return null
      }
      return (
        <Chart
          key={chartData.title}
          chartIds={chartData.chartIds}
          selectedFrequency={selectedFrequency}
          selectedCompany={selectedCompany}
          selectedPeriod={selectedPeriod}
          title={chartData.title}
          baseUrl={chartData.baseUrl}
        />
      )
    }

    const chartComponents = Object.entries(dashboardData).map(
      ([key, chartData]) =>
        key === 'responsibleChart' ? null : renderChart(chartData),
    )

    const plainChart = this.chartEmptyState ? 'hidden' : ''

    return (
      <div className="row y-baseline">
        <div className="grid-container ml10">{chartComponents}</div>
        <div className="col full-height no-flex">
          <div className="h70 bg-palette-brand-lightest pa12 ml10">
            <div className="text large bold">
              {Localization.translator.responsibleCompanies}
            </div>
          </div>
          <div
            className={`as-start no-flex ${plainChart}`}
            id={dashboardData.responsibleChart.chartIds.entireProject}
          />
          {this.chartEmptyState && <EmptyState className="ml10 w600" />}
        </div>
      </div>
    )
  }
}
