import React from 'react'

import { observer } from 'mobx-react'

import { DeliveryStatus } from '~/client/graph'
import ListTagCell, {
  TagCellItemKeyEnum,
} from '~/client/src/desktop/components/ListTagCell'
import DeliveryAssociationStatus from '~/client/src/shared/components/DeliveryAssociationStatus/DeliveryAssociationStatus'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import User from '~/client/src/shared/models/User'

import { IDateCell } from '../../../../Deliveries/components/DeliveriesList/DeliveriesList.store'
import DeliveryDateCell from '../../../../Deliveries/components/DeliveriesList/components/DeliveryDateCell/DeliveryDateCell'
import DeliveryMaterialsCell from '../../../../Deliveries/components/DeliveriesList/components/DeliveryMaterialsCell/DeliveryMaterialsCell'
import ReportsListTable from '../components/ReportsListTable/ReportsListTable'
import DeliveryReportListStore, { DataKeys } from './DeliveryReportList.store'

interface IProps {
  store: DeliveryReportListStore
}

const LOADER_SIZE = 20

const statusCellRenderer = (status?: DeliveryStatus) =>
  status ? (
    <DeliveryStatusLabel status={status} />
  ) : (
    <Loader size={LOADER_SIZE} className="no-flex" />
  )

const attrCellRenderer = value =>
  value ? (
    <SitemapAttributeTag
      dataObject={value}
      shouldShowAsTag={true}
      contentContainerClassName="text-ellipsis py2"
    >
      <span title={value.name} className="text large">
        {value.name}
      </span>
    </SitemapAttributeTag>
  ) : (
    <>{Localization.translator.unspecified}</>
  )

const companyCellRenderer = (values: Company[]) => (
  <ListTagCell items={values} renderItemKey={TagCellItemKeyEnum.COMPANY} />
)

@observer
export default class DeliveryReportList extends React.Component<IProps> {
  public render() {
    const {
      rows,
      columns,
      columnsWidthState,
      groupingKey,
      collapsedCategories,
      toggleCategoryCollapsing,
      sortState,
      handleColumnSort,
      onRowClick,
      toggleCategory,
    } = this.props.store

    return (
      <ReportsListTable
        rows={rows}
        groupingKey={groupingKey}
        sortedColumnKey={sortState.columnKey}
        sortingOrder={sortState.order}
        columns={columns}
        columnsWidthState={columnsWidthState}
        onColumnSort={handleColumnSort}
        collapsedCategories={collapsedCategories}
        columnKeyToCellRenderer={this.columnKeyToCellRenderer}
        onCategoryCollapsingToggle={toggleCategoryCollapsing}
        onCellClick={onRowClick}
        onCategoryCheckboxToggle={toggleCategory}
      />
    )
  }

  private get columnKeyToCellRenderer() {
    const columnsHandler = {
      [DataKeys.ON_SITE_CONTACTS]: (values: User[]) => (
        <ListTagCell items={values} renderItemKey={TagCellItemKeyEnum.USER} />
      ),
      [DataKeys.COMPANY]: companyCellRenderer,
      [DataKeys.VENDOR]: companyCellRenderer,
      [DataKeys.STATUS]: statusCellRenderer,

      [DataKeys.GATE]: attrCellRenderer,
      [DataKeys.ZONE]: attrCellRenderer,
      [DataKeys.ROUTE]: attrCellRenderer,
      [DataKeys.BUILDING]: attrCellRenderer,
      [DataKeys.LEVEL]: attrCellRenderer,
      [DataKeys.AREA]: attrCellRenderer,

      [DataKeys.DATE]: ({ dates, isActive }: IDateCell) => (
        <DeliveryDateCell dates={dates} isActive={isActive} />
      ),
      [DataKeys.DURATION]: ({ dates }: IDateCell) => (
        <DeliveryDateCell dates={dates} />
      ),
      [DataKeys.BOOKING_ID]: ({ delivery, code }) => (
        <div className="row ml8">
          <DeliveryAssociationStatus
            deliveryId={delivery.id}
            className="no-grow"
          />
          {delivery.isFromConcreteDirect && (
            <Icons.ConcreteDirect className="row no-grow ml10" />
          )}
          <span className="text-ellipsis ml10">
            {code}
            {delivery.recurringSettingId && (
              <Icons.Repeat className="inline-block no-outline no-grow ml8 pt4" />
            )}
          </span>
        </div>
      ),
      [DataKeys.MATERIALS_SECTION]: ({
        id,
        onAddClick,
      }: {
        id: string
        onAddClick: (deliveryId: string, shouldAddMaterial?: boolean) => void
      }) => (
        <DeliveryMaterialsCell
          deliveryId={id}
          onAddMaterialClick={onAddClick}
        />
      ),
    }

    return columnsHandler
  }
}
