import React from 'react'

import { inject, observer } from 'mobx-react'

import FormFieldWrapper from '~/client/src/shared/components/FormFieldWrapper/FormFieldWrapper'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ConcreteDirectIntegrationStore from '~/client/src/shared/stores/domain/ConcreteDirectStores/ConcreteDirectIntegration.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import SubmitButton from '../../SubmitButton'
import ConcreteDirectConfigurationFormStore from './ConcreteDirectConfigurationForm.store'

const SUBMIT_BUTTON_WIDTH = 160

interface IConcreteDirectConfigurationForm {
  initProjectId: string

  graphExecutorStore?: GraphExecutorStore
  concreteDirectIntegrationStore?: ConcreteDirectIntegrationStore
  projectDateStore?: ProjectDateStore
}

@inject(
  'graphExecutorStore',
  'concreteDirectIntegrationStore',
  'projectDateStore',
)
@observer
export default class ConcreteDirectConfigurationForm extends React.Component<IConcreteDirectConfigurationForm> {
  private readonly store: ConcreteDirectConfigurationFormStore

  public constructor(props: IConcreteDirectConfigurationForm) {
    super(props)

    this.store = new ConcreteDirectConfigurationFormStore(
      props.graphExecutorStore,
      props.initProjectId,
      props.projectDateStore,
      props.concreteDirectIntegrationStore,
    )
  }

  public render() {
    const {
      isUpdating,
      submitCDConfig,
      isSubmitBtnDisabled,
      form: { error },
    } = this.store
    return (
      <div className="setup-form-body">
        {this.store.concreteDirectFields.map(field => (
          <div className="setup-form-parameter px30" key={field.id}>
            <FormFieldWrapper key={field.id} field={field} />
          </div>
        ))}
        {error && (
          <div className="text center large red bold pt10 w500">* {error}</div>
        )}
        <SubmitButton
          className="setup-form-controls submit-button-container w500"
          text={Localization.translator.saveConfiguration}
          width={SUBMIT_BUTTON_WIDTH}
          loading={isUpdating}
          clickHandler={submitCDConfig}
          disabled={isSubmitBtnDisabled}
        />
      </div>
    )
  }
}
