import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopCalendarUnsafe from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendar'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'

import LogisticsStore from '../../Logistics.store'
import DesktopLogisticsCalendarStore from './LogisticsCalendar.store'

const DesktopCalendar = withErrorBoundary(DesktopCalendarUnsafe)

interface IProps {
  store: DesktopLogisticsCalendarStore
  logisticsStore: LogisticsStore

  state?: DesktopInitialState
}

@inject('state')
@observer
export default class LogisticsCalendar extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    this.props.logisticsStore.initializeCalendarView()
  }

  public render() {
    const { logisticsStore, state, store } = this.props

    const {
      isOneDayCalendarModeActive,
      selectedDatePickerMode,
      onEventClicked,
      setEditableEvent,
      startDate,
      editableCalendarEvent,
      isCreationAvailable,
    } = logisticsStore

    return (
      <div className="full-height ml10 full-width">
        <DesktopCalendar
          store={store}
          multiDayEvents={store.multiDayEvents}
          isDayCalendarModeActive={isOneDayCalendarModeActive}
          onNewEventCreate={store.onNewEventCreate}
          onEventClicked={onEventClicked}
          onEventDateUpdate={store.onEventDateChange}
          setEditableEvent={setEditableEvent}
          isEventCreatingDisabled={
            state.userActiveProjectSettings?.isPresentationUser ||
            !isCreationAvailable
          }
          startDate={startDate}
          calendarMode={selectedDatePickerMode}
          editableEvent={editableCalendarEvent}
          isEventMovable={true}
        />
      </div>
    )
  }
}
