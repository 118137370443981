import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import FilterResetter from '~/client/src/desktop/components/FilterResetter/FilterResetter'
import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import ConcreteDirectFilterButton from '../ConcreteDirectFilterButton'

// localization: no display text to translate

interface IProps {
  store: DeliveryFilterStore
  areCDOrdersDisabled: boolean
  hasCDIntegration: boolean
  toggleShowingOfCDOrders(): void
  forceCloseMap: { [filterType: string]: boolean }

  shouldUseAllFilterTypes?: boolean

  dynamicOverflowListStore: DynamicOverflowListStore
  eventsStore?: DesktopEventStore
}

const OVERFLOW_ITEMS_LABEL_WIDTH = 70
const MIN_CONTAINER_WIDTH = 200

@inject('eventsStore')
@observer
export default class DeliveryFilter extends React.Component<IProps> {
  @observable private isOverflowedFiltersModalShown: boolean = false

  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    props.store.setShouldUseAllFilterTypes(props.shouldUseAllFilterTypes)
    props.store.syncFilters()

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handleDesktopPostEventCallback,
    )
  }

  public UNSAFE_componentWillUpdate(nextProps: IProps) {
    const { shouldUseAllFilterTypes, store } = this.props

    if (nextProps.shouldUseAllFilterTypes !== shouldUseAllFilterTypes) {
      store.setShouldUseAllFilterTypes(nextProps.shouldUseAllFilterTypes)
      store.syncFilters()
    }
  }

  public componentDidUpdate() {
    const {
      forceCloseMap,
      eventsStore: { appState },
    } = this.props

    if (forceCloseMap) {
      const { fieldsMap } = appState.deliveryFilters
      Object.keys(fieldsMap).forEach(filterType => {
        if (forceCloseMap[filterType]) {
          fieldsMap[filterType].isFilterShown = false
        }
      })
    }
  }

  public componentDidMount() {
    this.props.store.resetAllFilters()
  }

  public render() {
    return (
      <MenuCloser
        className="row full-height location-filter x-start"
        closeMenu={this.hideOverflowedFiltersModal}
        isOpen={this.isOverflowedFiltersModalShown}
      >
        {this.renderHandleButtons()}
        {this.renderPopups()}
      </MenuCloser>
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  private renderPopups(): JSX.Element[] {
    return this.filterTypes.map(filterType => {
      const store = this.storesMap[filterType]

      return (
        <SuperFilter
          key={filterType}
          filterStore={store}
          hideOtherModalsFn={this.hideOverflowedFiltersModal}
        />
      )
    })
  }

  private renderHandleButtons(): JSX.Element {
    const { dynamicOverflowListStore, store } = this.props

    return (
      <DynamicOverflowList
        items={this.filterButtons}
        store={dynamicOverflowListStore}
        elementsClassName="vertical-align-middle"
        additionalWidth={OVERFLOW_ITEMS_LABEL_WIDTH}
        minContainerWidthConstraint={MIN_CONTAINER_WIDTH}
      >
        {({ visibleElements, overflowElements, containerRefSetter }) => {
          return (
            <div className="overflowed-filters-list" ref={containerRefSetter}>
              {visibleElements}
              <div className="inline-block vertical-align-middle">
                {this.renderOverflowedFiltersLabel(overflowElements)}
              </div>
              <div className="inline-block pl5 vertical-align-middle">
                <FilterResetter color="light" onReset={store.resetAllFilters} />
              </div>
            </div>
          )
        }}
      </DynamicOverflowList>
    )
  }

  private renderOverflowedFiltersLabel = (
    hiddenElements: JSX.Element[],
  ): JSX.Element => {
    if (!hiddenElements?.length) {
      return null
    }

    return (
      <>
        <FilterHandleButton
          isSelected={this.isOverflowedFiltersModalShown}
          isActive={this.isOverflowedFiltersModalShown}
          className="text large bold px10"
          onClick={this.toggleOverflowedFiltersModal}
        >
          ...
        </FilterHandleButton>
        {this.isOverflowedFiltersModalShown && (
          <div className="absolute col pa10 overflowed-filters-modal overflow-auto bg-white mt10 ba-grey">
            {hiddenElements.map(item => {
              return (
                <div key={item.key} className="pa5">
                  {item}
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }

  private get filterButtons(): JSX.Element[] {
    const { areCDOrdersDisabled, hasCDIntegration, toggleShowingOfCDOrders } =
      this.props

    const filterButtons = this.filterTypes.map(filterType => {
      const store = this.storesMap[filterType]
      return (
        <SuperFilterHandleButton
          className="no-grow"
          key={filterType}
          filterStore={store}
        />
      )
    })

    return hasCDIntegration
      ? [
          ...filterButtons,
          <ConcreteDirectFilterButton
            isActive={!areCDOrdersDisabled}
            toggleShowingOfCDOrders={toggleShowingOfCDOrders}
          />,
        ]
      : filterButtons
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }

  private get filterTypes(): string[] {
    return Object.keys(this.storesMap)
  }

  private handleDesktopPostEventCallback = (eventContext: EventContext) => {
    const { store } = this.props

    store.onDeliveriesReceived(eventContext)

    const [eventType] = eventContext.event

    if (e.RESET_ALL_FILTERS === eventType) {
      Object.keys(this.storesMap).forEach(filterType => {
        const filterStore = this.storesMap[filterType]

        filterStore.clickOnSelectAll()
        filterStore.clickOnApply()
      })

      this.hideOverflowedFiltersModal()
    }
  }

  @action.bound
  private toggleOverflowedFiltersModal() {
    this.isOverflowedFiltersModalShown = !this.isOverflowedFiltersModalShown
  }

  @action.bound
  private hideOverflowedFiltersModal() {
    this.isOverflowedFiltersModalShown = false
  }
}
