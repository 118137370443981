import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import FilterFooter from '~/client/src/desktop/components/Filters/FilterFooter/FilterFooter'
import SavedFiltersActionBar from '~/client/src/desktop/components/Filters/SavedFilters/SavedFiltersActionBar'
import SavedFiltersContainer from '~/client/src/desktop/components/Filters/SavedFilters/SavedFiltersSelector/SavedFiltersContainer'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'

import DesktopDeliverySavedFiltersStore from './DesktopDeliverySavedFilters.store'

import '../SavedFilters/SavedFilters.scss'

// localization: translated

interface IProps {
  desktopDeliverySavedFiltersStore: DesktopDeliverySavedFiltersStore
  getDeliveryCountByCustomFilter: (filter: CustomDeliveryListFilter) => number
  selectCustomFilterById: (id: string) => void
  editCustomFilter: () => void
  selectCustomFilter: (event) => void
  customFilters: CustomDeliveryListFilter[]
}

@observer
export default class DeliverySavedFiltersModal extends React.Component<IProps> {
  public render() {
    const {
      selectCustomFilter,
      customFilters,
      selectCustomFilterById,
      editCustomFilter,
      getDeliveryCountByCustomFilter,
    } = this.props

    const store = this.props.desktopDeliverySavedFiltersStore
    const {
      selectedCustomFilterId,
      appliedCustomFilterId,
      deleteCustomFilter,
      onSaveFiltersClicked,
    } = store
    const activeFilterId =
      store.selectedCustomFilterId || store.appliedCustomFilterId
    return (
      <div className="filter-container saved">
        <SavedFiltersActionBar
          selectedCustomFilterId={selectedCustomFilterId}
          appliedCustomFilterId={appliedCustomFilterId}
          deleteCustomFilter={deleteCustomFilter}
          onSaveFiltersClicked={onSaveFiltersClicked}
          editCustomFilter={editCustomFilter}
        />
        <div className="filter-container-content mx15">
          <SavedFiltersContainer
            activeFilterId={activeFilterId}
            editCustomFilter={editCustomFilter}
            getEntitiesCountByCustomFilter={getDeliveryCountByCustomFilter}
            selectCustomFilterById={selectCustomFilterById}
            customFilters={customFilters}
            selectCustomFilter={selectCustomFilter}
            numberOfEntitiesTitle={Localization.translator.numberOfDeliveries}
          />
        </div>
        <div className="bt-light-grey mt10">
          <FilterFooter
            clickOnApply={this.applyClicked}
            clickOnCancel={store.closeSavedFilters}
          />
        </div>
      </div>
    )
  }

  public componentWillUnmount() {
    this.props.desktopDeliverySavedFiltersStore.clearSavedFilters()
  }

  @action.bound
  private applyClicked() {
    const { applyCustomFilter, selectedCustomFilterId } =
      this.props.desktopDeliverySavedFiltersStore
    if (selectedCustomFilterId) {
      applyCustomFilter()
    }
  }
}
