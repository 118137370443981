import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'

import {
  BlockTypeEnum,
  IWorkflowStep,
  WorkflowStepType,
} from '~/client/graph/types.generated'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  getBlockTypeDisplayName,
  getWorkflowStepDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'
import PermitType from '~/client/src/shared/models/PermitType'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import {
  NO_DEADLINE_OPTION,
  deadlineOptions,
  getDeadlineOptionText,
} from '~/client/src/shared/utils/DeadlineOptions'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { enumToList } from '~/client/src/shared/utils/converters'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ShowInAppModal from '../../../ShowInAppModal/ShowInAppModal'
import { FormGeneralFields } from '../../FormTypeConfigurator.store'
import FormGeneralOptionsDropdown, {
  IFormGeneralTabDropdownOption,
} from './components/FormGeneralOptionsDropdown'

// localization: translated

interface IProps {
  formType: PermitType

  getMonthDayYearAndTimeToDisplay(date: Date | number): string
  onChange(generalProps: FormGeneralFields): void

  formCategoriesStore?: FormCategoriesStore
}

enum TitleMandatority {
  MANDATORY = 'true',
  OPTIONAL = 'false',
}

const blockSortPriority: { [K in BlockTypeEnum]: number } = {
  [BlockTypeEnum.Block]: 1,
  [BlockTypeEnum.Warn]: 2,
  [BlockTypeEnum.Hide]: 3,
}
const allBlockTypes = enumToList(BlockTypeEnum).sort(
  (a, b) => blockSortPriority[a] - blockSortPriority[b],
)

const highlightedBlockTypes = [BlockTypeEnum.Block, BlockTypeEnum.Hide]

const getTitleOptionName = (isWorkflowTitleMandatory: boolean) =>
  isWorkflowTitleMandatory
    ? Localization.translator.mandatory
    : Localization.translator.optional

@inject('formCategoriesStore')
@observer
export default class GeneralFormConfigurator extends React.Component<IProps> {
  public render() {
    const { formType, getMonthDayYearAndTimeToDisplay, formCategoriesStore } =
      this.props
    const {
      name: formTypeName,
      type: formTypeType,
      isEnabled: isShownInApp,
      categoryId,
      shouldBlockOnNonWorkTimes,
      isWorkflowTitleMandatory,
      bookingDeadlineOptions,
      getFormattedUpdatedAt,
      isAutoEndingEnabled,
      canActivateAutoEnd,
      autoEndStepId,
    } = formType

    const lastUpdatedAtLabel = getFormattedUpdatedAt(
      getMonthDayYearAndTimeToDisplay,
    )
    const hasBookingOptions = !!bookingDeadlineOptions?.blockType
    const category = formCategoriesStore.getById(categoryId)

    return (
      <div className="col mw400 pl10">
        <StruxhubInput
          className="overflow-hidden no-flex pb10"
          label={Localization.translator.formName}
          isRequired
          isValid
          isHelperTextCondensed
          value={formTypeName || EMPTY_STRING}
          onChange={this.changeFormName}
        />
        <div className="pb10">
          <FormGeneralOptionsDropdown
            portalClassName="general-opt-dropdown"
            popoverClassName="full-width"
            targetClassName="full-width"
            customSelectorLabel={Localization.translator.formCategory}
            isRequiredTextShown
            selectedValue={category?.id}
            options={this.categoriesOptions}
            onItemClick={this.changeCategory}
          />
        </div>
        <div className="row pt8 pb10">
          <span className="text large line-extra-large">
            {Localization.translator.icon}
          </span>
          <StruxhubTextValueSelector
            className="overflow-hidden no-flex pb10"
            isMinimalisticMode
            isHelperTextCondensed
            isReadOnly
            customIconElement={
              <PermitTypeIcon
                className="row no-grow mr5"
                permitType={formTypeType}
              />
            }
          />
        </div>
        <ShowInAppModal
          formTypeId={null}
          modalClassName="mw400"
          isShown={isShownInApp}
          isDisplayedAsSelector
          onEnableChange={this.changeShowInApp}
        />
        <div className="row py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.workflowTitle}
          </div>
          <FormGeneralOptionsDropdown
            popoverClassName="mw160 mt10"
            itemRowClassName="large"
            selectedValue={isWorkflowTitleMandatory.toString()}
            isHighlighted={isWorkflowTitleMandatory}
            options={this.titleOptions}
            onItemClick={this.changeTitleIsMandatory}
          />
        </div>
        <div className="row general-tab-toggle py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.autoFinishWorkflowOnFinishDate}
          </div>
          <Switch
            className="primary-blue-switch bp3-align-right no-outline-container"
            checked={isAutoEndingEnabled}
            disabled={!canActivateAutoEnd}
            onChange={this.toggleAutomaticEnd}
          />
        </div>
        {isAutoEndingEnabled && (
          <div className="pb10">
            <FormGeneralOptionsDropdown
              portalClassName="general-opt-dropdown"
              popoverClassName="full-width"
              targetClassName="full-width"
              customSelectorLabel={
                Localization.translator.executeAutomaticFinish
              }
              selectedValue={autoEndStepId}
              options={this.autoEndOptions}
              onItemClick={this.changeAutomaticEndStep}
            />
          </div>
        )}
        <div className="row general-tab-toggle py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.blockRequestsForNonWorkTimes}
          </div>
          <Switch
            className="primary-blue-switch bp3-align-right no-outline-container"
            checked={shouldBlockOnNonWorkTimes}
            onChange={this.changeBlockNonWorkTime}
          />
        </div>
        <div className="row py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.workflowBookingDeadline}
          </div>
          <FormGeneralOptionsDropdown
            popoverClassName="mw160 mt10"
            itemRowClassName="large"
            selectedValue={
              bookingDeadlineOptions?.blockType || BlockTypeEnum.Hide
            }
            isHighlighted={
              !hasBookingOptions ||
              highlightedBlockTypes.includes(bookingDeadlineOptions.blockType)
            }
            options={this.deadlineBlockOptions}
            onItemClick={this.changeBookingBlockType}
          />
        </div>
        {hasBookingOptions &&
          bookingDeadlineOptions.blockType !== BlockTypeEnum.Hide && (
            <>
              <span className="text large light pb10">
                {Localization.translator.workflowDeadlineDescr}
              </span>
              <div className="pb10">
                <FormGeneralOptionsDropdown
                  portalClassName="general-opt-dropdown"
                  popoverClassName="full-width"
                  targetClassName="full-width"
                  customSelectorLabel={
                    Localization.translator.workflowsMustBeRequested
                  }
                  selectedValue={String(
                    bookingDeadlineOptions.deadlineInterval || -1,
                  )}
                  options={this.bookingDeadlineOptions}
                  onItemClick={this.changeDeadlineOptions}
                />
              </div>
            </>
          )}
        <div className="row py10 text large line-extra-large">
          <span>{Localization.translator.lastUpdated}</span>
          <span className="no-grow nowrap">{lastUpdatedAtLabel}</span>
        </div>
      </div>
    )
  }

  private changeFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({ name: event.target.value })
  }

  private changeCategory = (categoryId: string) => {
    this.props.onChange({ categoryId })
  }

  private changeShowInApp = () => {
    this.props.onChange({ isEnabled: !this.props.formType.isEnabled })
  }

  private changeBlockNonWorkTime = () => {
    this.props.onChange({
      shouldBlockOnNonWorkTimes: !this.props.formType.shouldBlockOnNonWorkTimes,
    })
  }

  private changeTitleIsMandatory = (
    isWorkflowTitleMandatory: TitleMandatority,
  ) => {
    this.props.onChange({
      isWorkflowTitleMandatory: JSON.parse(isWorkflowTitleMandatory),
    })
  }

  private toggleAutomaticEnd = () => {
    const { formType, onChange } = this.props

    onChange({
      autoEndStepIds: !formType.autoEndStepId ? [formType.initialStep.id] : [],
    })
  }

  private changeAutomaticEndStep = (stepId: string) => {
    this.props.onChange({
      autoEndStepIds: !stepId ? [] : [stepId],
    })
  }

  private changeBookingBlockType = (blockType: BlockTypeEnum) => {
    const { formType, onChange } = this.props

    let deadlineInterval =
      formType.bookingDeadlineOptions?.deadlineInterval || NO_DEADLINE_OPTION
    if (
      blockType !== BlockTypeEnum.Hide &&
      deadlineInterval === NO_DEADLINE_OPTION
    ) {
      deadlineInterval = 1
    }

    onChange({
      bookingDeadlineOptions: {
        deadlineInterval,
        blockType,
      },
    })
  }

  private changeDeadlineOptions = (deadlineOption: string) => {
    const { formType, onChange } = this.props
    const deadlineInterval = Number(deadlineOption)

    if (deadlineInterval === NO_DEADLINE_OPTION) {
      this.changeBookingBlockType(BlockTypeEnum.Hide)
      return
    }

    onChange({
      bookingDeadlineOptions: {
        deadlineInterval,
        blockType: formType.bookingDeadlineOptions.blockType,
      },
    })
  }

  private get titleOptions(): IFormGeneralTabDropdownOption[] {
    return [
      { id: TitleMandatority.MANDATORY, name: getTitleOptionName(true) },
      { id: TitleMandatority.OPTIONAL, name: getTitleOptionName(false) },
    ]
  }

  private get bookingDeadlineOptions(): IFormGeneralTabDropdownOption[] {
    return deadlineOptions.map(opt => ({
      id: opt.toString(),
      name: getDeadlineOptionText(opt),
    }))
  }

  private get deadlineBlockOptions(): IFormGeneralTabDropdownOption[] {
    return allBlockTypes.map(blockType => ({
      id: blockType,
      name: getBlockTypeDisplayName(blockType),
    }))
  }

  private get autoEndOptions(): IFormGeneralTabDropdownOption[] {
    const { workflowSteps, initialStep } = this.props.formType
    return workflowSteps.reduce((list, step, idx) => {
      if (step.type !== WorkflowStepType.RecurringInspection) {
        list.push({
          id: !idx || idx === 1 ? initialStep.id : step.id,
          name: !idx
            ? Localization.translator.atAnyStep
            : this.getAutoEndStepName(step, idx),
          isDisabled: idx === 1,
        })
      }
      return list
    }, [])
  }

  private get categoriesOptions(): IFormGeneralTabDropdownOption[] {
    return this.props.formCategoriesStore.activeCategories.reduce(
      (list, category) => {
        list.push({
          id: category.id,
          name: category.name,
        })
        return list
      },
      [{ id: undefined, name: UNASSIGNED }],
    )
  }

  private getAutoEndStepName = (step: IWorkflowStep, index: number): string => {
    const { workflowSteps } = this.props.formType

    const onlyAt =
      index + 1 === workflowSteps.length
        ? Localization.translator.onlyAt
        : Localization.translator.onlyAtOrAfter
    const stepDisplayName = getWorkflowStepDisplayName(
      step.type,
      getWorkflowStepLevel(step.id, workflowSteps),
    )

    return `${onlyAt} ${Localization.translator.step} ${
      index + 1
    }: ${stepDisplayName}`
  }
}
