import * as React from 'react'

import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Layer, MarkerDragEvent, Source } from 'react-map-gl'

import { SitemapItemShapeType, SitemapLineArrowPosition } from '~/client/graph'
import MapBoxViewerStore, {
  MAX_ITEM_ZOOM_LEVEL,
  PROJECT_MARKER_ZOOM_LEVEL,
} from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import {
  ITEM_EDITOR_SHAPE_FILL_LAYER,
  POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER,
  SHAPE_EDITABLE_POINTS_LAYER,
  SHAPE_SELECTED_FILL_LAYER,
  SHAPE_SELECTED_LINE_LAYER,
  SHAPE_SELECTED_POINTS_LAYER,
} from '~/client/src/shared/components/MapBoxViewer/mapboxConstants'
import GlobeViewMarker from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewMarker'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import PolyLineShapeCoordinates from '~/client/src/shared/components/SitemapHelpers/models/PolyLineShapeCoordinates'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import MapBoxEditorStore from '../../stores/MapBoxEditor.store'
import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  store: MapViewItemsSetupStore
  mapBoxViewerStore: MapBoxViewerStore
  mapBoxEditorStore: MapBoxEditorStore
}

@observer
export default class GlobeVIewEditableItem extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      store: { selectedMapViewItemDrawnPart },
    } = this.props
    if (!this.mapViewItem || !this.mapViewItem.isDisplayed) {
      return null
    }

    if (!selectedMapViewItemDrawnPart || !this.mapViewItem.isDisplayed) {
      return this.renderItem()
    }

    switch (selectedMapViewItemDrawnPart) {
      case MapViewItemDrawnPart.Icon:
      case MapViewItemDrawnPart.Label:
        return (
          <>
            {this.renderShape()}
            {this.renderEditableItemMarker()}
          </>
        )
      case MapViewItemDrawnPart.Shape:
        return (
          <>
            {this.renderItemMarker()}
            {this.renderEditableShape()}
          </>
        )
    }
  }

  private get mapViewItem(): MapViewItemBase {
    return this.props.store.selectedMapViewItem
  }

  @computed
  private get fillShapeGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return this.mapViewItem.sitemapItem.shapeCoordinates.getShapePolygonFeature(
      {
        fill: ThemeMode.getHEXColor(
          this.mapViewItem.globeViewItemProperties.shapeProperties?.fillColor,
        ),
        'fill-opacity':
          this.mapViewItem.globeViewItemProperties.shapeProperties?.fillOpacity,
        itemId: this.mapViewItem.id,
      },
    )
  }

  @computed
  private get lineShapeGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return this.mapViewItem.sitemapItem.shapeCoordinates.getShapeLineStringFeature(
      {
        stroke: ThemeMode.getHEXColor(
          this.mapViewItem.globeViewItemProperties.shapeProperties?.lineColor,
        ),
        'stroke-width':
          this.mapViewItem.globeViewItemProperties.shapeProperties?.lineWidth,
        'text-width':
          18 +
          this.mapViewItem.globeViewItemProperties.shapeProperties?.lineWidth *
            5,
        itemId: this.mapViewItem.id,
      },
    )
  }

  @computed
  private get arrowsShapeGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    if (
      this.mapViewItem.sitemapItem.shapeCoordinates?.coordinates?.length > 1
    ) {
      const arrowPosition = (
        this.mapViewItem.globeViewItemProperties
          .shapeProperties as SitemapPolyLineProperties
      )?.arrowPosition
      if (
        this.mapViewItem.globeViewItemProperties.shapeProperties?.type !==
          SitemapItemShapeType.Polyline ||
        arrowPosition === SitemapLineArrowPosition.None
      ) {
        return null
      }
      const coordinates =
        this.mapViewItem.sitemapItem.shapeCoordinates.shapeFeatureCoordinates
      return {
        type: 'Feature',
        properties: {
          stroke: ThemeMode.getHEXColor(
            this.mapViewItem.globeViewItemProperties.shapeProperties?.lineColor,
          ),
          'text-width':
            25 +
            this.mapViewItem.globeViewItemProperties.shapeProperties
              ?.lineWidth *
              5,
          'text-field': [
            SitemapLineArrowPosition.StartEnd,
            SitemapLineArrowPosition.StartMiddleEnd,
          ].includes(arrowPosition)
            ? '►'
            : '◄',
          'text-color': ThemeMode.getHEXColor(
            this.mapViewItem.globeViewItemProperties.shapeProperties?.lineColor,
          ),
          itemId: this.mapViewItem.id,
        },
        geometry: {
          type: 'LineString',
          coordinates,
        },
      }
    }
  }

  @computed
  private get boundingBoxGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    if (this.mapViewItem.sitemapItem.shapeCoordinates?.coordinates?.length) {
      return this.mapViewItem.sitemapItem.shapeCoordinates.getBoundingBoxFeature(
        {},
      )
    }
  }

  private renderItemMarker() {
    return (
      <GlobeViewMarker
        item={this.mapViewItem}
        key={0}
        onDragEnd={this.onMarkerDragEnd.bind(this, false)}
        onDrag={this.onMarkerDragEnd.bind(this, false)}
        isDraggable={this.isMarkerDraggable}
        isHighlighted={true}
        isSelected={true}
        onMarkerDblClick={this.selectDrawnPartEditing}
      />
    )
  }

  @action.bound
  private selectDrawnPartEditing(part: MapViewItemDrawnPart): void {
    const { selectSitemapItemDrawnPart } = this.props.store
    selectSitemapItemDrawnPart(part)
  }

  private onMarkerDragEnd = (
    isMarkerMoveOnly: boolean,
    e: MarkerDragEvent,
  ): void => {
    const { store, mapBoxEditorStore } = this.props
    if (
      store.selectedMapViewItemDrawnPart &&
      store.selectedMapViewItemDrawnPart === MapViewItemDrawnPart.Shape
    ) {
      return
    }

    const newCoords = {
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    }

    if (!isMarkerMoveOnly) {
      const latDif =
        store.selectedMapViewItem.sitemapItem.coordinates.latitude -
        e.lngLat.lat
      const lngDif =
        store.selectedMapViewItem.sitemapItem.coordinates.longitude -
        e.lngLat.lng
      if (this.mapViewItem.sitemapItem.shapeCoordinates?.coordinates) {
        store.selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates =
          this.mapViewItem.sitemapItem.shapeCoordinates.coordinates.map(c => ({
            latitude: c.latitude - latDif,
            longitude: c.longitude - lngDif,
          }))
      }
    }

    store.selectedMapViewItem.sitemapItem.coordinates = newCoords
    store.addCurrentStateToHistory()
    mapBoxEditorStore.isEditableItemInDragMode = false
  }

  private renderShape = (): JSX.Element => {
    const { selectedShapePointIdx } = this.props.mapBoxEditorStore
    const { selectedMapViewItemDrawnPart } = this.props.store
    if (!this.mapViewItem.sitemapItem?.shapeCoordinates?.coordinates?.length) {
      return null
    }
    return (
      <>
        {this.renderFillLayer()}
        {this.renderLineLayer()}
        {this.renderArrowsLayer()}
        {selectedMapViewItemDrawnPart === MapViewItemDrawnPart.Shape &&
          this.renderShapeEditablePoints()}
        {selectedShapePointIdx !== null && this.renderShapeSelectedPoint()}
        {this.renderBoundingBox()}
      </>
    )
  }

  private renderFillLayer = (): JSX.Element => {
    if (
      (this.mapViewItem.sitemapItem.shapeCoordinates?.coordinates?.length < 3 &&
        this.mapViewItem.sitemapItem.shapeCoordinates?.type !==
          SitemapItemShapeType.Circle) ||
      (this.mapViewItem.sitemapItem.shapeCoordinates.type ===
        SitemapItemShapeType.Polyline &&
        !this.mapViewItem.sitemapItem.shapeCoordinates.isClosed)
    ) {
      return null
    }
    const id =
      this.props.store.selectedMapViewItemDrawnPart ===
      MapViewItemDrawnPart.Shape
        ? ITEM_EDITOR_SHAPE_FILL_LAYER
        : SHAPE_SELECTED_FILL_LAYER
    return (
      <Source
        id="item_editor_fill_shape_data"
        type="geojson"
        data={this.fillShapeGeoJson}
      >
        <Layer
          id={id}
          type="fill"
          paint={{
            'fill-color': ['get', 'fill'],
            'fill-opacity': ['get', 'fill-opacity'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderLineLayer = (): JSX.Element => {
    if (
      this.mapViewItem.sitemapItem.shapeCoordinates?.coordinates?.length < 2 &&
      this.mapViewItem.sitemapItem.shapeCoordinates?.type !==
        SitemapItemShapeType.Circle
    ) {
      return null
    }

    const id =
      this.props.store.selectedMapViewItemDrawnPart ===
      MapViewItemDrawnPart.Shape
        ? 'item_editor_shape_line'
        : SHAPE_SELECTED_LINE_LAYER
    return (
      <Source
        id="item_editor_shape_line_data"
        type="geojson"
        data={this.lineShapeGeoJson}
      >
        <Layer
          id={id}
          type="line"
          layout={{
            'line-join': 'none',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': ['get', 'stroke'],
            'line-width': ['get', 'stroke-width'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderArrowsLayer = (): JSX.Element => {
    if (this.mapViewItem.sitemapItem.shapeCoordinates.isClosed) {
      return null
    }

    return (
      <Source
        id="item_editor_arrows_shape_data"
        type="geojson"
        data={this.arrowsShapeGeoJson}
      >
        <Layer
          id="item_editor_arrows_line"
          type="symbol"
          layout={{
            'text-field': ['get', 'text-field'],
            'text-size': ['get', 'text-width'],
            'symbol-placement': 'line',
            'symbol-avoid-edges': true,
            'symbol-spacing': [
              'interpolate',
              // Set the exponential rate of change to 0.5
              ['linear'],
              ['zoom'],
              // When zoom is 10, spacing will be 1px.
              10,
              1,
              // When zoom is 20 or higher, spacing will be 60px.
              20,
              60,
            ],
            'text-rotation-alignment': 'map',
            'text-keep-upright': false,
          }}
          paint={{
            'text-color': ['get', 'text-color'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderBoundingBox = (): JSX.Element => {
    if (!this.mapViewItem.sitemapItem?.shapeCoordinates?.coordinates?.length) {
      return null
    }
    return (
      <Source
        id="bounding_box_editor_data"
        type="geojson"
        data={this.boundingBoxGeoJson}
      >
        <Layer
          id="bounding_box_editor_line"
          type="line"
          paint={{
            'line-color': ThemeMode.getHEXColor(Colors.primary60),
            'line-width': 2,
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderItem(): JSX.Element {
    return (
      <>
        {this.renderShape()}
        {this.renderItemMarker()}
      </>
    )
  }

  private renderEditableItemMarker = (): JSX.Element => {
    return (
      <GlobeViewMarker
        item={this.mapViewItem}
        key={0}
        onDragEnd={this.onMarkerDragEnd.bind(this, true)}
        onDrag={this.onMarkerDragEnd.bind(this, true)}
        isDraggable={this.isMarkerDraggable}
        isSelected={true}
        onMarkerDblClick={this.selectDrawnPartEditing}
      />
    )
  }

  private renderEditableShape = (): JSX.Element => {
    return this.renderShape()
  }

  private renderShapeSelectedPoint(): JSX.Element {
    return (
      <>
        <Source
          id="shape_selected_points_data"
          type="geojson"
          data={this.shapeSelectedPointGeoJson}
        >
          <Layer
            id={SHAPE_SELECTED_POINTS_LAYER}
            type="circle"
            paint={{
              'circle-radius': ['get', 'circle-radius'],
              'circle-color': 'blue',
              'circle-stroke-width': 1,
              'circle-stroke-color': 'blue',
            }}
            maxzoom={MAX_ITEM_ZOOM_LEVEL}
            minzoom={PROJECT_MARKER_ZOOM_LEVEL}
          />
        </Source>
        {this.mapViewItem.sitemapItem.shapeCoordinates.type ===
          SitemapItemShapeType.Polyline &&
          this.renderPolylineAdditionalPoints()}
      </>
    )
  }

  private renderShapeEditablePoints(): JSX.Element {
    return (
      <>
        <Source
          id="shape_editable_points_data"
          type="geojson"
          data={this.shapeEditablePointsGeoJson}
        >
          <Layer
            id={SHAPE_EDITABLE_POINTS_LAYER}
            type="circle"
            paint={{
              'circle-radius': ['get', 'circle-radius'],
              'circle-color': 'white',
              'circle-stroke-width': 1,
              'circle-stroke-color': 'blue',
            }}
            maxzoom={MAX_ITEM_ZOOM_LEVEL}
            minzoom={PROJECT_MARKER_ZOOM_LEVEL}
          />
        </Source>
        {this.mapViewItem.sitemapItem.shapeCoordinates.type ===
          SitemapItemShapeType.Polyline &&
          this.renderPolylineAdditionalPoints()}
      </>
    )
  }

  private renderPolylineAdditionalPoints(): JSX.Element {
    return (
      <Source
        id="polyline_additional_editable_points_data"
        type="geojson"
        data={this.polylineAdditionalEditablePointsGeoJson}
      >
        <Layer
          id={POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER}
          type="circle"
          paint={{
            'circle-radius': ['get', 'circle-radius'],
            'circle-color': ['get', 'circle-color'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  @computed
  private get shapeSelectedPointGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    const { selectedShapePointIdx } = this.props.mapBoxEditorStore
    const coords =
      this.mapViewItem.sitemapItem.shapeCoordinates.shapeEditorPointCoordinates.filter(
        (p, i) => i === selectedShapePointIdx,
      )
    return {
      type: 'FeatureCollection',
      features: coords?.map((c, idx) => ({
        type: 'Feature',
        properties: {
          idx,
          'circle-radius':
            this.mapViewItem.globeViewItemProperties.shapeProperties
              ?.lineWidth + 5 || 8,
          shapeType: this.mapViewItem.sitemapItem.shapeCoordinates.type,
        },
        geometry: {
          type: 'Point',
          coordinates: c,
        },
      })),
    }
  }

  @computed
  private get shapeEditablePointsGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    const coords =
      this.mapViewItem.sitemapItem.shapeCoordinates.shapeEditorPointCoordinates

    return {
      type: 'FeatureCollection',
      features: coords?.map((c, idx) => ({
        type: 'Feature',
        properties: {
          idx,
          'circle-radius':
            this.mapViewItem.globeViewItemProperties.shapeProperties
              ?.lineWidth + 5 || 8,
          shapeType: this.mapViewItem.sitemapItem.shapeCoordinates.type,
        },
        geometry: {
          type: 'Point',
          coordinates: c,
        },
      })),
    }
  }

  @computed
  private get polylineAdditionalEditablePointsGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    const coords = (
      this.mapViewItem.sitemapItem.shapeCoordinates as PolyLineShapeCoordinates
    ).shapeAdditionalEditorPointCoordinates
    return {
      type: 'FeatureCollection',
      features: coords?.map((c, idx) => ({
        type: 'Feature',
        properties: {
          idx,
          shapeType: this.mapViewItem.sitemapItem.shapeCoordinates.type,
          'circle-radius':
            this.mapViewItem.globeViewItemProperties.shapeProperties
              ?.lineWidth + 3 || 5,
          'circle-color': ThemeMode.getHEXColor(
            this.mapViewItem.globeViewItemProperties.shapeProperties?.lineColor,
          ),
        },
        geometry: {
          type: 'Point',
          coordinates: c,
        },
      })),
    }
  }

  private get isMarkerDraggable(): boolean {
    const { store } = this.props
    return (
      !store.selectedMapViewItemDrawnPart ||
      store.selectedMapViewItemDrawnPart !== MapViewItemDrawnPart.Shape
    )
  }
}
