import * as React from 'react'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'

const CONTENT_OFFSET = 16
const ICON_SIZE = 16

interface IProps {
  isItemAssigned: (item: MapViewItemBase) => boolean
  isAttributeAssigned: (item: MapViewItemBase) => boolean
  item: MapViewItemBase
  childrenCount?: number
}

export default class SitemapItemTag extends React.Component<IProps> {
  private text: HTMLDivElement = null

  public componentDidUpdate() {
    this.setEllipsisStyle()
  }

  public render() {
    const { isItemAssigned, item, childrenCount, isAttributeAssigned } =
      this.props
    const shouldShowAsTag = isItemAssigned(item) || isAttributeAssigned(item)

    return (
      <SitemapAttributeTag
        shouldShowAsTag={shouldShowAsTag}
        sitemapItem={item.sitemapItem}
        dataObject={item.dataObject}
        iconSize={ICON_SIZE}
      >
        <div className="text large ellipsis" ref={this.setTextRef}>
          {item.displayName || item.sitemapItem.name}
          {!!childrenCount && (
            <span className="text large light">&nbsp;({childrenCount})</span>
          )}
        </div>
      </SitemapAttributeTag>
    )
  }

  private setTextRef = (ref: HTMLDivElement) => {
    this.text = ref
    this.setEllipsisStyle()
  }

  private setEllipsisStyle() {
    if (!this.text) {
      return
    }

    const content = this.text.parentElement
    const tag = content.parentElement

    if (content.clientWidth > tag.clientWidth - CONTENT_OFFSET) {
      content.style.maxWidth = `${tag.clientWidth - CONTENT_OFFSET}px`
    }
  }
}
