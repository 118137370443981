import { FilterType } from '../enums/FilterType'
import Localization from '../localization/LocalizationManager'

export function getTitleByFilterType(filterType: FilterType): string {
  switch (filterType) {
    case FilterType.Company:
      return Localization.translator.company
    case FilterType.Equipment:
      return Localization.translator.equipment
    case FilterType.Location:
      return Localization.translator.location
    case FilterType.ResponsibleContact:
      return Localization.translator.responsibleContact
    case FilterType.Status:
      return Localization.translator.status
    case FilterType.Type:
      return Localization.translator.workflow

    default:
      throw new Error(filterType + ' is unhandled')
  }
}
