import { action, computed } from 'mobx'

import BaseGroupingOptionsStore, {
  EMPTY_OPTION,
  ICustomBandOption,
} from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions.store'
import { IBandOption } from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import FilterInfo from '~/client/src/shared/stores/substates/FilterInfo'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

export default class LogisticsGroupingOptionsStore extends BaseGroupingOptionsStore {
  public leftCaptionKey = KnownTranslatorKeys.options

  public constructor(
    protected readonly eventsStore: DesktopEventStore,
    protected readonly onShowChanged: (isShown: boolean) => void,
    protected readonly onChanged: () => void,
    private filters: IFilters,
    private groupingOptions: string[],
    private defaultGroupingOption: string,
    private desktopFilterMap: { [key: string]: FilterInfo },
    private translatorKeyGetter: (id: string) => KnownTranslatorKeys,
  ) {
    super(eventsStore, onShowChanged)
  }

  @computed
  public get bandsOptions(): IBandOption[] {
    return this.groupingOptions.map((option, index) => {
      return {
        id: option,
        nameTraslatorKey: this.translatorKeyGetter(option),
        num: index,
      }
    })
  }

  @computed
  public get groupByCaption(): string {
    return this.getGroupByCaptionByOption(this.appliedOption)
  }

  @action.bound
  public getGroupByCaptionByOptionId = (optionId: string): string => {
    const option = this.bandsOptions.find(o => o.id === optionId)

    return this.getGroupByCaptionByOption(option)
  }

  @action.bound
  private getGroupByCaptionByOption = (option: IBandOption): string => {
    if (!option) {
      return EMPTY_STRING
    }

    const { name, nameTraslatorKey } = option
    return nameTraslatorKey
      ? Localization.getText(nameTraslatorKey)
      : name || EMPTY_STRING
  }

  @computed
  public get options(): ICustomBandOption[] {
    const options: ICustomBandOption[] = [
      {
        name: EMPTY_OPTION,
        caption: EMPTY_OPTION,
      },
      ...Object.values(this.desktopFilterMap).map(({ name, getCaption }) => {
        return {
          name,
          caption: getCaption(),
        }
      }),
    ]

    return options
  }

  @action.bound
  public initPopup() {
    this.selectedBandsOptionIdDraft = this.appliedOption.id
  }

  @action.bound
  public applyGroupingOptions() {
    this.hideGroupingOptions()

    if (this.filters.groupingKey !== this.selectedBandsOptionIdDraft) {
      this.filters.groupingKey = this.selectedBandsOptionIdDraft
      this.onChanged()
    }
  }

  @action.bound
  public resetGroupingOptions() {
    this.selectedBandsOptionIdDraft = this.defaultGroupingOption
  }

  public get appliedOption() {
    return this.bandsOptions.find(o => o.id === this.filters.groupingKey)
  }

  public get areDefaultOptionsSelected(): boolean {
    return this.appliedOption.id === this.defaultGroupingOption
  }
}
