import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'

import MapViewSetUpStore from '../../MapViewSetUp.store'
import { ILocationListObject, locationObjects } from './locationObjectsHelper'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 24

export interface IProps {
  store?: MapViewSetUpStore
}

@observer
export default class LocationObjectsSelector extends React.Component<IProps> {
  public render() {
    return (
      <div className="object-selector absolute bg-white">
        {locationObjects.map((section, sIdx) => {
          return section.map((object, idx) => {
            if (
              [MapViewItemType.Line, MapViewItemType.TextBox].includes(
                object?.sitemapItemType,
              )
            ) {
              return null
            }
            return (
              <div
                className={classList({
                  'object-wrapper pointer relative': true,
                })}
                key={`${sIdx} ${idx}`}
              >
                {this.renderObject(object, `${sIdx} ${idx}`)}
                {this.renderObjectChildren(object)}
              </div>
            )
          })
        })}
      </div>
    )
  }

  private renderObject = (
    object: ILocationListObject,
    key: React.Key,
  ): JSX.Element => {
    return (
      <div
        className="row object"
        onClick={this.onObjectClicked.bind(this, object)}
      >
        <div key={key} className="object-icon pointer no-grow">
          <object.Icon color={Colors.neutral60} size={ICON_SIZE} />
          {object.children && <div className="arrow" />}
        </div>
        <div className="text large">{object.getLabel()}</div>
      </div>
    )
  }

  private renderObjectChildren = (object: ILocationListObject): JSX.Element => {
    if (!object.children) {
      return null
    }

    return (
      <div className="object-children absolute bg-white pointer">
        {object.children.map((childObject, childIdx) => {
          return this.renderObject(childObject, childIdx)
        })}
      </div>
    )
  }

  private onObjectClicked(object: ILocationListObject) {
    const { mapViewItemsSetupStore } = this.props.store
    const { dataObjectType } = object
    if (dataObjectType) {
      mapViewItemsSetupStore.createAttributeFromTilesetPolyshape(dataObjectType)
    }
  }
}
