import * as React from 'react'

import { Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { WorkflowStepType } from '~/client/graph'
import { WorkflowStepCategory } from '~/client/src/shared/enums/WorkflowStepCategory'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  getWorkflowCategoryDisplayName,
  getWorkflowStepDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'

// localization: translated

interface IProps {
  isOpened: boolean
  hideDialog(): void
}

interface IStepDescriptionData {
  category?: string
  step: string
  description: string
  constraints?: string
  hasBic?: boolean
  isMultiple?: boolean
}

@observer
export default class WorkflowStepDescriptionsDialog extends React.Component<IProps> {
  public render() {
    const { isOpened, hideDialog } = this.props
    return (
      <Dialog
        isOpen={isOpened}
        className="workflow-step-descriptions-modal brada8 overflow-hidden"
        canEscapeKeyClose
        canOutsideClickClose
        onClose={hideDialog}
      >
        {this.descriptionTable}
      </Dialog>
    )
  }

  private get descriptionTable(): JSX.Element {
    const { stepCategory, workflowStep, constraints, bicText, multipleText } =
      Localization.translator.workflowTableText

    return (
      <div className="col overflow-auto bg-white">
        <table className="pb10" cellPadding={10} cellSpacing={0}>
          <thead className="bg-white sticky top0 text large left bold">
            <tr>
              <th className="bb-palette-grey">{stepCategory}</th>
              <th className="bb-palette-grey">{workflowStep}</th>
              <th className="bb-palette-grey">
                {Localization.translator.description}
              </th>
              <th className="bb-palette-grey">{constraints}</th>
              <th className="bb-palette-grey">{bicText}</th>
              <th className="bb-palette-grey">{multipleText}</th>
            </tr>
          </thead>
          <tbody>
            {this.tableData.map((data, idx) => {
              const borderClass = !idx
                ? null
                : data.category
                ? 'bt-palette-grey'
                : 'bt-cool-grey'

              return (
                <tr key={data.step}>
                  <td
                    className={classList({
                      'text large line-extra-large': true,
                      [borderClass]: !!borderClass,
                    })}
                  >
                    {data.category}
                  </td>
                  <td
                    className={classList({
                      'text large bold line-extra-large': true,
                      [borderClass]: !!borderClass,
                    })}
                  >
                    {data.step}
                  </td>
                  <td
                    className={classList({
                      'text large line-extra-large': true,
                      [borderClass]: !!borderClass,
                    })}
                  >
                    {data.description}
                  </td>
                  <td
                    className={classList({
                      'text large line-extra-large': true,
                      [borderClass]: !!borderClass,
                    })}
                  >
                    {data.constraints}
                  </td>
                  <td
                    className={classList({
                      'text large line-extra-large': true,
                      bold: !!data.hasBic,
                      [borderClass]: !!borderClass,
                    })}
                  >
                    {data.hasBic
                      ? Localization.translator.yes
                      : Localization.translator.no}
                  </td>
                  <td
                    className={classList({
                      'text large line-extra-large': true,
                      bold: !!data.isMultiple,
                      [borderClass]: !!borderClass,
                    })}
                  >
                    {data.isMultiple
                      ? Localization.translator.yes
                      : Localization.translator.no}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  private get tableData(): IStepDescriptionData[] {
    const {
      submissionDescr,
      submissionConstr,
      requestDescr,
      requestConstr,
      reviewDescr,
      approvalDescr,
      approvalConstr,
      startDescr,
      autoStartDescr,
      onSiteDescr,
      readyToInspectDescr,
      inspectionDescr,
      recurringDescr,
      recurringConstr,
      finishDescr,
    } = Localization.translator.workflowTableText

    return [
      {
        category: getWorkflowCategoryDisplayName(
          WorkflowStepCategory.INITIATION,
        ),
        step: getWorkflowStepDisplayName(WorkflowStepType.Submission),
        description: submissionDescr,
        constraints: submissionConstr,
      },
      {
        step: getWorkflowStepDisplayName(WorkflowStepType.Request),
        description: requestDescr,
        constraints: requestConstr,
      },
      {
        category: getWorkflowCategoryDisplayName(WorkflowStepCategory.APPROVAL),
        step: getWorkflowStepDisplayName(WorkflowStepType.Review),
        description: reviewDescr,
        isMultiple: true,
        hasBic: true,
      },
      {
        step: getWorkflowStepDisplayName(WorkflowStepType.Approval),
        description: approvalDescr,
        constraints: approvalConstr,
        hasBic: true,
      },
      {
        category: getWorkflowCategoryDisplayName(WorkflowStepCategory.START),
        step: getWorkflowStepDisplayName(WorkflowStepType.Start),
        description: startDescr,
        hasBic: true,
      },
      {
        step: Localization.translator.automaticStart,
        description: autoStartDescr,
      },
      {
        step: getWorkflowStepDisplayName(WorkflowStepType.OnSite),
        description: onSiteDescr,
        isMultiple: true,
        hasBic: true,
      },
      {
        category: getWorkflowCategoryDisplayName(
          WorkflowStepCategory.INSPECTION,
        ),
        step: getWorkflowStepDisplayName(WorkflowStepType.ReadyToInspect),
        description: readyToInspectDescr,
        isMultiple: true,
        hasBic: true,
      },
      {
        step: getWorkflowStepDisplayName(WorkflowStepType.Inspection),
        description: inspectionDescr,
        isMultiple: true,
        hasBic: true,
      },
      {
        step: getWorkflowStepDisplayName(WorkflowStepType.RecurringInspection),
        description: recurringDescr,
        constraints: recurringConstr,
      },
      {
        category: getWorkflowCategoryDisplayName(WorkflowStepCategory.FINISH),
        step: getWorkflowStepDisplayName(WorkflowStepType.Finish),
        description: finishDescr,
        hasBic: true,
      },
    ]
  }
}
