import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

// localization: translated

interface IProps {
  isDisabled?: boolean
  section: ISavedFiltersSection
}

export interface ISavedFiltersSection {
  getLabel: () => string
  icon: JSX.Element
  onClick: () => void
  isDisabled?: boolean
}

@observer
export default class SectionButton extends React.Component<IProps> {
  public render() {
    const {
      section: { onClick, getLabel, icon },
    } = this.props
    return (
      <button
        className={classList({
          'row x-between section ba-primary-blue bg-white text large primary-blue pointer brada2 pl5 m5':
            true,
          'disabled default-cursor': this.isDisabled,
        })}
        disabled={this.isDisabled}
        onClick={onClick}
      >
        {getLabel()}
        <span className="no-grow">{icon}</span>
      </button>
    )
  }

  private get isDisabled(): boolean {
    const { isDisabled, section } = this.props
    return isDisabled || section.isDisabled
  }
}
