import * as React from 'react'

import { Dialog } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { DELETE_TILESET } from '~/client/src/shared/stores/EventStore/eventConstants'

import MapViewSetUpStore from '../AppsSitemap/MapViewSetUp.store'

import './DeleteTilesetConfirmDialog.scss'

interface IProps {
  store: MapViewSetUpStore
  state?: DesktopInitialState
}

const areYouSureYouWantToDeleteThisTileset =
  'Are you sure you want to delete this tileset?'

@inject('state')
@observer
export default class DeleteTilesetConfirmDialog extends React.Component<IProps> {
  public render() {
    const { tilesetsSetupStore } = this.props.store
    const { deletableTileset, hideTilesetDeleteConfirmDialog } =
      tilesetsSetupStore

    if (!deletableTileset) {
      return null
    }

    return (
      <Dialog
        isOpen={!!deletableTileset}
        title=""
        isCloseButtonShown={true}
        canOutsideClickClose={false}
        onClose={hideTilesetDeleteConfirmDialog}
        className="confirm-dialog delete-tileset hidden-header"
      >
        <div className="col">
          <div className="text bold extra-large pa20">
            {areYouSureYouWantToDeleteThisTileset}
          </div>
          <div className="row px20 mb30">
            <div className="col full-height ml20 mw270">
              <div className="text extra-large bold text-ellipsis">
                {deletableTileset.name}
              </div>
            </div>
          </div>
          {this.renderFooter()}
        </div>
      </Dialog>
    )
  }

  private renderFooter() {
    const {
      store: {
        tilesetsSetupStore: {
          applyTilesetDeleteConfirmDialog,
          hideTilesetDeleteConfirmDialog,
        },
      },
      state: { loading },
    } = this.props

    const isSitemapBeingDeleted = loading.get(DELETE_TILESET)
    return (
      <div className="bt-input-border row x-around dialog-footer">
        <div
          className="text large ml20 mr10 row x-center y-center pointer action-button mt5 mb5"
          onClick={hideTilesetDeleteConfirmDialog}
        >
          {Localization.translator.cancel}
        </div>
        <div
          className={classList({
            'bg-blue-bright brada5 text white large mr20 ml10 row x-center y-center pointer action-button mt5 mb5':
              true,
            'inactive-element': isSitemapBeingDeleted,
          })}
          onClick={applyTilesetDeleteConfirmDialog}
        >
          {isSitemapBeingDeleted ? <Loader /> : Localization.translator.delete}
        </div>
      </div>
    )
  }
}
