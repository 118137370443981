import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { FilterType, LocationType } from '~/client/graph'
import SitemapItemsColorPicker from '~/client/src/desktop/components/SitemapItemsColorPicker/SitemapItemsColorPicker'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import ProjectSetUpPage from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPages'
import {
  ACTION_KEY,
  DTO_ID_KEY,
  DelayedActionType,
  NEW_SITEMAP_TYPE_KEY,
  SITEMAP_ID_KEY,
} from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/models/ISitemapDelayedAction'
import HierarchyChains from '~/client/src/shared/components/HierarchyChains'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import IDeliveryAttributeStore from '~/client/src/shared/stores/domain/interfaces/IDeliveryAttributeStore'
import IDeliveryAttributeWithColorStore from '~/client/src/shared/stores/domain/interfaces/IDeliveryAttributeWithColorStore'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'
import IDeliveryAttributeWithColorDto from '~/client/src/shared/types/IDeliveryAttributeWithColorDto'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import DeliveryRequestSetUpStore from '../../../DeliveryRequestSetUp.store'
import AttributeNameEditor from './AttributeNameEditor'
import SitemapSelector from './SitemapSelector'

export interface IDeliveryAttributeEditorProps {
  store: IDeliveryAttributeStore
  datePickerStore?: TwoMonthsDatePickerStore
  deliveryRequestSetUpStore: DeliveryRequestSetUpStore
  canClose?: boolean
  isColorPickerShown?: boolean
  canAssignSitemap?: boolean
  sitemapPostCreationAction?: DelayedActionType
  isEditAllowed?: boolean
  isBeingEdited?: boolean
  title: string
  projectSetUpPageStore?: ProjectSetUpPageStore
  isKeySection?: boolean
  activityFiltersStore?: ActivityFiltersStore
  tagsStore?: TagsStore
}

const ESCAPE_KEY_CODE = 27
const ENTER_KEY_CODE = 13

const enterNameOf = (value: string) => `Enter name of ${value?.toLowerCase()}`

@inject('activityFiltersStore', 'tagsStore')
@observer
class DeliveryAttributeEditor extends React.Component<IDeliveryAttributeEditorProps> {
  @observable private newAttributeName: string = ''
  @observable private existingAttributeName: string = ''

  public render() {
    return (
      <div className="delivery-attribute-editor mb15">
        {this.renderExistingValues()}
        {this.renderInput()}
      </div>
    )
  }

  private renderExistingValues() {
    const {
      store,
      isColorPickerShown,
      isEditAllowed,
      canAssignSitemap,
      isKeySection,
    } = this.props

    const { editingElementId } = this.props.deliveryRequestSetUpStore

    const items = store.list.slice()

    return items.map((item, index) => {
      return (
        <div
          key={index}
          className={classList({
            'col y-center mb10': true,
            'bb-input-border': isKeySection && canAssignSitemap,
          })}
        >
          <div className="row">
            <div className="delivery-attribute-editor-result-row row pb7">
              <div className="pl10">
                {item.id === editingElementId ? (
                  <div className="result-row-name">
                    <StruxhubInput
                      isMinimalisticMode={true}
                      autoFocus={true}
                      value={this.existingAttributeName || item.name}
                      onBlur={this.saveDeliveryAttributeDtoName.bind(
                        this,
                        item,
                      )}
                      onKeyDown={this.onEditInputKeyDown.bind(this, item)}
                      onChange={this.updateExistingAttributeName}
                    />
                  </div>
                ) : (
                  <span className="result-row-name text-ellipsis text grey-light extra-large">
                    {item.name}
                    <HierarchyChains
                      className="text light ml30"
                      hierarchyChains={this.getHierarchyChains(
                        item as LocationBase,
                      )}
                    />
                  </span>
                )}
                {isEditAllowed && this.renderEditFieldPicker(item.id)}
                {isColorPickerShown &&
                  this.renderColorPicker(
                    item as IDeliveryAttributeWithColorDto,
                  )}
              </div>
              <Icon
                className="bg-grey-scale-light br-rounded pointer text white mr20"
                icon={IconNames.SMALL_CROSS}
                onClick={this.removeItem.bind(this, item.id)}
              />
            </div>
          </div>
          {canAssignSitemap &&
            this.renderSitemapSelector(item as ILocationAttributeDto)}
        </div>
      )
    })
  }

  private onEditInputKeyDown(
    item: IDeliveryAttributeDto,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if ([ESCAPE_KEY_CODE, ENTER_KEY_CODE].includes(event.keyCode)) {
      this.saveDeliveryAttributeDtoName(item)
    }
  }

  @action.bound
  private clearExistingAttributeName() {
    this.existingAttributeName = ''
  }

  @action.bound
  private updateExistingAttributeName(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    this.existingAttributeName = event.target.value
  }

  @action.bound
  private updateNewAttributeName(event: React.ChangeEvent<HTMLInputElement>) {
    this.newAttributeName = event.target.value
  }

  private renderColorPicker(item: IDeliveryAttributeWithColorDto) {
    return (
      <SitemapItemsColorPicker
        value={item.color}
        className="float-right"
        onChange={this.onColorChanged.bind(this, item.id)}
      />
    )
  }

  private renderEditFieldPicker(id: string) {
    const { store, deliveryRequestSetUpStore } = this.props
    return (
      <AttributeNameEditor
        attributeId={id}
        clearNewAttributeName={this.clearExistingAttributeName}
        newAttributeName={this.existingAttributeName}
        store={store}
        deliveryRequestSetUpStore={deliveryRequestSetUpStore}
      />
    )
  }

  private saveDeliveryAttributeDtoName(item: IDeliveryAttributeDto) {
    const { store, deliveryRequestSetUpStore } = this.props
    if (this.existingAttributeName) {
      store.updateName(item.id, this.existingAttributeName)
      deliveryRequestSetUpStore.editingElementId = ''
    }
  }

  private onColorChanged(id: string, newColor: string) {
    const store = this.props.store as IDeliveryAttributeWithColorStore
    store.updateColor(id, newColor)
  }

  private removeItem(id: string) {
    const { store, title, deliveryRequestSetUpStore } = this.props
    const { showDeleteConfirmationDialog } = deliveryRequestSetUpStore
    showDeleteConfirmationDialog(id, title.toUpperCase(), store)
  }

  @action.bound
  private async addNewItem() {
    if (!this.newAttributeName) {
      return
    }

    const item: LocationBase = await this.props.store.createFromName(
      this.newAttributeName,
    )
    this.newAttributeName = ''

    if (item?.type === LocationType.Building) {
      this.props.activityFiltersStore.setDefaultActivityLocationRelationships(
        FilterType.Building,
        item,
      )
    }
  }

  private renderInput(): JSX.Element {
    return (
      <div className="row y-center delivery-attribute-editor-input">
        <StruxhubInput
          label={enterNameOf(this.props.title)}
          isRequiredTextHidden={true}
          value={this.newAttributeName}
          onChange={this.updateNewAttributeName}
          onValueReset={this.resetNewAttributeName}
          onKeyDown={this.onAddInputKeyDown}
        />
        <Icons.Plus
          className="pt10 ml10 add-icon no-grow"
          onClick={this.addNewItem}
        />
      </div>
    )
  }

  private onAddInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if ([ESCAPE_KEY_CODE, ENTER_KEY_CODE].includes(event.keyCode)) {
      this.addNewItem()
    }
  }

  private renderSitemapSelector(item: ILocationAttributeDto) {
    if (!this.props.canAssignSitemap) {
      return null
    }

    const { assignedSitemaps = [] } = item
    return assignedSitemaps.map((sitemapId, idx) => (
      <SitemapSelector
        key={idx}
        sitemapId={sitemapId}
        onChange={this.onItemSitemapChange.bind(this, item, sitemapId)}
        onNewSitemapUpload={this.onNewSitemapUpload.bind(this, item)}
        isExtraSmall={true}
        onNameClick={this.onSelectedSitemapClick.bind(this, sitemapId)}
      />
    ))
  }

  private onNewSitemapUpload(item: ILocationAttributeDto, type: SitemapType) {
    const { projectSetUpPageStore, sitemapPostCreationAction } = this.props
    projectSetUpPageStore.navigateTo(ProjectSetUpPage.MAP_SETUP, {
      [NEW_SITEMAP_TYPE_KEY]: type,
      [ACTION_KEY]: sitemapPostCreationAction,
      [DTO_ID_KEY]: item.id,
    })
  }

  private onItemSitemapChange(
    item: LocationAttributeBase,
    oldSitemapId: string,
    sitemapId: string,
  ) {
    const { store } = this.props
    item.deassignSitemap(oldSitemapId)
    item.assignSitemap(sitemapId)
    store.saveItem(item)
  }

  private onSelectedSitemapClick(sitemapId: string) {
    if (!sitemapId) {
      return
    }
    const { projectSetUpPageStore } = this.props
    projectSetUpPageStore.navigateTo(ProjectSetUpPage.MAP_SETUP, {
      [ACTION_KEY]: DelayedActionType.SelectSitemap,
      [SITEMAP_ID_KEY]: sitemapId,
    })
  }

  private getHierarchyChains(item: LocationBase): string[] {
    const { tagStoreByTagTypeMap } = this.props.tagsStore
    return item.getHierarchyChains?.(tagStoreByTagTypeMap) || []
  }

  @action.bound
  private resetNewAttributeName() {
    this.newAttributeName = ''
  }
}

export default DeliveryAttributeEditor
