import { action, observable } from 'mobx'

import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import { SavedViewEditableFields } from '~/client/src/shared/enums/SavedViewEditableFields'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'
import CustomWorkflowsFilter from '~/client/src/shared/types/CustomWorkflowFilter'
import { UNASSIGNED_FILTER_VALUE } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'

// localization: no display text to translate

export default abstract class DesktopSavedFiltersStore {
  @observable public isShown: boolean = false
  @observable public shouldSaveFilterDialogShow: boolean = false
  @observable public shouldActivityPresetDialogShow: boolean = false
  @observable public editableFilterName: string = ''
  @observable public editableFilterIsPublic: boolean = false
  @observable public selectedCustomFilterId: string = null
  @observable public selectedActivityPresetId: string = null
  // TODO: Move below fields to client\src\desktop\components\Filters\CustomWorkflowsFilters\DesktopCustomWorkflowsFilters.store.ts
  @observable public isEditingSavedView: boolean = false
  @observable public editableSavedViewId: string = null
  @observable public editableSavedViewName: string = ''
  @observable public editableSavedViewIsPublic: boolean = false
  @observable public editableSavedViewField: SavedViewEditableFields =
    SavedViewEditableFields.None
  public abstract customFilterInstance:
    | CustomActivityListFilter
    | CustomDeliveryListFilter
    | CustomWorkflowsFilter

  @observable public filterDialogMode = CustomFilterDialogModes.Save

  public constructor(
    protected readonly eventsStore: DesktopEventStore,
    protected readonly onShowChanged?: (isShown: boolean) => void,
  ) {}

  public get appliedCustomFilterId() {
    return this.eventsStore.appState.filters.appliedCustomFilterId
  }

  public abstract get filtersCount(): number
  public abstract get filtersDescriptions(): string[]

  @action.bound
  public toggleSaveFilterDialog() {
    this.shouldSaveFilterDialogShow = !this.shouldSaveFilterDialogShow
  }

  @action.bound
  public selectCustomFilter(event) {
    this.selectedCustomFilterId = event.target.value
    this.selectedActivityPresetId = null
  }

  @action.bound
  public selectCustomFilterById(id: string) {
    this.selectedCustomFilterId = id
    this.selectedActivityPresetId = null
  }

  public retrieveFormattedFilterValues(
    filterValues: string[],
    filterType: string,
  ) {
    const index = filterValues.indexOf(UNASSIGNED_FILTER_VALUE)
    if (index === -1) {
      return filterValues
    }

    filterValues[index] = `unassigned-${filterType}`
    return filterValues
  }

  public abstract getSelectedFilterValues(filterType: string)

  @action.bound
  public toggleSavedFilters() {
    if (this.isShown || this.shouldSaveFilterDialogShow) {
      this.isShown = false
      this.shouldSaveFilterDialogShow = false
    } else {
      this.isShown = true
    }

    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public closeSavedFilters() {
    this.isShown = false
    this.clearSavedFilters()

    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public onFilterNameChange(event) {
    this.editableFilterName = event.target.value || ''
  }

  @action.bound
  public onFilterIsPublicToggle() {
    this.editableFilterIsPublic = !this.editableFilterIsPublic
  }

  @action.bound
  public onSavedViewNameChange(event) {
    this.editableSavedViewName = event.target.value || ''
  }

  @action.bound
  public onSavedViewIsPublicChange(value: boolean) {
    this.editableSavedViewIsPublic = value
  }

  @action.bound
  public clearSavedFilters() {
    this.shouldSaveFilterDialogShow = false
    this.shouldActivityPresetDialogShow = false
    this.selectedActivityPresetId = null
    this.selectedCustomFilterId = null
    this.editableFilterName = ''
    this.customFilterInstance = null
    this.editableFilterIsPublic = false
  }

  protected abstract createCustomFilterInstance(
    codeFilterValuesByTypeMap: Map<string, string[]>,
  )

  protected resetCustomFilter(skipClose?: boolean) {
    this.selectedCustomFilterId = null
    this.eventsStore.appState.filters.appliedCustomFilterId = null

    if (!skipClose) this.closeSavedFilters()
  }

  protected resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  protected showSaveFilterDialog() {
    this.editableFilterName = ''
    this.editableFilterIsPublic = false
    this.filterDialogMode = CustomFilterDialogModes.Save
    this.shouldSaveFilterDialogShow = true
  }
}
