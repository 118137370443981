import * as React from 'react'

import { IReactionDisposer, action, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import ConfirmDialogTypes from '~/client/src/desktop/enums/ConfirmDialogTypes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import * as Icons from '~/client/src/shared/components/Icons'
import LogisticsMapView from '~/client/src/shared/components/LogisticsMapView/LogisticsMapView'
import LogisticsMapViewSetUpStore from '~/client/src/shared/components/LogisticsMapView/LogisticsMapViewSetUp.store'
import MapViewsGallery from '~/client/src/shared/components/SitemapsGallery/MapViewsGallery'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  DELETE_ANNOUNCEMENTS,
  SAVE_LOGISTICS_CONFIGURATIONS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaturixProjectsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixProjects.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'

import LogisticsStore from '../../Logistics.store'
import ConfirmAnnouncementDialog from '../ConfirmAnnouncementDialog'
import LogisticsSideBar from '../LogisticsSideBar'
import LogisticsMapStore from './LogisticsMap.store'

interface IProps {
  store: LogisticsStore
  logisticsMapStore: LogisticsMapStore
  isCreationAvailable: boolean

  shouldShowEntity?: boolean
  entityOrder?: number
  isPermitOnly?: boolean

  state?: DesktopInitialState
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
  deliveryDetailsStore?: DeliveryDetailsStore
  activitiesStore?: ActivitiesStore
  maturixProjectsStore?: MaturixProjectsStore

  globeViewsStore?: GlobeViewsStore
  eventsStore?: EventsStore
  tilesetsStore?: TilesetsStore
}

const showFor = 'Show for'

@inject(
  'state',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'tagsStore',
  'deliveryDetailsStore',
  'activitiesStore',
  'maturixProjectsStore',
  'globeViewsStore',
  'eventsStore',
  'tilesetsStore',
)
@observer
export default class LogisticsMap extends React.Component<IProps> {
  public readonly store: LogisticsMapViewSetUpStore = null
  private disposeDisplayedPermitIdReaction: IReactionDisposer
  private disposeDisplayedDeliveryIdReaction: IReactionDisposer
  private disposeDisplayedActivityIdReaction: IReactionDisposer

  public constructor(props: IProps) {
    super(props)

    this.store = new LogisticsMapViewSetUpStore(
      props.sitemapsStore,
      props.tilesetsStore,
      props.globeViewsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.state,
      props.eventsStore,
      () => props.store.displayedPermitId,
    )

    const config = props.isPermitOnly
      ? props.state.formsMapIdsList
      : props.state.logisticsMapIdsList

    const isGlobeMode = !!config?.[0]?.globeViewId
    if (isGlobeMode) {
      const globe = props.globeViewsStore.byId.get(config?.[0]?.globeViewId)
      this.store.globeViewControlStore.selectGlobe(globe)
    } else {
      const whiteboard = props.sitemapsStore.byId.get(config?.[0]?.sitemapId)
      this.store.selectSitemap(whiteboard)
      this.store.mapBoxViewerStore.setViewportFromAddress()
    }
  }

  public componentDidMount() {
    const { changeDatePickerMode, setInitialDateValue } = this.props.store
    changeDatePickerMode(TwoMonthsDatePickerMode.ONE_DAY_DEFAULT_TODAY)
    setInitialDateValue()

    this.disposeDisplayedPermitIdReaction = reaction(
      () => this.props.store.displayedPermitId,
      displayedPermitId => {
        if (!displayedPermitId) {
          this.store.deselectPermits()
        }
      },
      { fireImmediately: true },
    )
    this.disposeDisplayedDeliveryIdReaction = reaction(
      () => this.props.deliveryDetailsStore.displayedDelivery,
      displayedDelivery => {
        if (!displayedDelivery) {
          this.store.deselectDeliveries()
        }
      },
      { fireImmediately: true },
    )
    this.disposeDisplayedActivityIdReaction = reaction(
      () => this.props.activitiesStore.selectedActivity,
      selectedActivity => {
        if (!selectedActivity) {
          this.store.deselectActivities()
        }
      },
      { fireImmediately: true },
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.props.shouldShowEntity &&
      prevProps.entityOrder !== this.props.entityOrder
    ) {
      this.props.store.hideAnnouncementDialog()
      const announcement =
        this.props.logisticsMapStore.orderedAnnouncements[
          this.props.entityOrder
        ]
      if (announcement) {
        this.props.store.showAnnouncementContent(announcement)
      }
    }
  }

  public componentWillUnmount() {
    this.disposeDisplayedPermitIdReaction?.()
    this.disposeDisplayedDeliveryIdReaction?.()
    this.disposeDisplayedActivityIdReaction?.()
  }

  public render() {
    const {
      store: {
        removeAnnouncements,
        selectedAnnouncements,
        shouldShowConfirmDeleteDialog,
      },
      state,
      isPermitOnly,
    } = this.props

    const areAnnouncementsBeingDeleted = state.loading.get(DELETE_ANNOUNCEMENTS)

    return (
      <>
        <div className="row full-height relative">{this.content}</div>
        {!isPermitOnly && (
          <ConfirmAnnouncementDialog
            type={ConfirmDialogTypes.DELETE}
            isOpen={shouldShowConfirmDeleteDialog}
            loading={areAnnouncementsBeingDeleted}
            closeDialog={this.hideConfirmDeleteDialog}
            performItems={removeAnnouncements}
            itemsToPerform={selectedAnnouncements}
          />
        )}
      </>
    )
  }

  private get content() {
    const {
      store,
      isPermitOnly,
      store: { state },
    } = this.props
    const { openPermitCreationForm, showCreationAndEditionAnnouncementForm } =
      store

    return (
      <>
        <LogisticsSideBar
          store={store}
          isPermitOnly={isPermitOnly}
          logisticsMapViewSetUpStore={this.store}
          mapStore={this.props.logisticsMapStore}
        />
        <div className="col full-height">
          <div className="full-height relative">
            <MapViewsGallery
              mapIds={
                isPermitOnly ? state.formsMapIdsList : state.logisticsMapIdsList
              }
              selectedGlobeViewId={
                this.store.globeViewControlStore.selectedGlobeView?.id
              }
              selectedWhiteboardId={this.store.sitemap?.id}
              renderMapViewComponent={this.renderMapViewComponent}
              eventName={SAVE_LOGISTICS_CONFIGURATIONS}
              selectGlobe={this.selectGlobeAndCloseModals}
              selectSitemap={this.selectSitemapAndCloseModals}
              areArrowsLeftSided={true}
              shouldUseFullHeight={true}
              shouldShowCreateNewButton={true}
              FileInputType={DesktopFileInput}
              openPermitCreationForm={openPermitCreationForm}
              openAnnouncementCreationForm={
                !isPermitOnly && showCreationAndEditionAnnouncementForm
              }
              mapBoxViewerStore={this.store.mapBoxViewerStore}
              isPermitOnly={isPermitOnly}
              shouldDisableZoom={true}
            />
          </div>
        </div>
      </>
    )
  }

  private renderMapViewComponent = (): JSX.Element => {
    const {
      isPermitOnly,
      store: {
        showAnnouncementContent,
        showPermitViewOrApprovalDialog,
        displayDeliverySideView,
        displayActivitySideView,
        showRemoveDialog,
        arePermitsHidden,
        areAnnouncementsHidden,
        areDeliveriesHidden,
        areActivitiesHidden,
        areMonitoringsHidden,
        toggleAnnouncementsHiddenState,
        toggleDeliveriesHiddenState,
        togglePermitsHiddenState,
        toggleMonitoringsHiddenState,
      },
    } = this.props

    return (
      <div className="sitemap-container full-width full-height">
        <LogisticsMapView
          globe={this.store.globeViewControlStore.selectedGlobeView}
          sitemap={this.store.sitemap}
          store={this.store}
          mapBoxViewerStore={this.store.mapBoxViewerStore}
          logisticsStore={this.props.store}
          openAnnouncement={showAnnouncementContent}
          openPermit={showPermitViewOrApprovalDialog}
          openDelivery={displayDeliverySideView}
          openActivity={displayActivitySideView}
          deleteAnnouncement={showRemoveDialog}
          arePermitsHidden={arePermitsHidden}
          areAnnouncementsHidden={areAnnouncementsHidden}
          areDeliveriesHidden={areDeliveriesHidden}
          areActivitiesHidden={areActivitiesHidden}
          areMonitoringsHidden={areMonitoringsHidden}
          renderShowOnMenu={this.renderShowOnMenu}
          toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
          toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
          togglePermitsHiddenState={togglePermitsHiddenState}
          toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
          isPermitOnly={isPermitOnly}
        />
      </div>
    )
  }

  @action.bound
  private hideConfirmDeleteDialog() {
    this.props.store.shouldShowConfirmDeleteDialog = false
  }

  private renderShowOnMenu = () => {
    const { isPermitOnly } = this.props

    if (isPermitOnly) {
      return
    }

    const {
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleActivitiesHiddenState,
      toggleMonitoringsHiddenState,
      areDeliveriesHidden,
      areAnnouncementsHidden,
      arePermitsHidden,
      areActivitiesHidden,
      areMonitoringsHidden,
    } = this.props.store

    return (
      <>
        <div className="show-on-text-box text lp15 small grey uppercase pa5 br-light-input-border row y-center nowrap no-grow">
          <Icons.RoundedLocation className="shown-on-icon no-grow mr5" />
          {showFor}
        </div>
        <div className="show-on-icons-box row">
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': areAnnouncementsHidden,
            })}
          >
            <Icons.SidebarAnnouncement
              className="no-grow"
              onClick={toggleAnnouncementsHiddenState}
            />
          </div>
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': arePermitsHidden,
            })}
          >
            <Icons.GeneralForm
              className="row no-grow permit-icon text white"
              onClick={togglePermitsHiddenState}
            />
          </div>
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': areDeliveriesHidden,
            })}
          >
            <Icons.Delivery
              className="row no-grow delivery-icon"
              onClick={toggleDeliveriesHiddenState}
            />
          </div>
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': areActivitiesHidden,
            })}
          >
            <Icons.Activities
              className="row no-grow activity-icon"
              onClick={toggleActivitiesHiddenState}
            />
          </div>
          {this.props.maturixProjectsStore.activeProjectId && (
            <div
              className={classList({
                'icon-holder row y-center x-center no-grow mx5': true,
                'de-selected': areMonitoringsHidden,
              })}
            >
              <Icons.Sensor
                className="row no-grow activity-icon"
                onClick={toggleMonitoringsHiddenState}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  @action.bound
  private selectGlobeAndCloseModals(globe: GlobeView) {
    this.store.deselectSitemap()
    this.store.globeViewControlStore.selectGlobe(globe)
    this.store.mapBoxViewerStore.setViewportFromAddress()

    this.store.deselectAll()
  }

  @action.bound
  private selectSitemapAndCloseModals(sitemap: Sitemap) {
    this.store.globeViewControlStore.deselectGlobe()
    this.store.selectSitemap(sitemap)

    this.store.mapBoxViewerStore.setViewportFromAddress()

    this.store.deselectAll()
  }
}
