import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Redirect, RouteComponentProps, Switch, withRouter } from 'react-router'

import DesktopInitialState, {
  SidebarComponentName,
} from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import Deliveries from '~/client/src/desktop/views/Deliveries/Deliveries'
import Logistics from '~/client/src/desktop/views/Logistics/Logistics'
import Materials from '~/client/src/desktop/views/Materials/Materials'
import NotificationDetailsView from '~/client/src/desktop/views/NotificationDetails/NotificationDetailsView'
import NotificationsView from '~/client/src/desktop/views/Notifications/Notifications'
import PasswordResetViewBaseDesktop from '~/client/src/desktop/views/PasswordResetViewBase/PasswordResetViewBaseDesktop'
import ProjectSetUp from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUp'
import SavePasswordView from '~/client/src/desktop/views/SavePasswordView/SavePasswordView'
import ChatUnsafe from '~/client/src/shared/components/Chat/Chat'
import WrappedRoute from '~/client/src/shared/components/WrappedRoute'
import IRoute from '~/client/src/shared/interfaces/IRoute'

import { withErrorBoundary } from '../shared/components/ErrorBoundary'
import GlobalUserCardUnsafe from '../shared/components/GlobalUserCard/GlobalUserCard'
import { Loader } from '../shared/components/Loader'
import Redirector from '../shared/components/Tenants/Redirector'
import UsersDirectory from '../shared/components/UsersDirectory/UsersDirectory'
import { getTransitionPath } from '../shared/constants/commonRoutes'
import Localization from '../shared/localization/LocalizationManager'
import {
  GET_PRESENTATION_USER_TOKEN,
  LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS,
} from '../shared/stores/EventStore/eventConstants'
import RoutesStore from '../shared/stores/ui/Routes.store'
import InfoView from '../shared/views/InfoView/InfoView'
import DesktopFullScreenPreview from './components/DesktopFullscreenPreview/DesktopFullscreenPreview'
import WrappedSidebar from './components/WrappedSidebar/WrappedSidebar'
import desktopRoutes from './constants/desktopRoutes'
import Analytics from './views/Analytics/Analytics'
import Forms from './views/Forms/Forms'
import DesktopLoginView from './views/Login/DesktopLoginView'
import PermitTypesView from './views/PermitTypes/PermitTypesView'
import Photos from './views/Photos/Photos'
import { ProjectsView } from './views/Projects/Projects'
import SignUpView from './views/SignUp/SignUpView'
import SimpleGanttView from './views/SimpleGanttView/SimpleGanttView'

const Chat = withErrorBoundary(ChatUnsafe)
const GlobalUserCard = withErrorBoundary(GlobalUserCardUnsafe)

// localization: translated

const routes: IRoute[] = [
  {
    path: desktopRoutes.HOME,
    component: Logistics,
    isLogisticsRelated: true,
  },
  {
    path: desktopRoutes.ACTIVITIES,
    component: SimpleGanttView,
    isTrackerRelated: true,
  },
  {
    path: desktopRoutes.LOGIN,
    component: DesktopLoginView,
  },
  {
    path: desktopRoutes.SIGN_UP,
    component: SignUpView,
  },
  {
    path: desktopRoutes.FORM_TYPES,
    component: PermitTypesView,
  },
  {
    path: desktopRoutes.RESET_PASSWORD,
    component: PasswordResetViewBaseDesktop,
  },
  {
    path: desktopRoutes.SAVE_PASSWORD,
    component: SavePasswordView,
  },
  {
    path: desktopRoutes.PROJECT_SETUP,
    component: ProjectSetUp,
  },
  {
    path: desktopRoutes.PHOTOS,
    component: Photos,
    isPhotosRelated: true,
  },
  {
    path: desktopRoutes.ANALYTICS,
    component: Analytics,
    isAnalyticsRelated: true,
  },
  {
    path: desktopRoutes.NOTIFICATIONS,
    exact: true,
    component: NotificationsView,
  },
  {
    path: desktopRoutes.NOTIFICATIONS_DETAILS,
    component: NotificationDetailsView,
  },
  {
    path: desktopRoutes.FORMS,
    component: Forms,
    isFormsRelated: true,
  },
  {
    path: desktopRoutes.DELIVERIES,
    component: Deliveries,
    isDeliveriesRelated: true,
  },
  {
    path: desktopRoutes.INFO,
    component: InfoView,
  },
  {
    path: desktopRoutes.MATERIALS,
    component: Materials,
    isMaterialsRelated: true,
  },
  {
    path: desktopRoutes.PROJECTS,
    component: ProjectsView,
  },
  {
    path: desktopRoutes.REDIRECTOR,
    component: Redirector,
  },
]

const limitedRoutes: IRoute[] = [
  {
    path: desktopRoutes.ACTIVITIES,
    component: SimpleGanttView,
    isTrackerRelated: true,
  },
  {
    path: desktopRoutes.DELIVERIES,
    component: Deliveries,
    isDeliveriesRelated: true,
  },
  {
    path: desktopRoutes.FORMS,
    component: Forms,
    isFormsRelated: true,
  },
  {
    path: desktopRoutes.LOGIN,
    component: DesktopLoginView,
  },
  {
    path: desktopRoutes.SAVE_PASSWORD,
    component: SavePasswordView,
  },
  {
    path: desktopRoutes.RESET_PASSWORD,
    component: PasswordResetViewBaseDesktop,
  },
  {
    path: desktopRoutes.INFO,
    component: InfoView,
  },
]

interface IProps extends RouteComponentProps {
  common?: DesktopCommonStore
  state?: DesktopInitialState
}

@inject('common', 'state')
@observer
class DesktopRoutes extends React.Component<IProps> {
  private readonly store: RoutesStore = null

  public constructor(props: IProps) {
    super(props)
    this.store = new RoutesStore(props.state)
    props.common.initHistory(props.history)
  }

  private get routes(): IRoute[] {
    const {
      didInitialize,
      isDeliveriesDisabled,
      isTrackerDisabled,
      isLogisticsDisabled,
      isFormsDisabled,
      isMaterialsDisabled,
      isPhotosDisabled,
    } = this.props.state

    const displayedRoutes = didInitialize
      ? [...routes, this.defaultRoute]
      : limitedRoutes

    return displayedRoutes.filter(
      route =>
        (!isDeliveriesDisabled || !route.isDeliveriesRelated) &&
        (!isLogisticsDisabled || !route.isLogisticsRelated) &&
        (!isTrackerDisabled || !route.isTrackerRelated) &&
        (!isMaterialsDisabled || !route.isMaterialsRelated) &&
        (!isPhotosDisabled || !route.isPhotosRelated) &&
        (!isFormsDisabled || !route.isFormsRelated),
    )
  }

  public render() {
    const { viewToDisplay } = this.props.common
    const { loading, userActiveProjectSettings } = this.props.state
    const { areProjectTypesLoading } = this.store

    const isPresentationModeLoading =
      loading.get(GET_PRESENTATION_USER_TOKEN) ||
      (userActiveProjectSettings?.isPresentationUser &&
        (areProjectTypesLoading ||
          loading.get(LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS)))

    return (
      <main className="full-height">
        <DesktopFullScreenPreview />
        {areProjectTypesLoading || isPresentationModeLoading ? (
          <div className="absolute-block" style={{ top: '50%' }}>
            <div className="col x-center y-center">
              <Loader size={25} />
              <span>
                {isPresentationModeLoading
                  ? Localization.translator.loadingPresentationMode
                  : Localization.translator.loading}
              </span>
            </div>
          </div>
        ) : (
          <>
            {this.renderSidebar()}
            <Switch>
              {this.routes.map(route => (
                <WrappedRoute
                  defaultViewToDisplay={getTransitionPath(
                    viewToDisplay,
                    this.props.state.activeOrInitProjectCode,
                  )}
                  key={route.path}
                  {...route}
                />
              ))}
            </Switch>
          </>
        )}
      </main>
    )
  }

  private get defaultRoute(): IRoute {
    return {
      path: '/',
      component: this.redirectToDefaultRoute,
    }
  }

  private redirectToDefaultRoute = () => {
    const { viewToDisplay } = this.props.common
    return (
      <Redirect
        to={getTransitionPath(
          viewToDisplay,
          this.props.state.activeOrInitProjectCode,
        )}
      />
    )
  }

  private renderSidebar(): JSX.Element {
    const { idOfUserDisplayedOnGlobalCard, activeSideBarComponent } =
      this.props.state

    const shouldShowSidebar =
      idOfUserDisplayedOnGlobalCard ||
      activeSideBarComponent !== SidebarComponentName.None

    return (
      shouldShowSidebar && (
        <WrappedSidebar>{this.renderSidebarContent()}</WrappedSidebar>
      )
    )
  }

  @action.bound
  private renderSidebarContent() {
    const {
      idOfUserDisplayedOnGlobalCard,
      hideChat,
      recipientIdForChatInit,
      activeSideBarComponent,
    } = this.props.state
    if (idOfUserDisplayedOnGlobalCard) {
      return <GlobalUserCard userId={idOfUserDisplayedOnGlobalCard} />
    }
    switch (activeSideBarComponent) {
      case SidebarComponentName.Chat:
        return (
          <Chat
            onClose={hideChat}
            shouldMessageBeSentOnEnter={true}
            recipientIdForInit={recipientIdForChatInit}
          />
        )
      case SidebarComponentName.UserDirectory:
        return (
          <UsersDirectory
            onHeaderCloseClick={this.handleSideUserDirectoryClose}
          />
        )
    }
  }

  @action.bound
  private handleSideUserDirectoryClose() {
    this.props.state.activeSideBarComponent = SidebarComponentName.None
  }
}

export default withRouter(DesktopRoutes)
