import { action, computed, observable } from 'mobx'

import { ISiteLocation } from '~/client/graph'
import MapBoxViewerStore from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import BaseMapViewSetUpStore from '~/client/src/shared/components/SitemapHelpers/BaseMapViewSetUp.store'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import Activity from '~/client/src/shared/models/Activity'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import GlobeViewControlStore from '~/client/src/shared/stores/GlobeViewControl.store'
import InitialState from '~/client/src/shared/stores/InitialState'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'
import { lbsBands } from '~/client/src/shared/types/IHierarchyParent'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { areArraysEqual } from '~/client/src/shared/utils/util'

export default class ActivitiesMapViewSetUpStore extends BaseMapViewSetUpStore {
  @observable public selectedActivities: Activity[] = []
  @observable public selectedAttrId: string = null
  @observable public topOffset: number = 0
  @observable public leftOffset: number = 0
  public readonly mapBoxViewerStore: MapBoxViewerStore
  public readonly globeViewControlStore: GlobeViewControlStore
  private assignedActivitiesMap: { [attrId: string]: boolean } = {}

  public constructor(
    public readonly sitemapsStore: SitemapsStore,
    protected readonly basemapsStore: BasemapsStore,
    protected readonly sitemapItemsStore: SitemapItemsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
    state: InitialState,
    eventsStore: EventsStore,
    public readonly globeViewsStore: GlobeViewsStore,
    public readonly tilesetsStore: TilesetsStore,
  ) {
    super(
      sitemapsStore,
      globeViewsStore,
      basemapsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )

    this.globeViewControlStore = new GlobeViewControlStore(
      eventsStore,
      globeViewsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )

    this.mapBoxViewerStore = new MapBoxViewerStore(
      sitemapsStore,
      tilesetsStore,
      locationAttributesStore,
      state,
      this.globeViewControlStore,
      basemapsStore,
      eventsStore,
      false,
      () => this.getSelectedMapViewItem(),
    )
  }

  public get isGlobeMode() {
    return !!this.globeViewControlStore.selectedGlobeView
  }

  private getSelectedMapViewItem = () => {
    return (
      this.isGlobeMode ? this.displayedGlobeItems : this.displayedSitemapItems
    ).find(i => i.id === this.selectedAttrId)
  }

  @computed
  public get selectedMapViewItem(): MapViewItemBase {
    return this.getSelectedMapViewItem()
  }

  public isSitemapItemSupported = (item: MapViewItemBase): boolean => {
    return !item.isDataLess
  }

  @action.bound
  public resetAttrSelection() {
    this.selectedAttrId = EMPTY_STRING
  }

  @action.bound
  public selectAttr(attrId: string, y?: number, x?: number) {
    if (this.selectedAttrId === attrId) {
      return this.resetAttrSelection()
    }

    this.selectedAttrId = attrId
    this.topOffset = y
    this.leftOffset = x
  }

  public selectSitemapItem = (
    item: MapViewItemBase,
    y?: number,
    x?: number,
  ) => {
    if (this.isSitemapItemSupported(item)) {
      this.selectAttr(item.id, y, x)
    }
  }

  @action.bound
  public selectActivities(
    activities?: Activity[],
    item?: MapViewItemBase,
    y?: number,
    x?: number,
  ) {
    if (!this.areActivitiesSelected) {
      this.deselectAll()
    }

    this.selectedAttrId = item.id === this.selectedAttrId ? null : item.id

    this.selectedActivities = areArraysEqual(
      this.selectedActivities,
      activities,
    )
      ? []
      : [...activities]

    this.topOffset = y
    this.leftOffset = x
  }

  @action.bound
  public deselectAll() {
    this.selectedActivities = []
    this.selectedAttrId = null
    this.topOffset = null
    this.leftOffset = null
  }

  public getRelatedItems = (locations: ISiteLocation[]): MapViewItemBase[] => {
    if (!locations?.length) {
      return []
    }

    return locations.reduce((list, loc) => {
      const relatedItem =
        this.getDisplayedItemById(loc.id) || this.findParentItemByAttrId(loc.id)

      if (relatedItem?.sitemapItemProperties?.iconProperties?.position) {
        list.push(relatedItem)
      }

      return list
    }, [] as MapViewItemBase[])
  }

  public findParentItemByAttrId = (id: string): MapViewItemBase => {
    const attribute = this.hierarchyAttributes.find(a => a.id === id)

    const parents = attribute?.getHierarchyChainsObjects(
      this.tagsStore.tagStoreByTagTypeMap,
    )

    let closestParent: MapViewItemBase = null

    parents?.some(
      a => (closestParent = this.getDisplayedItemById(a.id)) && !!closestParent,
    )

    return closestParent
  }

  private getDisplayedItemById = (id: string): MapViewItemBase => {
    return this.displayedSitemapItems?.find(item => item.id === id)
  }

  public get displayedGlobeItems(): MapViewItemBase[] {
    return this.mapBoxViewerStore.displayedGlobeViewItems
  }

  public get areActivitiesSelected(): boolean {
    return !!this.selectedActivities.length
  }

  @computed
  public get sortedSitemapAttributes(): MapViewItemBase[] {
    return (this.displayedSitemapItems || [])
      .filter(item => item.dataObject)
      .sort((a, b) => {
        const aNodeType = a.dataObject.type
        const bNodeType = b.dataObject.type

        const aIndex = lbsBands.indexOf(aNodeType)
        const bIndex = lbsBands.indexOf(bNodeType)

        return bIndex - aIndex
      })
  }

  public getClosestAttrIdToActivitiesMap = (
    availableActivities: Activity[],
    displayedActivitySitemapItems: MapViewItemBase[],
  ): { [attrId: string]: Activity[] } => {
    this.deAssignActivities()

    const displayedAttrsIds = displayedActivitySitemapItems.map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = availableActivities.filter(
        a =>
          a.locations.some(l => l.id === attrId) &&
          !this.assignedActivitiesMap[a.id],
      )
      this.assignActivitiesToAttrs(acc[attrId].map(d => d.id))
      return acc
    }, {})
  }

  private deAssignActivities = () => {
    this.assignedActivitiesMap = {}
  }

  private assignActivitiesToAttrs = (activitiesIds: string[]) => {
    activitiesIds.forEach(id => {
      this.assignedActivitiesMap[id] = true
    })
  }
}
