import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  CalendricalType,
  IWorkflowStep,
  WorkflowStepType,
} from '~/client/graph'

import WorkflowDiagramWrapper from '../WorkflowDiagramWrapper/WorkflowDiagramWrapper'
import ApprovalStepDiagram from './components/ApprovalStepDiagram'
import FinishStepDiagram from './components/FinishStepDiagram'
import InspectionStepDiagram from './components/InspectionStepDiagram'
import OnSiteStepDiagram from './components/OnSiteStepDiagram'
import ReadyToInspectStepDiagram from './components/ReadyToInspectStepDiagram'
import RecurringInspectionStepDiagram from './components/RecurringInspectionStepDiagram'
import RequestStepDiagram from './components/RequestStepDiagram'
import ReviewStepDiagram from './components/ReviewStepDiagram'
import StartStepDiagram from './components/StartStepDiagram'
import SubmissionStepDiagram from './components/SubmissionStepDiagram'

import './PermitWorkflowDiagram.scss'

// localization: no text to translate

interface IProps {
  workflowSteps: IWorkflowStep[]
  isAutoActivationEnabled: boolean

  inspectionFrequency?: number
  inspectionFrequencyType?: CalendricalType
  className?: string
}

const WorkflowStepMap: { [K in WorkflowStepType] } = {
  [WorkflowStepType.Submission]: SubmissionStepDiagram,
  [WorkflowStepType.Request]: RequestStepDiagram,
  [WorkflowStepType.Review]: ReviewStepDiagram,
  [WorkflowStepType.Approval]: ApprovalStepDiagram,
  [WorkflowStepType.Start]: StartStepDiagram,
  [WorkflowStepType.OnSite]: OnSiteStepDiagram,
  [WorkflowStepType.ReadyToInspect]: ReadyToInspectStepDiagram,
  [WorkflowStepType.Inspection]: InspectionStepDiagram,
  [WorkflowStepType.RecurringInspection]: RecurringInspectionStepDiagram,
  [WorkflowStepType.Finish]: FinishStepDiagram,
}

@observer
export default class PermitWorkflowDiagram extends React.Component<IProps> {
  public render() {
    const {
      className,
      inspectionFrequency,
      inspectionFrequencyType,
      isAutoActivationEnabled,
      workflowSteps,
    } = this.props
    const hasStartAction = workflowSteps.some(
      s => s.type === WorkflowStepType.Start,
    )

    return (
      <WorkflowDiagramWrapper
        className={classList({
          'permit-type-diagram': true,
          [className]: !!className,
        })}
      >
        <div className="empty-block" />

        <div className="row relative py20">
          {workflowSteps.map((step, idx) => {
            const Component = WorkflowStepMap[step.type]
            return (
              <Component
                key={step.id}
                isLastStep={workflowSteps.length === idx + 1}
                hasStartStep={hasStartAction}
                isAutoActivationEnabled={isAutoActivationEnabled}
                inspectionFrequency={inspectionFrequency}
                inspectionFrequencyType={inspectionFrequencyType}
              />
            )
          })}
        </div>

        <div className="empty-block mb10" />
      </WorkflowDiagramWrapper>
    )
  }
}
