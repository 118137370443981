import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

export enum FormTypeFilterType {
  FORM_CATEGORY = 'form-category',
  APP = 'app',
  CREATED_BY = 'created-by',
}

export const getFormTypeFilterTypeCaption = (type: FormTypeFilterType) => {
  switch (type) {
    case FormTypeFilterType.FORM_CATEGORY:
      return Localization.translator.formCategory
    case FormTypeFilterType.APP:
      return Localization.translator.app
    case FormTypeFilterType.CREATED_BY:
      return Localization.translator.createdBy
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const formTypeFilterTypes = enumToList(FormTypeFilterType)
