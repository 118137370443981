import { action, computed, observable } from 'mobx'

import { IFullscreenPreviewComment } from '~/client/src/shared/components/FileFullscreenPreview/FileFullscreenPreview.store'
import { IMultiSelectOption } from '~/client/src/shared/components/MultiSelectFilter/MultiSelectFilter'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import FileType from '~/client/src/shared/enums/FileType'
import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'
import Delivery from '~/client/src/shared/models/Delivery'
import Photo from '~/client/src/shared/models/Photo'
import { UNKNOWN_USER_NAME } from '~/client/src/shared/models/User'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import ProjectDateStore, {
  DAYS_IN_WEEK,
  areIntervalTimesIntersects,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import DateInterval from '~/client/src/shared/types/DateInterval'

import { IDateFilters } from '../../components/Filters/DateSelector/DateSelector.store'
import DesktopInitialState from '../../stores/DesktopInitialState'

enum PhotosMode {
  Schedule = 'schedule',
  Deliveries = 'deliveries',
}

enum PhotosViewMode {
  Map = 'map',
  Grid = 'grid',
}

interface IDeliveryPhotos {
  delivery: Delivery
  photos: Photo[]
}

export interface IPhotosFilterOption {
  name: string
  count: number
  id: string
}

export enum PhotoFilterTypes {
  reporter = 'Reporter',
  company = 'Company',
  supplier = 'Supplier',
  gate = 'Gate',
  area = 'Area',
  route = 'Route',
  building = 'Building',
  status = 'Status',
  none = 'none',
}

const DELETED_USER_OPTION_ID = 'deleted-user-id'

interface IPhotosByDays {
  day: Date
  deliveriesPhotos: IDeliveryPhotos[]
}

export default class PhotosPageStore {
  @observable public selectedGates: string[] = []
  @observable public selectedZones: string[] = []
  @observable public selectedRoutes: string[] = []
  @observable public selectedBuildings: string[] = []
  @observable public selectedStates: string[] = []
  @observable public selectedCompanies: string[] = []
  @observable public selectedReporters: string[] = []
  @observable public selectedSuppliers: string[] = []
  @observable public selectedPage: number = 0

  public dateFilters = observable<IDateFilters>({
    startDate: null,
    daysToAdd: 0,
    isDateFilterActive: true,
  })

  @observable private viewMode: PhotosViewMode = PhotosViewMode.Grid
  @observable private mode: PhotosMode = PhotosMode.Deliveries
  @observable private shownFilterType: PhotoFilterTypes = PhotoFilterTypes.none

  public constructor(
    private projectsStore: ProjectsStore,
    private state: DesktopInitialState,
    private gatesStore: GatesStore,
    private routesStore: RoutesStore,
    private buildingsStore: BuildingsStore,
    private companiesStore: CompaniesStore,
    private zonesStore: ZonesStore,
    private photosStore: PhotosStore,
    private messagesStore: MessagesStore,
    private deliveriesStore: DeliveriesStore,
    private eventsStore: EventsStore,
    private deliveryStatusChangesStore: DeliveryStatusChangesStore,
    private projectDateStore: ProjectDateStore,
    private projectMembersStore: ProjectMembersStore,
  ) {
    if (!this.isDeliveriesModeAvailable) {
      this.setScheduleMode()
    }
    this.setInitialDate()
    this.dateFilters.daysToAdd = 3 * DAYS_IN_WEEK - 1
  }

  public setInitialDate() {
    this.dateFilters.startDate = this.projectDateStore.startOfWeek(new Date())
  }

  public get allStatuses() {
    const statesToExclude = ['changed', 'incomplete-done', 'deleted']
    return Object.values(DeliveryStatus)
      .filter(status => !statesToExclude.includes(status))
      .map(status => {
        const count = this.getDeliveryPhotosCountByStatus(status)
        const countLabel = count ? ` (${count})` : ''
        return {
          label: status + countLabel,
          value: status,
          disabled: !count,
        }
      })
  }

  public get allGates(): IMultiSelectOption[] {
    return this.gatesStore.list.map(({ id, name }) => {
      const count = this.getDeliveryRelatedPhotosCount(
        FieldIds.GATE,
        id,
        PhotoFilterTypes.gate,
      )
      const countLabel = count ? ` (${count})` : ''
      return {
        label: name + countLabel,
        value: id,
        disabled: !count,
      }
    })
  }

  public get allRoutes(): IMultiSelectOption[] {
    return this.routesStore.list.map(({ id, name }) => {
      const count = this.getDeliveryRelatedPhotosCount(
        FieldIds.ROUTE,
        id,
        PhotoFilterTypes.route,
      )
      const countLabel = count ? ` (${count})` : ''
      return {
        label: name + countLabel,
        value: id,
        disabled: !count,
      }
    })
  }

  public get allBuildings(): IMultiSelectOption[] {
    return this.buildingsStore.list.map(({ id, name }) => {
      const count = this.getDeliveryRelatedPhotosCount(
        FieldIds.BUILDING,
        id,
        PhotoFilterTypes.building,
      )
      const countLabel = count ? ` (${count})` : ''
      return {
        label: name + countLabel,
        value: id,
        disabled: !count,
      }
    })
  }

  public get allZones(): IMultiSelectOption[] {
    return this.zonesStore.list.map(({ id, name }) => {
      const count = this.getDeliveryRelatedPhotosCount(
        FieldIds.ZONE,
        id,
        PhotoFilterTypes.area,
      )
      const countLabel = count ? ` (${count})` : ''
      return {
        label: name + countLabel,
        value: id,
        disabled: !count,
      }
    })
  }

  public get suppliersOptions(): IPhotosFilterOption[] {
    return [
      ...new Set(this.deliveriesStore.list.map(delivery => delivery.vendor)),
    ].map(supplier => {
      return {
        name: supplier,
        count: this.getDeliveryRelatedPhotosCount(
          FieldIds.VENDOR,
          supplier,
          PhotoFilterTypes.supplier,
        ),
        id: supplier,
      }
    })
  }

  public get companiesOptions(): IPhotosFilterOption[] {
    const companies = [
      ...new Set(this.deliveriesStore.list.map(delivery => delivery.company)),
    ]

    return companies.map(company => {
      const companyName = this.companiesStore.getCompanyNameById(company)
      return {
        name: companyName,
        count: this.getDeliveryRelatedPhotosCount(
          FieldIds.COMPANY,
          company,
          PhotoFilterTypes.company,
        ),
        id: company,
      }
    })
  }

  public get reportersOptions(): IPhotosFilterOption[] {
    const options = this.allUsersIds
      .map(userId => {
        const user = this.projectMembersStore.getById(userId)

        if (!user) {
          return null
        }

        return {
          name: `${user.firstName} ${user.lastName}`,
          count: this.getDeliveryRelatedPhotosCount(
            'userId',
            userId,
            PhotoFilterTypes.reporter,
          ),
          id: userId,
        }
      })
      .filter(o => !!o)

    if (this.deletedUsersIds.length > 0) {
      const deletedUsersDeliveriesCount = this.deletedUsersIds.reduce(
        (sum, userId) => {
          sum += this.getDeliveryRelatedPhotosCount(
            'userId',
            userId,
            PhotoFilterTypes.reporter,
          )
          return sum
        },
        0,
      )

      options.push({
        name: UNKNOWN_USER_NAME + 's',
        count: deletedUsersDeliveriesCount,
        id: DELETED_USER_OPTION_ID,
      })
    }
    return options
  }

  public shouldAddCommentIcon = (messageId: string) => {
    return !!this.messagesStore.list.find(m => m.id === messageId).text
  }

  @action.bound
  public openPhoto(
    messagesIds: string[],
    index: number,
    deliveryStatus: DeliveryStatus,
    companyName: string,
    gate: string,
    area: string,
  ) {
    const messages = this.messagesStore.list.filter(m =>
      messagesIds.includes(m.id),
    )
    const comments: IFullscreenPreviewComment[] = []
    const files: IFilePreviewProperties[] = []
    messages.forEach(message => {
      const { photoId, userId, text, createdAt: timestamp } = message
      const photo = this.photosStore.list.find(p => p.id === photoId)
      files.push({ fileUrl: photo.url, fileType: FileType.Image })
      const photoDetails = []
      photoDetails.push(gate, area)
      comments.push({
        authorId: userId,
        text,
        deliveryStatus,
        companyName,
        timestamp,
        photoDetails,
      })
    })

    this.eventsStore.dispatch(SHOW_FULLSCREEN_PREVIEW, files, index, comments)
  }

  public getStatus = (
    delivery: Delivery,
    messageId: string,
  ): DeliveryStatus => {
    const { id, status } = delivery
    const { threadId } = this.messagesStore.list.find(m => m.id === messageId)
    const statusChange = this.deliveryStatusChangesStore
      .getStatusChangesForDelivery(id)
      .find(deliveryStatus => deliveryStatus.threadId === threadId)
    return statusChange ? statusChange.status : status
  }

  @action.bound
  public onApplyClick(options: string[], photoFilterType: PhotoFilterTypes) {
    switch (photoFilterType) {
      case PhotoFilterTypes.company:
        this.selectedCompanies = options
        break
      case PhotoFilterTypes.reporter:
        this.selectedReporters = options
        break
      case PhotoFilterTypes.supplier:
        this.selectedSuppliers = options
        break
    }
    this.shownFilterType = PhotoFilterTypes.none
  }

  @action.bound
  public resetAllFilters() {
    this.selectedCompanies = this.companiesOptions.map(option => option.id)
    this.selectedReporters = this.reportersOptions.map(option => option.id)
    this.selectedSuppliers = this.suppliersOptions.map(option => option.id)

    this.selectedGates = this.allGates.map(option => option.value)
    this.selectedStates = this.allStatuses.map(option => option.value)
    this.selectedZones = this.allZones.map(option => option.value)
    this.selectedBuildings = this.allBuildings.map(option => option.value)
    this.selectedRoutes = this.allRoutes.map(option => option.value)
  }

  @computed
  public get activeFilterType(): PhotoFilterTypes {
    return this.shownFilterType
  }

  @action.bound
  public toggle(filterType: PhotoFilterTypes) {
    if (this.shownFilterType === filterType) {
      this.shownFilterType = PhotoFilterTypes.none
      return
    }

    this.shownFilterType = filterType
  }

  @action.bound
  public setGateFilterValue(gates: string[]) {
    this.selectedGates = gates
  }

  @action.bound
  public setRouteFilterValue(routes: string[]) {
    this.selectedRoutes = routes
  }

  @action.bound
  public setBuildingFilterValue(buildings: string[]) {
    this.selectedBuildings = buildings
  }

  @action.bound
  public setZoneFilterValue(zones: string[]) {
    this.selectedZones = zones
  }

  @action.bound
  public setStateFilterValue(states: string[]) {
    this.selectedStates = states
  }

  public get allDeliveryMessagesIds(): string[] {
    return this.messagesStore.list
      .filter(
        m =>
          !m.activityId &&
          this.deliveriesStore.list.map(d => d.threadId).includes(m.threadId),
      )
      .map(m => m.id)
  }

  @computed
  public get photosCount(): number {
    if (!this.filteredPhotosByDays.length) {
      return 0
    }
    return this.filteredPhotosByDays.reduce((sum, PhotosByDay) => {
      PhotosByDay.deliveriesPhotos.forEach(deliveryPhotos => {
        sum += deliveryPhotos.photos.length
      })
      return sum
    }, 0)
  }

  @computed
  public get filteredPhotosByDays(): IPhotosByDays[] {
    const photosByDays: IPhotosByDays[] = []

    this.allDeliveries
      .filter(delivery => this.isDeliveryPassFilters(delivery))
      .forEach(singleDelivery => {
        this.getDeliveryPhotosByDays(singleDelivery, photosByDays)
      })
    return photosByDays
  }

  @computed
  public get photosByDays(): IPhotosByDays[] {
    const photosByDays: IPhotosByDays[] = []
    this.allDeliveries.forEach(singleDelivery => {
      this.getDeliveryPhotosByDays(singleDelivery, photosByDays)
    })
    return photosByDays
  }

  @computed
  public get allDeliveries(): Delivery[] {
    return this.deliveriesStore.list.sort((a, b) => b.startDate - a.startDate)
  }

  public get isScheduleModeAvailable(): boolean {
    return !this.state.isTrackerDisabled
  }

  public get isDeliveriesModeAvailable(): boolean {
    return !this.state.isDeliveriesDisabled
  }

  public get isScheduleModeActive(): boolean {
    return this.mode === PhotosMode.Schedule
  }

  public get isDeliveriesModeActive(): boolean {
    return this.mode === PhotosMode.Deliveries
  }

  public get isGridViewModeActive(): boolean {
    return this.viewMode === PhotosViewMode.Grid
  }

  public get isMapViewModeActive(): boolean {
    return this.viewMode === PhotosViewMode.Map
  }

  @action.bound
  public setScheduleMode() {
    this.mode = PhotosMode.Schedule
  }

  @action.bound
  public setDeliveriesMode() {
    this.mode = PhotosMode.Deliveries
  }

  public get isDateFilterActive(): boolean {
    return this.dateFilters.isDateFilterActive
  }

  private get startDate(): Date {
    const { currentProjectStartDate } = this.projectsStore
    const { startDate } = this.dateFilters
    return this.isDateFilterActive ? startDate : currentProjectStartDate
  }

  private get endDate(): Date {
    const { currentProjectEndDate } = this.projectsStore
    if (!this.isDateFilterActive) {
      return currentProjectEndDate
    }

    return this.projectDateStore.getEndDate(this.dateFilters)
  }

  private isDeliveryPassFilters(
    delivery: Delivery,
    excludedFilterType?: PhotoFilterTypes,
  ): boolean {
    const {
      gate,
      zone,
      status,
      company,
      vendor,
      startDate,
      endDate,
      route,
      building,
    } = delivery
    const { hiddenFields } = this.state.delivery
    const selectedDateInterval = new DateInterval(
      this.startDate,
      this.endDate,
      this.projectDateStore,
    )
    const deliveryDateInterval = new DateInterval(
      new Date(startDate),
      new Date(endDate),
      this.projectDateStore,
    )
    if (
      this.isDateFilterActive &&
      !areIntervalTimesIntersects(selectedDateInterval, deliveryDateInterval)
    ) {
      return false
    }
    if (
      !hiddenFields[FieldIds.GATE] &&
      !!gate &&
      !this.selectedGates.includes(gate) &&
      excludedFilterType !== PhotoFilterTypes.gate
    ) {
      return false
    }
    if (
      !hiddenFields[FieldIds.ZONE] &&
      !!zone &&
      !this.selectedZones.includes(zone) &&
      excludedFilterType !== PhotoFilterTypes.area
    ) {
      return false
    }
    if (
      !hiddenFields[FieldIds.ROUTE] &&
      !!route &&
      !this.selectedRoutes.includes(route) &&
      excludedFilterType !== PhotoFilterTypes.route
    ) {
      return false
    }
    if (
      !hiddenFields[FieldIds.BUILDING] &&
      !!building &&
      !this.selectedBuildings.includes(building) &&
      excludedFilterType !== PhotoFilterTypes.building
    ) {
      return false
    }
    if (
      !this.selectedStates.includes(status) &&
      excludedFilterType !== PhotoFilterTypes.status
    ) {
      return false
    }
    if (
      !hiddenFields[FieldIds.COMPANY] &&
      company &&
      !this.selectedCompanies.includes(company) &&
      excludedFilterType !== PhotoFilterTypes.company
    ) {
      return false
    }
    // check if delivery's user selected OR deleted users selected and contain delivery's user
    if (
      !this.selectedReporters.includes(delivery.userId) &&
      (!this.selectedReporters.includes(DELETED_USER_OPTION_ID) ||
        !this.deletedUsersIds.includes(delivery.userId)) &&
      excludedFilterType !== PhotoFilterTypes.reporter
    ) {
      return false
    }
    if (
      !hiddenFields[FieldIds.VENDOR] &&
      vendor &&
      !this.selectedSuppliers.includes(vendor) &&
      excludedFilterType !== PhotoFilterTypes.supplier
    ) {
      return false
    }

    return true
  }

  private getDeliveryRelatedPhotosCount(
    propertyName: string,
    propertyValue: string,
    excludedFilterType: PhotoFilterTypes,
  ) {
    if (!this.photosByDays.length) {
      return 0
    }

    let count = 0
    this.photosByDays.forEach(photosByDay =>
      photosByDay.deliveriesPhotos.forEach(deliveryPhotos => {
        const { delivery, photos } = deliveryPhotos
        if (
          delivery[propertyName] === propertyValue &&
          this.isDeliveryPassFilters(delivery, excludedFilterType)
        ) {
          count += photos.length
        }
      }),
    )
    return count
  }

  @computed
  private get deletedUsersIds(): string[] {
    return this.allUsersIds.filter(
      userId => !this.projectMembersStore.getById(userId),
    )
  }

  private get allUsersIds(): string[] {
    return [...new Set(this.deliveriesStore.list.map(d => d.userId))]
  }

  private getDeliveryPhotosCountByStatus(status: DeliveryStatus) {
    if (!this.photosByDays.length) {
      return 0
    }

    let count = 0
    this.photosByDays.forEach(photosByDay =>
      photosByDay.deliveriesPhotos.forEach(deliveryPhotos => {
        const { delivery, photos } = deliveryPhotos
        count += photos.filter(
          photo =>
            this.getStatus(delivery, photo.messageId) === status &&
            this.isDeliveryPassFilters(delivery, PhotoFilterTypes.status),
        ).length
      }),
    )
    return count
  }

  private getDeliveryPhotosByDays(
    singleDelivery: Delivery,
    photosByDays: IPhotosByDays[],
  ) {
    const { startOfDay, isSameDay } = this.projectDateStore
    this.getDeliveryPhotos(singleDelivery).forEach(photo => {
      const day = startOfDay(singleDelivery.startDate)
      const photosByDay = photosByDays.find(messageByDay =>
        isSameDay(messageByDay.day, day),
      )

      if (!photosByDay) {
        photosByDays.push({
          day,
          deliveriesPhotos: [
            {
              delivery: singleDelivery,
              photos: [photo],
            },
          ],
        })
      } else {
        const photosOnDay = photosByDay.deliveriesPhotos.find(
          d => d.delivery.id === singleDelivery.id,
        )
        if (photosOnDay) {
          photosOnDay.photos.push(photo)
        } else {
          const deliveryPhotos: IDeliveryPhotos = {
            delivery: singleDelivery,
            photos: [photo],
          }
          photosByDay.deliveriesPhotos.push(deliveryPhotos)
        }
      }
    })
  }

  private getDeliveryPhotos(delivery: Delivery) {
    const deliveryThreadsIds = this.deliveryStatusChangesStore
      .getStatusChangesForDelivery(delivery.id)
      .map(status => status.threadId)
      .concat(delivery.threadId)
    const messagesIds = this.messagesStore.list
      .filter(m => deliveryThreadsIds.includes(m.threadId))
      .map(m => m.id)
    return this.photosStore.list.filter(p => messagesIds.includes(p.messageId))
  }
}
