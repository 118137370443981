import * as React from 'react'

import { PopperModifiers, Position, Tooltip } from '@blueprintjs/core'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'

import ISetUpStep from './ISetUpStep'

interface ISetUpNavigationRowProps {
  step: ISetUpStep
  projectSetUpPageStore: ProjectSetUpPageStore
  isShortVersion?: boolean
}
const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: false,
  },
  flip: { enabled: false },
  hide: { enabled: false },
}
const iconSize = 18
const iconProps = { size: iconSize, className: 'row x-center y-center no-grow' }

@observer
export default class SetUpNavigationRow extends React.Component<ISetUpNavigationRowProps> {
  public render() {
    const { projectSetUpPageStore, step, isShortVersion } = this.props
    const { page, getTitle, getIcon } = step
    const titleTextElement = <div className="title-text">{getTitle()}</div>
    const isAllowed = projectSetUpPageStore.isAllowedToNavigate(page)

    return (
      <div
        onClick={this.navigateTo}
        className={classList({
          'navigation-row row y-center py8': true,
          current: projectSetUpPageStore.currentPage === page,
          pointer: isAllowed,
          'inactive-element': !isAllowed,
        })}
      >
        <Tooltip
          disabled={!isShortVersion}
          modifiers={popoverPopperModifiers}
          position={Position.RIGHT}
          content={titleTextElement}
          className="row"
        >
          <span
            className={classList({
              'navigation-row-title row no-grow': true,
              'mr5 pl24': !isShortVersion,
            })}
          >
            <div className="no-grow">{getIcon?.(iconProps)}</div>
            {titleTextElement}
          </span>
        </Tooltip>
      </div>
    )
  }

  @action.bound
  private navigateTo() {
    const { projectSetUpPageStore, step } = this.props
    projectSetUpPageStore.navigateTo(step.page)
  }
}
