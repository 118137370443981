import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import PhotosPageStore from '../PhotosPageStore.store'

interface IProps {
  photosStore: PhotosPageStore
}

@observer
export default class PhotosViewToggle extends React.Component<IProps> {
  public render() {
    const { isGridViewModeActive, isMapViewModeActive } = this.props.photosStore

    return (
      <div className="view-row row x-evenly">
        <span
          className={classList({
            'inline-block text header pointer no-grow': true,
            light: !isGridViewModeActive,
            'bb-primary-blue primary-blue': isGridViewModeActive,
          })}
        >
          Grid
        </span>
        <span
          className={classList({
            'inline-block text header pointer no-grow': true,
            light: !isMapViewModeActive,
            'bb-primary-blue primary-blue': isMapViewModeActive,
          })}
        >
          Map
        </span>
      </div>
    )
  }
}
