import * as React from 'react'

import ActivityCode from '~/client/src/shared/models/ActivityCode'

interface IResponsibilityCellProps {
  responsibilityCode: ActivityCode
  isCode: boolean
}

export default class ResponsibilityCell extends React.Component<IResponsibilityCellProps> {
  public render() {
    const { responsibilityCode, isCode } = this.props
    let responsibilityText = '-'
    if (responsibilityCode) {
      responsibilityText = isCode
        ? responsibilityCode.shortName
        : responsibilityCode.name
    }

    return (
      <div className="text cell-wrapper px10 bb-cool-grey">
        <div className="hidable-content">{responsibilityText}</div>
      </div>
    )
  }
}
