import { action, computed, observable } from 'mobx'

import { MonitoringStatus, SitePermitStatus } from '~/client/graph'
import LogisticActionTypes from '~/client/src/desktop/enums/LogisticsActionTypes'
import {
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import ClosureStatus from '~/client/src/shared/enums/ClosureStatus'
import LogisticsGroupingOption from '~/client/src/shared/enums/LogisticsGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  getClosureStatusDisplayName,
  getFormStatusDisplayName,
  getMonitoringStatusDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'
import ILogisticItem, {
  LogisticItemApp,
} from '~/client/src/shared/models/ILogisticItem'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { workflowDoneStatuses } from '~/client/src/shared/models/Permit'
import User from '~/client/src/shared/models/User'
import BaseLogisticsList from '~/client/src/shared/stores/BaseLogisticsLists.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import AnnouncementFollowingsStore from '~/client/src/shared/stores/domain/AnnouncementFollowings.store'
import CastFollowingsStore from '~/client/src/shared/stores/domain/CastFollowings.store'
import ClosureFollowingsStore from '~/client/src/shared/stores/domain/ClosureFollowings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import {
  CATEGORY_ROW_HEIGHT,
  DEFAULT_ID_KEY,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { logisticsDataKeys } from '~/client/src/shared/types/LogisticsDataKeys'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  appTypeToSortIndexMap,
  getAppSectionName,
} from '~/client/src/shared/utils/logisticsHelper'
import {
  groupingAttributeMapper,
  groupingCommonMapper,
} from '~/client/src/shared/utils/mappers'
import {
  EMPTY_STRING,
  LOCATION_SEPARATOR,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import LogisticsStore from '../../Logistics.store'

const unassignedValues = [UNASSIGNED, NO_SPECIFIED, EMPTY_STRING, NO_VALUE]

export default class LogisticsListStore
  extends BaseMultiBandListStore<ILogisticItem>
  implements BaseLogisticsList
{
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })
  @observable public shouldSubscriptionConfirmModalShow: boolean = false
  @observable public isDeletionConfirmModalShown: boolean = false
  @observable public activeActionType: LogisticActionTypes = null

  public constructor(
    private readonly logisticsStore: LogisticsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly sitePermitsStore: SitePermitsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly deliveryFollowingsStore?: DeliveryFollowingsStore,
    private readonly activityFollowingsStore?: ActivityFollowingsStore,
    private readonly announcementFollowingsStore?: AnnouncementFollowingsStore,
    private readonly sitePermitFollowingsStore?: SitePermitFollowingsStore,
    private readonly castFollowingsStore?: CastFollowingsStore,
    private readonly closureFollowingsStore?: ClosureFollowingsStore,
    private readonly permitTypesStore?: PermitTypesStore,
  ) {
    super(
      logisticsStore.filters,
      () => logisticsStore.allLogistics,
      [],
      DEFAULT_ID_KEY,
    )
  }

  public columnsWidthState = new Map<string, number>([
    [logisticsDataKeys.CHECKBOX, 40],
    [logisticsDataKeys.LOGISTIC_APP, 220],
    [logisticsDataKeys.LOGISTIC_NAME, 300],
    [logisticsDataKeys.LOGISTIC_STATUS, 190],
    [logisticsDataKeys.LOGISTIC_LOCATION, 300],
    [logisticsDataKeys.LOGISTIC_COMPANY, 230],
    [logisticsDataKeys.LOGISTIC_DATE, 250],
    [logisticsDataKeys.LOGISTIC_RESPONSIBLE, 300],
  ])

  @computed
  public get columns(): ILWFCColumn[] {
    const columns = [
      {
        dataKey: logisticsDataKeys.CHECKBOX,
      },
      {
        label: Localization.translator.myAndAppWithEmptySpaces,
        dataKey: logisticsDataKeys.LOGISTIC_APP,
      },
      {
        label: Localization.translator.name,
        dataKey: logisticsDataKeys.LOGISTIC_NAME,
      },
      {
        label: Localization.translator.status,
        dataKey: logisticsDataKeys.LOGISTIC_STATUS,
      },
      {
        label: Localization.translator.location,
        dataKey: logisticsDataKeys.LOGISTIC_LOCATION,
      },
      {
        label: Localization.translator.company,
        dataKey: logisticsDataKeys.LOGISTIC_COMPANY,
      },
      {
        label: Localization.translator.date,
        dataKey: logisticsDataKeys.LOGISTIC_DATE,
      },
      {
        label: Localization.translator.responsibleUser,
        dataKey: logisticsDataKeys.LOGISTIC_RESPONSIBLE,
      },
    ]

    return columns.map(column => ({
      ...column,
      width: this.columnsWidthState.get(column.dataKey),
    }))
  }

  @computed
  public get statusTitle(): string {
    if (this.isSomeRowSelected) {
      return `${this.selection.size} of ${this.displayedCount} selected`
    }

    return `${this.displayedCount} items showing`
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { groupingKey } = this.logisticsStore.filters

    return this.toBandTreeNodeRows(
      [groupingKey],
      null,
      '',
      0,
      false,
      this.sortTreeObjs,
    )
  }

  private sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) {
        return 1
      }
      if (unassignedValues.includes(b.id)) {
        return -1
      }
      if (this.groupingKey === LogisticsGroupingOption.APP) {
        return appTypeToSortIndexMap[a.id] - appTypeToSortIndexMap[b.id]
      }
      if (this.groupingKey === LogisticsGroupingOption.DATE) {
        return parseInt(b.id, 10) - parseInt(a.id, 10)
      }

      return this.collator.compare(a.name, b.name)
    })
  }

  @computed
  public get categoryToInstancesMap(): {
    [categoryKey: string]: ILogisticItem[]
  } {
    const map = {}

    this.filteredCollection.forEach(logistic => {
      const categoryIds = this.getCategoryIds(logistic, this.groupingKey)
      categoryIds.forEach(formattedCategoryId => {
        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(logistic)
        } else {
          map[formattedCategoryId] = [logistic]
        }
      })
    })

    return map
  }

  private getCategoryIds(logistic: ILogisticItem, band: string) {
    switch (band) {
      case LogisticsGroupingOption.COMPANY:
        return logistic.companyIds?.map(
          id => this.companiesStore.getCompanyById(id)?.id || UNASSIGNED,
        )
      case LogisticsGroupingOption.RESPONSIBLE:
        const members = this.projectMembersStore.getByIds(
          logistic.responsibleContactIds,
        )
        return members.map(member => member.id || UNASSIGNED)
      case LogisticsGroupingOption.DATE:
        return [logistic.startDate?.toString() || UNASSIGNED]
      case LogisticsGroupingOption.STATUS:
        return [logistic.status || UNASSIGNED]
      case LogisticsGroupingOption.APP:
        return [logistic.app]
      case LogisticsGroupingOption.TYPE:
        return [logistic.type]
      case LogisticsGroupingOption.LBS:
        return this.getLocationCategoryIds(logistic.locations)
      case LogisticsGroupingOption.EQUIPMENT:
        return this.getLocationCategoryIds(logistic.equipment)
      case LogisticsGroupingOption.NONE:
        return [LogisticsGroupingOption.NONE]
      default:
        return [UNASSIGNED]
    }
  }

  @action.bound
  public selectRow(objectId: string) {
    this.resetSelection()
    this.selection.set(objectId, true)
  }

  @action.bound
  public onRowClick(rowData: LWFCRowData, columnKey: string) {
    if (columnKey === logisticsDataKeys.CHECKBOX) {
      this.toggleRowCheckbox(rowData[logisticsDataKeys.ID])
      return
    }
    const logistic = this.filteredCollection.find(
      logistic => logistic.id === rowData[logisticsDataKeys.ID],
    )

    switch (logistic.app) {
      case LogisticItemApp.FORM:
        const permit = this.sitePermitsStore.getFormById(logistic.entityId)
        this.logisticsStore.showPermitViewOrApprovalDialog(permit)
        break
      case LogisticItemApp.ANNOUNCEMENT:
        const announcements = this.logisticsStore.allAnnouncements.find(
          announcement => announcement.id === logistic.id,
        )
        this.logisticsStore.showCreationAndEditionAnnouncementForm(
          announcements,
        )
        break
      case LogisticItemApp.DELIVERY:
        const delivery = this.logisticsStore.allDeliveries.find(
          d => d.id === logistic.id,
        )
        this.logisticsStore.displayDeliverySideView(delivery?.id)
        break
      case LogisticItemApp.SCHEDULE:
        const activity = this.logisticsStore.allActivities.find(
          a => a.id === logistic.id,
        )
        this.logisticsStore.displayActivitySideView(activity.id)
        break
    }
  }

  @action.bound
  public toggleRowCheckbox(objectId: string) {
    this.toggleInstance(objectId)
  }

  @action.bound
  public setActionType(actionType: LogisticActionTypes) {
    if (actionType === LogisticActionTypes.SUBSCRIBE) {
      return this.openSubscriptionConfirmModal()
    }
    if (actionType === LogisticActionTypes.DELETE) {
      return this.openDeletionConfirmModal()
    }

    this.activeActionType = actionType
  }

  @action.bound
  public resetActionType() {
    this.activeActionType = null
  }

  @action.bound
  public openSubscriptionConfirmModal() {
    this.shouldSubscriptionConfirmModalShow = true
  }

  @action.bound
  public closeSubscriptionConfirmModal() {
    this.shouldSubscriptionConfirmModalShow = false
  }

  @action.bound
  public openDeletionConfirmModal() {
    this.isDeletionConfirmModalShown = true
  }

  @action.bound
  public closeDeletionConfirmModal() {
    this.isDeletionConfirmModalShown = false
  }

  @computed
  public get unsubscribedSelectedLogisticsIds(): string[] {
    if (!this.selectedInstances.length) {
      return []
    }
    const results = this.selectedInstances.filter(logistic => {
      switch (logistic.app) {
        case LogisticItemApp.FORM:
          return !this.sitePermitFollowingsStore.isEntityFollowed(logistic.id)
        case LogisticItemApp.DELIVERY:
          return !this.deliveryFollowingsStore.isEntityFollowed(logistic.id)
        case LogisticItemApp.ANNOUNCEMENT:
          return !this.announcementFollowingsStore.isEntityFollowed(logistic.id)
        case LogisticItemApp.SCHEDULE:
          return !this.activityFollowingsStore.isEntityFollowed(logistic.id)
        case LogisticItemApp.SENSOR:
          return !this.castFollowingsStore.isEntityFollowed(logistic.id)
        case LogisticItemApp.CLOSURE:
          return !this.closureFollowingsStore.isEntityFollowed(logistic.id)
        default:
          return false
      }
    })

    return results.map(l => l.id)
  }

  @computed
  public get deletionConfirmMessage(): string {
    return Localization.translator.logisticsListDescriptions.deletionConfirmMsg(
      this.selectedInstancesCount,
    )
  }

  @computed
  public get subscriptionConfirmMessage(): string {
    const dSelectedCount = this.selection.size
    const dUnsubscribedCount = this.unsubscribedSelectedLogisticsIds.length

    const { subscribeConfirmMassage, unsubscribeConfirmMessage } =
      Localization.translator.logisticsListDescriptions

    if (this.isSubscribeMode) {
      return subscribeConfirmMassage(dUnsubscribedCount, dSelectedCount)
    }

    return unsubscribeConfirmMessage(dSelectedCount)
  }

  @computed
  public get subscriptionConfirmButtonText(): string {
    return this.isSubscribeMode
      ? Localization.translator.subscribe_verb
      : Localization.translator.yesUnsubscribe
  }

  public get isSubscribeMode(): boolean {
    return !!this.unsubscribedSelectedLogisticsIds.length
  }

  @action.bound
  public async deleteForms() {
    if (this.isUpdating || this.areSelectedWorkflowsDone) {
      return
    }

    await this.sitePermitsStore.deleteForms(this.selectedInstancesIds)

    this.closeDeletionConfirmModal()
    this.resetSelection()
  }

  @action.bound
  public handleChangeAssociations() {
    if (this.isSubscribeMode) {
      this.subscribe()
    } else {
      this.unsubscribe()
    }

    this.closeSubscriptionConfirmModal()
  }

  public get areSelectedWorkflowsDone(): boolean {
    return this.selectedInstances.some(i =>
      workflowDoneStatuses.includes(i.status as SitePermitStatus),
    )
  }

  public get isUpdating(): boolean {
    return this.sitePermitsStore.isDeleting
  }

  @action.bound
  private subscribe() {
    const unsubscribedSelectedLogistics = this.selectedInstances.filter(l =>
      this.unsubscribedSelectedLogisticsIds.includes(l.id),
    )
    const unsubscribedSelectedLogisticsMap =
      unsubscribedSelectedLogistics.reduce((acc, logistic) => {
        if (!acc[logistic.app]) acc[logistic.app] = []

        acc[logistic.app].push(logistic.entityId)

        return acc
      }, {})

    if (unsubscribedSelectedLogisticsMap[LogisticItemApp.FORM]) {
      this.sitePermitFollowingsStore.followEntities(
        unsubscribedSelectedLogisticsMap[LogisticItemApp.FORM],
      )
    }

    if (unsubscribedSelectedLogisticsMap[LogisticItemApp.DELIVERY]) {
      this.deliveryFollowingsStore.followEntities(
        unsubscribedSelectedLogisticsMap[LogisticItemApp.DELIVERY],
      )
    }

    if (unsubscribedSelectedLogisticsMap[LogisticItemApp.ANNOUNCEMENT]) {
      this.announcementFollowingsStore.followEntities(
        unsubscribedSelectedLogisticsMap[LogisticItemApp.ANNOUNCEMENT],
      )
    }

    if (unsubscribedSelectedLogisticsMap[LogisticItemApp.SCHEDULE]) {
      this.activityFollowingsStore.followEntities(
        unsubscribedSelectedLogisticsMap[LogisticItemApp.SCHEDULE],
      )
    }

    if (unsubscribedSelectedLogisticsMap[LogisticItemApp.SENSOR]) {
      this.castFollowingsStore.followEntities(
        unsubscribedSelectedLogisticsMap[LogisticItemApp.SENSOR],
      )
    }

    if (unsubscribedSelectedLogisticsMap[LogisticItemApp.CLOSURE]) {
      this.closureFollowingsStore.followEntities(
        unsubscribedSelectedLogisticsMap[LogisticItemApp.CLOSURE],
      )
    }
  }

  @action.bound
  private unsubscribe() {
    const selectedLogisticsMap = this.selectedInstances.reduce(
      (acc, logistic) => {
        if (!acc[logistic.app]) acc[logistic.app] = []

        acc[logistic.app].push(logistic.id)

        return acc
      },
      {},
    )

    if (selectedLogisticsMap[LogisticItemApp.FORM]) {
      this.sitePermitFollowingsStore.unfollowEntities(
        selectedLogisticsMap[LogisticItemApp.FORM],
      )
    }

    if (selectedLogisticsMap[LogisticItemApp.DELIVERY]) {
      this.deliveryFollowingsStore.unfollowEntities(
        selectedLogisticsMap[LogisticItemApp.DELIVERY],
      )
    }

    if (selectedLogisticsMap[LogisticItemApp.ANNOUNCEMENT]) {
      this.announcementFollowingsStore.unfollowEntities(
        selectedLogisticsMap[LogisticItemApp.ANNOUNCEMENT],
      )
    }

    if (selectedLogisticsMap[LogisticItemApp.SCHEDULE]) {
      this.activityFollowingsStore.unfollowEntities(
        selectedLogisticsMap[LogisticItemApp.SCHEDULE],
      )
    }

    if (selectedLogisticsMap[LogisticItemApp.SENSOR]) {
      this.castFollowingsStore.unfollowEntities(
        selectedLogisticsMap[LogisticItemApp.SENSOR],
      )
    }

    if (selectedLogisticsMap[LogisticItemApp.CLOSURE]) {
      this.closureFollowingsStore.unfollowEntities(
        selectedLogisticsMap[LogisticItemApp.CLOSURE],
      )
    }
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore

    switch (currentBand) {
      case LogisticsGroupingOption.NONE:
        return [
          {
            id: LogisticsGroupingOption.NONE,
            name: LogisticsGroupingOption.NONE,
          },
        ]
      case LogisticsGroupingOption.APP:
        return Object.values(LogisticItemApp).map(app => ({
          id: app,
          name: getAppSectionName(app),
        }))

      case LogisticsGroupingOption.COMPANY:
        return [
          ...this.filteredCollection
            .flatMap(logistic => logistic.companyIds)
            .map(companyId => {
              const company = this.companiesStore.getCompanyById(companyId)
              return {
                name: company?.name || UNASSIGNED,
                id: company?.id || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.RESPONSIBLE:
        return [
          ...this.filteredCollection
            .flatMap(logistic => logistic.responsibleContactIds)
            .map(userId => {
              const user = this.projectMembersStore.getById(userId)
              return {
                name: user?.fullName || UNASSIGNED,
                id: user?.id || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.DATE:
        return this.filteredCollection
          .map(logistic => logistic.startDate)
          .map(date => {
            return {
              name: getMonthDayAndTimeToDisplay(date) || UNASSIGNED,
              id: date?.toString() || UNASSIGNED,
            }
          })
      case LogisticsGroupingOption.LBS:
        return [
          ...this.locationAttributesStore.attributesWithDeletedWithoutEquipment.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.EQUIPMENT:
        return [
          ...this.locationAttributesStore.offloadingEquipmentsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]

      case LogisticsGroupingOption.STATUS:
        return [
          ...Object.values(SitePermitStatus).map(s => ({
            id: s,
            name: getFormStatusDisplayName(s),
          })),
          ...Object.values(ClosureStatus).map(s => ({
            id: s,
            name: getClosureStatusDisplayName(s),
          })),
          ...Object.values(MonitoringStatus).map(s => ({
            id: s,
            name: getMonitoringStatusDisplayName(s),
          })),
        ]
      case LogisticsGroupingOption.TYPE:
        return [
          ...this.filteredCollection
            .flatMap(logistic => logistic.type)
            .map(type => {
              const permitType =
                this.permitTypesStore.getLastUpdatedTypeByType(type)
              return {
                name: permitType?.name || UNASSIGNED,
                id: type || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: ILogisticItem[] }
  } {
    const map = {}

    const groupingOptions = Object.values(LogisticsGroupingOption)

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(logistic => {
        const groupNames: string[] = this.getCategoryIds(logistic, band)
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(groupName => {
          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }

          const index = bandMap[groupName].findIndex(
            l => l.startDate > logistic.startDate,
          )

          if (index === -1) {
            bandMap[groupName].push(logistic)
          } else {
            bandMap[groupName].splice(index, 0, logistic)
          }
        })
      })
    })
    return map
  }

  protected toRows(logistics: ILogisticItem[]) {
    return logistics.map(logistic => {
      const data = {
        [logisticsDataKeys.ID]: logistic.id,
        [logisticsDataKeys.CHECKBOX]: this.selection.get(logistic.id),
        [logisticsDataKeys.LOGISTIC_APP]: {
          app: logistic.app,
          logisticId: logistic.entityId,
        },
        [logisticsDataKeys.LOGISTIC_STATUS]: {
          app: logistic.app,
          status: logistic.status,
          workflowStepLevel: logistic.workflowStepLevel,
          isLate: logistic.isStatusLate,
          isOpened: logistic.isOpened,
        },
        [logisticsDataKeys.LOGISTIC_LOCATION]: {
          isBreadcrumb: logistic.app === LogisticItemApp.DELIVERY,
          locations: logistic.locations,
        },
        [logisticsDataKeys.LOGISTIC_NAME]: {
          id: logistic.id,
          name: logistic.name,
        },
        [logisticsDataKeys.LOGISTIC_COMPANY]: logistic.companyIds
          ?.map(id => this.companiesStore.getCompanyById(id))
          .filter(c => c),
        [logisticsDataKeys.LOGISTIC_DATE]: {
          startDate: logistic?.startDate,
          endDate: logistic?.endDate,
        },
        [logisticsDataKeys.LOGISTIC_RESPONSIBLE]:
          this.projectMembersStore.getByIds(logistic.responsibleContactIds),
      }

      return { data }
    })
  }

  protected get instancesInPeriodInterval(): ILogisticItem[] {
    return this.logisticsStore.logisticItemsInPeriodInterval
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getSortingValue(aRowData, columnKey)
    const v2 = this.getSortingValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      if (unassignedValues.includes(v1)) {
        return 1
      }
      if (unassignedValues.includes(v2)) {
        return -1
      }
      return this.collator.compare(v1, v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  protected searchFiltering = (logistic: ILogisticItem) => {
    const key = this.filter.searchKey.toLowerCase()
    const entityValues: string[] = [
      ...logistic.locations.map(l => l.name),
      logistic.name,
      this.projectDateStore.getWeekdayMonthDayYearAndTimeToDisplay(
        logistic.startDate,
      ),
      this.projectDateStore.getWeekdayMonthDayYearAndTimeToDisplay(
        logistic.endDate,
      ),
      logistic.app,
      logistic.status,
      ...this.projectMembersStore
        .getByIds(logistic.responsibleContactIds)
        .map(u => u.fullName),
      ...logistic.companyIds.map(id =>
        this.companiesStore.getCompanyNameById(id),
      ),
    ]
    return entityValues.some(value => value?.toLowerCase().includes(key))
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: this.getCategoryLabelById(categoryId),
      categoryLabel: `${this.getCategoryLabelById(categoryId)} (${count})`,
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
    }

    return { category, data: {}, height: CATEGORY_ROW_HEIGHT, level: 0 }
  }

  protected getCategoryLabelById(categoryId: any): string {
    switch (this.groupingKey) {
      case LogisticsGroupingOption.RESPONSIBLE:
        const user = this.projectMembersStore.getById(categoryId)
        return User.getFullNameToDisplay(user, this.userProjectsStore, true)
      default:
        return categoryId
    }
  }

  private getSortingValue(rowData: LWFCRowData, columnKey: string) {
    switch (columnKey) {
      case logisticsDataKeys.LOGISTIC_LOCATION:
        const locations = rowData[columnKey]?.locations
        return locations?.length > 1
          ? locations.length.toString()
          : locations?.[0]?.name || EMPTY_STRING
      case logisticsDataKeys.LOGISTIC_STATUS:
        return rowData[columnKey]?.status || EMPTY_STRING
      case logisticsDataKeys.LOGISTIC_NAME:
        return rowData[columnKey]?.name || EMPTY_STRING
      case logisticsDataKeys.LOGISTIC_APP:
        return rowData[columnKey].app
      case logisticsDataKeys.LOGISTIC_DATE:
        return rowData[columnKey]?.startDate || 0
      case logisticsDataKeys.LOGISTIC_COMPANY:
        const companiesData = rowData[columnKey]
        return companiesData?.length > 1
          ? companiesData.length.toString()
          : companiesData?.[0]?.name || EMPTY_STRING
      case logisticsDataKeys.LOGISTIC_RESPONSIBLE:
        const usersData = rowData[columnKey]
        return usersData?.length > 1
          ? usersData.length.toString()
          : usersData?.[0]?.fullName || EMPTY_STRING

      default:
        const value = rowData[columnKey]
        return value === NO_SPECIFIED ? EMPTY_STRING : value
    }
  }

  private getLocationCategoryIds = (locations: LocationBase[]): string[] => {
    return Array.from(
      locations?.reduce(
        (set, l) => set.add(`${l.id}${LOCATION_SEPARATOR}${l.type}`),
        new Set<string>(),
      ),
    )
  }
}
