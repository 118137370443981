import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { QRCode } from 'react-qrcode-logo'

import { ICompany } from '~/client/graph'
import BaseAddEditDialogWrapper from '~/client/src/desktop/components/BaseAddEditDialogWrapper/BaseAddEditDialogWrapper'
import CompanyDomainsInput from '~/client/src/desktop/components/CompanyDomainsInput/CompanyDomainsInput'
import STXCompanyLinker from '~/client/src/desktop/components/STXCompanyLinker/STXCompanyLinker'
import CompanyFieldId from '~/client/src/desktop/enums/CompanyFieldId'
import ImagePreview from '~/client/src/shared/components/ImagePreview/ImagePreview'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import InfoSectionId, {
  getSectionTranslate,
} from '~/client/src/shared/enums/InfoSectionId'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import {
  SCAN_CODE_SEPARATOR,
  ScanCodeTypes,
} from '~/client/src/shared/models/Scanner'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { QR_CODE_LOGO_SRC } from '~/client/src/shared/utils/usefulStrings'

import AddEditCompanyDialogStore from './AddEditCompanyDialog.store'
import MergeCompanyDialogStore from './MergeCompanyDialog.store'
import ResponsibleToggler from './ResponsibleToggler/ResponsibleToggler'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  closeDialog: () => void
  loading: boolean
  onSubmit: (items: Company[], lastUpdatedCompanyId: string) => void
  itemsToEdit: ICompany[]
  allItems: ICompany[]

  errorMessage?: string
  resetErrorMessage?: () => void
  isMergingMode?: boolean

  projectMembersStore?: ProjectMembersStore
  whiteListItemsStore?: WhiteListItemsStore
  companiesStore?: CompaniesStore
}

const domains = 'Domains'

@inject('projectMembersStore', 'whiteListItemsStore', 'companiesStore')
@observer
export default class CompanyDialog extends React.Component<IProps> {
  private readonly store: AddEditCompanyDialogStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = props.isMergingMode
      ? new MergeCompanyDialogStore(
          props.itemsToEdit,
          props.allItems,
          props.resetErrorMessage,
          props.onSubmit,
          props.closeDialog,
          props.projectMembersStore,
          props.whiteListItemsStore,
          props.companiesStore,
          [CompanyFieldId.Code],
        )
      : new AddEditCompanyDialogStore(
          props.itemsToEdit,
          props.allItems,
          props.resetErrorMessage,
          props.onSubmit,
          props.closeDialog,
          props.projectMembersStore,
          props.whiteListItemsStore,
          props.companiesStore,
        )
  }

  public componentDidMount() {
    this.store.requestAdditionalData()
  }

  private get logoUrl() {
    return this.store.activeFormStore.fields[CompanyFieldId.LogoUrl]
  }

  public render() {
    if (!this.props.itemsToEdit.length) {
      return <div />
    }

    return (
      <BaseAddEditDialogWrapper
        store={this.store}
        isLoading={this.props.loading}
        errorInfo={this.store.activeFormStore.error || this.props.errorMessage}
      >
        <div className="row align-start">
          <div className="col">
            {this.logoUrl && (
              <div className="h90">
                <ImagePreview>
                  <img
                    alt={Localization.translator.logo}
                    src={this.logoUrl}
                    className="center"
                  />
                </ImagePreview>
              </div>
            )}
            {this.renderSection(InfoSectionId.Global)}
            <div className="ma12 ba-none bb-light-grey as-stretch" />
            {this.renderSection(InfoSectionId.CompanyContactInfo)}
            <div className="ma12 ba-none bb-light-grey as-stretch" />
            {this.renderSection(InfoSectionId.CompanyAddress)}
            <div className="ma12 ba-none bb-light-grey as-stretch" />
            {this.renderSection(InfoSectionId.QRCodes)}
            <div className="ma12 ba-none bb-light-grey as-stretch" />
            {this.renderSection(InfoSectionId.LegalInfo)}
          </div>
          <div className="mx24 my12 no-grow ba-none br-light-grey as-stretch" />
          <div className="col">
            {this.renderSection(InfoSectionId.Project)}
            <div className="ma12 ba-none bb-light-grey as-stretch" />

            {!this.store.isAddMode && (
              <>
                {this.renderSection(InfoSectionId.WhiteList)}
                <div className="ma12 ba-none bb-light-grey as-stretch" />
              </>
            )}

            {this.renderSection(InfoSectionId.Users)}
          </div>
        </div>
      </BaseAddEditDialogWrapper>
    )
  }

  private renderSection(sectionId: InfoSectionId) {
    const {
      isAddMode,
      getFieldsBySectionId,
      companyMembers,
      responsibleContactIds,
      handleResponsibleContactIdsChange,
    } = this.store

    const fields = getFieldsBySectionId(sectionId)

    let content = null
    switch (sectionId) {
      case InfoSectionId.Users:
        content = (
          <ResponsibleToggler
            members={companyMembers}
            responsibleContactIds={responsibleContactIds}
            onChange={handleResponsibleContactIdsChange}
          />
        )
        break

      case InfoSectionId.WhiteList:
        content = (
          <CompanyDomainsInput
            className="px8"
            label={domains}
            companyId={this.store.activeItemId}
            isLoading={this.store.areWhiteListItemsBeingLoaded}
          />
        )
        break
      case InfoSectionId.QRCodes:
        content = (
          <div className="col x-center my20">
            <QRCode
              value={this.companyQRCode}
              logoImage={QR_CODE_LOGO_SRC}
              size={200}
              fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
            />
          </div>
        )
        break

      case InfoSectionId.Global:
        content = isAddMode ? null : (
          <div className="row mx8 relative">
            <STXCompanyLinker companyId={this.store.activeItemId} />
          </div>
        )
        break

      default:
        content = BaseAddEditDialogWrapper.renderFields(
          fields,
          AvatarType.Company,
        )
    }

    return (
      <div>
        <div className="text bold uppercase light lp05 py12">
          {this.getSectionTitle(sectionId, companyMembers?.length)}
        </div>
        {content}
      </div>
    )
  }

  private get companyQRCode(): string {
    return `${ScanCodeTypes.company}${SCAN_CODE_SEPARATOR}${this.store.activeItemId}`
  }

  private getSectionTitle(
    sectionId: InfoSectionId,
    companyMembersCount: number = 0,
  ): string {
    const title = getSectionTranslate(sectionId)

    return sectionId === InfoSectionId.Users
      ? `${title} (${companyMembersCount})`
      : title
  }
}
