import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { BULLET } from '~/client/src/shared/utils/usefulStrings'

import FormTypesListStore from './FormTypesList.store'
import FormTypesHeaderBar from './components/FormTypesHeaderBar/FormTypesHeaderBar'
import FormTypesTable from './components/FormTypesTable'

import './FormTypesList.scss'

// localization: translated

interface IProps {
  isUpdating: boolean

  onSelectType(id: string): void
  onShowInAppChange(formTypeId: string): void
  onAddNewFormClick(): void
  onRemoveTypes(typeIds: string[]): void

  projectDateStore?: ProjectDateStore
  state?: DesktopInitialState
  permitTypesStore?: PermitTypesStore
  formCategoriesStore?: FormCategoriesStore
}

@inject('projectDateStore', 'state', 'permitTypesStore', 'formCategoriesStore')
@observer
export default class FormTypesList extends React.Component<IProps> {
  private readonly store: FormTypesListStore

  public constructor(props: IProps) {
    super(props)

    this.store = new FormTypesListStore(
      props.state,
      props.permitTypesStore,
      props.projectDateStore,
      props.formCategoriesStore,
    )
  }

  public render() {
    const { onAddNewFormClick, onSelectType, onShowInAppChange } = this.props
    const {
      rows,
      columns,
      minTableWidth,
      collapsedCategories,
      sortState,
      selectOrResetAll,
      handleColumnSort,
      toggleCategoryCollapsing,
      toggleCategory,
      toggleInstance,
    } = this.store

    return (
      <div className="col form-types-list-page">
        {this.deletionConfirmDialog}

        <div className="row py6 my10">
          <div className="text size22 line-28 no-grow nowrap">
            {Localization.translator.forms}
          </div>
          <div className="row x-end pl10">
            <BaseActionButton
              className="mx4 scale-blue-theme beautiful-shadow-hover brada24 px16"
              isAnimated
              isEnabled
              title={Localization.translator.newFormType}
              icon={<Icons.PlusRounded className="row no-grow" />}
              onClick={onAddNewFormClick}
            />
          </div>
        </div>

        <FormTypesHeaderBar formTypesListStore={this.store} />

        <div className="col form-types-list full-width pb20 overflow-auto">
          <FormTypesTable
            rows={rows}
            columns={columns}
            minTableWidth={minTableWidth}
            collapsedCategories={collapsedCategories}
            sortingOrder={sortState.order}
            sortedColumnKey={sortState.columnKey}
            selectOrDeselectAll={selectOrResetAll}
            collapseCategory={toggleCategoryCollapsing}
            selectCategory={toggleCategory}
            selectFormType={toggleInstance}
            onSelectType={onSelectType}
            onColumnSort={handleColumnSort}
            onShowInAppChange={onShowInAppChange}
          />
        </div>
      </div>
    )
  }

  private get deletionConfirmDialog(): JSX.Element {
    const {
      isRemoveDialogShown,
      hideDeleteFormsDialog,
      deletionConfirmMessage,
    } = this.store
    const { isUpdating } = this.props

    return (
      <ConfirmDialog
        isOpen={isRemoveDialogShown}
        loading={isUpdating}
        onCancelClicked={isUpdating ? NOOP : hideDeleteFormsDialog}
        onDoneClicked={this.deleteFormTypes}
        doneTitle={`${Localization.translator.yes}, ${Localization.translator.delete}`}
      >
        <div className="text large red pre-line pb10">
          {Localization.translator.logisticsListDescriptions.areYouWantToDelete}
          <br />
          <br />
          {BULLET} {deletionConfirmMessage}
        </div>
      </ConfirmDialog>
    )
  }

  private deleteFormTypes = () => {
    this.props.onRemoveTypes(this.store.selectedInstances.map(t => t.type))
    this.store.resetSelection()
    this.store.hideDeleteFormsDialog()
  }
}
