import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapAttributeIconComponent from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeIcon'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import { getDeliveryStatusesAsTags } from '~/client/src/shared/constants/deliveryStatusesTags'
import { getFormsStatusesAsTags } from '~/client/src/shared/constants/formStatusesTags'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import AreasStore from '~/client/src/shared/stores/domain/Areas.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import LogisticsObjectsStore from '~/client/src/shared/stores/domain/LogisticsObjects.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import VerticalObjectsStore from '~/client/src/shared/stores/domain/VerticalObjects.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'

import { NotificationSetupModes } from '../../../../../../NotificationsSetup'

import './TagsByGroupsModal.scss'

// translated

interface IGroupAttributeOption {
  name: string
  id: IGroupAttributeOptionId
  nodeType: TagType
  iconName: TagIconType
  store: { list: ITag[] }
}

enum LogisticsIds {
  logisticsLevel = 'logistics-level',
  logisticsArea = 'logistics-area',
  formCategory = 'form-category',
}

type IGroupAttributeOptionId = FieldIds | LogisticsIds

interface IProps {
  shouldShown: boolean
  onClose: () => void
  onOptionClick: (dataObject: ITag) => void
  mode?: NotificationSetupModes

  shouldShownExtendedSection?: boolean

  shouldUseScrollOnContent?: boolean

  eventsStore?: DesktopEventStore
  gatesStore?: GatesStore
  zonesStore?: ZonesStore
  buildingsStore?: BuildingsStore
  routesStore?: RoutesStore
  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  companiesStore?: CompaniesStore
  levelsStore?: LevelsStore
  areasStore?: AreasStore
  verticalObjectsStore?: VerticalObjectsStore
  logisticsObjectsStore?: LogisticsObjectsStore
  formCategoriesStore?: FormCategoriesStore
}

@inject(
  'eventsStore',
  'gatesStore',
  'zonesStore',
  'buildingsStore',
  'routesStore',
  'offloadingEquipmentsStore',
  'companiesStore',
  'levelsStore',
  'areasStore',
  'verticalObjectsStore',
  'logisticsObjectsStore',
  'formCategoriesStore',
)
@observer
export default class TagsByGroupsModal extends React.Component<IProps> {
  @observable private selectedGroupId: FieldIds = null

  public render() {
    if (!this.props.shouldShown) {
      return null
    }

    return (
      <MenuCloser
        closeMenu={this.props.onClose}
        className="absolute tags-by-group-modal-wrapper"
      >
        <div
          className="assign-sitemap-dialog brada4 py15"
          onMouseDown={this.stopPropagation}
        >
          {this.assignableMainGroups.map(({ name, id, nodeType, iconName }) =>
            this.renderGroup(name, id, nodeType, iconName),
          )}
          <div className="separator" />
          {this.assignableSecondaryGroups.map(
            ({ name, id, nodeType, iconName }) =>
              this.renderGroup(name, id, nodeType, iconName),
          )}
          {this.renderExtendedSection()}
        </div>
      </MenuCloser>
    )
  }

  private renderGroup(
    optionText: string,
    id: IGroupAttributeOptionId,
    type: TagType,
    iconName: TagIconType,
    hideRightIcon?: boolean,
  ) {
    const dataObject = {
      type,
      iconName,
    } as LocationAttributeBase

    return (
      <div
        key={id}
        className={classList({
          'row group relative pointer px10': true,
          active: id === this.selectedGroupId,
        })}
        onClick={this.toggleOptions.bind(this, id)}
      >
        {this.renderOptions(id)}
        <div
          className={classList({
            'no-grow mr10': true,
            gate: id === FieldIds.GATE,
          })}
        >
          <SitemapAttributeIconComponent dataObject={dataObject} />
        </div>
        <div>{optionText}</div>
        {!hideRightIcon && (
          <Icon className="caret-icon" icon={IconNames.CARET_RIGHT} />
        )}
      </div>
    )
  }

  private getGroupName(id: FieldIds) {
    return this.props.eventsStore.appState.getDeliveryFieldName(id)
  }

  private get assignableMainGroups(): IGroupAttributeOption[] {
    const {
      zonesStore,
      buildingsStore,
      routesStore,
      gatesStore,
      levelsStore,
      areasStore,
    } = this.props
    const buildingGroup: IGroupAttributeOption = {
      name: this.getGroupName(FieldIds.BUILDING),
      store: buildingsStore,
      id: FieldIds.BUILDING,
      nodeType: LocationType.Building,
      iconName: MapViewLocationIcon.Building,
    }
    const gateGroup: IGroupAttributeOption = {
      name: this.getGroupName(FieldIds.GATE),
      store: gatesStore,
      id: FieldIds.GATE,
      nodeType: LocationType.Gate,
      iconName: MapViewLocationIcon.Gate,
    }
    const routeGroup: IGroupAttributeOption = {
      name: this.getGroupName(FieldIds.ROUTE),
      store: routesStore,
      id: FieldIds.ROUTE,
      nodeType: LocationType.Route,
      iconName: MapViewLocationIcon.Route,
    }
    const zoneGroup: IGroupAttributeOption = {
      name: this.getGroupName(FieldIds.ZONE),
      store: zonesStore,
      id: FieldIds.ZONE,
      nodeType: LocationType.Zone,
      iconName: MapViewLocationIcon.Zone,
    }
    const levelGroup: IGroupAttributeOption = {
      name: Localization.translator.level,
      store: levelsStore as any,
      id: LogisticsIds.logisticsLevel,
      nodeType: TagType.Level,
      iconName: TagIconType.Level,
    }
    const areaGroup: IGroupAttributeOption = {
      name: Localization.translator.area,
      store: areasStore as any,
      id: LogisticsIds.logisticsArea,
      nodeType: TagType.Area,
      iconName: TagIconType.Area,
    }

    return [
      buildingGroup,
      zoneGroup,
      levelGroup,
      areaGroup,
      gateGroup,
      routeGroup,
    ]
  }

  private get assignableSecondaryGroups(): IGroupAttributeOption[] {
    const { offloadingEquipmentsStore, mode } = this.props

    const equipmentGroup: IGroupAttributeOption = {
      name: this.getGroupName(FieldIds.OFFLOADING_EQUIPMENT),
      store: offloadingEquipmentsStore,
      id: FieldIds.OFFLOADING_EQUIPMENT,
      nodeType: LocationType.OffloadingEquipment,
      iconName: MapViewLocationIcon.Equipment,
    }

    switch (mode) {
      case NotificationSetupModes.delivery:
        return [equipmentGroup]

      case NotificationSetupModes.form:
        return [equipmentGroup, ...this.formsWorkflowSpecificGroups]

      default:
        return [equipmentGroup]
    }
  }

  private get formsWorkflowSpecificGroups(): IGroupAttributeOption[] {
    const { verticalObjectsStore, logisticsObjectsStore } = this.props

    return [
      {
        name: this.getGroupName(FieldIds.VERTICAL_OBJECT),
        store: verticalObjectsStore as any,
        id: FieldIds.VERTICAL_OBJECT,
        nodeType: LocationType.VerticalObject,
        iconName: MapViewLocationIcon.Stairs,
      },
      {
        name: this.getGroupName(FieldIds.LOGISTICS_OBJECT),
        store: logisticsObjectsStore as any,
        id: FieldIds.LOGISTICS_OBJECT,
        nodeType: LocationType.LogisticsObject,
        iconName: MapViewLocationIcon.Logistics,
      },
    ]
  }

  private get extendedGroups(): IGroupAttributeOption[] {
    const { companiesStore, mode, formCategoriesStore } = this.props
    const companyGroup: IGroupAttributeOption = {
      name: Localization.translator.company,
      store: { list: companiesStore.availableCompaniesAsTags } as any,
      id: FieldIds.COMPANY,
      nodeType: TagType.Company,
      iconName: TagIconType.Company,
    }

    if (mode === NotificationSetupModes.delivery) {
      return [
        companyGroup,
        {
          name: Localization.translator.deliveryStatus,
          store: { list: getDeliveryStatusesAsTags() },
          id: FieldIds.STATUS,
          nodeType: TagType.Status,
          iconName: TagIconType.Status,
        },
      ]
    } else if (mode === NotificationSetupModes.form) {
      return [
        companyGroup,
        {
          name: Localization.translator.formStatus,
          store: { list: getFormsStatusesAsTags() },
          id: FieldIds.STATUS,
          nodeType: TagType.Status,
          iconName: TagIconType.Status,
        },
        {
          name: Localization.translator.formCategory,
          store: { list: formCategoriesStore.activeCategoriesAsTags } as any,
          id: LogisticsIds.formCategory,
          nodeType: TagType.FormCategory,
          iconName: TagIconType.FormCategory,
        },
      ]
    }

    return [companyGroup]
  }

  private renderOption(dataObject: ITag) {
    return (
      <div
        key={dataObject.id}
        className="row option"
        onClick={this.handleOnOptionClick.bind(this, dataObject)}
      >
        <SitemapAttributeTag
          dataObject={dataObject as LocationAttributeBase}
          shouldShowAsTag={true}
        >
          <div className="large text ellipsis">{dataObject.name}</div>
        </SitemapAttributeTag>
      </div>
    )
  }

  private toggleOptions(id: FieldIds, e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()

    if (this.selectedGroupId === id) {
      this.selectedGroupId = null
      return
    }

    this.selectedGroupId = id
  }

  private renderOptions(groupId: IGroupAttributeOptionId) {
    if (this.selectedGroupId !== groupId) {
      return
    }

    const { store } = this.selectedGroup
    const { shouldUseScrollOnContent } = this.props

    return (
      <div
        className={classList({
          'assign-sitemap-dialog brada4 options py15 px10': true,
          'scrollable-content scrollable': shouldUseScrollOnContent,
        })}
      >
        {store.list.map(item =>
          this.renderOption(item as LocationAttributeBase),
        )}
      </div>
    )
  }

  @computed
  private get allGroups() {
    let groups = [
      ...this.assignableMainGroups,
      ...this.assignableSecondaryGroups,
    ]

    if (this.props.shouldShownExtendedSection) {
      groups = groups.concat(this.extendedGroups)
    }

    return groups
  }

  @computed
  private get selectedGroup() {
    return this.allGroups.find(({ id }) => id === this.selectedGroupId)
  }

  private renderExtendedSection() {
    if (!this.props.shouldShownExtendedSection) {
      return
    }

    return (
      <>
        <div className="separator" />
        {this.extendedGroups.map(({ name, id, nodeType, iconName }) =>
          this.renderGroup(name, id, nodeType, iconName),
        )}
      </>
    )
  }

  private handleOnOptionClick(
    dataObject: ITag,
    e: React.MouseEvent<HTMLElement>,
  ) {
    e.stopPropagation()
    this.props.onOptionClick(dataObject)
  }

  private stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }
}
