import React from 'react'

import { observer } from 'mobx-react'

import ListTagCell, {
  TagCellItemKeyEnum,
} from '~/client/src/desktop/components/ListTagCell'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Company from '~/client/src/shared/models/Company'
import User from '~/client/src/shared/models/User'

import ReportsListTable from '../components/ReportsListTable/ReportsListTable'
import ScanStationListStore, { DataKeys } from './ScanStationList.store'

interface IProps {
  store: ScanStationListStore
}

const renderCompanies = (companies: Company[]): JSX.Element => (
  <ListTagCell items={companies} renderItemKey={TagCellItemKeyEnum.COMPANY} />
)
const renderUsers = (users: User[]): JSX.Element => (
  <ListTagCell items={users} renderItemKey={TagCellItemKeyEnum.USER} />
)

@observer
export default class ScanStationList extends React.Component<IProps> {
  public render() {
    const {
      rows,
      columns,
      columnsWidthState,
      groupingKey,
      collapsedCategories,
      toggleCategoryCollapsing,
      sortState,
      handleColumnSort,
      onRowClick,
      toggleCategory,
    } = this.props.store

    return (
      <ReportsListTable
        rows={rows}
        groupingKey={groupingKey}
        sortedColumnKey={sortState.columnKey}
        sortingOrder={sortState.order}
        columns={columns}
        columnsWidthState={columnsWidthState}
        onColumnSort={handleColumnSort}
        collapsedCategories={collapsedCategories}
        columnKeyToCellRenderer={this.columnKeyToCellRenderer}
        onCategoryCollapsingToggle={toggleCategoryCollapsing}
        onCellClick={onRowClick}
        onCategoryCheckboxToggle={toggleCategory}
      />
    )
  }

  private get columnKeyToCellRenderer() {
    return {
      [DataKeys.CHECKBOX]: value => <Checkbox isChecked={value} />,
      [DataKeys.SCANNER_NAME]: this.renderString,
      [DataKeys.USER]: renderUsers,
      [DataKeys.COMPANY]: renderCompanies,
      [DataKeys.START_TIME]: this.renderString,
      [DataKeys.QUEUE_TIME]: this.renderString,
      [DataKeys.FINISH_TIME]: this.renderString,
      [DataKeys.ELAPSED_TIME]: this.renderString,
      [DataKeys.EMPLOYEE_ID]: this.renderString,
      [DataKeys.EMAIL]: this.renderString,
      [DataKeys.PHONE_NUMBER]: this.renderString,
    }
  }

  private renderString = (value: string): JSX.Element => {
    return (
      <div title={value} className="text ellipsis">
        {value}
      </div>
    )
  }
}
