import * as React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import FilterFooter from '~/client/src/desktop/components/Filters/FilterFooter/FilterFooter'
import FilterHeader from '~/client/src/desktop/components/Filters/FilterHeader/FilterHeader'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import FilterOption from '~/client/src/shared/components/Filters/FilterUtils/FilterOption/FilterOption'
import { Loader } from '~/client/src/shared/components/Loader'
import { FilterSourceType } from '~/client/src/shared/stores/substates/ActivityFilterInfo'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'

import './SuperFilter.scss'

interface ISuperFilter {
  filterStore: SuperFilterStore
  isClearAllShown?: boolean

  hideOtherModalsFn?(): void
}

@observer
export default class SuperFilter extends React.Component<ISuperFilter> {
  private get message() {
    const { filterCaption } = this.store
    return 'You have to configure filter ' + filterCaption
  }

  public componentDidMount() {
    this.props.filterStore.clearNotPresentOptions()
  }

  public render() {
    const { isShown, isLoading, isEmpty } = this.store

    return (
      isShown && (
        <div
          className={classList({
            'filter-container location': true,
            'short row': isLoading || isEmpty,
          })}
        >
          {isLoading ? <Loader /> : this.renderFilterArea()}
        </div>
      )
    )
  }

  private get store(): SuperFilterStore {
    return this.props.filterStore
  }

  private get notification() {
    return <div className="filter-notification center">{this.message}</div>
  }

  private getCroppedAmount = (instancesIds: string[]) => {
    return this.store.filterOptionInstances(instancesIds).length
  }

  @computed
  private get filter() {
    const {
      clickOnDeselectAll,
      clickOnSelectAll,
      isDisabled,
      applyButtonLabel,
      shortNameCaption,
      nameCaption,
      totalHint,
      shouldHideCount,
    } = this.store

    const className = 'bb-light-grey' + (isDisabled ? ' inactive-element' : '')
    return (
      <>
        <FilterHeader
          clickOnSelectAll={clickOnSelectAll}
          clickOnClearAll={this.props.isClearAllShown && clickOnDeselectAll}
          className={className}
        >
          <div className="row px10 py10">
            {shortNameCaption && (
              <div className="filter-header-hint code">{shortNameCaption}</div>
            )}
            <div className="filter-header-hint desk">{nameCaption}</div>
            {!shouldHideCount && (
              <div className="filter-header-hint act">
                <span className="text large primary-blue">Viewable</span>
                <span className="ml5 mr5">/</span>
                {totalHint}
              </div>
            )}
          </div>
        </FilterHeader>

        <div className={'filter-content pt10 pb10 ' + className}>
          {this.renderOptionsTree()}
        </div>

        <FilterFooter
          clickOnCancel={this.onCancelClick}
          clickOnApply={this.onApplyClick}
          applyButtonText={applyButtonLabel}
        />
      </>
    )
  }

  private renderFilterArea() {
    return !this.store.isEmpty ? this.filter : this.notification
  }

  private renderOptionsTree() {
    const {
      optionsTree,
      changeRootOption,
      isRootOptionChecked,
      selectedOptions,
      changeOption,
      clickOnOnly,
      shouldHideCount,
    } = this.store

    const showRootOptions =
      this.store.filterSourceType === FilterSourceType.ProjectMember

    return optionsTree.map(rootOption => {
      const options = rootOption.options.map((option, index) => {
        const { id, shortName, name, instancesIds } = option
        return (
          <FilterOption
            key={index}
            id={id}
            code={shortName}
            description={name}
            instancesIds={instancesIds}
            selectedOptions={selectedOptions}
            changeOption={changeOption}
            onOnlyClicked={clickOnOnly}
            croppedAmount={this.getCroppedAmount(instancesIds)}
            shouldIgnoreCount={shouldHideCount}
          />
        )
      })

      return (
        <div className="filter-section" key={rootOption.id}>
          {showRootOptions && (
            <div className="filter-section-title">
              <Checkbox
                label={rootOption.name}
                onClick={changeRootOption.bind(rootOption.options)}
                isChecked={isRootOptionChecked(rootOption.options)}
              />
            </div>
          )}
          {options}
        </div>
      )
    })
  }

  private onCancelClick = () => {
    this.store.clickOnCancel()
    this.props.hideOtherModalsFn?.()
  }

  private onApplyClick = () => {
    this.store.clickOnApply()
    this.props.hideOtherModalsFn?.()
  }
}
