import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'

import MapViewSetUpStore from '../../MapViewSetUp.store'
import { ViewType } from '../../stores/MapBoxEditor.store'
import { ILocationListObject, locationObjects } from './locationObjectsHelper'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 24

export interface IProps {
  store?: MapViewSetUpStore
}

@observer
export default class LocationObjectsPallet extends React.Component<IProps> {
  public render() {
    return (
      <div
        className={classList({
          'object-pallet absolute': true,
          'inactive-element':
            this.props.store.mapViewItemsSetupStore.isSitemapBlocked,
        })}
      >
        {locationObjects.map((section, sIdx) => {
          return section.map((object, idx) => {
            return (
              <div
                className={classList({
                  'object relative': true,
                  'left-rounded': idx === 0,
                  'right-rounded mr10': idx === section?.length - 1,
                })}
                key={`${sIdx} ${idx}`}
              >
                {this.renderObject(object, `${sIdx} ${idx}`)}
                {this.renderObjectChildren(object)}
              </div>
            )
          })
        })}
      </div>
    )
  }

  private renderObject = (
    object: ILocationListObject,
    key: React.Key,
    tooltipPosition?: Position,
  ): JSX.Element => {
    const isHighlighted = this.shouldHighlightObject(object)
    const color = isHighlighted ? Colors.neutral0 : Colors.neutral60

    const content = (
      <div
        key={key}
        className="object-icon pointer"
        onClick={this.onObjectClicked.bind(this, object)}
      >
        <object.Icon color={color} size={ICON_SIZE} />
        {object.children && <div className="arrow" />}
      </div>
    )

    if (!object.getLabel) {
      return content
    }

    const position =
      tooltipPosition || (!!object?.children && Position.TOP) || Position.BOTTOM

    return (
      <Tooltip
        key={key}
        className="bp3-dark"
        content={object.getLabel()}
        position={position}
      >
        {content}
      </Tooltip>
    )
  }

  private renderObjectChildren = (object: ILocationListObject): JSX.Element => {
    if (!object.children) {
      return null
    }

    return (
      <div className="object-children pointer absolute">
        {object.children.map((childObject, childIdx) => {
          return this.renderObject(childObject, childIdx, Position.RIGHT)
        })}
      </div>
    )
  }

  private onObjectClicked(object: ILocationListObject) {
    if (!this.props.store) {
      return
    }
    const { mapViewItemsSetupStore, isGlobeMode, onViewTypeSelect } =
      this.props.store
    if (mapViewItemsSetupStore.isSitemapBlocked) {
      return
    }
    const { dataObjectType, sitemapItemType, iconName } = object

    if (isGlobeMode) {
      onViewTypeSelect(ViewType.Objects)
    }
    if (sitemapItemType && sitemapItemType === MapViewItemType.Line) {
      mapViewItemsSetupStore.createDataLessItem(sitemapItemType)
      mapViewItemsSetupStore.selectSitemapItemDrawnPart(
        MapViewItemDrawnPart.Shape,
      )
      return
    }
    if (sitemapItemType && sitemapItemType === MapViewItemType.TextBox) {
      mapViewItemsSetupStore.enableCreatingText()
      return
    }
    if (dataObjectType) {
      mapViewItemsSetupStore.enableCreatingAttribute(dataObjectType, iconName)
      return
    }
  }

  private shouldHighlightObject(object: ILocationListObject) {
    if (!this.props.store) {
      return
    }
    const {
      selectedMapViewItem,
      creatableAttributeType,
      creatableAttributeIcon,
      isTextStickerCreationActive,
    } = this.props.store.mapViewItemsSetupStore

    const { sitemapItemType, dataObjectType, iconName } = object

    if (sitemapItemType === MapViewItemType.TextBox) {
      return isTextStickerCreationActive
    }

    if (
      sitemapItemType &&
      selectedMapViewItem &&
      !selectedMapViewItem.id &&
      selectedMapViewItem.sitemapItem.type === sitemapItemType
    ) {
      return true
    }

    return (
      creatableAttributeType === dataObjectType &&
      creatableAttributeIcon === iconName
    )
  }
}
