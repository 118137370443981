import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { downloadFile } from '~/client/src/shared/utils/util'

import DownloadActivitiesButtonStore from './DownloadActivitiesButton.store'

interface IProps {
  activitiesStore?: ActivitiesStore
  statusUpdatesStore?: StatusUpdatesStore
  locationStore?: LocationsStore
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  projectMembersStore?: ProjectMembersStore
}

@inject(
  'activitiesStore',
  'statusUpdatesStore',
  'locationStore',
  'state',
  'projectDateStore',
  'projectMembersStore',
)
@observer
export default class DownloadActivitiesButton extends React.Component<IProps> {
  private readonly contentType = 'text/csv'
  private store: DownloadActivitiesButtonStore

  public constructor(props: IProps) {
    super(props)

    this.store = new DownloadActivitiesButtonStore(
      props.activitiesStore,
      props.statusUpdatesStore,
      props.locationStore,
      props.state,
      props.projectDateStore,
      props.projectMembersStore,
    )
  }

  public render() {
    return (
      <div className="col x-end y-end">
        <div className="text right">
          <span
            className="text large primary-blue no-flex pointer underline"
            onClick={this.handleForceDownload}
          >
            {Localization.translator.downloadProjectDataCsv}
          </span>
        </div>
      </div>
    )
  }

  private get activitiesAsCsvLink() {
    const { allActivitiesAsCsv } = this.store

    const csvFile = new Blob([allActivitiesAsCsv], {
      type: this.contentType,
    })

    return window.URL.createObjectURL(csvFile)
  }

  @action.bound
  private handleForceDownload() {
    downloadFile(
      Localization.translator.schedule + '.csv',
      this.activitiesAsCsvLink,
    )
    window.URL.revokeObjectURL(this.activitiesAsCsvLink)
  }
}
