import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import TagsSelector from '~/client/src/desktop/components/TagsSelector/TagsSelector'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import CompactConfirmDialog from '~/client/src/shared/components/CompactConfirmDialog/CompactConfirmDialog'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import BulkEditCompaniesSideBarStore from './BulkEditCompaniesSideBar.store'

import Colors from '~/client/src/shared/theme.module.scss'

import './BulkEditCompaniesSideBar.scss'

interface IProps {
  selectedCompanies: Company[]
  onClose: () => void
  saveCompanies: (items: Company[]) => void
  loading: boolean
  errorMessage?: string
  isActivationMode: boolean

  toggleDeleteDialog: (isShow: boolean) => void
  tagsStore?: TagsStore
}

const availableTypes = [TagType.CompanyType, TagType.Role, TagType.Trade]
const requiredTypes = [TagType.CompanyType]

@inject('tagsStore')
@observer
export default class BulkEditCompaniesSideBar extends React.Component<IProps> {
  private readonly store: BulkEditCompaniesSideBarStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new BulkEditCompaniesSideBarStore(
      props.tagsStore,
      props.selectedCompanies,
      props.saveCompanies,
      availableTypes,
      requiredTypes,
    )
  }

  public componentDidMount() {
    const { onClose, selectedCompanies } = this.props

    if (!selectedCompanies.length) {
      onClose()
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { onClose, selectedCompanies } = this.props

    if (!selectedCompanies.length) {
      onClose()
    }

    if (selectedCompanies !== prevProps.selectedCompanies) {
      this.store.init(selectedCompanies)
    }
  }

  public render() {
    const content = this.props.loading
      ? this.renderLoader()
      : this.renderContent()

    return (
      <View className="bulk-edit-companies-side-bar absolute bl-palette-brand-lighter bg-white">
        {content}
      </View>
    )
  }

  private renderContent(): JSX.Element {
    const { onClose, errorMessage } = this.props
    const {
      changeSelection,
      defaultSelection,
      handleApply,
      canApply,
      isConfirmationModalOpened,
      closeConfirmationModal,
      onConfirm,
    } = this.store

    return (
      <>
        <Header className="col pa10 px20 bb-light-grey no-grow">
          <div className="row">
            <Icon
              icon={IconNames.CROSS}
              className="no-grow pointer mr12"
              color={Colors.neutral60}
              onClick={onClose}
            />
            <span className="text bold small-header">
              {Localization.translator.edit_verb}
            </span>
            <BaseActionButton
              title={Localization.translator.apply}
              className="scale-blue-theme bold"
              isEnabled={canApply}
              onClick={handleApply}
            />
          </div>
          <div className="text large primary py5">
            {this.selectedCompaniesLabel}
          </div>
        </Header>
        <Content className="px20">
          <TagsSelector
            onChange={changeSelection}
            availableTypes={availableTypes}
            initSelection={defaultSelection}
          />
          {this.renderActionButtons()}
        </Content>
        <Footer>
          <div className="text center large red bold pb24">{errorMessage}</div>
        </Footer>
        <CompactConfirmDialog
          isShown={isConfirmationModalOpened}
          title={Localization.translator.confirmChange}
          onHide={closeConfirmationModal}
          onApply={onConfirm}
        >
          {Localization.translator.emptyTagTypes(this.store.tagsWithErrors)}
        </CompactConfirmDialog>
      </>
    )
  }

  private renderActionButtons() {
    const { toggleDeleteDialog, isActivationMode } = this.props

    return (
      <div className="col">
        <div className="bt-palette-brand-lighter mb10" />
        <BaseActionButton
          icon={
            <Icon
              icon={isActivationMode ? IconNames.CONFIRM : IconNames.TRASH}
            />
          }
          className="inverse-scale-blue-theme mw120 mb10"
          isEnabled={true}
          isActive={true}
          shouldShowBorder={false}
          title={
            isActivationMode
              ? Localization.translator.activate
              : Localization.translator.deactivate
          }
          onClick={toggleDeleteDialog}
        />
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      <div className="full-height full-width absolute modal-overlay">
        <Loader />
      </div>
    )
  }

  private get selectedCompaniesLabel(): string {
    return Localization.translator.xCompaniesSelected(
      this.props.selectedCompanies.length,
    )
  }
}
