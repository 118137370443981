import * as React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopperModifiers,
  Position,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { AutoSizer, List } from 'react-virtualized'

import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'

export interface IFormGeneralTabDropdownOption {
  id: string
  name: string
  isDisabled?: boolean
}

interface IProps {
  selectedValue: string
  options: IFormGeneralTabDropdownOption[]

  isRequiredTextShown?: boolean
  isHighlighted?: boolean
  customSelectorLabel?: string
  portalClassName?: string
  popoverClassName?: string
  targetClassName?: string
  itemRowClassName?: string

  onItemClick(stepId: string): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: true, boundariesElement: 'scrollParent' },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

const ROW_HEIGHT = 40
const MODAL_HEIGHT_RATIO = 0.4
const OVERSCAN_ROW_COUNT = 10

@observer
export default class FormGeneralOptionsDropdown extends React.Component<IProps> {
  public render() {
    const {
      selectedValue,
      options,
      isHighlighted,
      customSelectorLabel,
      portalClassName,
      popoverClassName,
      targetClassName,
      isRequiredTextShown,
    } = this.props

    const selectedValueName = options.find(o => o.id === selectedValue)?.name

    return (
      <Popover
        className="vertical-align-middle no-grow relative"
        portalClassName={portalClassName}
        targetClassName={targetClassName}
        popoverClassName={popoverClassName}
        autoFocus={false}
        enforceFocus={false}
        usePortal={false}
        position={Position.BOTTOM_RIGHT}
        modifiers={popoverPopperModifiers}
        content={this.optionsModal}
      >
        {customSelectorLabel ? (
          <StruxhubTextValueSelector
            className="overflow-hidden no-flex"
            label={customSelectorLabel}
            isRequired
            isRequiredTextHidden={!isRequiredTextShown}
            isHelperTextCondensed
            value={selectedValueName}
          />
        ) : (
          <div className="row pointer nowrap no-select">
            <span
              className={classList({
                'text large grey-light': true,
                'blue-highlight': isHighlighted,
              })}
            >
              {selectedValueName}
            </span>
            <Icon className="ml5 text grey-light" icon={IconNames.CARET_DOWN} />
          </div>
        )}
      </Popover>
    )
  }

  private get optionsModal(): JSX.Element {
    const { options, selectedValue } = this.props

    return (
      <div className="col no-outline-container">
        <div className="virtualized-list-smart-wrapper">
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                data={options}
                width={width}
                height={Math.min(
                  window.innerHeight * MODAL_HEIGHT_RATIO,
                  ROW_HEIGHT * options.length,
                )}
                rowHeight={ROW_HEIGHT}
                rowCount={options.length}
                rowRenderer={this.renderRow}
                scrollToAlignment="start"
                scrollToIndex={options.findIndex(o => o.id === selectedValue)}
                overscanRowCount={OVERSCAN_ROW_COUNT}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }

  private renderRow = ({ key, style, index }: any): JSX.Element => {
    const option = this.props.options[index]

    if (!option) {
      return null
    }

    const { itemRowClassName, selectedValue, onItemClick } = this.props

    const isSelected = selectedValue === option.id

    return (
      <div
        key={key}
        style={style}
        className={classList({
          [`row highlighted-hover ${Classes.POPOVER_DISMISS}`]: true,
          'unclickable-element': isSelected,
          pointer: !isSelected,
        })}
        onClick={onItemClick.bind(null, option.id)}
        title={option.name}
      >
        <div
          className={classList({
            'pl16 text line-24 ellipsis': true,
            [itemRowClassName]: !!itemRowClassName,
            'extra-large': !itemRowClassName,
          })}
        >
          {option.name}
        </div>
        {isSelected && (
          <Icons.CheckPaletteBlue
            className={classList({
              'no-flex pr16': true,
              opacity5: option.isDisabled,
            })}
          />
        )}
      </div>
    )
  }
}
