import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import FilterResetter from '~/client/src/desktop/components/FilterResetter/FilterResetter'
import LocationFilterPopup from '~/client/src/desktop/components/Filters/LocationFilter/LocationFilterPopup'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import LocationFilterStore from './LocationFilter.store'

// localization: no display text to translate

interface IHeaderBarProps {
  dynamicOverflowListStore: DynamicOverflowListStore
  eventsStore?: DesktopEventStore
  activityListStore: DesktopActivityListStore
  activityCodeTypesStore?: ActivityCodeTypesStore
  activityFiltersStore?: ActivityFiltersStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  resourcesStore?: ResourcesStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  forceCloseMap?: { [filterType: string]: boolean }
  onShowChanged?: (isShown: boolean, filterType: string) => void
}

const OVERFLOW_ITEMS_LABEL_WIDTH = 75
const MIN_CONTAINER_WIDTH = 200

@inject(
  'eventsStore',
  'activityCodeTypesStore',
  'activityFiltersStore',
  'activityAssignmentsStore',
  'resourcesStore',
  'companiesStore',
  'userProjectsStore',
)
@observer
export default class LocationFilter extends React.Component<IHeaderBarProps> {
  @observable private isOverflowedFiltersModalShown: boolean = false
  private readonly locationFilterStore: LocationFilterStore

  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IHeaderBarProps) {
    super(props)

    this.locationFilterStore = new LocationFilterStore(
      props.eventsStore,
      props.activityListStore,
      props.activityCodeTypesStore,
      props.activityFiltersStore,
      props.activityAssignmentsStore,
      props.resourcesStore,
      props.companiesStore,
      props.userProjectsStore,
      props.onShowChanged,
      props.dynamicOverflowListStore.enableRecalculation,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handleDesktopPostEventCallback,
    )
  }

  public componentDidUpdate() {
    const { forceCloseMap } = this.props
    if (forceCloseMap) {
      const { locationsMap } = this.props.eventsStore.appState.filters
      Object.keys(locationsMap).forEach(filterType => {
        if (forceCloseMap[filterType]) {
          locationsMap[filterType].isFilterShown = false
        }
      })
    }
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    return (
      <MenuCloser
        className="row full-height location-filter x-start"
        closeMenu={this.hideOverflowedFiltersModal}
        isOpen={this.isOverflowedFiltersModalShown}
      >
        {this.renderScrollableFilterBar()}
        <LocationFilterPopup store={this.locationFilterStore} />
      </MenuCloser>
    )
  }

  private renderScrollableFilterBar() {
    return (
      <DynamicOverflowList
        items={this.filterButtons}
        store={this.props.dynamicOverflowListStore}
        elementsClassName="vertical-align-middle"
        additionalWidth={OVERFLOW_ITEMS_LABEL_WIDTH}
        minContainerWidthConstraint={MIN_CONTAINER_WIDTH}
      >
        {({ visibleElements, overflowElements, containerRefSetter }) => {
          return (
            <div className="overflowed-filters-list" ref={containerRefSetter}>
              {visibleElements}
              <div className="inline-block vertical-align-middle">
                {this.renderOverflowedFiltersLabel(overflowElements)}
              </div>
              <div className="inline-block pl5 vertical-align-middle">
                <FilterResetter
                  color="light"
                  onReset={this.locationFilterStore.resetAllFilters}
                />
              </div>
            </div>
          )
        }}
      </DynamicOverflowList>
    )
  }

  private renderOverflowedFiltersLabel = (
    hiddenElements: JSX.Element[],
  ): JSX.Element => {
    if (!hiddenElements?.length) {
      return null
    }

    return (
      <>
        <FilterHandleButton
          isSelected={this.isOverflowedFiltersModalShown}
          isActive={this.isOverflowedFiltersModalShown}
          className="text large bold px10"
          onClick={this.toggleOverflowedFiltersModal}
        >
          ...
        </FilterHandleButton>
        {this.isOverflowedFiltersModalShown && (
          <div className="absolute col pa10 overflowed-filters-modal overflow-auto bg-white mt10 ba-grey">
            {hiddenElements.map(item => {
              return (
                <div key={item.key} className="pa5">
                  {item}
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }

  private get filterButtons() {
    const storesMap = this.locationFilterStore.filterStoresByTypeMap
    return Object.keys(storesMap).map(filterType => {
      const store = storesMap[filterType]
      return <SuperFilterHandleButton key={filterType} filterStore={store} />
    })
  }

  private handleDesktopPostEventCallback = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (e.RESET_ALL_FILTERS === eventType) {
      Object.keys(this.storesMap).forEach(filterType => {
        const filterStore = this.storesMap[filterType]

        filterStore.clickOnSelectAll()
        filterStore.clickOnApply()
      })

      this.hideOverflowedFiltersModal()
    }
  }

  private get storesMap() {
    return this.locationFilterStore.filterStoresByTypeMap
  }

  @action.bound
  private toggleOverflowedFiltersModal() {
    this.isOverflowedFiltersModalShown = !this.isOverflowedFiltersModalShown
  }

  @action.bound
  private hideOverflowedFiltersModal() {
    this.isOverflowedFiltersModalShown = false
  }
}
