import { action, computed } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  BasicDataKeys,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import ScanStationGroupingOption from '~/client/src/shared/enums/ScanStationGroupingOption'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import { ITreeNodeObj } from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore, {
  millisecondsToTime,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { groupingCommonMapper } from '~/client/src/shared/utils/mappers'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ReportsStore from '../../../Reports.store'

enum SpecificDataKeys {
  SCANNER_NAME = 'scannerName',
  USER = 'user',
  COMPANY = 'company',
  QUEUE_TIME = 'queueTime',
  START_TIME = 'startTime',
  FINISH_TIME = 'finishTime',
  ELAPSED_TIME = 'elapsedTime',
  START_LOCATION = 'startLocation',
  FINISH_LOCATION = 'finishLocation',
  SCAN_MASTER = 'scanMaster',
  EMPLOYEE_ID = 'employeeId',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
}

export const DataKeys = { ...BasicDataKeys, ...SpecificDataKeys }
const FILTER_KEYS = ['id']
const DEFAULT_SORT_KEY = 'scannerName'

enum AcceptableReportFilterTypes {
  company = 'company',
}

export default class ScanStationListStore extends BaseMultiBandListStore<IScannerHistoryPair> {
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly reportsStore: ReportsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {
    super(
      state.scanStationFilters,
      () => reportsStore.allScanStationHistory,
      FILTER_KEYS,
    )
  }

  public columnsWidthState = new Map<string, number>([
    [DataKeys.CHECKBOX, 60],
    [DataKeys.SCANNER_NAME, 200],
    [DataKeys.COMPANY, 200],
    [DataKeys.USER, 200],
    [DataKeys.EMPLOYEE_ID, 200],
    [DataKeys.EMAIL, 200],
    [DataKeys.PHONE_NUMBER, 200],
    [DataKeys.QUEUE_TIME, 250],
    [DataKeys.START_TIME, 250],
    [DataKeys.FINISH_TIME, 250],
    [DataKeys.ELAPSED_TIME, 200],
    [DataKeys.SCAN_MASTER, 200],
  ])

  @computed
  public get columns(): ILWFCColumn[] {
    const columns = [
      {
        dataKey: DataKeys.CHECKBOX,
      },
      {
        label: Localization.translator.scannerName,
        dataKey: DataKeys.SCANNER_NAME,
      },
      {
        label: Localization.translator.company,
        dataKey: DataKeys.COMPANY,
      },
      {
        label: Localization.translator.user,
        dataKey: DataKeys.USER,
      },
      {
        label: Localization.translator.employeeIDNumber,
        dataKey: DataKeys.EMPLOYEE_ID,
      },
      {
        label: Localization.translator.email_noun,
        dataKey: DataKeys.EMAIL,
      },
      {
        label: Localization.translator.phoneNumber,
        dataKey: DataKeys.PHONE_NUMBER,
      },
      {
        label: Localization.translator.addedToQueue,
        dataKey: DataKeys.QUEUE_TIME,
      },
      {
        label: Localization.translator.startTime,
        dataKey: DataKeys.START_TIME,
      },
      {
        label: Localization.translator.endTime,
        dataKey: DataKeys.FINISH_TIME,
      },
      {
        label: Localization.translator.elapsedTime,
        dataKey: DataKeys.ELAPSED_TIME,
      },
      {
        label: Localization.translator.scanMaster,
        dataKey: DataKeys.SCAN_MASTER,
      },
    ]

    return columns.map(column => ({
      ...column,
      width: this.columnsWidthState.get(column.dataKey),
    }))
  }

  @action.bound
  public onRowClick(rowData: LWFCRowData, columnKey: string) {
    const id = rowData[DataKeys.ID]
    switch (columnKey) {
      case DataKeys.CHECKBOX:
        this.toggleInstance(id)
        return
      case DataKeys.USER:
        return
      default:
        const item: IScannerHistoryPair =
          this.reportsStore.allScanStationHistory.find(pair => pair.id === id)
        this.reportsStore.showScanStationReports(item)
    }
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { groupingKey } = this.state.scanStationFilters

    const keys = []
    keys.push(groupingKey)

    return this.toBandTreeNodeRows(keys, null, '', 0, false)
  }

  public get reportInfo() {
    const {
      scanStationFilters: { fieldsMap },
      user: { id: userId },
    } = this.state
    const { order, columnKey } = this.sortState

    const filters = this.getScanStationFilters(fieldsMap)
    return {
      userId,
      projectTimeZone: this.projectDateStore.getClientTimezoneId(),
      filteredIds: {
        scanHistories: this.filteredCollection.map(pair => pair.history.id),
      },
      filters,
      grouping: {
        scanHistories: this.groupingKey,
      },
      sorting: {
        scanHistories: [
          {
            ascending: order !== SortOrder.DESC,
            key: columnKey || DEFAULT_SORT_KEY,
          },
        ],
      },
    }
  }

  private getScanStationFilters(fieldsMap: {
    [filterType: string]: UIFilterInfo
  }): {
    [filterType: string]: string[]
  } {
    const result: { [filterType: string]: string[] } = {}

    Object.entries(fieldsMap).forEach(([filterType, filter]) => {
      switch (filterType) {
        case AcceptableReportFilterTypes.company:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).map(id => this.companiesStore.getCompanyById(id)?.name)
          break
      }
    })

    return result
  }

  @computed
  protected get categoryToInstancesMap() {
    const map = {}

    this.filteredCollection.forEach(pair => {
      const categoryIds = this.getCategoryIds(pair, this.groupingKey) || [
        UNASSIGNED,
      ]

      categoryIds.forEach(id => {
        const formattedCategoryId = id || UNASSIGNED

        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(pair)
        } else {
          map[formattedCategoryId] = [pair]
        }
      })
    })

    return map
  }

  public getCategoryIds(report: IScannerHistoryPair, band: string): string[] {
    const startDate = report.history?.date
    const { getMonthDayAndYearToDisplay, getWeekRange, getMonthRange } =
      this.projectDateStore
    switch (band) {
      case ScanStationGroupingOption.SCANNER:
        return [report.scanner?.id || UNASSIGNED]
      case ScanStationGroupingOption.DAY:
        return [getMonthDayAndYearToDisplay(startDate) || UNASSIGNED]
      case ScanStationGroupingOption.WEEK:
        return [getWeekRange(startDate) || UNASSIGNED]
      case ScanStationGroupingOption.MONTH:
        return [getMonthRange(startDate) || UNASSIGNED]
      case ScanStationGroupingOption.COMPANY:
        const user = this.projectMembersStore.getById(report.history.userId)
        if (!user) return [UNASSIGNED]
        const companyId = UserProject.getCompanyId(user, this.userProjectsStore)
        return [companyId || UNASSIGNED]
      case ScanStationGroupingOption.NONE:
        return [ScanStationGroupingOption.NONE]
      default:
        return []
    }
  }

  @action.bound
  public selectRow(objectId: string) {
    this.resetSelection()
    this.selection.set(objectId, true)
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getSortingValue(aRowData, columnKey)
    const v2 = this.getSortingValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      return this.collator.compare(v1, v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  private getSortingValue(rowData: LWFCRowData, columnKey: string) {
    const pair = this.filteredCollection.find(
      c => c.id === rowData[DataKeys.ID],
    )

    switch (columnKey) {
      case DataKeys.USER:
        const usersData = rowData[columnKey]
        return usersData?.length > 1
          ? usersData.length.toString()
          : usersData?.[0]?.fullName || EMPTY_STRING

      case DataKeys.COMPANY:
        const companiesData = rowData[columnKey]
        return companiesData?.length > 1
          ? companiesData.length.toString()
          : companiesData?.[0]?.name || EMPTY_STRING

      case DataKeys.QUEUE_TIME:
        return pair.history.queueDate
      case DataKeys.START_TIME:
        return pair.history.date
      case DataKeys.FINISH_TIME:
        return pair.history.endDate || pair.history.date

      default:
        return rowData[columnKey]
    }
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndYearToDisplay, getWeekRange, getMonthRange } =
      this.projectDateStore

    switch (currentBand) {
      case ScanStationGroupingOption.NONE:
        return [groupingCommonMapper(ScanStationGroupingOption.NONE)]
      case ScanStationGroupingOption.COMPANY:
        return [
          ...this.filteredCollection.map(report => {
            const user = this.projectMembersStore.getById(report.history.userId)
            if (!user) return groupingCommonMapper(UNASSIGNED)
            const companyId = UserProject.getCompanyId(
              user,
              this.userProjectsStore,
            )
            const companyName = UserProject.getCompanyName(
              user,
              this.userProjectsStore,
              this.companiesStore,
            )
            return {
              name: companyName || UNASSIGNED,
              id: companyId || UNASSIGNED,
            }
          }),
          groupingCommonMapper(UNASSIGNED),
        ]
      case ScanStationGroupingOption.DAY:
        return this.filteredCollection
          .map(
            report =>
              getMonthDayAndYearToDisplay(report.history.date) || UNASSIGNED,
          )
          .map(groupingCommonMapper)
      case ScanStationGroupingOption.WEEK:
        return this.filteredCollection
          .map(report => getWeekRange(report.history.date) || UNASSIGNED)
          .map(groupingCommonMapper)
      case ScanStationGroupingOption.MONTH:
        return this.filteredCollection
          .map(report => getMonthRange(report.history.date) || UNASSIGNED)
          .map(groupingCommonMapper)
      case ScanStationGroupingOption.SCANNER:
        return this.filteredCollection.map(pair => ({
          name: pair.scanner?.name || UNASSIGNED,
          id: pair.scanner?.id || UNASSIGNED,
        }))
      default:
        return []
    }
  }

  protected get instancesInPeriodInterval(): IScannerHistoryPair[] {
    return this.reportsStore.scanStationHistoriesInPeriodInterval
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: IScannerHistoryPair[] }
  } {
    const map = {}

    const groupingOptions = Object.values(ScanStationGroupingOption)

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(report => {
        const groupNames: string[] = this.getCategoryIds(report, band) || [
          UNASSIGNED,
        ]

        groupNames.forEach(group => {
          const groupName = group ? group : UNASSIGNED

          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }
          bandMap[groupName].push(report)
        })
      })
    })

    return map
  }

  protected toRows(reports: IScannerHistoryPair[]) {
    return reports.map(report => {
      const { date: startDate, queueDate, endDate, userId } = report.history
      const user = this.projectMembersStore.getById(userId)

      const company = user
        ? this.companiesStore.getCompanyById(
            UserProject.getCompanyId(user, this.userProjectsStore),
          )
        : null

      const { getWeekdayMonthDayYearAndTimeToDisplay } = this.projectDateStore
      const data = {
        [DataKeys.ID]: report.id,
        [DataKeys.CHECKBOX]: this.selection.get(report.id),
        [DataKeys.SCANNER_NAME]: report.scanner?.name,
        [DataKeys.COMPANY]: company ? [company] : null,
        [DataKeys.USER]: user ? [user] : null,
        [DataKeys.EMPLOYEE_ID]: user?.employeeId || NO_VALUE,
        [DataKeys.EMAIL]: user?.email || NO_VALUE,
        [DataKeys.PHONE_NUMBER]: user?.phoneNumber || NO_VALUE,

        [DataKeys.QUEUE_TIME]: queueDate
          ? getWeekdayMonthDayYearAndTimeToDisplay(queueDate)
          : NO_VALUE,
        [DataKeys.START_TIME]: startDate
          ? getWeekdayMonthDayYearAndTimeToDisplay(startDate)
          : NO_VALUE,
        [DataKeys.FINISH_TIME]: endDate
          ? getWeekdayMonthDayYearAndTimeToDisplay(endDate)
          : NO_VALUE,
        [DataKeys.ELAPSED_TIME]:
          startDate && endDate
            ? millisecondsToTime(endDate - startDate)
            : NO_VALUE,
      }

      return { data }
    })
  }

  protected searchFiltering = (pair: IScannerHistoryPair) => {
    const { getWeekdayMonthDayYearAndTimeToDisplay } = this.projectDateStore
    const { date: startDate, endDate, userId } = pair.history
    const user = this.projectMembersStore.getById(userId)
    const companyName = user
      ? UserProject.getCompanyName(
          user,
          this.userProjectsStore,
          this.companiesStore,
        )
      : EMPTY_STRING
    const key = this.filter.searchKey.toLowerCase()
    const entityValues: string[] = [
      pair.scanner?.name || EMPTY_STRING,
      companyName,
      User.getFullNameToDisplay(user, this.userProjectsStore),
      user?.employeeId || EMPTY_STRING,
      getWeekdayMonthDayYearAndTimeToDisplay(startDate) || EMPTY_STRING,
      getWeekdayMonthDayYearAndTimeToDisplay(endDate) || EMPTY_STRING,
      startDate && endDate
        ? millisecondsToTime(endDate - startDate)
        : EMPTY_STRING,
    ]

    return entityValues.some(value => value?.toLowerCase().includes(key))
  }
}
