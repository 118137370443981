import * as React from 'react'

import { observer } from 'mobx-react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import OperationRule from '~/client/src/shared/models/OperationRule'

import { NotificationSetupModes } from '../../NotificationsSetup'
import BaseNotificationRulesStore from './BaseNotificationRules.store'
import RuleRow from './components/RuleRow/RuleRow'

import './NotificationRules.scss'

// translated

interface IProps {
  store: BaseNotificationRulesStore
  mode: NotificationSetupModes
}

const RULE_ROW_HEIGHT = 63
const OVERSCAN_ROW_COUNT = 6

@observer
export default class NotificationRules extends React.Component<IProps> {
  private readonly cellMeasurerCache: CellMeasurerCache = null

  public constructor(props: IProps) {
    super(props)

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: RULE_ROW_HEIGHT,
      fixedWidth: true,
    })
  }

  public componentDidMount() {
    window.addEventListener('resize', this.clearCellMeasurerCache)
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.clearCellMeasurerCache)
    this.store.clearServiceRows()
  }

  public render() {
    const { rules } = this.store

    return (
      <div className="col notification-rules-wrapper">
        {this.renderDeletionConfirmDialog()}

        <div className="row ml40 mb20 mr20" style={{ minHeight: 50 }}>
          <BaseActionButton
            className="primary-theme"
            title={Localization.translator.addRule}
            isEnabled={true}
            onClick={this.showNewRuleRow}
            icon={<Icons.PlusRounded className="row" />}
          />
          {this.renderLoader()}
        </div>

        {this.renderHeader()}

        <div className="virtualized-list-smart-wrapper">
          <AutoSizer>
            {({ width, height }) => (
              <List
                deferredMeasurementCache={this.cellMeasurerCache}
                data={rules}
                width={width}
                height={height}
                rowCount={rules.length}
                overscanRowCount={OVERSCAN_ROW_COUNT}
                scrollToAlignment="start"
                rowHeight={this.cellMeasurerCache.rowHeight}
                rowRenderer={this.renderRow}
                className="notification-list"
              />
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }

  public renderRow = ({ key, style, index, parent }: any) => {
    const rule = this.store.rules[index]

    if (!rule) {
      return null
    }

    const { removeRule } = this.store

    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div
            style={style}
            ref={registerChild}
            className="col bb-palette-brand-lighter"
          >
            <RuleRow
              rule={rule}
              onRemoveRule={removeRule}
              onAddAssignmentPlaceholderRow={this.addAssignmentPlaceholderRow}
              onSaveChanges={this.saveRuleChanges}
              notificationRulesStore={this.store}
              measureSize={measure}
              mode={this.props.mode}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }

  public performRemoveRule = () => {
    this.store.performRemoveRule()
    this.clearCellMeasurerCache()
  }

  public showNewRuleRow = () => {
    this.store.showNewRuleRow()
    this.clearCellMeasurerCache()
  }

  private addAssignmentPlaceholderRow = (rule: OperationRule) => {
    this.store.addAssignmentPlaceholderRow(rule)
    this.clearCellMeasurerCache()
  }

  private saveRuleChanges = (
    rule: OperationRule,
    shouldSendRequest: boolean = true,
  ) => {
    if (shouldSendRequest) {
      this.store.saveRuleChanges(rule)
    } else {
      this.store.clearServiceRows()
    }

    this.clearCellMeasurerCache()
  }

  private renderDeletionConfirmDialog() {
    const { shouldDeletionConfirmModalShow, hideDeletionConfirm } = this.store

    return (
      <ConfirmDialog
        isOpen={shouldDeletionConfirmModalShow}
        onCancelClicked={hideDeletionConfirm}
        onDoneClicked={this.performRemoveRule}
        doneTitle={Localization.translator.delete}
      >
        <div className="text large pre-line">
          {Localization.translator.shouldDeleteRule}?
        </div>
      </ConfirmDialog>
    )
  }

  private renderHeader() {
    const {
      ifEntityHasTheFollowingTags,
      thenDoAction,
      toUserTeamRoleOrCompany,
    } = Localization.translator

    return (
      <header className="row bb-brand-dark lp05 py8 ml25 mr10">
        <div className="text large bold uppercase pl15">
          {ifEntityHasTheFollowingTags(this.entityName)}
        </div>
        <div
          className="text large bold uppercase no-grow pl15"
          style={{ minWidth: 300 }}
        >
          {thenDoAction}
        </div>
        <div
          className="text large bold uppercase no-grow pl15"
          style={{ minWidth: 350 }}
        >
          {toUserTeamRoleOrCompany}
        </div>
      </header>
    )
  }

  private renderLoader() {
    const { isLoading } = this.store

    return (
      <div className="row x-end">
        {isLoading && (
          <Loader
            className="no-grow full-height col x-center y-center"
            hint={`${Localization.translator.updating}...`}
          />
        )}
      </div>
    )
  }

  private clearCellMeasurerCache = () => {
    this.cellMeasurerCache.clearAll()
  }

  private get entityName(): string {
    switch (this.props.mode) {
      case NotificationSetupModes.delivery:
        return Localization.translator.delivery
      case NotificationSetupModes.form:
        return Localization.translator.form
    }
  }

  private get store() {
    return this.props.store
  }
}
