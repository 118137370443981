import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import PhotosPageStore from '../PhotosPageStore.store'

interface IProps {
  photosStore: PhotosPageStore
}

@observer
export default class PhotosModeToggle extends React.Component<IProps> {
  public render() {
    const {
      isDeliveriesModeActive,
      isDeliveriesModeAvailable,
      isScheduleModeActive,
      isScheduleModeAvailable,
    } = this.props.photosStore

    return (
      <div className="mode-row row x-evenly">
        {isDeliveriesModeAvailable && (
          <span
            className={classList({
              'inline-block text header pointer no-grow': true,
              light: !isDeliveriesModeActive,
              'bb2-primary-blue bold primary-blue': isDeliveriesModeActive,
            })}
          >
            Deliveries
          </span>
        )}
        {isScheduleModeAvailable && (
          <span
            className={classList({
              'inline-block text header pointer no-grow': true,
              light: !isScheduleModeActive,
              'bb2-primary-blue bold primary-blue': isScheduleModeActive,
            })}
          >
            Tracker
          </span>
        )}
      </div>
    )
  }
}
