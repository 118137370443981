import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import WorkflowTypes, {
  WorkflowTypesInfo,
} from '~/client/src/desktop/enums/WorkflowTypes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import WorkflowBanner from '~/client/src/desktop/views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/Utils/WorkflowBanner/WorkflowBanner'
import { Loader } from '~/client/src/shared/components/Loader'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import ProjectSetUpPageStore from '../../../../ProjectSetUpPage.store'
import DeliveryRequestSetUpStore from './DeliveryRequestSetUp.store'
import DeliveryDetailsConfigurations from './components/DeliveryDetailsConfigurations/DeliveryDetailsConfigurations'
import DeleteDeliveryAttributeConfirmDialog from './components/DeliveryDetailsConfigurations/components/DeleteDeliveryAttributeConfirmDialog'
import MemberSearchList from './components/MemberSearchList/MemberSearchList'

interface IDeliveryRequestSetUp {
  projectSetUpPageStore: ProjectSetUpPageStore

  eventsStore?: DesktopEventStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

const deliveryApprovalTeam = 'delivery approval team'
const deliveryApprovalTeamDescription = `Authorize the following users to validate and schedule
Delivery Requests, which will appear in their Notifications stream`
const loading = 'Loading'
const workflowChart = {
  action: {
    change: 'Action: Change',
    request: 'Action: Request Delivery',
    scheduled: 'Action: Delivery Scheduled',
    onSite: 'Action: Delivery On-site',
    inspected: 'Action: Delivery Inspected',
    done: 'Action: Delivery Done',
  },
  text: {
    requested: 'Requested',
    scheduled: 'Scheduled',
    onSite: 'On-site',
    done: 'Done',
  },
}

@inject('eventsStore', 'userProjectsStore', 'projectMembersStore')
@observer
class DeliveryRequestSetUp extends React.Component<IDeliveryRequestSetUp> {
  private readonly store: DeliveryRequestSetUpStore

  public constructor(props: IDeliveryRequestSetUp) {
    super(props)

    this.store = new DeliveryRequestSetUpStore(
      props.eventsStore,
      props.projectSetUpPageStore,
      props.userProjectsStore,
      props.projectMembersStore,
    )
  }

  public render() {
    const {
      keyWord,
      resultMembersList,
      docMasters,
      handleClear,
      handleChange,
      handleApply,
      handleCancel,
      isDeleteConfirmationDialogShown,
      deletableDeliveryAttributeType,
      deletableDeliveryAttributeName,
      hideDeleteConfirmationDialog,
      applyDeleteConfirmationDialog,
      isLoading,
    } = this.store

    const bannerData = WorkflowTypesInfo[WorkflowTypes.DELIVERY_REQUEST]
    const { title: bannerTitle, description: bannerDescription } = bannerData

    return (
      <div className="workflows-setup col">
        <div className="workflows-setup-container">
          <main className="workflows-setup-main">
            <WorkflowBanner title={bannerTitle} description={bannerDescription}>
              {this.renderWorkflowChart()}
            </WorkflowBanner>
            {isLoading ? (
              <Loader
                className="loader col x-center y-center m20"
                hint={loading}
              />
            ) : (
              <div className="relative">
                <DeleteDeliveryAttributeConfirmDialog
                  isShown={isDeleteConfirmationDialogShown}
                  onApply={applyDeleteConfirmationDialog}
                  onHide={hideDeleteConfirmationDialog}
                  type={deletableDeliveryAttributeType}
                  name={deletableDeliveryAttributeName}
                />
                <form className="form-setup">
                  <div className="delivery-approval-team-section">
                    <div className="text huge bold pb10 bb-light-grey capitalize">
                      {deliveryApprovalTeam}
                    </div>
                    <div className="text large py15 light">
                      {deliveryApprovalTeamDescription}
                    </div>
                    <MemberSearchList
                      value={keyWord}
                      membersList={resultMembersList}
                      appliedMembers={docMasters}
                      onClear={handleClear}
                      onChange={handleChange}
                      onApply={handleApply}
                      onCancel={handleCancel}
                    />
                  </div>
                </form>

                <DeliveryDetailsConfigurations
                  deliveryRequestSetUpStore={this.store}
                  projectSetUpPageStore={this.props.projectSetUpPageStore}
                />
              </div>
            )}
          </main>
        </div>
      </div>
    )
  }

  private renderWorkflowChart() {
    // TODO: move to separate component for reusing
    const { action, text } = workflowChart

    return (
      <div className="banner-chart col">
        <div className="row top-level relative">
          <div className="top-line left" />
          <div className="arrow left">
            <Icon icon={IconNames.CARET_RIGHT} />
          </div>
          <div className="banner-chart-rectangle top-level">
            {action.change}
          </div>
          <div className="top-line right" />
          <div className="arrow right">
            <Icon icon={IconNames.CARET_RIGHT} />
          </div>
        </div>
        <div className="row bottom-level">
          <div className="banner-chart-rectangle">{action.request}</div>
          <div className="banner-chart-next-step">
            <div className="line" />
            <div className="banner-chart-text text large bold-font">
              {text.requested}
            </div>
            <div className="line" />
            <Icon className="arrow" icon={IconNames.CARET_RIGHT} />
          </div>
          <div className="banner-chart-rectangle">{action.scheduled}</div>
          <div className="banner-chart-next-step">
            <div className="line" />
            <div className="banner-chart-text text large bold-font">
              {text.scheduled}
            </div>
            <div className="line" />
            <Icon className="arrow" icon={IconNames.CARET_RIGHT} />
          </div>
          <div className="banner-chart-rectangle">{action.onSite}</div>
          <div className="banner-chart-next-step">
            <div className="line" />
            <div className="banner-chart-text text large bold-font">
              {text.onSite}
            </div>
            <div className="line" />
            <Icon className="arrow" icon={IconNames.CARET_RIGHT} />
          </div>
          <div className="banner-chart-rectangle">{action.inspected}</div>
          <div className="banner-chart-next-step">
            <div className="line" />
            <Icon className="arrow" icon={IconNames.CARET_RIGHT} />
          </div>
          <div className="banner-chart-rectangle">{action.done}</div>
          <div className="banner-chart-next-step">
            <div className="line" />
            <Icon className="arrow" icon={IconNames.CARET_RIGHT} />
          </div>
          <div className="banner-chart-text text large bold-font">
            {text.done}
          </div>
        </div>
      </div>
    )
  }
}

export default DeliveryRequestSetUp
