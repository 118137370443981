import * as React from 'react'

import { Button } from '@blueprintjs/core'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import PresentationSetUpStore from '../PresentationSetUp.store'

interface IProps {
  store: PresentationSetUpStore
  isLoading: boolean
}

export default class PresentationModeHeader extends React.Component<IProps> {
  public render() {
    const { store, isLoading } = this.props

    return (
      <div className="mb30">
        <div className="text huge bold inline-block mr30 capitalize">
          {Localization.translator.presentationMode}
        </div>
        <div className="inline-block mr30">
          <Button
            loading={isLoading}
            className="white-button"
            text={Localization.translator.turnOn}
            onClick={store.showConfirmationPresentationDialog}
          />
        </div>
        <div className="text large light inline-block">
          {Localization.translator.presentationModeDescriptions.header}
        </div>
      </div>
    )
  }
}
