import * as React from 'react'

import { observer } from 'mobx-react'

import TwoMonthsDatePicker from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ClosuresDatePickerStore from './ClosuresDatePicker.store'
import ProjectClosingInterval from './ProjectClosingInterval'

interface IProps {
  store: ClosuresDatePickerStore
  className?: string
}

@observer
export default class ClosuresDatePicker extends React.Component<IProps> {
  public render() {
    return (
      <TwoMonthsDatePicker
        store={this.props.store}
        className={this.props.className}
        customHeaderRenderer={this.renderHeader}
        customFooterRenderer={this.renderFooter}
      />
    )
  }

  private renderHeader = () => {
    const { titleTranslatorKey, hide, intevalName, onIntevalNameChange } =
      this.props.store
    return (
      <div className="row pb10 mb20 bb-light-grey y-center">
        <div className="text bold uppercase extra-large">
          {titleTranslatorKey && Localization.getText(titleTranslatorKey)}
        </div>
        <StruxhubInput
          label={Localization.translator.closureName}
          onChange={onIntevalNameChange}
          value={intevalName}
        />
        <div className="ml15 no-grow">
          <Icons.Cross className="pointer" onClick={hide} />
        </div>
      </div>
    )
  }

  private renderFooter = () => {
    const { onApplyClick, hide, onRemove, project } = this.props.store

    return (
      <div>
        <div
          className="tm-dp-btn row x-end bold-font text"
          onClick={onApplyClick}
        >
          {Localization.translator.save}
        </div>
        <div className="tm-dp-btn-container col">
          <div className="full-width large text bold-font bb-light-input-border">
            {Localization.translator.customProjectClosures}
          </div>
          <div className="closing-intervals-holder full-width">
            {project.closedIntervals &&
              project.closedIntervals.map((interval, idx) => (
                <ProjectClosingInterval
                  key={idx}
                  item={project}
                  interval={interval}
                  onRemove={onRemove}
                />
              ))}
          </div>
        </div>

        <div className="tm-dp-btn-container">
          <div className="tm-dp-btn row x-end bold-font text" onClick={hide}>
            {Localization.translator.close}
          </div>
        </div>
      </div>
    )
  }
}
