import { IDeliveryAttributeEditorProps } from './DeliveryAttributeEditor'
import IDeliverySettingsSelect from './IDeliverySettingSelect'

export enum ConfigurationType {
  MANDATORITY,
  CONFIGURATION,
  PROJECT_MATERIAL_OPTION,
  NON_WORK_TIMES_BLOCK,
  BOOKING_DEADLINE,
  MATERIAL_SECTION,
  INSPECTION_SECTION,
  EXTENSIBILITY,
}

interface IPulldownText {
  mandatory: string
  optional: string
  hide?: string
}

export interface IDeliveryPulldownSettings {
  configurationType: ConfigurationType
  customModalText?: IPulldownText
  onMandatoryChange(fieldName: string, value: boolean): void
  onHide?(fieldName: string, value: boolean): void
}

export enum DeliverySettingType {
  SELECT = 'Select',
  SWITCH = 'Switch',
}

export default interface IDeliverySettingDto {
  fieldName?: string
  relatedHideableFieldName?: string
  isKeySection?: boolean
  isEditable?: boolean
  title: string
  description?: string
  deliveryAttribute?: IDeliveryAttributeEditorProps
  hasSelect?: boolean
  type?: DeliverySettingType
  isInspector?: boolean
  isMaterialCategory?: boolean
  hasOptionsPreview?: boolean
  isMultiValueInput?: boolean
  isDisabled?: boolean
  select?: IDeliverySettingsSelect
  deliveryPulldownSettings?: IDeliveryPulldownSettings
  getIcon?: (props?: any) => JSX.Element
  onSwitchChange?: (fieldName: string, value: boolean) => void
  isCollapsible?: boolean
}
