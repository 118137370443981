import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import { ICompany } from '~/client/graph'
import BaseAddEditDialogWrapper from '~/client/src/desktop/components/BaseAddEditDialogWrapper/BaseAddEditDialogWrapper'
import ConfirmDialogTypes from '~/client/src/desktop/enums/ConfirmDialogTypes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import QRCodeAccess from '~/client/src/shared/components/QRCodes/components/QRCodeAccess'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import InfoSectionId, {
  getSectionTranslate,
} from '~/client/src/shared/enums/InfoSectionId'
import User from '~/client/src/shared/models/User'
import { INVITE_PROJECT_MEMBERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { areObjectArraysEqual } from '~/client/src/shared/utils/util'

import CompanyDialog from '../../../CompaniesDirectory/CompanyDialog/CompanyDialog'
import AddEditMemberDialogStore from './AddEditMemberDialog.store'
import ConfirmUsersDialog from './ConfirmUsersDialog/ConfirmUsersDialog'
import PermissionsGrid from './PermissionsGrid/PermissionsGrid'
import ResetPasswordRow from './ResetPasswordRow'

interface IProps {
  onInvite?: (item: User, cb: () => void) => void
  isOpen: boolean
  closeDialog: () => void
  loading: boolean
  onSubmit: (items: User[], lastUpdatedItemId?: string) => void
  itemsToEdit: User[]
  allItems: User[]

  errorMessage?: string
  resetErrorMessage?: () => void

  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  state?: DesktopInitialState
}

@inject('state', 'companiesStore', 'userProjectsStore')
@observer
export default class AddEditMemberDialog extends React.Component<IProps> {
  private readonly store: AddEditMemberDialogStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new AddEditMemberDialogStore(
      props.itemsToEdit,
      props.allItems,
      props.resetErrorMessage,
      props.companiesStore,
      props.userProjectsStore,
      props.onSubmit,
      props.closeDialog,
      props.state,
    )
  }

  public componentDidMount() {
    this.store.recreateFormStores()
    this.store.updateFormStores()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    this.store.allItems = this.props.allItems

    if (!areObjectArraysEqual(prevProps.itemsToEdit, this.props.itemsToEdit)) {
      this.store.itemsToEdit = this.props.itemsToEdit
      this.store.recreateFormStores()
      this.store.updateFormStores()
    }

    if (prevProps.onSubmit !== this.props.onSubmit) {
      this.store.updateItems = this.props.onSubmit
    }
  }

  public render() {
    const { itemsToEdit, isOpen, state, onInvite } = this.props
    const {
      allCompanies,
      hideCompanyModal,
      shouldShowCompanyModal,
      saveRelatedCompany: saveCompanies,
      areCompaniesBeingUpdated,
      relatedCompany,
      shouldShowConfirmInviteDialog,
      hideConfirmInviteDialog,
      editingItem,
    } = this.store

    if (!itemsToEdit?.length || !isOpen) {
      return <div />
    }

    return (
      <>
        <BaseAddEditDialogWrapper
          store={this.store}
          isLoading={this.props.loading}
          errorInfo={
            this.store.activeFormStore.error || this.props.errorMessage
          }
          customFooterRenderer={this.renderFooter}
        >
          {onInvite && (
            <ConfirmUsersDialog
              type={ConfirmDialogTypes.INVITE}
              isOpen={shouldShowConfirmInviteDialog}
              loading={state.loading.get(INVITE_PROJECT_MEMBERS)}
              closeDialog={hideConfirmInviteDialog}
              performItems={this.handleInviteConfirmClick}
              itemsToPerform={[User.fromDto(editingItem)]}
            />
          )}
          <div className="row y-start">
            {this.renderSection(InfoSectionId.Personal)}
            <div className="mx24 my12 no-grow ba-none br-light-grey as-stretch" />
            {this.renderSection(InfoSectionId.Company)}
            {this.renderToCompanyIcon(relatedCompany)}
          </div>
          <div className="ma12 ba-none bb-light-grey as-stretch" />
          <div className="row y-start">
            {this.renderSection(InfoSectionId.Project)}
            <div className="mx24 my12 no-grow ba-none br-light-grey as-stretch" />
            {this.renderSection(InfoSectionId.Additional)}
          </div>
          <div className="ma12 ba-none bb-light-grey as-stretch" />
          {this.renderSection(InfoSectionId.WorkflowPermission)}
          {this.store.editingItem?.id && (
            <>
              <div className="ma12 ba-none bb-light-grey as-stretch" />
              {this.renderSection(InfoSectionId.QRCodes)}
            </>
          )}
          {shouldShowCompanyModal && (
            <CompanyDialog
              closeDialog={hideCompanyModal}
              loading={areCompaniesBeingUpdated}
              onSubmit={saveCompanies}
              itemsToEdit={[relatedCompany]}
              allItems={allCompanies}
            />
          )}
        </BaseAddEditDialogWrapper>
      </>
    )
  }

  private renderSection(sectionId: InfoSectionId): JSX.Element {
    const {
      getFieldsBySectionId,
      getPermissionConfig,
      handlePermissionChange,
    } = this.store

    const fields = getFieldsBySectionId(sectionId)
    const title = getSectionTranslate(sectionId)

    let content

    switch (sectionId) {
      case InfoSectionId.WorkflowPermission:
        content = (
          <PermissionsGrid
            value={getPermissionConfig()}
            onChange={handlePermissionChange}
          />
        )
        break
      case InfoSectionId.QRCodes:
        content = (
          <QRCodeAccess
            user={this.store.editingItem}
            shouldShowQRCode={true}
            className="pl20"
          />
        )
        break
      default:
        content = BaseAddEditDialogWrapper.renderFields(fields, AvatarType.User)
        break
    }

    return (
      <div>
        <div className="text bold uppercase light lp05 py12">{title}</div>
        {content}
      </div>
    )
  }

  private renderFooter = (): JSX.Element => {
    if (this.errorInfo) {
      return BaseAddEditDialogWrapper.renderErrorInfo(this.errorInfo)
    }

    if (this.props.onInvite) {
      const { isAddMode, editingItem, showConfirmInviteDialog } = this.store
      return (
        <ResetPasswordRow
          isAddMode={isAddMode}
          user={editingItem}
          onInvite={showConfirmInviteDialog}
        />
      )
    }
  }

  public renderToCompanyIcon({ isDeleted, id }: ICompany): JSX.Element {
    if (isDeleted) {
      return null
    }

    return (
      <div className="row company-card-btn">
        <Icon
          className="to-company-icon pointer"
          icon={id ? IconNames.CHEVRON_RIGHT : IconNames.PLUS}
          onClick={this.handleEditClick}
        />
      </div>
    )
  }

  private get errorInfo(): string {
    return this.store.activeFormStore.error || this.props.errorMessage
  }

  private handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    this.store.showCompanyModal()
  }

  private handleInviteConfirmClick = ([user]: User[]): void => {
    this.props.onInvite(user, this.store.hideConfirmInviteDialog)
  }
}
