import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import * as turf from '@turf/turf'
import { Units } from '@turf/turf'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  IGeoJson2DGeographicCoordinates,
  LocationType,
  SitemapItemShapeType,
  SitemapLineArrowPosition,
} from '~/client/graph'
import {
  maxLat,
  maxLng,
  minLat,
  minLng,
} from '~/client/src/desktop/components/GeneralMapViewSetUp/GlobeProperties'
import SitemapItemsColorPicker from '~/client/src/desktop/components/SitemapItemsColorPicker/SitemapItemsColorPicker'
import StruxhubNumberWithControlsInput from '~/client/src/desktop/components/StruxhubDesktopInputs/StruxhubNumberWithControlsInput/StruxhubNumberWithControlsInput'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import GeoreferencingLabel from '~/client/src/shared/components/GeoreferencingLabel/GeoreferenceingLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import CircleShapeCoordinates from '~/client/src/shared/components/SitemapHelpers/models/CircleShapeCoordinates'
import GlobeViewItemProperties from '~/client/src/shared/components/SitemapHelpers/models/GlobeViewItemProperties'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import SitemapItemProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemProperties'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IGeoPosition from '~/client/src/shared/models/IGeoPosition'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import LocationBaseWithDimensions from '~/client/src/shared/models/LocationObjects/LocationBaseWithDimensions'
import { LocationIntegrationType } from '~/client/src/shared/models/LocationObjects/LocationIntegration'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import {
  FORBIDDEN_SITE_NAME,
  FORBIDDEN_SITE_NAME_MESSAGE,
} from '~/client/src/shared/stores/domain/LocationBase.store'

import MapViewSetUpStore, { SetUpSteps } from '../../MapViewSetUp.store'
import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import SitemapsSetupStore from '../../stores/SitemapsSetup.store'
import BuildingProperties from './BuildingProperties'
import DimensionProperties from './DimensionProperties'
import IconSelector from './IconSelector'
import ObjectAccessibleLevelsProperties from './ObjectAccessibleLevelsProperties'
import ParentsSelector from './ParentSelector'
import PropertySelect, { IPropertySelectOption } from './PropertySelect'
import ShapeSelector from './ShapeSelector'

import Colors from '~/client/src/shared/theme.module.scss'

import './PropertiesPanel.scss'

const propertiesPanel = {
  selectItemToEditProperties: 'Select item to edit properties',
  finishEditing: 'Finish editing',
  objectNestedUnder: 'Object nested under',
  edit: 'Edit',
  name: 'Name',
  shape: 'Shape',
  color: 'Color',
  object: 'Object',
  fills: 'Fills',
  opacity: 'Opacity',
  borders: 'Borders',
  line: 'Line',
  width: 'Width',
  radius: 'Radius',
  divisionStartAngle: 'Division start angle',
  divisionEndAngle: 'Division end angle',
  crane: 'Crane',
  limitSwingRadius: 'Limit swing radius',
  textStyle: 'Text style',
  fontSize: 'Font Size',
  fontColor: 'Font color',
  showTextBox: 'Show Text Box',
  showLabel: 'Show Label',
  showIcon: 'Show Icon',
  showShape: 'Show Shape',
  isClosed: 'Is Closed',
  lineStyle: 'Line Style',
  arrows: 'Arrows',
  icon: 'Icon',
  iconColor: 'Icon color',
  text: 'Text',
}

const NAME_INPUT_MAX_WIDTH = 215
const LETTER_WIDTH = 10

const PERCENT_MODIFIER = 100
const MAX_OPACITY_VALUE = 100
const MIN_OPACITY_VALUE = 0
const OPACITY_STEP = 1

const MAX_LINE_WIDTH_VALUE = 10
const MIN_LINE_WIDTH_VALUE = 1
const LINE_WIDTH_STEP = 1
const DESIMAL_LINE_WIDTH_STEP = 1

const MIN_RADIUS = 1
const MAX_RADIUS = 1000

const MAX_FONT_VALUE = 100
const MIN_FONT_VALUE = 4
const FONT_STEP = 1
const maxDegrees = 360
const maxZoomValue = 20
const minZoomValue = 0
const zoomStep = 0.01
const bearingStep = 0.1

const coordinatesOfThePoint = 'Coordinates of the point'
const latitude = 'Latitude'
const longitude = 'Longitude'
const saveAlignment = 'Save Alignment'
const georeference = 'Georeferencing'
const LOADER_SIZE = 40
const zoomOfTheCurrentView = 'Zoom of current view'
const orientationOfTheCurrentView = 'Orientation of the current view'
const planProperties = 'Plan properties'
const dimensionUnits = 'Dimension units'

interface IProps {
  store: MapViewSetUpStore
  saveAlignment: () => void
  exitRubber: () => void
  step: SetUpSteps
  viewport: IGeoPosition
  isGlobeMode?: boolean
}

const MAX_ANGLE = 180
const MIN_ANGLE = -180

enum DistanceUnitsTypes {
  feet = 'feet',
  meters = 'meters',
}

// TODO: extract all the text to the top
@observer
export default class PropertiesPanel extends React.Component<IProps> {
  @observable private isLoading: boolean = false
  @observable private selectedCraneDistanceUnitType: Units =
    DistanceUnitsTypes.feet

  private readonly lineArrowPositionOptions: IPropertySelectOption[] = [
    { label: 'None', value: SitemapLineArrowPosition.None },
    { label: 'Start, End', value: SitemapLineArrowPosition.StartEnd },
    {
      label: 'Start, End  (Reversed)',
      value: SitemapLineArrowPosition.StartEndReversed,
    },
    {
      label: 'Start, Middle, End',
      value: SitemapLineArrowPosition.StartMiddleEnd,
    },
    {
      label: 'Start, Middle, End (Reversed)',
      value: SitemapLineArrowPosition.StartMiddleEndReversed,
    },
  ]

  private readonly lineArrowGlobePositionOptions: IPropertySelectOption[] = [
    { label: 'None', value: SitemapLineArrowPosition.None },
    {
      label: 'Start, Middle, End',
      value: SitemapLineArrowPosition.StartMiddleEnd,
    },
    {
      label: 'Start, Middle, End (Reversed)',
      value: SitemapLineArrowPosition.StartMiddleEndReversed,
    },
  ]

  private get itemProperties():
    | GlobeViewItemProperties
    | SitemapItemProperties {
    return this.props.isGlobeMode
      ? this.selectedMapViewItem.globeViewItemProperties
      : this.selectedMapViewItem.sitemapItemProperties
  }

  public render(): JSX.Element {
    const { isGlobeMode, store } = this.props
    const { whiteboard: sitemap } = store.mapViewItemsSetupStore
    if (!sitemap) {
      return null
    }

    return (
      <div
        className={classList({
          'properties-panel full-height no-grow relative': true,
          'globe-panel': isGlobeMode,
        })}
      >
        {this.renderLoader()}
        <div
          className={classList({
            'full-height scrollable': true,
            'unclickable-element': this.shouldShowLoading,
          })}
        >
          {this.renderControlButton()}
          {this.renderProperties()}
        </div>
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      this.shouldShowLoading && (
        <div className="full-height full-width absolute modal-overlay unclickable-element">
          <Loader
            className="col x-center y-center full-height full-width"
            size={LOADER_SIZE}
          />
        </div>
      )
    )
  }

  private renderStepPlanProperties(): JSX.Element {
    const { viewport } = this.props

    return (
      <div className="col pa10">
        <div className="text large center pb12 light bold bb-light-cool-grey">
          {planProperties}
        </div>
        <div className="col mt20">
          <StruxhubInput
            label={latitude}
            type="number"
            value={viewport.latitude.toString()}
            isRequired={true}
            onChange={this.changeLat}
            min={minLat}
            max={maxLat}
            negativeOrDecimal={true}
            withDelay={true}
          />
          <StruxhubInput
            label={longitude}
            type="number"
            value={viewport.longitude.toString()}
            isRequired={true}
            onChange={this.changeLng}
            min={minLng}
            max={maxLng}
            negativeOrDecimal={true}
            withDelay={true}
          />
          <StruxhubNumberWithControlsInput
            label={zoomOfTheCurrentView}
            type="number"
            isRequired={true}
            max={maxZoomValue}
            min={minZoomValue}
            step={zoomStep}
            value={viewport.zoom.toFixed(3)}
            onValueChange={this.setZoom}
            onChange={this.handleZoomChangeEvent}
            negativeOrDecimal={true}
            withDelay={true}
          />
          <StruxhubNumberWithControlsInput
            label={orientationOfTheCurrentView}
            type="number"
            isRequired={true}
            max={maxDegrees}
            min={-maxDegrees}
            step={bearingStep}
            value={viewport.bearing.toFixed(2)}
            onChange={this.handleOrientationChangeEvent}
            onValueChange={this.setOrientation}
            negativeOrDecimal={true}
            withDelay={true}
          />
          {this.props.step === SetUpSteps.alignPlan && (
            <>
              <div
                className="action-step-button pointer text large center"
                onClick={this.props.saveAlignment}
              >
                {saveAlignment}
              </div>
              <div
                className="action-step-button pointer text large center reversed"
                onClick={this.props.exitRubber}
              >
                {Localization.translator.cancel}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  private changeLat = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { setViewportProp } = this.props.store.mapBoxViewerStore
    setViewportProp(Number(event.target.value), 'latitude')
  }

  private changeLng = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { setViewportProp } = this.props.store.mapBoxViewerStore
    setViewportProp(Number(event.target.value), 'longitude')
  }

  private renderPlanProperties(): JSX.Element {
    const { selectedSitemap } = this.sitemapsSetupStore

    return (
      <div className="col">
        <div className="text bold extra-large center pb12 mt10">
          {planProperties}
        </div>
        <div className="col">
          <div className="text medium pb12 mt10 no-grow w-max-content mx10 bold">
            {georeference}
          </div>
          <div className="row bb-light-cool-grey pa10">
            <GeoreferencingLabel
              isReferenced={selectedSitemap?.isReferenced}
              className="no-grow"
            />
            {this.renderEditGeoreference()}
          </div>
        </div>
      </div>
    )
  }

  @action.bound
  private handleOrientationChangeEvent(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    this.setOrientation(+event.target.value)
  }

  @action.bound
  private setOrientation(value: number): void {
    const bearing = Number(value.toFixed(2))

    const newBearing = isNaN(bearing) ? 0 : bearing
    this.props.store.mapBoxViewerStore.setViewportProp(newBearing, 'bearing')
  }

  @action.bound
  private handleZoomChangeEvent(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    this.setZoom(+event.target.value)
  }

  @action.bound
  private setZoom(zoom: number): void {
    this.props.store.mapBoxViewerStore.setViewportProp(zoom, 'zoom')
  }

  private renderEditGeoreference(): JSX.Element {
    if (this.isSelectedSitemapReferenced) {
      return (
        <div className="row x-end">
          <div className="row x-around dialog-footer">
            <div
              onClick={this.onEditGeoreferenceClick}
              className="text light-blue large edit-ref-button bold right mr20 pointer"
            >
              {Localization.translator.edit_verb}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row x-end">
        <div className="row x-around dialog-footer">
          <BaseActionButton
            id="edit-georeference"
            isEnabled={true}
            onClick={this.onEditGeoreferenceClick}
            title={Localization.translator.edit_verb}
          />
        </div>
      </div>
    )
  }

  @action.bound
  private onEditGeoreferenceClick(): void {
    this.props.store.mapBoxEditorStore.setLeftVisibility()
    if (this.props.store.mapBoxViewerStore?.mapRef?.getMap?.()) {
      this.props.store.mapBoxViewerStore.setViewportFromAddress()
    }
    this.props.store.setStep(SetUpSteps.alignPlan)
  }

  private renderControlButton(): JSX.Element {
    if (!this.selectedMapViewItem) {
      return (
        <div className="text large pb12 mt10">
          {this.props.step
            ? this.renderStepPlanProperties()
            : this.renderPlanProperties()}
        </div>
      )
    }

    return (
      <div className="properties-section pa12 mt10">
        <div className="text title uppercase pb12">{propertiesPanel.edit}</div>
        <button
          className={classList({
            'full-width bg-primary-blue ba-none brada4 pa10': true,
            'inactive-element': !this.selectedMapViewItem.isValid(
              this.props.isGlobeMode,
            ),
          })}
          onClick={this.saveSelectedMapViewItem}
        >
          <span className="text white large">
            {propertiesPanel.finishEditing}
          </span>
        </button>
      </div>
    )
  }

  private saveSelectedMapViewItem = async (): Promise<void> => {
    if (this.selectedMapViewItem.displayName === FORBIDDEN_SITE_NAME) {
      return
    }
    this.setLoading(true)

    await this.mapViewItemsSetupStore.saveSelectedMapViewItem(false, true)

    this.setLoading(false)
  }

  private renderHeader(): JSX.Element {
    const dataObject = this.selectedMapViewItem.dataObject
    return (
      <div className="row pb12">
        <div className="text title uppercase">{propertiesPanel.object}</div>
        {!dataObject?.is(LocationIntegrationType.MaturixStation) && (
          <Icons.Delete className="no-grow pointer" onClick={this.deleteItem} />
        )}
      </div>
    )
  }

  private renderProperties(): JSX.Element {
    const { isGlobeMode } = this.props.store
    if (!this.selectedMapViewItem) {
      return null
    }

    return (
      <>
        <div className="properties-section px12 pt12">
          {this.renderHeader()}
          {this.renderNameInput()}
          {this.renderParentsInput()}
        </div>
        {this.renderIconSection()}
        {this.renderColorsSection()}
        {this.renderTextStyle()}
        {this.renderCraneSection()}
        {this.renderLineStyle()}
        {this.renderObjectAccessibleLevelsProperties()}
        {this.renderDimensions()}
        {isGlobeMode && this.renderCoordinatesInfo()}
        {this.renderBuildingsProperties()}
      </>
    )
  }

  private renderDimensions(): JSX.Element {
    const location = this.selectedMapViewItem?.dataObject
    return location instanceof LocationBaseWithDimensions ? (
      <DimensionProperties dataObject={location} />
    ) : null
  }

  private renderCoordinatesInfo(): JSX.Element {
    const { isDisplayed, sitemapItem } = this.selectedMapViewItem

    const position: IGeoJson2DGeographicCoordinates = sitemapItem.coordinates

    if (!isDisplayed || !position) {
      return null
    }

    return (
      <div className="col properties-section px12 pt12">
        <div className="text uppercase title pb12">{coordinatesOfThePoint}</div>
        <StruxhubInput
          label={latitude}
          type="number"
          isRequired={true}
          onChange={this.updateItemLatitude}
          value={position.latitude?.toString()}
          negativeOrDecimal={true}
          max={maxLat}
          min={minLat}
          withDelay={true}
        />
        <StruxhubInput
          label={longitude}
          type="number"
          isRequired={true}
          onChange={this.updateItemLongitude}
          value={position.longitude?.toString()}
          negativeOrDecimal={true}
          max={maxLng}
          min={minLng}
          withDelay={true}
        />
      </div>
    )
  }

  private updateItemLatitude = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (!this.selectedMapViewItem?.sitemapItem?.coordinates) {
      return
    }
    this.selectedMapViewItem.sitemapItem.coordinates.latitude = Number(
      event.target.value,
    )
  }

  private updateItemLongitude = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (!this.selectedMapViewItem?.sitemapItem?.coordinates) {
      return
    }
    this.selectedMapViewItem.sitemapItem.coordinates.longitude = Number(
      event.target.value,
    )
  }

  @action.bound
  private deleteItem(): void {
    this.mapViewItemsSetupStore.showDeleteConfirmationDialog(
      this.selectedMapViewItem,
    )
  }

  private renderNameInput(): JSX.Element {
    const { selectedMapViewItem, isItemAssignedToSitemap } =
      this.props.store.mapViewItemsSetupStore
    const shouldShowTag = isItemAssignedToSitemap(selectedMapViewItem)
    const color =
      this.itemProperties.labelProperties?.color || Colors.paletteBrandDark

    return (
      <StruxhubInput
        label={propertiesPanel.name}
        isRequired={true}
        isDisabled={
          !this.props.isGlobeMode && this.selectedMapViewItem.isRichTextBox
        }
        value={this.selectedMapViewItem.displayName}
        isValid={this.selectedMapViewItem.displayName !== FORBIDDEN_SITE_NAME}
        validationMessage={FORBIDDEN_SITE_NAME_MESSAGE}
      >
        {(onFocus, onBlur) => (
          <SitemapAttributeTag
            className="no-flex"
            shouldShowAsTag={shouldShowTag}
            dataObject={this.selectedMapViewItem.dataObject}
            sitemapItem={this.selectedMapViewItem.sitemapItem}
          >
            <input
              className="ba-none no-outline bg-transparent text extra-large line-24"
              type="text"
              value={this.selectedMapViewItem.displayName}
              onChange={this.onItemNameChange}
              onFocus={onFocus}
              onBlur={this.addCurrentStateToHistory.bind(this, onBlur)}
              disabled={
                !this.props.isGlobeMode &&
                this.selectedMapViewItem.isRichTextBox
              }
              style={{
                width: Math.min(
                  (this.selectedMapViewItem.displayName.length + 1) *
                    LETTER_WIDTH,
                  NAME_INPUT_MAX_WIDTH,
                ),
                color,
              }}
            />
          </SitemapAttributeTag>
        )}
      </StruxhubInput>
    )
  }

  @action.bound
  private onItemNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.selectedMapViewItem.setName(event.target.value)
  }

  private renderParentsInput(): JSX.Element {
    const { selectedSitemapItemAllowedParents } = this.mapViewItemsSetupStore
    if (!selectedSitemapItemAllowedParents.length) {
      return null
    }

    return (
      <div className="pb12">
        <ParentsSelector
          label={propertiesPanel.objectNestedUnder}
          store={this.mapViewItemsSetupStore}
        />
      </div>
    )
  }

  private renderIconSection(): JSX.Element {
    const colorPicker = this.renderIconColorPicker()

    return (
      colorPicker && (
        <div className="properties-section px12 pt12">{colorPicker}</div>
      )
    )
  }

  private renderColorsSection(): JSX.Element {
    const dataObject = this.selectedMapViewItem.dataObject
    if (dataObject?.is(LocationIntegrationType.MaturixStation)) {
      return null
    }
    const shapeColorPicker = this.renderShapeColorPicker()
    const bordersSection = this.renderBordersSection()
    if (!shapeColorPicker && !bordersSection) {
      return null
    }
    return (
      <div className="properties-section px12 pt12">
        {shapeColorPicker}
        {bordersSection}
      </div>
    )
  }

  private renderIconColorPicker(): JSX.Element {
    const shouldHideColorPicker =
      !this.itemProperties.iconProperties &&
      !this.itemProperties.labelProperties
    if (
      shouldHideColorPicker ||
      (this.itemProperties.iconProperties
        ? this.isPartPropertiesHidden(MapViewItemDrawnPart.Icon)
        : this.isPartPropertiesHidden(MapViewItemDrawnPart.Label))
    ) {
      return null
    }

    const label = this.selectedMapViewItem.isRichTextBox
      ? propertiesPanel.borders
      : this.itemProperties.iconProperties
      ? propertiesPanel.icon
      : propertiesPanel.text
    return (
      <>
        <div className="text uppercase title pb12">{label}</div>
        <div className="pb12 row y-end">
          <div className="no-grow pr12 pb4">
            <div className="text pb4">{propertiesPanel.icon}</div>
            <div>
              <IconSelector
                item={this.selectedMapViewItem}
                store={this.mapViewItemsSetupStore}
              />
            </div>
          </div>
          <div className="no-grow pr12">
            <div className="text pb4">{propertiesPanel.color}</div>
            <div>
              <SitemapItemsColorPicker
                value={this.selectedMapViewItem.color}
                onChange={this.onColorChange}
              />
            </div>
          </div>
          {!this.selectedMapViewItem.isDataLess && (
            <div className="row pb8">
              <div className="no-grow">
                <Checkbox
                  isChecked={this.itemProperties.iconProperties.isDisplayed}
                  className="properties-checkbox"
                  onClick={this.toggleIconVisibility}
                />
              </div>
              <div className="text large pl8">{propertiesPanel.showIcon}</div>
            </div>
          )}
        </div>
      </>
    )
  }

  @action.bound
  private toggleIconVisibility(): void {
    const { iconProperties } = this.itemProperties
    iconProperties.toggleIsDisplayed()
    if (
      !iconProperties.isDisplayed &&
      this.mapViewItemsSetupStore.selectedMapViewItemDrawnPart ===
        MapViewItemDrawnPart.Icon
    ) {
      this.mapViewItemsSetupStore.deselectMapViewItemDrawnPart()
    }
    this.addCurrentStateToHistory()
  }

  @action.bound
  private toggleIsClosedShape(): void {
    if (!this.selectedMapViewItem?.sitemapItem?.shapeCoordinates) {
      return
    }
    this.selectedMapViewItem.sitemapItem.shapeCoordinates.isClosed =
      !this.selectedMapViewItem.sitemapItem.shapeCoordinates.isClosed
  }

  @action.bound
  private toggleShapeVisibility(): void {
    const { shapeProperties } = this.itemProperties
    shapeProperties.toggleIsDisplayed()
    if (
      !shapeProperties.isDisplayed &&
      this.mapViewItemsSetupStore.selectedMapViewItemDrawnPart ===
        MapViewItemDrawnPart.Shape
    ) {
      this.mapViewItemsSetupStore.deselectMapViewItemDrawnPart()
    }
    this.addCurrentStateToHistory()
  }

  @action.bound
  private onColorChange(color: string): void {
    this.selectedMapViewItem.setAllColors(color)
    this.addCurrentStateToHistory()
  }

  private renderShapeColorPicker(): JSX.Element {
    const { isDataLess } = this.selectedMapViewItem
    const { shapeProperties } = this.itemProperties

    if (
      !shapeProperties ||
      this.isPartPropertiesHidden(MapViewItemDrawnPart.Shape)
    ) {
      return this.renderShapeSelectOnly()
    }

    if (shapeProperties.type === SitemapItemShapeType.Polyline) {
      const poly = shapeProperties as SitemapPolyLineProperties
      if (!poly.isClosed) {
        return this.renderShapeSelectOnly()
      }
    }

    const { fillColor, fillOpacity } = shapeProperties
    return (
      <>
        <div className="col pb12">
          <div className="text title uppercase pb12">
            {propertiesPanel.shape}
          </div>
          {!isDataLess && (
            <div className="row">
              <div className="no-grow">
                <Checkbox
                  isChecked={shapeProperties.isDisplayed}
                  className="properties-checkbox"
                  onClick={this.toggleShapeVisibility}
                />
              </div>
              <div className="text large pl8">{propertiesPanel.showShape}</div>
            </div>
          )}
        </div>
        <div className="pb12">
          {this.renderShapeSelect()}
          <div className="inline-block vertical-align-middle">
            <div className="inline-block vertical-align-middle pr12">
              <div className="text pb4">{propertiesPanel.shape}</div>
              <div>
                <SitemapItemsColorPicker
                  value={fillColor}
                  opacity={fillOpacity}
                  onChange={this.onFillColorChange}
                />
              </div>
            </div>
            <div className="inline-block vertical-align-middle">
              <StruxhubInput
                label={propertiesPanel.opacity}
                customRightIcon="%"
                type="number"
                isRequiredTextHidden={true}
                step={OPACITY_STEP}
                min={MIN_OPACITY_VALUE}
                max={MAX_OPACITY_VALUE}
                value={Math.round(fillOpacity * PERCENT_MODIFIER).toString()}
                onChange={this.onFillOpacityChange}
                withDelay={true}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  private renderShapeSelect(): JSX.Element {
    if (
      this.selectedMapViewItem.isDataLess ||
      this.isPartPropertiesHidden(MapViewItemDrawnPart.Shape)
    ) {
      return null
    }

    const {
      copy,
      paste,
      copiedShapeProperties: copiedShape,
      getAllowedShapesForType,
    } = this.mapViewItemsSetupStore

    const isPasteAvailable =
      copiedShape &&
      getAllowedShapesForType(
        this.selectedMapViewItem.dataObject.type,
      ).includes(copiedShape?.type)

    return (
      <div className="row">
        <div className="inline-block vertical-align-top pr12">
          <div className="text pb4">{propertiesPanel.shape}</div>
          <ShapeSelector
            item={this.selectedMapViewItem}
            store={this.mapViewItemsSetupStore}
          />
        </div>
        {this.selectedMapViewItem?.sitemapItem?.shapeCoordinates?.type ===
          SitemapItemShapeType.Polyline && this.renderIsClosedToggle()}
        {!this.props.isGlobeMode && (
          <div className="row">
            <Icon
              icon={IconNames.DUPLICATE}
              onClick={copy}
              className={classList({
                pointer: true,
                'inactive-element': !this.itemProperties.shapeProperties,
              })}
            />
            <div
              className={classList({
                'inactive-element': !this.itemProperties.shapeProperties,
                'text large pl8': true,
              })}
            >
              {Localization.translator.copy}
            </div>
            <Icon
              className={classList({
                pointer: true,
                'inactive-element': !isPasteAvailable,
              })}
              icon={IconNames.INSERT}
              onClick={paste}
            />
            <div
              className={classList({
                'inactive-element': !isPasteAvailable,
                'text large pl8': true,
              })}
            >
              {Localization.translator.paste}
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderShapeSelectOnly(): JSX.Element {
    const shapeSelect = this.renderShapeSelect()
    return (
      shapeSelect && (
        <div className="col">
          <div className="text title uppercase pb12">
            {propertiesPanel.shape}
          </div>
          <div className="pb12">{shapeSelect}</div>
        </div>
      )
    )
  }

  private renderIsClosedToggle(): JSX.Element {
    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    return (
      <div className="row">
        <div className="no-grow">
          <Checkbox
            isChecked={shapeCoordinates.isClosed}
            className="properties-checkbox"
            onClick={this.toggleIsClosedShape}
          />
        </div>
        <div className="text large pl8">{propertiesPanel.isClosed}</div>
      </div>
    )
  }

  @action.bound
  private onFillColorChange(color: string): void {
    const { shapeProperties } = this.itemProperties
    shapeProperties.setFillColor(color)
    this.addCurrentStateToHistory()
  }

  @action.bound
  private onFillOpacityChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const { shapeProperties } = this.itemProperties
    const percentOpacity = Number(event.target.value)
    const opacity =
      Math.max(Math.min(percentOpacity, 100), 0) / PERCENT_MODIFIER
    shapeProperties.setFillOpacity(opacity)
    this.addCurrentStateToHistory()
  }

  private renderBordersSection(): JSX.Element {
    const { shapeProperties } = this.itemProperties

    if (
      !shapeProperties ||
      this.isPartPropertiesHidden(MapViewItemDrawnPart.Shape)
    ) {
      return null
    }

    const { lineColor, lineWidth } = shapeProperties

    return (
      <>
        <div className="text title uppercase">{propertiesPanel.borders}</div>
        <div className="inline-block vertical-align-middle pb12">
          <div className="inline-block vertical-align-middle pr12">
            <div className="text pb4">{propertiesPanel.line}</div>
            <SitemapItemsColorPicker
              className="no-grow"
              value={lineColor}
              onChange={this.onLineColorChange}
            />
          </div>
          <div className="inline-block vertical-align-middle">
            <StruxhubInput
              label={propertiesPanel.width}
              customRightIcon="px"
              isRequiredTextHidden={true}
              type="number"
              step={LINE_WIDTH_STEP}
              min={MIN_LINE_WIDTH_VALUE}
              max={MAX_LINE_WIDTH_VALUE}
              value={lineWidth.toString()}
              onChange={this.onLineWidthChange}
              withDelay={true}
            />
          </div>
        </div>
      </>
    )
  }

  @action.bound
  private onLineColorChange(color: string): void {
    const { shapeProperties } = this.itemProperties
    shapeProperties.setLineColor(color)

    if (this.selectedMapViewItem.isDataLess) {
      this.selectedMapViewItem.setColor(color)
    }

    this.addCurrentStateToHistory()
  }

  @action.bound
  private onLineWidthChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { shapeProperties } = this.itemProperties
    const width = Number(event.target.value)
    shapeProperties.setLineWidth(width)
    this.addCurrentStateToHistory()
  }

  @action.bound
  private setDistanceUnitType(
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void {
    this.selectedCraneDistanceUnitType = DistanceUnitsTypes[event.target.value]
  }

  private renderCraneSection(): JSX.Element {
    const { shapeProperties } = this.itemProperties
    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    if (
      !shapeProperties ||
      shapeProperties.type !== SitemapItemShapeType.Circle ||
      this.isPartPropertiesHidden(MapViewItemDrawnPart.Shape)
    ) {
      return null
    }
    const circle = this.props.isGlobeMode
      ? (shapeCoordinates as CircleShapeCoordinates)
      : (shapeProperties as SitemapCircleProperties)

    return (
      <div className="properties-section pa12">
        <div className="text title uppercase pb12">{propertiesPanel.crane}</div>
        {this.props.isGlobeMode && (
          <StruxhubSelect
            label={dimensionUnits}
            value={this.selectedCraneDistanceUnitType.toString()}
            onChange={this.setDistanceUnitType}
          >
            {Object.keys(DistanceUnitsTypes).map(option => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })}
          </StruxhubSelect>
        )}
        <StruxhubInput
          label={propertiesPanel.radius}
          customRightIcon={
            this.isSelectedSitemapReferenced &&
            this.selectedCraneDistanceUnitType.toString()
          }
          isRequiredTextHidden={true}
          type="number"
          step={DESIMAL_LINE_WIDTH_STEP}
          value={this.circleRadius.toFixed(0)}
          onChange={this.onRadiusChange}
          withDelay={true}
          min={MIN_RADIUS}
          max={MAX_RADIUS}
        />
        <div className="row">
          <div className="no-grow">
            <Checkbox
              isChecked={circle.isDivided}
              className="properties-checkbox"
              onClick={this.toggleCircleIsDivided}
            />
          </div>
          <div className="text large pl8">
            {propertiesPanel.limitSwingRadius}
          </div>
        </div>
        {circle.isDivided && (
          <>
            <StruxhubInput
              label={propertiesPanel.divisionStartAngle}
              customRightIcon="°"
              isRequiredTextHidden={true}
              type="number"
              step={LINE_WIDTH_STEP}
              min={MIN_ANGLE}
              max={MAX_ANGLE}
              negativeOrDecimal={true}
              value={this.startAngle}
              onChange={this.onStartAngleChange}
              withDelay={true}
            />
            <StruxhubInput
              label={propertiesPanel.divisionEndAngle}
              customRightIcon="°"
              isRequiredTextHidden={true}
              type="number"
              step={LINE_WIDTH_STEP}
              min={MIN_ANGLE}
              max={MAX_ANGLE}
              negativeOrDecimal={true}
              value={this.endAngle}
              onChange={this.onEndAngleChange}
              withDelay={true}
            />
          </>
        )}
      </div>
    )
  }

  @computed
  private get circleRadius(): number {
    if (this.props.isGlobeMode) {
      const {
        sitemapItem: { shapeCoordinates },
      } = this.selectedMapViewItem
      if (
        shapeCoordinates.type !== SitemapItemShapeType.Circle ||
        shapeCoordinates.coordinates.length < 2
      ) {
        return 0
      }

      return (shapeCoordinates as CircleShapeCoordinates).getRadius(
        this.selectedCraneDistanceUnitType,
      )
    } else {
      return (this.itemProperties.shapeProperties as SitemapCircleProperties)
        .radius
    }
  }
  @action.bound
  private onEndAngleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { isGlobeMode } = this.props
    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    const { shapeProperties } = this.itemProperties
    const circle = shapeProperties as SitemapCircleProperties
    const circleShapeCoords = shapeCoordinates as CircleShapeCoordinates
    const angle = Number(this.verifyAngle(event.target.value))

    if (!isNaN(angle)) {
      if (isGlobeMode) {
        circleShapeCoords.divisionEndAngle = angle
      } else {
        circle.setDivisionEndAngle(angle)
      }
    }
  }

  @action.bound
  private onStartAngleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { isGlobeMode } = this.props
    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    const { shapeProperties } = this.itemProperties
    const circle = shapeProperties as SitemapCircleProperties
    const circleShapeCoords = shapeCoordinates as CircleShapeCoordinates
    const angle = Number(this.verifyAngle(event.target.value))

    if (!isNaN(angle)) {
      if (isGlobeMode) {
        circleShapeCoords.divisionStartAngle = angle
      } else {
        circle.setDivisionStartAngle(angle)
      }
    }
  }

  @action.bound
  private verifyAngle(angleStr: string): string {
    const angle = Number(angleStr)

    if (angle > MAX_ANGLE) {
      return MAX_ANGLE.toString()
    } else if (angle < MIN_ANGLE) {
      return MIN_ANGLE.toString()
    }
    return angleStr
  }

  @action.bound
  private onRadiusChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const radius = +event.target.value

    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    const newPoint = turf.destination(
      [
        shapeCoordinates.coordinates[0].longitude,
        shapeCoordinates.coordinates[0].latitude,
      ],
      radius,
      -90,
      { units: this.selectedCraneDistanceUnitType },
    ).geometry.coordinates
    shapeCoordinates.coordinates[1] = {
      latitude: newPoint[1],
      longitude: newPoint[0],
    }
  }

  @action.bound
  private toggleCircleIsDivided(): void {
    const { isGlobeMode } = this.props
    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    const { shapeProperties } = this.itemProperties
    if (isGlobeMode) {
      const circle = shapeCoordinates as CircleShapeCoordinates
      circle.isClosed = !circle.isClosed
      if (circle.isDivided) {
        circle.divisionStartAngle = 170
        circle.divisionEndAngle = 180
      } else {
        circle.divisionEndAngle = null
        circle.divisionStartAngle = null
      }
    } else {
      const circle = shapeProperties as SitemapCircleProperties
      circle.toggleIsDivided()
      this.addCurrentStateToHistory()
    }
  }

  private renderTextStyle(): JSX.Element {
    const { isDataLess, isRichTextBox } = this.selectedMapViewItem
    const { labelProperties } = this.itemProperties
    if (
      !labelProperties ||
      this.isPartPropertiesHidden(MapViewItemDrawnPart.Label)
    ) {
      return null
    }

    return (
      <div className="properties-section pa12">
        <div className="text title uppercase">{propertiesPanel.textStyle}</div>
        <div className="pb12 row y-center">
          <div className="no-grow pr12">
            <StruxhubInput
              label={propertiesPanel.fontSize}
              customRightIcon="px"
              isRequiredTextHidden={true}
              type="number"
              step={FONT_STEP}
              min={MIN_FONT_VALUE}
              max={MAX_FONT_VALUE}
              value={labelProperties.fontSize.toString()}
              onChange={this.onFontSizeChange}
              withDelay={true}
            />
          </div>

          <div className="row pb8">
            <div className="no-grow">
              <Checkbox
                isChecked={labelProperties.isTextBoxDisplayed}
                className="properties-checkbox"
                onClick={this.toggleTextBoxVisibility}
                isDisabled={!labelProperties.isDisplayed}
              />
            </div>
            <div className="text large pl8">{propertiesPanel.showTextBox}</div>
          </div>
          {!isDataLess && (
            <div className="row pb8">
              <div className="no-grow">
                <Checkbox
                  isChecked={labelProperties.isDisplayed}
                  className="properties-checkbox"
                  onClick={this.toggleLabelVisibility}
                />
              </div>
              <div className="text large pl8">{propertiesPanel.showLabel}</div>
            </div>
          )}
        </div>
        {isRichTextBox && (
          <div className="pb12 row y-end">
            <div className="no-grow pr12">
              <div className="text pb4">{propertiesPanel.fontColor}</div>
              <div>
                <SitemapItemsColorPicker
                  value={labelProperties.color}
                  onChange={this.onTextColorChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  @action.bound
  private onTextColorChange(color: string): void {
    this.itemProperties.labelProperties.color = color
    this.addCurrentStateToHistory()
  }

  @action.bound
  private onFontSizeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { labelProperties } = this.itemProperties
    labelProperties.setFontSize(Number(event.target.value))
    this.addCurrentStateToHistory()
  }

  @action.bound
  private toggleTextBoxVisibility(): void {
    const { labelProperties } = this.itemProperties
    labelProperties.toggleTextBoxVisibility()
    this.addCurrentStateToHistory()
  }

  @action.bound
  private toggleLabelVisibility(): void {
    const { labelProperties } = this.itemProperties
    labelProperties.toggleIsDisplayed()
    if (
      !labelProperties.isDisplayed &&
      this.mapViewItemsSetupStore.selectedMapViewItemDrawnPart ===
        MapViewItemDrawnPart.Label
    ) {
      this.mapViewItemsSetupStore.deselectMapViewItemDrawnPart()
    }
    this.addCurrentStateToHistory()
  }

  private renderLineStyle(): JSX.Element {
    const { isGlobeMode } = this.props
    const {
      sitemapItem: { shapeCoordinates },
    } = this.selectedMapViewItem
    const { shapeProperties } = this.itemProperties

    if (
      !shapeProperties ||
      shapeProperties.type !== SitemapItemShapeType.Polyline ||
      this.isPartPropertiesHidden(MapViewItemDrawnPart.Shape)
    ) {
      return null
    }

    const poly = shapeProperties as SitemapPolyLineProperties

    if (
      (isGlobeMode && shapeCoordinates?.isClosed) ||
      (!isGlobeMode && poly?.isClosed)
    ) {
      return null
    }

    const options = isGlobeMode
      ? this.lineArrowGlobePositionOptions
      : this.lineArrowPositionOptions
    const value = options.map(o => o.value).includes(poly.arrowPosition)
      ? poly.arrowPosition
      : SitemapLineArrowPosition.None

    return (
      <div className="properties-section pa12">
        <div className="text title uppercase pb12">
          {propertiesPanel.lineStyle}
        </div>
        <div>
          <div className="text pb4">{value}</div>
          <div>
            <PropertySelect
              options={options}
              value={poly.arrowPosition}
              onChange={this.onLineArrowPositionChange}
            />
          </div>
        </div>
      </div>
    )
  }

  @computed
  private get startAngle(): string {
    const { isGlobeMode } = this.props
    if (!this.itemProperties?.shapeProperties) {
      return
    }
    const circle = this.itemProperties
      .shapeProperties as SitemapCircleProperties

    const circleShapeCoords = this.selectedMapViewItem.sitemapItem
      .shapeCoordinates as CircleShapeCoordinates

    return Math.round(
      isGlobeMode
        ? circleShapeCoords.divisionStartAngle
        : circle.divisionStartAngle,
    ).toString()
  }

  @computed
  private get endAngle(): string {
    const { isGlobeMode } = this.props
    if (!this.itemProperties?.shapeProperties) {
      return
    }
    const circle = this.itemProperties
      .shapeProperties as SitemapCircleProperties

    const circleShapeCoords = this.selectedMapViewItem.sitemapItem
      .shapeCoordinates as CircleShapeCoordinates

    return Math.round(
      isGlobeMode
        ? circleShapeCoords.divisionEndAngle
        : circle.divisionEndAngle,
    ).toString()
  }

  @action.bound
  private onLineArrowPositionChange(value: SitemapLineArrowPosition): void {
    const { shapeProperties } = this.itemProperties
    const poly = shapeProperties as SitemapPolyLineProperties
    poly.setArrowPosition(value)
    this.addCurrentStateToHistory()
  }

  private isPartPropertiesHidden(part: MapViewItemDrawnPart): boolean {
    const selectedPart =
      this.mapViewItemsSetupStore.selectedMapViewItemDrawnPart
    return selectedPart && selectedPart !== part
  }

  @action.bound
  private addCurrentStateToHistory(handleBlur?: () => void): void {
    handleBlur?.()
    this.mapViewItemsSetupStore.addCurrentStateToHistory()
  }

  private renderBuildingsProperties(): JSX.Element {
    const { dataObject } = this.selectedMapViewItem
    if (!dataObject || dataObject.type !== LocationType.Building) {
      return null
    }

    const building = dataObject as Building

    return (
      <BuildingProperties
        building={building}
        store={this.mapViewItemsSetupStore}
        setLoading={this.setLoading}
      />
    )
  }

  private renderObjectAccessibleLevelsProperties(): JSX.Element {
    const { dataObject } = this.selectedMapViewItem

    let sitemapObject: VerticalObject | OffloadingEquipment = null
    switch (dataObject?.type) {
      case LocationType.VerticalObject:
        sitemapObject = dataObject as VerticalObject
        break
      case LocationType.OffloadingEquipment:
        sitemapObject = dataObject as OffloadingEquipment
        break
    }

    if (!sitemapObject) {
      return null
    }

    return (
      <ObjectAccessibleLevelsProperties
        className="relative"
        dataObject={sitemapObject}
      />
    )
  }

  @action.bound
  private setLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @computed
  public get shouldShowLoading(): boolean {
    return (
      this.selectedMapViewItem &&
      (this.isLoading || this.mapViewItemsSetupStore.isSitemapUpdating)
    )
  }

  private get sitemapsSetupStore(): SitemapsSetupStore {
    return this.props.store.sitemapsSetupStore
  }

  private get mapViewItemsSetupStore(): MapViewItemsSetupStore {
    return this.props.store.mapViewItemsSetupStore
  }

  private get selectedMapViewItem(): MapViewItemBase {
    return this.mapViewItemsSetupStore.selectedMapViewItem
  }

  private get currentMapOrientation(): string {
    return this.props.store.mapBoxViewerStore.viewport.bearing.toFixed(2)
  }

  private get isSelectedSitemapReferenced(): boolean {
    return this.sitemapsSetupStore.selectedSitemap?.isReferenced
  }
}
