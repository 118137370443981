import { LocationType } from '~/client/graph'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'

export interface ILocationListObject {
  Icon: React.FC<any>
  getLabel?: () => string
  dataObjectType?: LocationType
  iconName?: MapViewLocationIcon
  sitemapItemType?: MapViewItemType
  children?: ILocationListObject[]
}

const textSticker = 'Text sticker'
const drawingSticker = 'Drawing sticker'
const gateEntry = 'Gate/Entry'

export const locationObjects: ILocationListObject[][] = [
  [
    {
      Icon: TagIcon.Building,
      dataObjectType: LocationType.Building,
      getLabel: () => Localization.translator.building,
    },
    {
      Icon: TagIcon.Zone,
      dataObjectType: LocationType.Zone,
      getLabel: () => Localization.translator.zone,
    },
    {
      Icon: TagIcon.Staging,
      dataObjectType: LocationType.Staging,
      getLabel: () => Localization.translator.staging,
    },
    {
      Icon: TagIcon.Area,
      dataObjectType: LocationType.Area,
      getLabel: () => Localization.translator.workArea,
    },
  ],
  [
    {
      Icon: TagIcon.Stairs,
      dataObjectType: LocationType.VerticalObject,
      iconName: MapViewLocationIcon.Stairs,
      getLabel: () => Localization.translator.verticalObject,
      children: [
        {
          Icon: TagIcon.Stairs,
          dataObjectType: LocationType.VerticalObject,
          iconName: MapViewLocationIcon.Stairs,
          getLabel: () => Localization.translator.stairway,
        },
        {
          Icon: TagIcon.Elevator,
          dataObjectType: LocationType.VerticalObject,
          iconName: MapViewLocationIcon.Elevator,
          getLabel: () => Localization.translator.elevator,
        },
        {
          Icon: TagIcon.Shaft,
          dataObjectType: LocationType.VerticalObject,
          iconName: MapViewLocationIcon.Shaft,
          getLabel: () => Localization.translator.shaft,
        },
      ],
    },
  ],
  [
    {
      Icon: TagIcon.Route,
      dataObjectType: LocationType.Route,
      getLabel: () => Localization.translator.route,
    },
    {
      Icon: TagIcon.Gate,
      dataObjectType: LocationType.Gate,
      getLabel: () => gateEntry,
    },
    {
      Icon: TagIcon.InteriorPath,
      dataObjectType: LocationType.InteriorPath,
      getLabel: () => Localization.translator.interiorPath,
    },
    {
      Icon: TagIcon.InteriorDoor,
      dataObjectType: LocationType.InteriorDoor,
      getLabel: () => Localization.translator.interiorDoor,
    },
  ],
  [
    {
      Icon: TagIcon.Equipment,
      dataObjectType: LocationType.OffloadingEquipment,
      iconName: MapViewLocationIcon.Equipment,
      getLabel: () => Localization.translator.sharedResources,
      children: [
        {
          Icon: TagIcon.Crane,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.Crane,
          getLabel: () => Localization.translator.crane,
        },
        {
          Icon: TagIcon.Hoist,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.Hoist,
          getLabel: () => Localization.translator.hoist,
        },
        {
          Icon: TagIcon.AerialLift,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.AerialLift,
          getLabel: () => Localization.translator.aerialLift,
        },
        {
          Icon: TagIcon.Gradall,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.Gradall,
          getLabel: () => Localization.translator.gradall,
        },
        {
          Icon: TagIcon.ForkLift,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.ForkLift,
          getLabel: () => Localization.translator.forkLift,
        },
      ],
    },
    {
      Icon: TagIcon.LogisticsObject,
      dataObjectType: LocationType.LogisticsObject,
      iconName: MapViewLocationIcon.Logistics,
      getLabel: () => Localization.translator.informationPoints,
      children: [
        {
          Icon: TagIcon.Bathroom,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Bathroom,
          getLabel: () => Localization.translator.restrooms,
        },
        {
          Icon: TagIcon.Break,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Break,
          getLabel: () => Localization.translator.breakText,
        },
        {
          Icon: TagIcon.Canteen,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Canteen,
          getLabel: () => Localization.translator.canteen,
        },
        {
          Icon: TagIcon.Dumpster,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Dumpster,
          getLabel: () => Localization.translator.dumpster,
        },
        {
          Icon: TagIcon.Entrance,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Entrance,
          getLabel: () => Localization.translator.access,
        },
        {
          Icon: TagIcon.HandWash,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.HandWash,
          getLabel: () => Localization.translator.washArea,
        },
        {
          Icon: TagIcon.Medical,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Medical,
          getLabel: () => Localization.translator.medicalArea,
        },
        {
          Icon: TagIcon.MeetingPoint,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.MeetingPoint,
          getLabel: () => Localization.translator.musterPoint,
        },
        {
          Icon: TagIcon.Parking,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Parking,
          getLabel: () => Localization.translator.parking,
        },
        {
          Icon: TagIcon.Smoking,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Smoking,
          getLabel: () => Localization.translator.smokingArea,
        },
        {
          Icon: TagIcon.Temperature,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Temperature,
          getLabel: () => Localization.translator.covidCheckpoint,
        },
        {
          Icon: TagIcon.Tent,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Tent,
          getLabel: () => Localization.translator.breakArea,
        },
        {
          Icon: TagIcon.Toilet,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Toilet,
          getLabel: () => Localization.translator.toilet,
        },
        {
          Icon: TagIcon.Walkway,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Walkway,
          getLabel: () => Localization.translator.walkway,
        },
        {
          Icon: TagIcon.ElectricalRoom,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.ElectricalRoom,
          getLabel: () => Localization.translator.electricalRoom,
        },
        {
          Icon: TagIcon.Trailer,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Trailer,
          getLabel: () => Localization.translator.trailer,
        },
      ],
    },
  ],
  [
    {
      Icon: TagIcon.Text,
      sitemapItemType: MapViewItemType.TextBox,
      getLabel: () => textSticker,
    },
    {
      Icon: TagIcon.Line,
      sitemapItemType: MapViewItemType.Line,
      getLabel: () => drawingSticker,
    },
  ],
]
