import { action, computed, observable } from 'mobx'

import { IAnalyticSettingsField } from '~/client/graph'
import AnalyticsSettingsStore from '~/client/src/desktop/stores/domain/AnalyticsSettings.store'
import AnalyticsReportsType from '~/client/src/shared/enums/AnalyticReportsType'
import Delivery from '~/client/src/shared/models/Delivery'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import SitePermit from '~/client/src/shared/models/Permit'
import {
  ACTIVATE_PROJECT,
  LOAD_ANALYTICS_SETTING,
  LOAD_AND_LISTEN_TO_COMPANIES,
  LOAD_AND_LISTEN_TO_FORM_CATEGORIES,
  LOAD_AND_LISTEN_TO_PERMIT_TYPES,
  LOAD_AND_LISTEN_TO_SCANNERS,
  LOAD_AND_LISTEN_TO_SCAN_HISTORIES,
  LOAD_AND_LISTEN_TO_USER_PROJECTS,
  LOAD_REPORT_TEMPLATE,
  RESET_ALL_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveryStore from '~/client/src/shared/stores/domain/Deliveries.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import SitePermitStore from '~/client/src/shared/stores/domain/SitePermits.store'
import ProjectDateStore, {
  areIntervalTimesIntersects,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import { IDateFilters } from '../../components/Filters/DateSelector/DateSelector.store'
import { TwoMonthsDatePickerMode } from '../../components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'

export default class ReportsStore {
  @observable public isScanStationDialogDisplayed: boolean = false
  @observable public isDeliveryReportDialogDisplayed: boolean = false
  @observable public isFormReportDialogDisplayed: boolean = false
  @observable public selectedScanStation: IScannerHistoryPair = null
  @observable public selectedFormReport: SitePermit = null
  @observable public selectedDeliveryReport: Delivery = null
  @observable public activeReportView: IAnalyticSettingsField = null
  @observable public dateFilters: IDateFilters = {
    startDate: null,
    daysToAdd: 0,
    isDateFilterActive: true,
  }
  @observable public selectedDatePickerMode: TwoMonthsDatePickerMode =
    TwoMonthsDatePickerMode.ONE_DAY

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly scanHistoriesStore: ScanHistoriesStore,
    private readonly sitePermitStore: SitePermitStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly analyticsSettingsStore: AnalyticsSettingsStore,
    private readonly deliveryStore: DeliveryStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {}

  @action.bound
  public setDefaultReportView() {
    this.activeReportView = this.dropdownFields?.[0]
  }

  @action.bound
  public activeReportViewChange(view: IAnalyticSettingsField) {
    this.activeReportView = view

    Promise.resolve().then(() => {
      this.eventsStore.dispatch(RESET_ALL_FILTERS)
    })
  }

  public loadReportTemplateFiles = (templateId: string) => {
    this.eventsStore.dispatch(LOAD_REPORT_TEMPLATE, templateId)
  }

  @action.bound
  public changeDatePickerMode(mode: TwoMonthsDatePickerMode): void {
    this.selectedDatePickerMode = mode
  }

  public get isDateFilterActive(): boolean {
    return this.dateFilters.isDateFilterActive
  }

  public get startDate(): Date {
    return this.isDateFilterActive
      ? this.dateFilters.startDate
      : this.projectDateStore.getMinAvailableDate()
  }

  public get endDate(): Date {
    return this.isDateFilterActive
      ? this.projectDateStore.getEndDate(this.dateFilters)
      : this.projectDateStore.getMaxAvailableDate()
  }

  private get availableReportFields(): string[] {
    return [
      ...this.permitTypesStore.actualTypes.map(type => type.id),
      AnalyticsReportsType.SCAN_STATION_REPORT,
      AnalyticsReportsType.DELIVERY_REPORT,
    ]
  }

  public get dropdownFields(): IAnalyticSettingsField[] {
    return (
      this.analyticsSettingsStore.mySettingFields.filter(
        field =>
          this.availableReportFields.includes(
            field.type === AnalyticsReportsType.FORM_REPORT
              ? field.id
              : field.type,
          ) && field.isEnabled,
      ) || []
    )
  }

  public areDatesWithinSelectedRange = (
    startDate: Date | number,
    endDate: Date | number,
    selectedStartDate: Date | number,
    selectedEndDate: Date | number,
  ): boolean => {
    const { startOfDay, endOfDay } = this.projectDateStore

    const selectedStart = startOfDay(selectedStartDate)
    const selectedEnd = endOfDay(selectedEndDate)

    return areIntervalTimesIntersects(
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      },
      {
        startDate: selectedStart,
        endDate: selectedEnd,
      },
    )
  }

  public get allScanStationHistory(): IScannerHistoryPair[] {
    return this.scanHistoriesStore.historiesByScannerPairs
  }

  public get scanStationHistoriesInPeriodInterval(): IScannerHistoryPair[] {
    return this.scanHistoriesStore.historiesByScannerPairs.filter(pair =>
      this.areDatesWithinSelectedRange(
        this.startDate,
        this.endDate,
        pair.history.date,
        pair.history.endDate || pair.history.date,
      ),
    )
  }

  public get formReportPermitsInPeriodInterval(): SitePermit[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allFormReportPermits.filter(form =>
      form.isScheduledWithinRange(startDate, endDate),
    )
  }

  public get deliveriesInPeriodInterval(): Delivery[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allDeliveries.filter(delivery =>
      delivery.isScheduledWithinRange(startDate, endDate),
    )
  }

  public get allDeliveries(): Delivery[] {
    return this.deliveryStore.availableDeliveries
  }

  @computed
  public get allFormReportPermits(): SitePermit[] {
    const activePermitType = this.permitTypesStore.actualTypes.find(
      t => t.id === this.activeReportView.id,
    )

    if (!activePermitType) {
      return []
    }

    return this.sitePermitStore.list.filter(
      form =>
        form.getTypeOfPermitType(this.permitTypesStore) ===
        activePermitType.type,
    )
  }

  public get isDataPrepared() {
    return !this.isLoading && this.isDataReceived
  }

  public get isScanStation() {
    return (
      this.activeReportView?.type === AnalyticsReportsType.SCAN_STATION_REPORT
    )
  }

  public get isFormReport() {
    return this.activeReportView?.type === AnalyticsReportsType.FORM_REPORT
  }

  public get isDeliveryReport() {
    return this.activeReportView?.type === AnalyticsReportsType.DELIVERY_REPORT
  }

  private get isLoading() {
    const { loading } = this.eventsStore.appState

    return (
      loading.get(ACTIVATE_PROJECT) ||
      loading.get(LOAD_AND_LISTEN_TO_SCAN_HISTORIES) ||
      loading.get(LOAD_AND_LISTEN_TO_SCANNERS) ||
      loading.get(LOAD_AND_LISTEN_TO_COMPANIES) ||
      loading.get(LOAD_AND_LISTEN_TO_USER_PROJECTS) ||
      loading.get(LOAD_AND_LISTEN_TO_PERMIT_TYPES) ||
      loading.get(LOAD_AND_LISTEN_TO_FORM_CATEGORIES) ||
      loading.get(LOAD_ANALYTICS_SETTING)
    )
  }

  private get isDataReceived() {
    const stores = [
      this.scanHistoriesStore,
      this.sitePermitStore,
      this.permitTypesStore,
      this.analyticsSettingsStore,
      this.deliveryStore,
    ]
    return stores.every(s => s.isDataReceived)
  }

  @action.bound
  public showScanStationReports(report: IScannerHistoryPair) {
    this.isScanStationDialogDisplayed = true
    this.selectedScanStation = report
  }

  @action.bound
  public closeScanStationReports() {
    this.isScanStationDialogDisplayed = false
    this.selectedScanStation = null
  }

  @action.bound
  public showFormReports(report: SitePermit) {
    this.isFormReportDialogDisplayed = true
    this.selectedFormReport = report.copy()
  }

  @action.bound
  public closeFormReports() {
    this.isFormReportDialogDisplayed = false
    this.selectedFormReport = null
  }
  @action.bound
  public showDeliveryReports(delivery: Delivery) {
    this.isDeliveryReportDialogDisplayed = true
    this.selectedDeliveryReport = delivery
  }
  @action.bound
  public closeDeliveryReports() {
    this.isDeliveryReportDialogDisplayed = false
    this.selectedDeliveryReport = null
  }
}
