import { action, computed } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  FormTypeFilterType,
  getFormTypeFilterTypeCaption,
} from '~/client/src/shared/enums/FormTypeFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import PermitType from '~/client/src/shared/models/PermitType'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import SuperFilterStore, {
  IOption,
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { NO_SPECIFIED } from '~/client/src/shared/utils/usefulStrings'

import FormTypesListStore from '../../../../FormTypesList.store'

export type ISourceMap = { [optionId: string]: string[] }

const unassignedValues = [UNASSIGNED, NO_SPECIFIED]

export default class BaseFormTypesFilterStore extends SuperFilterStore {
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public get totalHint(): string {
    return Localization.translator.total
  }

  public get isLoading(): boolean {
    return false
  }

  @computed
  public get isActive(): boolean {
    return !this.isLoading && this.areOptionsSelected
  }

  public get isHandleButtonActive(): boolean {
    return this.areOptionsSelected
  }

  public constructor(
    public readonly type: FormTypeFilterType,
    protected readonly state: DesktopInitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly formTypesListStore: FormTypesListStore,
    protected readonly onShowChanged: (isShown: boolean, type: string) => void,
    protected readonly fieldsMap: { [filterType: string]: UIFilterInfo },
    private readonly getOptionName?: (
      optionId: string,
      filterType: string,
    ) => string,
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXForms,
      getFormTypeFilterTypeCaption,
      onShowChanged,
      false,
      onClickHandler,
    )

    Guard.requireAll({
      type,
      state,
      sourceMap,
      formTypesListStore,
    })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event
    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)
    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options
          .map(option => ({
            id: option,
            name: this.getOptionName?.(option, this.type) || option,
            instancesIds: this.sourceMap[option],
          }))
          .sort(this.sortFilterOptions),
      },
    ]
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
  }

  @action
  public setInitialFilterValues() {
    if (!this.filter) {
      return
    }

    this.clickOnDeselectAll()
    this.clickOnApply()
  }

  public filterOptionInstances = (ids: string[]) => {
    return (this.allFilteredInstances as Array<PermitType>).filter(c =>
      ids.includes(c.id),
    )
  }

  @computed
  private get allFilteredInstances(): PermitType[] {
    const { getFilteredCollectionExcludeFilter } = this.formTypesListStore
    return getFilteredCollectionExcludeFilter([this.type])
  }

  private get areOptionsSelected(): boolean {
    return (
      this.selectedOptions.size &&
      this.allOptionIds.some(optionId => !this.selectedOptions.get(optionId))
    )
  }

  private sortFilterOptions = (a: IOption, b: IOption) => {
    if (unassignedValues.includes(a.name)) {
      return 1
    }
    if (unassignedValues.includes(b.name)) {
      return -1
    }

    return this.collator.compare(a.name, b.name)
  }
}
