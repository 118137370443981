import React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import EDITABLE_LABEL_ID from '~/client/src/shared/constants/editableLabel'
import { NOOP } from '~/client/src/shared/utils/noop'

interface IEditableLabelProps {
  text: string
  update: (newText: string) => void
  type?: string
  min?: number
  className?: string
  remove?: () => void
  inactiveHolderClassName?: string
  shouldRenderEditIcon?: boolean
  shouldBlockTextClick?: boolean
  isIconLeftSided?: boolean
  onChangeText?: (newText: string) => void
}

const defaultClassName =
  'editable-label text my20 large no-grow w-fit-content px10 brada4 row y-center'
@observer
export default class EditableLabel<
  T extends IEditableLabelProps,
> extends React.Component<T> {
  @observable public isEditing: boolean = false
  @observable protected draftText: string

  public render() {
    return this.isEditing ? this.renderActiveMode() : this.renderInactiveMode()
  }

  public triggerEditMode = event => {
    this.enterEditMode(event)
  }

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.exitEditMode()
    }
  }

  protected renderActiveMode() {
    const {
      type = 'text',
      min,
      shouldRenderEditIcon,
      isIconLeftSided,
    } = this.props

    return (
      <>
        {shouldRenderEditIcon && isIconLeftSided && (
          <Icons.EditFilled
            className="pl10 edit-text-icon"
            onClick={this.enterEditMode}
          />
        )}
        <StruxhubInput
          id={EDITABLE_LABEL_ID}
          type={type}
          min={min}
          onBlur={this.exitEditMode}
          onKeyDown={this.onKeyDown}
          value={this.draftText}
          onChange={this.updateDraftText}
          isMinimalisticMode={true}
          autoFocus={true}
        />
        {shouldRenderEditIcon && !isIconLeftSided && (
          <Icons.EditInactive
            className="ml10 no-grow"
            onClick={this.enterEditMode}
          />
        )}
      </>
    )
  }

  protected renderInactiveMode() {
    const {
      text,
      className,
      remove,
      shouldRenderEditIcon,
      shouldBlockTextClick,
      isIconLeftSided,
      inactiveHolderClassName,
    } = this.props

    return (
      <div
        className={classList({
          'editable-label-holder text-ellipsis row': true,
          [inactiveHolderClassName]: !!inactiveHolderClassName,
        })}
      >
        {shouldRenderEditIcon && isIconLeftSided && (
          <Icons.EditFilled
            className="pl10 no-grow"
            onClick={this.enterEditMode}
          />
        )}
        <span
          className={className || defaultClassName}
          onClick={!shouldBlockTextClick ? this.enterEditMode : NOOP}
        >
          {this.draftText || text}
        </span>
        {shouldRenderEditIcon && !isIconLeftSided && (
          <Icons.EditFilled className="pl10" onClick={this.enterEditMode} />
        )}
        {remove && <Icons.Delete onClick={remove} className="delete-icon" />}
      </div>
    )
  }

  @action.bound
  protected updateDraftText(event: React.SyntheticEvent<HTMLInputElement>) {
    const { onChangeText } = this.props
    this.draftText = event.currentTarget.value
    if (onChangeText) {
      onChangeText(this.draftText)
    }
  }

  @action.bound
  protected enterEditMode(event) {
    event?.stopPropagation()
    this.draftText = this.props.text
    this.isEditing = true
  }

  @action.bound
  protected exitEditMode() {
    const { text, update } = this.props
    const newText = this.draftText.trim()
    if (newText && newText !== text) {
      update(this.draftText)
    }

    this.draftText = ''
    this.isEditing = false
  }
}
