import * as turf from '@turf/turf'
import { GeoJSONFeature, LngLat, MapMouseEvent } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'

import {
  IGeoJson2DGeographicCoordinates,
  SitemapItemShapeType,
} from '~/client/graph'
import {
  SCALED_IMAGE_PIXEL_RATE,
  SCALED_IMAGE_QUALITY,
} from '~/client/src/shared/components/BaseMapView/BaseMapView'
import MapBoxViewerStore, {
  ISitemapWithBasemap,
} from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import {
  ITEM_EDITOR_SHAPE_FILL_LAYER,
  POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER,
  SHAPE_ARROW_LINE_LAYER,
  SHAPE_EDITABLE_POINTS_LAYER,
  SHAPE_FILL_LAYER,
  SHAPE_LINE_LAYER,
  SHAPE_SELECTED_FILL_LAYER,
  SHAPE_SELECTED_LINE_LAYER,
  SHAPE_SELECTED_POINTS_LAYER,
  TILESET_CIRCLE_LAYER_PREFIX,
  TILESET_FILL_LAYER_PREFIX,
  TILESET_LINE_LAYER_PREFIX,
  TILESET_SYMBOL_LAYER_PREFIX,
  UNDER_PLAN_FILL_LAYER,
} from '~/client/src/shared/components/MapBoxViewer/mapboxConstants'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import Keys from '~/client/src/shared/enums/Keys'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import GlobeView from '~/client/src/shared/models/GlobeView'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

import MapViewItemsSetupStore from './MapViewItemsSetup.store'
import SitemapsSetupStore from './SitemapsSetup.store'
import TilesetsSetupStore from './TilesetsSetup.store'

const DEGREE_IN_RADIANS = Math.PI / 180
const CONSTRAIN_ANGLE_STEP = 45
const RUBBER_MODE_OPACITY = 50

const CROSSHAIR_CURSOR = 'url(/static/cursors/draw.png), crosshair'
const ADD_POINT_CURSOR = 'url(/static/cursors/add-point.png), copy'
const CLOSE_SHAPE_CURSOR = 'url(/static/cursors/close-shape.png), auto'
const CURSOR_NE_ROTATE = 'url(/static/cursors/ne-rotate.png) 16 16, auto'
const CURSOR_NW_ROTATE = 'url(/static/cursors/nw-rotate.png) 16 16, auto'
const CURSOR_SE_ROTATE = 'url(/static/cursors/se-rotate.png) 16 16, auto'
const CURSOR_SW_ROTATE = 'url(/static/cursors/sw-rotate.png) 16 16, auto'

const SHOW_PLAN_STORAGE_KEY = 'show-plan-key'

export enum ViewType {
  Objects = 'Objects',
  Plans = 'Plans',
}

export default class MapBoxEditorStore {
  @observable public selectedViewType: ViewType = ViewType.Objects
  @observable public isEditableItemInDragMode: boolean = false

  //map evens props
  @observable public selectedShapePointIdx: number = null
  public draggableShapePointIdx: number = null
  public draggableBoundingBoxPointIdx: number = null

  private lastRotationCoords: LngLat = null
  private pressedKey: Keys = null
  private isRotateMode: boolean = false
  public selectedMapViewItemOnStartMoving: MapViewItemBase = null
  public mouseDownCoordinates: LngLat = null

  @observable public opacity: number = 0
  // visibility on map during rubber sheeting
  @observable public leftPanelVisibility: {
    plans: {
      [attrId: string]: boolean
    }
    items: {
      [attrId: string]: boolean
    }
    tilesets: {
      [attrId: string]: boolean
    }
  } = { plans: {}, items: {}, tilesets: {} }

  @observable public isDraggingMode: boolean = false
  @observable public isRubberMode: boolean = false

  public constructor(
    private readonly mapBoxViewerStore: MapBoxViewerStore,
    private readonly mapViewItemsSetupStore: MapViewItemsSetupStore,
    private readonly sitemapsStore: SitemapsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    public readonly globeViewSetupStore: GlobeViewSetupStore,
    public readonly basemapsStore: BasemapsStore,
    public readonly sitemapsSetupStore: SitemapsSetupStore,
    public readonly tilesetsSetupStore: TilesetsSetupStore,
  ) {
    this.setDefaultValues()
  }

  @action.bound
  public onViewTypeSelect(viewType: ViewType): void {
    this.selectedViewType = viewType
  }

  @action.bound
  public selectMapViewItem(item: MapViewItemBase): void {
    this.sitemapsSetupStore.deselectSitemap()
    this.tilesetsSetupStore.deselectTileset()
    this.mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
    this.mapViewItemsSetupStore.selectMapViewItem(item)
    this.onViewTypeSelect(ViewType.Objects)
  }

  @action.bound
  public selectSitemapById(sitemapId: string): void {
    this.mapViewItemsSetupStore.safelyDeselectMapViewItem()
    this.sitemapsSetupStore.selectSitemapById(sitemapId)
    this.onViewTypeSelect(ViewType.Plans)
  }

  public isSitemapItemSelected(item: MapViewItemBase): boolean {
    return this.mapViewItemsSetupStore.isMapViewItemSelected(item)
  }

  public get selectedGlobe(): GlobeView {
    return this.globeViewSetupStore.selectedGlobeView
  }

  @action.bound
  public setDefaultValues(): void {
    this.opacity = Number(localStorage.getItem(SHOW_PLAN_STORAGE_KEY))
  }

  @action.bound
  public toggleItemVisibility(itemId: string): void {
    this.leftPanelVisibility.items[itemId] =
      !this.leftPanelVisibility.items[itemId]
  }

  @action.bound
  public togglePlanVisibility(itemId: string): void {
    this.leftPanelVisibility.plans[itemId] =
      !this.leftPanelVisibility.plans[itemId]
  }

  @action.bound
  public toggleTilesetVisibility(itemId: string): void {
    this.leftPanelVisibility.tilesets[itemId] =
      !this.leftPanelVisibility.tilesets[itemId]
  }

  @action.bound
  public async createMapImage(): Promise<string> {
    return this.mapBoxViewerStore.createMapImage()
  }

  @action.bound
  public setOpacity(opacity: number): void {
    this.opacity = opacity
  }

  @action.bound
  public updateOpacityBySetupStep(): void {
    this.opacity = this.isDraggingMode
      ? RUBBER_MODE_OPACITY
      : Number(localStorage.getItem(SHOW_PLAN_STORAGE_KEY))
  }

  @action.bound
  public toggleOpacity(): void {
    this.opacity = this.isMapboxHidden ? 0 : 100
    localStorage.setItem(SHOW_PLAN_STORAGE_KEY, this.opacity.toString())
  }

  public get isMapboxHidden(): boolean {
    return this.opacity === 100
  }

  @action.bound
  public setLeftVisibility(): void {
    this.leftPanelVisibility = { plans: {}, items: {}, tilesets: {} }
    this.selectedGlobe.items.forEach(item => {
      this.leftPanelVisibility[item.sitemapItemId] = true
    })
  }

  public getDataURL(): string {
    if (!this.mapBoxViewerStore.mapRef) {
      return null
    }

    const stage = this.mapBoxViewerStore.mapRef.getMap().getCanvas()
    const dataUrlConfig = {
      mimeType: 'image/jpeg',
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width: stage.width,
      height: stage.height,
      x: stage.offsetLeft,
      y: stage.offsetTop,
    }
    return stage.toDataURL(Object.assign(dataUrlConfig))
  }

  @computed
  public get displayedGlobeItems(): MapViewItemBase[] {
    if (this.isRubberMode) {
      return this.mapBoxViewerStore.displayedGlobeViewItems.filter(
        item => this.leftPanelVisibility.items[item.id],
      )
    }
    return this.mapBoxViewerStore.displayedGlobeViewItems.filter(
      item => !this.isSitemapItemSelected(item),
    )
  }

  @computed
  public get displayedSitemapWithBasemaps(): ISitemapWithBasemap[] {
    if (this.isRubberMode) {
      return this.sitemapsStore.plans
        .filter(p => !!this.leftPanelVisibility.plans[p.id])
        .map(p => ({
          sitemap: p,
          basemap: this.basemapsStore.byId.get(p.basemapId),
        }))
    }
    return this.mapBoxViewerStore.sitemapWithBasemapsOnGlobe
  }

  @action.bound
  public onMapClick(e: MapMouseEvent): void {
    this.mapBoxViewerStore.clickCoords = e.lngLat
    this.selectedShapePointIdx = null
    this.mapBoxViewerStore.selectedMarkerItemId = null
    this.mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
    const selectedMapViewItem = this.mapViewItemsSetupStore.selectedMapViewItem
    const shapeFeature = e.features
      ?.filter(f =>
        [
          SHAPE_FILL_LAYER,
          SHAPE_LINE_LAYER,
          SHAPE_SELECTED_FILL_LAYER,
        ].includes(f?.layer?.id),
      )
      .at(-1)
    const shapeEditablePointsFeature = e.features?.find(
      f => f?.layer?.id === SHAPE_EDITABLE_POINTS_LAYER,
    )
    const itemEditorShapeFeature = e.features?.find(
      f => f?.layer?.id === ITEM_EDITOR_SHAPE_FILL_LAYER,
    )
    const polylineAdditionalEditablePointFeature = e.features?.find(
      f => f?.layer?.id === POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER,
    )
    const sitemapFeature = e.features?.find(
      f => f?.layer?.id === UNDER_PLAN_FILL_LAYER,
    )
    let tilesetShapeFeatures = e.features
      ?.filter(
        f =>
          f?.layer?.id.includes(TILESET_FILL_LAYER_PREFIX) ||
          f?.layer?.id.includes(TILESET_LINE_LAYER_PREFIX) ||
          f?.layer?.id.includes(TILESET_CIRCLE_LAYER_PREFIX) ||
          f?.layer?.id.includes(TILESET_SYMBOL_LAYER_PREFIX),
      )
      .filter((v, i, a) => a.findIndex(f => f.id === v.id) === i)
    // Because the mapbox divides the features tileset into parts, we only have a part of the geometry
    if (tilesetShapeFeatures?.length) {
      // Here we get the full features with all the geometry
      tilesetShapeFeatures = tilesetShapeFeatures.map(f =>
        f.geometry.type === 'Polygon' ? this.getTilesetFeature(f) : f,
      )
    }
    switch (true) {
      case this.isEditingPolyline &&
        !!shapeEditablePointsFeature &&
        shapeEditablePointsFeature.properties.idx === 0 &&
        !selectedMapViewItem.sitemapItem.shapeCoordinates.isClosed:
        selectedMapViewItem.sitemapItem.shapeCoordinates.isClosed = true
        break
      case !!shapeEditablePointsFeature:
        this.selectedShapePointIdx = shapeEditablePointsFeature.properties.idx
        break
      case this.isEditingPolyline && !!shapeEditablePointsFeature:
        break
      case !!polylineAdditionalEditablePointFeature:
        selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates = [
          ...selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates.slice(
            0,
            polylineAdditionalEditablePointFeature.properties.idx + 1,
          ),
          { latitude: e.lngLat.lat, longitude: e.lngLat.lng },
          ...selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates.slice(
            polylineAdditionalEditablePointFeature.properties.idx + 1,
          ),
        ]
        break
      case this.isEditingPolyline &&
        !selectedMapViewItem.sitemapItem.shapeCoordinates.isClosed:
        selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates.push({
          longitude: e.lngLat.lng,
          latitude: e.lngLat.lat,
        })
        break
      case e?.features?.length && !!itemEditorShapeFeature:
        break
      case e?.features?.length &&
        !!shapeFeature &&
        !this.mapBoxViewerStore.selectedMarkerItemId:
        const itemId = shapeFeature.properties?.itemId
        const currentItemId =
          this.mapViewItemsSetupStore.selectedMapViewItem?.id
        if (itemId && (!currentItemId || currentItemId !== itemId)) {
          this.selectMapViewItem(
            this.displayedGlobeItems.find(
              i => i.id === shapeFeature?.properties?.itemId,
            ),
          )
        }
        break
      case !this.mapBoxViewerStore.selectedMarkerItemId &&
        selectedMapViewItem &&
        !this.mapBoxViewerStore.selectedMarkerItemId:
        this.mapViewItemsSetupStore.safelyDeselectMapViewItem()
        break
      case e?.features?.length && !!tilesetShapeFeatures.length:
        this.mapBoxViewerStore.tilesetsControlStore.selectedTilesetFeatures =
          tilesetShapeFeatures
        this.mapBoxViewerStore.tilesetsControlStore.selectedTilesetFeature =
          tilesetShapeFeatures.at(-1)
        break
      case !!sitemapFeature:
        this.selectSitemapById(sitemapFeature?.properties?.sitemapId)
        break
      case !this.isRubberMode && !!this.sitemapsSetupStore.selectedSitemap:
        this.sitemapsSetupStore.deselectSitemap()
        break
      case !this.isRubberMode && !!this.tilesetsSetupStore.selectedTileset:
        this.tilesetsSetupStore.deselectTileset()
        break
    }
    this.mapBoxViewerStore.selectedMarkerItemId = null
  }

  // A function that finds all features with a same id and combines them into one
  private getTilesetFeature(feature: GeoJSONFeature): any {
    const relatedFeatures = this.mapBoxViewerStore.mapRef
      .getMap()
      .querySourceFeatures(feature.source, {
        sourceLayer: feature.sourceLayer,
        filter: ['==', ['id'], feature.id],
      })
      .map(f =>
        turf.polygon(
          (f as GeoJSON.Feature<GeoJSON.Polygon>).geometry.coordinates,
        ),
      )

    feature.geometry = turf.union(
      turf.featureCollection(relatedFeatures),
    ).geometry

    return feature
  }

  private getConstrainedPoint(
    point: LngLat,
    basePoint: IGeoJson2DGeographicCoordinates,
  ): IGeoJson2DGeographicCoordinates {
    const radiansAngle = Math.atan2(
      point.lat - basePoint.latitude,
      point.lng - basePoint.longitude,
    )
    const angle = radiansAngle / DEGREE_IN_RADIANS
    const constrainedAngle =
      Math.round(angle / CONSTRAIN_ANGLE_STEP) * CONSTRAIN_ANGLE_STEP

    const distance = Math.sqrt(
      Math.pow(point.lng - basePoint.longitude, 2) +
        Math.pow(point.lat - basePoint.latitude, 2),
    )

    return {
      longitude:
        basePoint.longitude +
        distance * Math.cos(constrainedAngle * DEGREE_IN_RADIANS),
      latitude:
        basePoint.latitude +
        distance * Math.sin(constrainedAngle * DEGREE_IN_RADIANS),
    }
  }

  @action.bound
  public onMapDblClick(e: MapMouseEvent): void {
    if (e?.features?.length) {
      const shapeEditorFeature = e.features.find(f =>
        [
          SHAPE_FILL_LAYER,
          SHAPE_LINE_LAYER,
          SHAPE_SELECTED_POINTS_LAYER,
          SHAPE_SELECTED_FILL_LAYER,
          SHAPE_SELECTED_LINE_LAYER,
        ].includes(f?.layer?.id),
      )
      this.onMapClick(e)
      if (shapeEditorFeature) {
        this.mapViewItemsSetupStore.selectSitemapItemDrawnPart(
          MapViewItemDrawnPart.Shape,
        )
      }
      e.originalEvent.stopPropagation()
    }
  }

  @action.bound
  public onMouseDown(e: MapMouseEvent): void {
    this.selectedShapePointIdx = null
    this.mouseDownCoordinates = e.lngLat

    const { selectedMapViewItem } = this.mapViewItemsSetupStore
    if (this.isEditingPolyline && e.originalEvent.shiftKey) {
      e.originalEvent.stopPropagation()
      const { coordinates } = selectedMapViewItem.sitemapItem.shapeCoordinates
      const prevPoint = coordinates[coordinates.length - 1]
      const point = this.getConstrainedPoint(
        this.mouseDownCoordinates,
        prevPoint,
      )
      this.mapViewItemsSetupStore.selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates.push(
        point,
      )
    }
    const shapeEditorFeature = e.features?.find(
      f => f?.layer?.id === SHAPE_EDITABLE_POINTS_LAYER,
    )
    const shapeFillFeature = e.features?.find(
      f => f?.layer?.id === ITEM_EDITOR_SHAPE_FILL_LAYER,
    )
    switch (true) {
      case this.pressedKey === Keys.Ctrl &&
        !!selectedMapViewItem &&
        !!selectedMapViewItem.globeViewItemProperties?.shapeProperties
          ?.isDisplayed:
        e.preventDefault()
        this.isRotateMode = true
        this.lastRotationCoords = this.mouseDownCoordinates
        break
      case e?.features?.length && !!shapeEditorFeature:
        e.preventDefault()
        const idx = shapeEditorFeature?.properties?.idx as number
        if (selectedMapViewItem && idx !== null) {
          this.draggableShapePointIdx = idx
          this.selectedShapePointIdx = idx
          this.isEditableItemInDragMode = true
        }
        break
      case e?.features?.length && !!shapeFillFeature:
        e.preventDefault()
        this.isEditableItemInDragMode = true
        this.selectedMapViewItemOnStartMoving = selectedMapViewItem.copy()
        break
    }
  }

  @action.bound
  public onMouseUp(): void {
    this.draggableShapePointIdx = null
    this.isEditableItemInDragMode = null
    this.draggableBoundingBoxPointIdx = null
    this.selectedMapViewItemOnStartMoving = null
    this.isRotateMode = false
  }

  @action.bound
  public onMouseMove(e: MapMouseEvent): void {
    const selectedMapViewItem = this.mapViewItemsSetupStore.selectedMapViewItem
    if (this.isEditableItemInDragMode && !!selectedMapViewItem) {
      if (this.draggableShapePointIdx !== null) {
        this.moveShapePoints(e.lngLat)
      } else {
        this.moveItem(e.lngLat)
      }
    }
    if (this.isRotateMode && !!selectedMapViewItem) {
      selectedMapViewItem.sitemapItem.shapeCoordinates.rotate(
        this.lastRotationCoords,
        e.lngLat,
      )
      this.lastRotationCoords = e.lngLat
    }
    const shapeEditablePointFeature = e.features?.find(
      f => f?.layer?.id === SHAPE_EDITABLE_POINTS_LAYER,
    )
    const polylineAdditionalEditablePointFeature = e.features?.find(
      f => f?.layer?.id === POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER,
    )
    const shapeFeature = e.features?.find(f =>
      [SHAPE_FILL_LAYER, SHAPE_LINE_LAYER, SHAPE_ARROW_LINE_LAYER].includes(
        f?.layer?.id,
      ),
    )
    const sitemapFeature = e.features?.find(
      f => f?.layer?.id === UNDER_PLAN_FILL_LAYER,
    )

    switch (true) {
      case this.isEditingPolyline &&
        !!shapeEditablePointFeature &&
        shapeEditablePointFeature.properties.idx === 0 &&
        !selectedMapViewItem.sitemapItem.shapeCoordinates.isClosed:
        this.mapBoxViewerStore.mapCursor = CLOSE_SHAPE_CURSOR
        break
      case this.isEditingPolyline && !!shapeEditablePointFeature:
        this.mapBoxViewerStore.mapCursor = CROSSHAIR_CURSOR
        break
      case (this.isEditingPolyline &&
        !selectedMapViewItem.sitemapItem?.shapeCoordinates?.isClosed) ||
        !!polylineAdditionalEditablePointFeature:
        this.mapBoxViewerStore.mapCursor = ADD_POINT_CURSOR
        break
      case this.pressedKey === Keys.Ctrl &&
        !!selectedMapViewItem &&
        selectedMapViewItem.sitemapItem?.shapeCoordinates?.type !==
          SitemapItemShapeType.Circle:
        this.mapBoxViewerStore.mapCursor = this.getRotationCursor(e.lngLat)
        break
      case !!e?.features?.length:
        this.mapBoxViewerStore.mapCursor = 'pointer'
        break
      default:
        this.mapBoxViewerStore.mapCursor = null
    }

    if (shapeFeature) {
      this.mapBoxViewerStore.hoveredShapeItemId =
        shapeFeature.properties?.itemId || null
      this.mapBoxViewerStore.hoveredSitemapId = null
    } else {
      this.mapBoxViewerStore.hoveredShapeItemId = null
      if (sitemapFeature && !this.mapBoxViewerStore.hoveredMarkerItemId) {
        this.mapBoxViewerStore.hoveredSitemapId =
          sitemapFeature.properties?.sitemapId || null
      } else {
        this.mapBoxViewerStore.hoveredSitemapId = null
      }
    }
  }

  private getRotationCursor(cursorPosition: LngLat): string {
    const shapeCoords =
      this.mapViewItemsSetupStore.selectedMapViewItem.sitemapItem
        .shapeCoordinates
    const lngDif = shapeCoords.centerPoint[0] - cursorPosition.lng
    const latDif = shapeCoords.centerPoint[1] - cursorPosition.lat
    if (latDif >= 0) {
      if (lngDif >= 0) {
        return CURSOR_SW_ROTATE
      }
      if (lngDif < 0) {
        return CURSOR_SE_ROTATE
      }
    }
    if (latDif < 0) {
      if (lngDif >= 0) {
        return CURSOR_NW_ROTATE
      }
      if (lngDif < 0) {
        return CURSOR_NE_ROTATE
      }
    }
  }

  private get isEditingPolyline(): boolean {
    const selectedMapViewItem = this.mapViewItemsSetupStore.selectedMapViewItem
    return (
      !!selectedMapViewItem &&
      this.mapViewItemsSetupStore.selectedMapViewItemDrawnPart ===
        MapViewItemDrawnPart.Shape &&
      (selectedMapViewItem.sitemapItem.shapeCoordinates?.type ===
        SitemapItemShapeType.Polyline ||
        selectedMapViewItem.sitemapItem?.type === MapViewItemType.Line)
    )
  }

  public get shouldUpdateViewport(): boolean {
    return !this.isEditableItemInDragMode && !this.isRotateMode
  }

  @action.bound
  private moveItem(moveCoords: LngLat): void {
    const selectedMapViewItem = this.mapViewItemsSetupStore.selectedMapViewItem
    const lngDif = (this.mouseDownCoordinates as LngLat)?.lng - moveCoords.lng
    const latDif = (this.mouseDownCoordinates as LngLat)?.lat - moveCoords.lat
    if (
      this.mapViewItemsSetupStore.selectedMapViewItemDrawnPart !==
      MapViewItemDrawnPart.Shape
    ) {
      selectedMapViewItem.sitemapItem.coordinates = {
        latitude:
          this.selectedMapViewItemOnStartMoving.sitemapItem.coordinates
            .latitude - latDif,
        longitude:
          this.selectedMapViewItemOnStartMoving.sitemapItem.coordinates
            .longitude - lngDif,
      }
    }
    if (selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates) {
      selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates =
        this.selectedMapViewItemOnStartMoving.sitemapItem.shapeCoordinates.coordinates.map(
          c => ({
            latitude: c.latitude - latDif,
            longitude: c.longitude - lngDif,
          }),
        )
    }
  }

  @action.bound
  private moveShapePoints(moveCoords: LngLat): void {
    const selectedMapViewItem = this.mapViewItemsSetupStore.selectedMapViewItem
    if (selectedMapViewItem.sitemapItem.shapeCoordinates?.coordinates?.length) {
      selectedMapViewItem.sitemapItem.shapeCoordinates.moveShapePoint(
        this.draggableShapePointIdx,
        moveCoords,
      )
    }
  }

  @action.bound
  public onKeyDown(event: KeyboardEvent): void {
    this.pressedKey = event.ctrlKey ? Keys.Ctrl : (event.key as Keys) || null

    if (
      this.selectedShapePointIdx !== null &&
      this.mapViewItemsSetupStore.selectedMapViewItem?.sitemapItem
        ?.shapeCoordinates?.type === SitemapItemShapeType.Polyline &&
      event.key === 'Delete'
    ) {
      this.mapViewItemsSetupStore.selectedMapViewItem.sitemapItem.shapeCoordinates.coordinates.splice(
        this.selectedShapePointIdx,
        1,
      )
      this.selectedShapePointIdx = null
    }
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onKeyUp(event: KeyboardEvent) {
    this.pressedKey = null
  }
}
