import * as React from 'react'

import { observer } from 'mobx-react'

import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DatePickerMonths from './DatePickerMonths'

import './TwoMonthsDatePicker.scss'

// localization: translated

interface IProps<StoreType> {
  store: StoreType

  className?: string
  customHeaderRenderer?: () => JSX.Element
  customFooterRenderer?: () => JSX.Element
}

@observer
export default class TwoMonthsDatePicker<
  StoreType extends TwoMonthsDatePickerStore,
> extends React.Component<IProps<StoreType>> {
  public render() {
    const { store, className, children } = this.props
    const { isShown, displayedMonths, titleTranslatorKey, shouldHideCalendar } =
      store
    if (!isShown) {
      return null
    }

    return (
      <div className={`${className} tm-dp-container col x-stretch y-start`}>
        {titleTranslatorKey && this.renderHeader()}
        {children}
        {!shouldHideCalendar && (
          <div className="tm-dp-months-container row y-start x-between no-flex-children-direct">
            {displayedMonths.map((value, index) => {
              return (
                <div className="tm-dp-month-picker" key={index}>
                  <DatePickerMonths
                    store={store}
                    isFirstMonth={!index}
                    date={value}
                  />
                </div>
              )
            })}
          </div>
        )}
        {this.renderFooter()}
      </div>
    )
  }

  protected renderHeader() {
    if (this.props.customHeaderRenderer) {
      return this.props.customHeaderRenderer()
    }

    const { titleTranslatorKey, hide } = this.props.store
    return (
      <div className="row pb10 mb20 bb-light-grey y-center">
        <div className="text bold uppercase extra-large">
          {titleTranslatorKey && Localization.getText(titleTranslatorKey)}
        </div>
        <div className="no-grow">
          <Icons.Cross className="pointer" onClick={hide} />
        </div>
      </div>
    )
  }

  protected renderFooter() {
    if (this.props.customFooterRenderer) {
      return this.props.customFooterRenderer()
    }

    const { onApplyClick, hide } = this.props.store

    return (
      <div className="tm-dp-btn-container">
        <div className="tm-dp-btn text large" onClick={hide}>
          {Localization.translator.cancel}
        </div>
        <div className="tm-dp-btn text large bold-font" onClick={onApplyClick}>
          {Localization.translator.apply}
        </div>
      </div>
    )
  }
}
