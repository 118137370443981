import React from 'react'

import { observer } from 'mobx-react'

import TableOffsetsColumnCollapseStore from '../../../TableOffsetsColumnCollapse.store'

interface IExtendedSectionsBarProps {
  tableOffsetsColumnCollapseStore: TableOffsetsColumnCollapseStore
}

@observer
export default class ExtendedSectionsBar extends React.Component<IExtendedSectionsBarProps> {
  public render() {
    const {
      assigneeSectionOffset,
      durationSectionOffset,
      plannedScheduleSectionOffset,
      actualScheduleSectionOffset,
      commentsSectionOffset,
      shouldCollapseAssignee,
      shouldCollapseDuration,
      shouldCollapsePlannedSchedule,
      shouldCollapseActualSchedule,
      shouldCollapseComments,
      activityNameSectionWidth,
    } = this.props.tableOffsetsColumnCollapseStore

    return (
      <div
        className="sections-holder"
        style={{ left: activityNameSectionWidth }}
      >
        {shouldCollapseAssignee && (
          <span
            className="section-title text small uppercase assignee no-bold"
            style={{ left: assigneeSectionOffset }}
          >
            Asgn.
          </span>
        )}
        {shouldCollapseDuration && (
          <span
            className="section-title text small uppercase duration no-bold"
            style={{ left: durationSectionOffset }}
          >
            Dur.
          </span>
        )}
        {shouldCollapsePlannedSchedule && (
          <span
            className="section-title text small uppercase planned-duration no-bold"
            style={{ left: plannedScheduleSectionOffset }}
          >
            Plan.
          </span>
        )}
        {shouldCollapseActualSchedule && (
          <span
            className="section-title text small uppercase actual-duration no-bold"
            style={{ left: actualScheduleSectionOffset }}
          >
            Actu.
          </span>
        )}
        {shouldCollapseComments && (
          <span
            className="section-title text small uppercase comments no-bold"
            style={{ left: commentsSectionOffset }}
          >
            Comm.
          </span>
        )}
      </div>
    )
  }
}
