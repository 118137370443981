import * as React from 'react'

import { Button, Classes, Dialog, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ProjectAccessType } from '~/client/graph'
import BaseObservableTable from '~/client/src/desktop/components/BaseObservableTable/BaseObservableTable'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import { LWFCRowData } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { getAccountTypeTranslate } from '~/client/src/shared/constants/ProjectRoles'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import {
  IProcoreCompanyWithProjectsAndUsers,
  IProcoreProjectWithUsers,
} from '~/client/src/shared/services/ProcoreService'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import { DataKeys } from '../../ProjectMembersList.store'
import ProcoreDirectoryDialogStore, {
  IProcoreUserView,
} from './ProcoreDirectoryDialog.store'

import './ProcoreDirectoryDialog.scss'

interface IProps {
  isOpen: boolean
  closeDialog: () => void
  addToProject: (users: User[]) => void
  loading: boolean
  procoreCompanies: IProcoreCompanyWithProjectsAndUsers[]

  state?: DesktopInitialState
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

@inject('state', 'userProjectsStore', 'projectMembersStore')
@observer
export default class ProcoreDirectoryDialog extends React.Component<IProps> {
  private readonly store: ProcoreDirectoryDialogStore

  @observable
  private selectedCompany: IProcoreCompanyWithProjectsAndUsers = null
  @observable private selectedProject: IProcoreProjectWithUsers = null

  private get title() {
    const { closeDialog, loading } = this.props

    let titleText: string
    let shouldShowAddButton = false
    if (this.selectedProject) {
      titleText = this.selectedProject.display_name
      shouldShowAddButton = true
    } else if (this.selectedCompany) {
      titleText = Localization.translator.selectProcoreProject
    } else {
      titleText = Localization.translator.selectProcoreCompany
    }

    return (
      <div className="row">
        <Icon
          icon={IconNames.CROSS}
          className="no-grow"
          onClick={closeDialog}
        />
        <img
          src="/static/icons/procore-icon.svg"
          style={{ height: '25px' }}
          className="mr15 ml5 no-grow"
        />
        <span className="text bold extra-large">{titleText}</span>
        {shouldShowAddButton && (
          <div className="no-grow">
            <Button
              text={Localization.translator.addXUsers(
                this.store.selectedUsersCount,
              )}
              className={classList({
                'procore-users-dialog-btns-item mr10 primary-blue-btn no-grow':
                  true,
                'inactive-element': !this.store.isSomeRowSelected,
              })}
              onClick={this.addSelectedUsers}
              loading={loading}
            />
          </div>
        )}
      </div>
    )
  }

  public constructor(props: IProps) {
    super(props)

    this.store = new ProcoreDirectoryDialogStore(
      props.state,
      this.getUserViews,
      props.userProjectsStore,
    )
  }

  public render() {
    const { isOpen } = this.props

    return (
      <Dialog
        isOpen={isOpen}
        title={this.title}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className={classList({
          'procore-users-dialog': true,
          narrow: !this.selectedProject,
        })}
        onClosed={this.onClosed}
      >
        <div className={Classes.DIALOG_BODY + ' col'}>
          {this.renderBackArrow()}
          <div className="content">{this.renderContent()}</div>
        </div>
      </Dialog>
    )
  }

  private renderBackArrow() {
    if (!this.selectedCompany) {
      return <div className="pb15 bb-light-grey" />
    }

    const onClick = () =>
      (this.selectedProject ? this.selectProject : this.selectCompany)(null)
    return (
      <div className="pb15 bb-light-grey">
        <div className="w-fit-content pointer" onClick={onClick}>
          <Icon icon={IconNames.ARROW_LEFT} />
          <span className="text large bold ml10">
            {Localization.translator.goBack}
          </span>
        </div>
      </div>
    )
  }

  @action.bound
  private onClosed() {
    this.selectedCompany = null
    this.selectedProject = null
  }

  private renderContent() {
    const { isOpen } = this.props
    if (!isOpen) {
      return null
    }

    if (this.selectedProject) {
      return this.renderUserTable()
    }

    if (this.selectedCompany) {
      return this.renderProjectsList()
    }

    return this.renderCompaniesList()
  }

  private renderCompaniesList() {
    const { procoreCompanies } = this.props
    if (!procoreCompanies) {
      return (
        <span className="text large bold ml10">
          {Localization.translator.noCompanies}
        </span>
      )
    }

    return procoreCompanies.map(company => {
      return (
        <div
          key={company.name}
          onClick={this.selectCompany.bind(this, company)}
          className="procore-list-item bb-light-grey pa20 pointer"
        >
          {company.name}
        </div>
      )
    })
  }

  @action.bound
  private selectCompany(company: IProcoreCompanyWithProjectsAndUsers) {
    this.selectedCompany = company
  }

  private renderProjectsList() {
    return this.selectedCompany.projects.map(project => {
      const { users } = project
      return (
        <div
          key={project.id}
          onClick={this.selectProject.bind(this, project)}
          className={classList({
            'procore-list-item bb-light-grey pa20 pointer': true,
            'inactive-element': !users,
          })}
        >
          {this.getProjectLabel(project)}
        </div>
      )
    })
  }

  private getProjectLabel(project: IProcoreProjectWithUsers) {
    const { display_name, users } = project
    const countLabel = !users
      ? Localization.translator.noDirectoryAccess
      : Localization.translator.xUsers(users.length)
    return `${display_name} (${countLabel})`
  }

  @action.bound
  private selectProject(project: IProcoreProjectWithUsers) {
    this.selectedProject = project
  }

  private renderUserTable() {
    return (
      <BaseObservableTable
        store={this.store}
        autoSizerClassName="table-container mt10 project-members-upload-content"
        fixedWidth={1090}
        valueRenderer={this.renderValue}
        isRowDisabled={this.getIsRowDisabled}
        renderMainCheckbox={this.renderSelectAll}
      />
    )
  }

  private renderSelectAll = () => {
    return (
      <Checkbox
        isCentered={true}
        isChecked={this.store.isAllSelected}
        onClick={this.toggleAll}
      />
    )
  }

  private toggleAll = () => {
    if (this.store.isAllSelected) {
      this.store.unselectAll()
    } else {
      this.store.selectAll()
    }
  }

  private renderValue = (value: any, dataKey: string, data: LWFCRowData) => {
    if (dataKey === DataKeys.CHECKBOX) {
      return <Checkbox isCentered={true} isChecked={value} />
    }

    if (dataKey === DataKeys.ACCOUNT_TYPE) {
      return (
        <div
          className="row no-select underline-hover"
          onClick={this.changeAccountType.bind(this, data)}
        >
          <Icon icon={IconNames.CHEVRON_DOWN} />
          <span className="no-grow ml10">
            {getAccountTypeTranslate(value as ProjectAccessType)}
          </span>
        </div>
      )
    }

    if (dataKey === DataKeys.PROJECTS && value) {
      return (
        <div className="row no-select underline-hover" title={value.join('\n')}>
          <span className="no-grow text large mr5">{value[0]}</span>
          {value.length > 1 && (
            <span className="no-grow text large primary-blue bold">
              {'+' + (value.length - 1)}
            </span>
          )}
        </div>
      )
    }

    return null
  }

  private getIsRowDisabled = (data: LWFCRowData) => {
    return !this.store.canSelectInstance(data.user)
  }

  private changeAccountType(
    rowData: LWFCRowData,
    event: React.MouseEvent<HTMLElement>,
  ) {
    event.stopPropagation()
    this.store.changeAccountType(
      rowData[DataKeys.ID],
      rowData[DataKeys.ACCOUNT_TYPE],
    )
  }

  private getUserViews = (): IProcoreUserView[] => {
    if (!this.selectedProject || !this.selectedProject.users) {
      return []
    }

    return this.selectedProject.users.map(user => {
      const struxhubUser = this.props.projectMembersStore.getByEmail(user.email)

      return Object.assign(
        observable({
          uid: user.email,
          accountType: null,
          companyName: user.vendor?.name || '',
          struxhubUser,
        }),
        user,
      )
    })
  }

  private addSelectedUsers = () => {
    const users = this.store.getSelectedUsers()
    this.props.addToProject(users)
  }
}
