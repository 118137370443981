import { action, computed, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MapBoxViewerStore from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import BaseMapViewSetUpStore from '~/client/src/shared/components/SitemapHelpers/BaseMapViewSetUp.store'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import Company from '~/client/src/shared/models/Company'
import Delivery from '~/client/src/shared/models/Delivery'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Material from '~/client/src/shared/models/Material'
import Sitemap from '~/client/src/shared/models/Sitemap'
import GlobeViewControlStore from '~/client/src/shared/stores/GlobeViewControl.store'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import DeliverySitemapPinStore from '~/client/src/shared/stores/ui/DeliverySitemapPin.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DesktopDeliveryViewStore from '../../Deliveries.store'
import DeliveriesBySitemapListStore from './DeliveriesBySitemapList.store'

// localization: no display text to translate

export default class DeliveriesMapStore {
  @observable public selectedAttrId: string = EMPTY_STRING
  public readonly deliveriesBySitemapListStore: DeliveriesBySitemapListStore =
    null
  public selectedDeliveriesMap: { [attrId: string]: string } = {}
  @observable public topOffset: number = 0
  @observable public leftOffset: number = 0

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore,
    public readonly baseSitemapSetUpStore: BaseMapViewSetUpStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly userProjectsStore: UserProjectsStore,
    public readonly globeViewControlStore: GlobeViewControlStore,
    public readonly mapBoxViewerStore: MapBoxViewerStore,
    public readonly globeViewsStore: GlobeViewsStore,
    public readonly deliverySitemapPinStore: DeliverySitemapPinStore,
  ) {
    this.deliveriesBySitemapListStore = new DeliveriesBySitemapListStore(
      this.eventsStore.appState,
      this.projectDateStore,
      this.deliveryAssignmentsStore,
      this.desktopDeliveryViewStore,
      this,
      this.companiesStore,
      this.projectMembersStore,
      this.materialCategoryStore,
      this.userProjectsStore,
    )
  }

  public get isGlobeMode(): boolean {
    return !!this.globeViewControlStore.selectedGlobeView
  }

  public get appState(): InitialState {
    return this.eventsStore.appState
  }

  @action.bound
  public mount() {
    if (!this.activeDeliveryId) {
      return
    }

    this.deliveriesBySitemapListStore.scrollToInstance(this.activeDeliveryId)
  }

  @action.bound
  public unmount() {
    this.baseSitemapSetUpStore.deselectSitemap()
  }

  @action.bound
  public setGroupingFilter() {
    const { deliveryFilters } = this.eventsStore.appState

    if (
      !Object.values(DeliveryGroupingOption).includes(
        deliveryFilters.groupingKey,
      ) ||
      deliveryFilters.groupingKey === DeliveryGroupingOption[FieldIds.LOCATION]
    ) {
      deliveryFilters.groupingKey = DeliveryGroupingOption[FieldIds.DATE]
    }
  }

  public getZoneById = (zoneId: string): Zone => {
    return this.desktopDeliveryViewStore.getZoneById(zoneId)
  }

  public getGateById = (gateId: string): Gate => {
    return this.desktopDeliveryViewStore.getGateById(gateId)
  }

  public getRouteById = (routeId: string): Route => {
    return this.desktopDeliveryViewStore.getRouteById(routeId)
  }

  public getBuildingById = (buildingId: string): Building => {
    return this.desktopDeliveryViewStore.getBuildingById(buildingId)
  }

  public getMaterialsByIds = (materialIds: string[]): Material[] => {
    return this.desktopDeliveryViewStore.getMaterialsByIds(materialIds)
  }

  public getCompanyById = (companyId: string): Company => {
    return this.desktopDeliveryViewStore.getCompanyById(companyId)
  }

  public getOffloadEquipmentsByIds = (
    offloadingEquipmentIds: string[],
  ): IDeliveryAttributeDto[] => {
    return this.desktopDeliveryViewStore.getOffloadEquipmentsByIds(
      offloadingEquipmentIds,
    )
  }

  @computed
  public get closestAttrIdToDeliveriesMap(): { [attrId: string]: Delivery[] } {
    this.deSelectDeliveries()

    const displayedAttrsIds = this.sortedSitemapItems
      .filter(this.isSitemapItemSupported)
      .map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = this.availableDeliveries.filter(
        d => d.isRelatedAttr(attrId) && !this.selectedDeliveriesMap[d.id],
      )
      this.selectDeliveries(
        acc[attrId].map(d => d.id),
        attrId,
      )
      return acc
    }, {})
  }

  @computed
  public get attributesBySitemapItems(): LocationBase[] {
    if (!this.selectedSitemap) {
      return []
    }

    const { items } = this.selectedSitemap
    const { getSitemapItemById } = this.baseSitemapSetUpStore

    const attributesBySitemap: LocationBase[] = []

    const sitemapItems = Object.values(items).filter(item => !item.isHidden)
    sitemapItems.forEach(itemData => {
      const item = getSitemapItemById(itemData.sitemapItemId)

      if (!item) {
        return
      }

      const attribute = this.allSitemapsAttributes.find(a =>
        item.isAssignedTo(a),
      )

      if (attribute) {
        attributesBySitemap.push(attribute)
      }
    })

    return attributesBySitemap
  }

  @computed
  public get attributesBySelectedSitemap(): LocationBase[] {
    if (!this.selectedSitemap && !this.isGlobeMode) {
      return []
    }

    return this.allSitemapsAttributes.filter(a =>
      a.isSitemapAssigned(this.selectedSitemap.id),
    )
  }

  public displayedSitemapDeliveries = (): Delivery[] => {
    if (!this.selectedSitemap && !this.isGlobeMode) {
      return []
    }

    return this.isGlobeMode || this.selectedSitemap.isReferenced
      ? this.deliveriesByReferencedSitemapItems
      : this.deliveriesBySitemapItems
  }

  @computed
  public get deliveriesBySitemapItems(): Delivery[] {
    return this.desktopDeliveryViewStore.currentViewDeliveries
      .filter(
        d =>
          this.attributesBySitemapItems.length &&
          this.attributesBySitemapItems.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get deliveriesByReferencedSitemapItems(): Delivery[] {
    return this.desktopDeliveryViewStore.currentViewDeliveries
      .filter(
        d =>
          this.mapBoxViewerStore.displayedGlobeViewItems.length &&
          this.mapBoxViewerStore.displayedGlobeViewItems.some(a =>
            d.isRelatedAttr(a.id),
          ),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get deliveriesByLowestChildrenTags(): Delivery[] {
    const lowestChildrenAttributes = this.attributesBySelectedSitemap.filter(
      a => !this.attributesBySelectedSitemap.some(at => at.isParent(a)),
    )

    return this.desktopDeliveryViewStore.currentViewDeliveries
      .filter(
        d =>
          lowestChildrenAttributes.length &&
          lowestChildrenAttributes.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get completedToTotalCountLabel(): string {
    const totalCount = this.availableDeliveries.length
    const completedCount = this.availableDeliveries.filter(d => d.isDone).length

    return `${completedCount}/${totalCount}`
  }

  public get listGroupingKey(): string {
    return this.deliveriesBySitemapListStore.groupingKey
  }

  public get activeDeliveryId(): string {
    return this.desktopDeliveryViewStore.activeDeliveryId
  }

  @computed
  public get availableDeliveries(): Delivery[] {
    return this.deliveriesBySitemapListStore.filteredCollection
  }

  public isSelectedAttr = (attrId: string): boolean => {
    return this.selectedAttrId === attrId
  }

  @action.bound
  public selectAttr(attrId: string, y?: number, x?: number) {
    if (this.selectedAttrId === attrId) {
      return this.resetAttrSelection()
    }

    this.selectedAttrId = attrId
    this.topOffset = y
    this.leftOffset = x
  }

  @action.bound
  public resetAttrSelection() {
    this.selectedAttrId = EMPTY_STRING
  }

  @action.bound
  public selectSitemap(sitemap: Sitemap) {
    this.baseSitemapSetUpStore.selectSitemap(sitemap)
  }

  @action.bound
  public deselectSitemap() {
    this.baseSitemapSetUpStore.deselectSitemap()
  }

  @action.bound
  public selectGlobe(globe: GlobeView) {
    this.globeViewControlStore.selectGlobe(globe)
  }

  @action.bound
  public deselectGlobe() {
    this.globeViewControlStore.deselectGlobe()
  }

  @action.bound
  public selectDelivery(deliveryId: string) {
    this.desktopDeliveryViewStore.activateDeliveryById(deliveryId)
    if (this.isGlobeMode) {
      const sitemapItem = this.displayedMapboxItems.find(
        i => i.id === this.selectedDeliveriesMap[deliveryId],
      )
      this.mapBoxViewerStore.setViewportFromItem(sitemapItem)
    }
  }

  @computed
  public get sortedSitemapItems(): MapViewItemBase[] {
    return this.deliverySitemapPinStore.getItemsByPinLocationOption(
      this.displayedMapboxItems,
    )
  }

  @computed
  public get sortedBoardItems(): MapViewItemBase[] {
    return this.deliverySitemapPinStore.getItemsByPinLocationOption(
      this.displayedSitemapItems,
    )
  }

  @action.bound
  public resetAllFilters() {
    this.desktopDeliveryViewStore.resetAllFilters()
  }

  public isSitemapItemSupported = (item: MapViewItemBase): boolean => {
    return !item.isDataLess
  }

  private sortDeliveriesFunc = (a: Delivery, b: Delivery): number => {
    const aBuilding = this.getBuildingById(a.building)
    const bBuilding = this.getBuildingById(b.building)
    const aBuildingName = (aBuilding && aBuilding.name) || EMPTY_STRING
    const bBuildingName = (bBuilding && bBuilding.name) || EMPTY_STRING

    return aBuildingName.localeCompare(bBuildingName)
  }

  @computed
  public get attrIdToDeliveriesMap(): { [attrId: string]: Delivery[] } {
    const displayedAttrsIds = [
      ...this.displayedMapboxItems,
      ...this.displayedSitemapItems,
    ]
      .filter(this.isSitemapItemSupported)
      .map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = this.availableDeliveries.filter(d =>
        d.isRelatedAttr(attrId),
      )
      return acc
    }, {})
  }

  @computed
  public get deliveriesGlobes(): GlobeView[] {
    const { list } = this.baseSitemapSetUpStore.globeViewsStore
    const { maps } = this.eventsStore.appState.delivery.configurations
    return list.filter(s =>
      maps?.some(orderedGlobe => orderedGlobe.globeViewId === s.id),
    )
  }

  @computed
  public get highlightedDeliveriesIds(): string[] {
    const deliveriesIds = this.attrIdToDeliveriesMap[this.selectedAttrId]
    return deliveriesIds && deliveriesIds.map(({ id }) => id)
  }

  @computed
  public get deliveriesSitemaps(): Sitemap[] {
    const { list } = this.baseSitemapSetUpStore.sitemapsStore
    const { maps } = this.eventsStore.appState.delivery.configurations
    return list.filter(s => maps.some(map => map.sitemapId === s.id))
  }

  @computed
  public get closestAttrIdToDeliveriesBoardMap(): {
    [attrId: string]: Delivery[]
  } {
    this.deSelectDeliveries()

    const displayedAttrsIds = this.sortedBoardItems
      .filter(this.isSitemapItemSupported)
      .map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = this.availableDeliveries.filter(
        d => d.isRelatedAttr(attrId) && !this.selectedDeliveriesMap[d.id],
      )
      this.selectDeliveries(
        acc[attrId].map(d => d.id),
        attrId,
      )
      return acc
    }, {})
  }

  @computed
  public get displayedSitemapItems(): MapViewItemBase[] {
    return this.baseSitemapSetUpStore.displayedSitemapItems
  }

  @computed
  public get displayedMapboxItems(): MapViewItemBase[] {
    return this.mapBoxViewerStore.displayedGlobeViewItems
  }

  @computed
  public get sitemapItems(): MapViewItemBase[] {
    return this.baseSitemapSetUpStore.sitemapItems
  }

  @computed
  public get allSitemapsAttributes(): LocationBase[] {
    return this.baseSitemapSetUpStore.hierarchyAttributes
  }

  @computed
  public get selectedMapViewItem(): MapViewItemBase {
    return (
      this.isGlobeMode ? this.displayedMapboxItems : this.displayedSitemapItems
    ).find(i => i.id === this.selectedAttrId)
  }

  @computed
  public get selectedSitemap(): Sitemap {
    return this.baseSitemapSetUpStore.sitemap
  }

  public get selectedSitemapUrl(): string {
    return this.baseSitemapSetUpStore.sitemapUrl
  }

  public get isInitialLoaderShown(): boolean {
    return this.baseSitemapSetUpStore.isInitialLoaderShown
  }

  private deSelectDeliveries = () => {
    this.selectedDeliveriesMap = {}
  }

  private selectDeliveries = (deliveriesIds: string[], attrId: string) => {
    deliveriesIds.forEach(id => {
      this.selectedDeliveriesMap[id] = attrId
    })
  }
}
