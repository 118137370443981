import * as React from 'react'

import { IReactionDisposer, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'

import { PresentationScreenKey } from '~/client/graph'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import ActivitiesHeaderBar from '~/client/src/desktop/views/SimpleGanttView/components/ActivitiesHeaderBar/ActivitiesHeaderBar'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import NavBar from '../../components/NavBar/NavBar'
import DesktopInitialState from '../../stores/DesktopInitialState'
import DesktopCommonStore from '../../stores/ui/DesktopCommon.store'
import UnsafeActivitiesMapView from './components/ActivitiesMapView/ActivitiesMap'
import ActivityGanttOrListView from './components/ActivityGanttOrListView/ActivityGanttOrListView'
import UnsafeSideBar from './components/SideBar/SideBar'

const SideBar = withErrorBoundary(UnsafeSideBar)
const ActivitiesMapView = withErrorBoundary(UnsafeActivitiesMapView)

interface ISimpleGanttViewProps {
  activitiesStore?: ActivitiesStore
  location?: any
  state?: DesktopInitialState

  activityPresetsStore?: ActivityPresetsStore
  activityFiltersStore?: ActivityFiltersStore
  activityDetailsStore?: ActivityDetailsStore
  statusUpdatesStore?: StatusUpdatesStore
  rfisStore?: RfisStore
  flagsStore?: FlagsStore
  scheduleCommentsStore?: ScheduleCommentsStore
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  safetyHazardsStore?: SafetyHazardsStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  activityFollowingsStore?: ActivityFollowingsStore
  deliveriesStore?: DeliveriesStore
  projectDateStore?: ProjectDateStore
  common?: DesktopCommonStore
  sitemapsStore?: SitemapsStore
  globeViewsStore?: GlobeViewsStore
}

@inject(
  'activitiesStore',
  'state',
  'activityPresetsStore',
  'activityFiltersStore',
  'activityDetailsStore',
  'statusUpdatesStore',
  'rfisStore',
  'flagsStore',
  'scheduleCommentsStore',
  'categoriesOfVarianceStore',
  'safetyHazardsStore',
  'activityAssignmentsStore',
  'activityFollowingsStore',
  'deliveriesStore',
  'projectDateStore',
  'common',
  'sitemapsStore',
  'globeViewsStore',
)
@observer
export default class SimpleGanttView extends React.Component<ISimpleGanttViewProps> {
  private disposePresentationReaction: IReactionDisposer
  private readonly store: DesktopActivityListStore

  public constructor(props: ISimpleGanttViewProps) {
    super(props)

    this.store = new DesktopActivityListStore(
      props.state,
      props.activitiesStore,
      props.activityPresetsStore,
      props.activityFiltersStore,
      props.activityDetailsStore,
      props.statusUpdatesStore,
      props.rfisStore,
      props.flagsStore,
      props.scheduleCommentsStore,
      props.categoriesOfVarianceStore,
      props.safetyHazardsStore,
      props.activityAssignmentsStore,
      props.activityFollowingsStore,
      props.deliveriesStore,
      props.projectDateStore,
      props.sitemapsStore,
      props.globeViewsStore,
    )
  }

  private get isProjectLoading(): boolean {
    const { loading } = this.props.state
    return (
      (loading.get(e.ACTIVATE_PROJECT) ||
        loading.get(e.GET_AUTH_USER_PROJECT) ||
        loading.get(e.GET_SCHEDULE) ||
        loading.get(e.GET_HIERARCHY_CONFIGURATION)) !== false
    )
  }

  public UNSAFE_componentWillMount() {
    const { location = {}, state } = this.props
    this.store.applyPageFromQueryParams(location.search)

    if (!state.userActiveProjectSettings?.isPresentationUser) {
      return
    }

    this.disposePresentationReaction = reaction(
      () => state.currentPresentationPage,
      page => {
        switch (page?.type) {
          case PresentationScreenKey.Gantt:
            state.activityList.viewMode = ViewModes.Gantt
            break
        }
      },
      { fireImmediately: true },
    )
  }

  public componentDidMount() {
    const { search } = this.props.common.history.location
    const activityId = search.replace('?', '')
    this.props.state.pushNotificationEntityId = activityId
  }

  public componentWillUnmount() {
    this.disposePresentationReaction?.()
  }

  public render() {
    const { viewMode } = this.props.state.activityList

    return (
      <Layout.View className="activities-view overflow-hidden">
        <Layout.Header className="layout-navigation">
          <NavBar />
          {!this.isProjectLoading && (
            <ActivitiesHeaderBar activityListStore={this.store} />
          )}
        </Layout.Header>
        <Layout.Content className="relative">
          <div className="relative-block">
            <div className="relative-block overflow-hidden" tabIndex={-1}>
              {!this.isProjectLoading && (
                <div className="gant-chart-container">
                  <SideBar activityListStore={this.store} />
                  <div className="gantt-container">
                    <ActivityGanttOrListView activityListStore={this.store} />
                    {viewMode === ViewModes.Map && (
                      <ActivitiesMapView store={this.store} />
                    )}
                  </div>
                </div>
              )}
              {this.isProjectLoading && <Loader hint="Loading Project" />}
            </div>
          </div>
        </Layout.Content>
        <Layout.Footer>
          <div />
        </Layout.Footer>
      </Layout.View>
    )
  }
}
