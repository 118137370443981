import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import CustomDeliveryListFiltersStore from '~/client/src/shared/stores/domain/CustomDeliveryListFilters.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'

import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import DesktopDeliveryViewStore from '../../../views/Deliveries/Deliveries.store'
import DeliveriesListStore from '../../../views/Deliveries/components/DeliveriesList/DeliveriesList.store'
import SaveFilterDialog from '../SavedFilters/components/SaveFilterDialog'
import DeliverySavedFiltersModal from './DeliverySavedFilterModal'
import DesktopDeliverySavedFiltersStore from './DesktopDeliverySavedFilters.store'

import '../SavedFilters/SavedFilters.scss'

// localization: translated

interface IProps {
  deliveryFilterStore: DeliveryFilterStore
  desktopDeliveryViewStore: DesktopDeliveryViewStore
  deliveriesListStore: DeliveriesListStore
  eventsStore?: DesktopEventStore
  customDeliveryListFiltersStore?: CustomDeliveryListFiltersStore
  onShowChanged?: (isShown: boolean) => void
  forceClose?: boolean
}

@inject('eventsStore', 'customDeliveryListFiltersStore')
@observer
export default class DeliverySavedFilters extends React.Component<IProps> {
  private desktopDeliverySavedFiltersStore: DesktopDeliverySavedFiltersStore =
    null
  public constructor(props: IProps) {
    super(props)

    this.desktopDeliverySavedFiltersStore =
      new DesktopDeliverySavedFiltersStore(
        props.deliveryFilterStore,
        props.desktopDeliveryViewStore,
        props.customDeliveryListFiltersStore,
        props.eventsStore,
        props.onShowChanged,
      )
  }

  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.desktopDeliverySavedFiltersStore.isShown = false
      this.desktopDeliverySavedFiltersStore.shouldSaveFilterDialogShow = false
    }
  }

  public render() {
    const {
      editableFilterName,
      editableFilterIsPublic,
      saveCustomFilter,
      isShown,
      shouldSaveFilterDialogShow,
      toggleSavedFilters,
      closeSavedFilters,
      onFilterNameChange,
      onFilterIsPublicToggle,
      filtersCount,
      filtersDescriptions,
      editCustomFilter,
      selectCustomFilterById,
      customFilters,
      selectCustomFilter,
      getDeliveryCountByCustomFilter,
    } = this.desktopDeliverySavedFiltersStore

    const { appliedActivityPresetId } = this.props.eventsStore.appState
    return (
      <div className="saved-filters no-grow">
        <FilterHandleButton
          className="saved-filters-handle-button"
          onClick={toggleSavedFilters}
          isSelected={isShown}
          isActive={!!appliedActivityPresetId}
        >
          <span>{Localization.translator.saved_deliveries}</span>
        </FilterHandleButton>

        {isShown && (
          <DeliverySavedFiltersModal
            desktopDeliverySavedFiltersStore={
              this.desktopDeliverySavedFiltersStore
            }
            editCustomFilter={editCustomFilter}
            getDeliveryCountByCustomFilter={getDeliveryCountByCustomFilter}
            selectCustomFilterById={selectCustomFilterById}
            customFilters={customFilters}
            selectCustomFilter={selectCustomFilter}
          />
        )}
        {shouldSaveFilterDialogShow && (
          <SaveFilterDialog
            filterName={editableFilterName}
            isPublic={editableFilterIsPublic}
            toggleIsPublic={onFilterIsPublicToggle}
            entitiesCountByFilter={getDeliveryCountByCustomFilter()}
            filtersCount={filtersCount}
            filtersDescriptions={filtersDescriptions}
            clickOnApply={saveCustomFilter}
            clickOnCancel={closeSavedFilters}
            onNameChange={onFilterNameChange}
            desktopSavedFiltersStore={this.desktopDeliverySavedFiltersStore}
            xEntitiesTranslatorKey={KnownTranslatorKeys.xDeliveries}
          />
        )}
      </div>
    )
  }
}
