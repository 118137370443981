import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ISiteLocation, LocationType } from '~/client/graph'
import { tagTypes } from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Activity from '~/client/src/shared/models/Activity'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { ITag } from '~/client/src/shared/models/Tag'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ActivityGanttOrListViewStore from '../../../ActivityGanttOrListView.store'

interface IProps {
  tagType: tagTypes
  tagCaption: string
  activity: Activity
  store: ActivityGanttOrListViewStore

  locationStore?: LocationsStore
  tagsStore?: TagsStore
}

const TAG_SEPARATOR = ', '

@inject('locationStore', 'tagsStore')
@observer
export default class TagCell extends React.Component<IProps> {
  public render() {
    return (
      <div className="text cell-wrapper px10 bb-cool-grey">{this.content}</div>
    )
  }

  private get content(): JSX.Element | JSX.Element[] {
    if (this.locationsAsTags.length) {
      return this.locationsAsTags.map(l => (
        <SitemapAttributeTag
          key={l.id}
          contentContainerClassName="text-ellipsis py2"
          shouldShowAsTag={true}
          dataObject={l as LocationBase}
        >
          <div className="text-ellipsis text large" title={l.name}>
            {l.name}
          </div>
        </SitemapAttributeTag>
      ))
    }

    if (this.getTagCellData) {
      return (
        <div className="text-ellipsis" title={this.getTagCellData}>
          {this.getTagCellData}
        </div>
      )
    }

    return <div>-</div>
  }

  private getLocationTagLabel(locationTagCodes: ActivityCode[]) {
    const locationTagLabel = locationTagCodes
      .map(locationTagCode => {
        return (
          locationTagCode.shortName ||
          locationTagCode.name ||
          EMPTY_STRING
        ).replace(/Zone/g, EMPTY_STRING)
      })
      .join(TAG_SEPARATOR)
    return locationTagLabel || EMPTY_STRING
  }

  @computed
  private get locationsAsTags(): ITag[] {
    const {
      tagType,
      activity: { assignedLocations },
      tagsStore,
    } = this.props

    if (!assignedLocations?.length) {
      return []
    }

    let filteredLocations: ISiteLocation[] = []
    switch (tagType) {
      case tagTypes.level:
        filteredLocations = assignedLocations.filter(
          l => l.type === LocationType.Level,
        )
        break
      case tagTypes.zone:
        filteredLocations = assignedLocations.filter(
          l => l.type === LocationType.Zone,
        )
        break
      case tagTypes.building:
        filteredLocations = assignedLocations.filter(
          l => l.type === LocationType.Building,
        )
        break
    }

    return filteredLocations.map(l => tagsStore.getTagById(l.id)).filter(t => t)
  }

  @computed
  private get getTagCellData(): string {
    const { tagType, store, activity } = this.props

    if (tagType === tagTypes.assignedMembers) {
      return store.getActivityAssignedUserNamesString(activity)
    }

    const { buildingCodeIds, levelCodeIds, zoneCodeIds } =
      this.props.locationStore

    let tagCodeIds: string[]

    switch (tagType) {
      case tagTypes.level:
        tagCodeIds = levelCodeIds
        break
      case tagTypes.zone:
        tagCodeIds = zoneCodeIds
        break
      case tagTypes.building:
        tagCodeIds = buildingCodeIds
        break
    }

    return this.getLocationTagLabel(
      activity.codes.filter(c => tagCodeIds.includes(c.id)),
    )
  }
}
