import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import './ViewModeToggle.scss'

// localization: translated

interface IViewModeToggle {
  state?: DesktopInitialState
  viewState: any
  viewModes: IModeSettings[]
}

export interface IModeSettings {
  viewMode: ViewModes
  getTooltipText: () => string
  itemStyle: string
  isDisabled?: boolean
}

export const getModeSettings = (mode: ViewModes, isDisabled?: boolean) => {
  switch (mode) {
    case ViewModes.Calendar:
      return {
        viewMode: ViewModes.Calendar,
        getTooltipText: () => Localization.translator.calendarView,
        itemStyle: 'views-toggle-calendar',
        isDisabled,
      }

    case ViewModes.Gantt:
      return {
        viewMode: ViewModes.Gantt,
        getTooltipText: () => Localization.translator.ganttView,
        itemStyle: 'views-toggle-gantt',
        isDisabled,
      }

    case ViewModes.List:
      return {
        viewMode: ViewModes.List,
        getTooltipText: () => Localization.translator.listView,
        itemStyle: 'views-toggle-list',
        isDisabled,
      }

    case ViewModes.Map:
      return {
        viewMode: ViewModes.Map,
        getTooltipText: () => Localization.translator.mapView,
        itemStyle: 'views-toggle-map',
        isDisabled,
      }
  }
}

@observer
export default class ViewModeToggle extends React.Component<IViewModeToggle> {
  private get currentViewMode() {
    return this.props.viewState.viewMode
  }

  public render() {
    return (
      <div className="views-toggle no-grow">
        {this.props.viewModes.map(item => this.renderMenuItem(item))}
      </div>
    )
  }

  private renderMenuItem({
    getTooltipText,
    itemStyle,
    viewMode,
    isDisabled,
  }: IModeSettings) {
    return (
      <div className="item-wrapper no-outline-container" key={viewMode}>
        <TooltipWrapper content={getTooltipText()}>
          <div
            className={classList({
              'views-toggle-item pointer': true,
              [itemStyle]: true,
              active: this.currentViewMode === viewMode,
              'disabled not-allowed': isDisabled,
            })}
            onClick={this.setViewMode.bind(this, viewMode, isDisabled)}
          />
        </TooltipWrapper>
      </div>
    )
  }

  @action.bound
  private setViewMode(mode: ViewModes) {
    if (this.currentViewMode !== mode) {
      this.props.viewState.viewMode = mode
    }
  }
}
