import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import Tree, {
  ITreeNode,
} from '~/client/src/desktop/views/ProjectSetUp/components/ActivityFilters/components/Tree'
import IActivityCodeNode from '~/client/src/shared/interfaces/IActivityCodeNode'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import ActivityCodeLabel from './ActivityCodeLabel'
import ActivityFilterSetupDialogStore, {
  ActivityFilterSetupDialogType,
} from './ActivityFilterSetupDialog.store'

interface IProps {
  store: ActivityFilterSetupDialogStore
  onDialogClosed?: () => void
  shouldSkipSavingDuringApplying?: boolean

  projectRolesStore?: ProjectRolesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  companiesStore?: CompaniesStore
}

const CATEGORY_LEVEL = 1

@inject(
  'projectRolesStore',
  'userProjectsStore',
  'projectMembersStore',
  'companiesStore',
)
@observer
export default class ActivityFilterSetupDialog extends React.Component<IProps> {
  public render() {
    const { isActivityFilterSetupDialogShown, submitCaption } = this.props.store

    return (
      <Dialog
        className="activity-filter-setup-dialog"
        isOpen={isActivityFilterSetupDialogShown}
        title={this.content.title}
        isCloseButtonShown={true}
        canOutsideClickClose={false}
        onClose={this.closeDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="text large mb20">{this.content.subTitle}</div>
          <div>
            <Tree
              content={this.tree}
              onNodeExpand={this.onTreeNodeExpanded}
              onNodeChecked={this.onTreeNodeChecked}
            />
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <div
              className="pointer text extra-large primary-blue"
              onClick={this.closeDialog}
            >
              {Localization.translator.cancel}
            </div>
            <div
              className="pointer text extra-large primary-blue bold"
              onClick={this.submitDialog}
            >
              {submitCaption}
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  @action.bound
  private closeDialog() {
    const { store, onDialogClosed } = this.props
    store.hideActivityFilterSetupDialog()
    if (onDialogClosed) {
      onDialogClosed()
    }
  }

  @action.bound
  private submitDialog() {
    const { store, onDialogClosed } = this.props
    store.applyActivityFilterSetupDialog(
      this.props.shouldSkipSavingDuringApplying,
    )

    onDialogClosed?.()
  }

  @action.bound
  private onTreeNodeChecked(node: ITreeNode) {
    const newValue = !node.isChecked
    this.setChildrenValue(node, newValue)
    this.setParentValue(node)
  }

  private setParentValue(node: ITreeNode) {
    let value = false
    while (node.parent) {
      const { parent } = node
      value = value || parent.children.some(child => child.isChecked)
      if (parent.level === CATEGORY_LEVEL) {
        this.setValue(parent, value)
      }
      node = parent
    }
  }

  private setChildrenValue(node: ITreeNode, value: boolean) {
    this.setValue(node, value)
    if (node.level === CATEGORY_LEVEL && node.children) {
      node.children.forEach(childNode => {
        this.setChildrenValue(childNode, value)
      })
    }
  }

  private setValue(node: ITreeNode, value: boolean) {
    if (node.isChecked === value) {
      return
    }

    const {
      setSelectedFilterDialogItem,
      setSelectedFilterDialogCategory,
      isSingleSelectMode,
    } = this.props.store

    if (isSingleSelectMode && !node.parent && value) {
      const currentlySelectedNode = this.tree.find(n => n.isChecked)
      if (currentlySelectedNode) {
        this.onTreeNodeChecked(currentlySelectedNode)
      }
    }

    node.isChecked = value
    const setSelectedValue = node.parent
      ? setSelectedFilterDialogItem
      : setSelectedFilterDialogCategory

    setSelectedValue(node.dataId, value)
  }

  @action.bound
  private onTreeNodeExpanded(node: ITreeNode) {
    this.props.store.expandNode(node.dataId)
  }

  private get tree(): ITreeNode[] {
    const { activityFilterSetupDialogType } = this.props.store
    switch (activityFilterSetupDialogType) {
      case ActivityFilterSetupDialogType.ActivityCodes:
      case ActivityFilterSetupDialogType.HiddenActivityCodes:
        return this.activityCodesTree

      case ActivityFilterSetupDialogType.ProjectMembers:
        return this.projectMembersTree

      default:
        if (activityFilterSetupDialogType) {
          throw new Error(
            activityFilterSetupDialogType + ' dialog type is unhandled',
          )
        }
    }
  }

  @computed
  private get activityCodesTree(): ITreeNode[] {
    const {
      activityCodeTypes,
      activityCodesTree,
      selectedFilterDialogCategoryIds,
      expandedDialogNodes,
    } = this.props.store

    const tree: ITreeNode[] = activityCodeTypes.map(({ name, id }) => {
      const node: ITreeNode = {
        component: <div className="text primary-blue large">{name}</div>,
        isExpanded: expandedDialogNodes.includes(id),
        isCheckboxDisplayed: true,
        isChecked: selectedFilterDialogCategoryIds.includes(id),
        dataId: id,
        level: CATEGORY_LEVEL,
      }

      node.children = this.generateActivityCodeTree(activityCodesTree[id], node)

      return node
    })

    return tree
  }

  private generateActivityCodeTree(
    activityCodes: IActivityCodeNode[],
    parent: ITreeNode,
  ): ITreeNode[] {
    if (!activityCodes || !activityCodes.length) {
      return null
    }

    const {
      selectedFilterDialogItemIds,
      expandedDialogNodes,
      hiddenActivityCodeIds,
    } = this.props.store

    return activityCodes
      .filter(({ code }) => !hiddenActivityCodeIds.includes(code.id))
      .map(({ code, children }) => {
        const node: ITreeNode = {
          component: (
            <ActivityCodeLabel
              code={code}
              hasChildren={!!children && !!children.length}
            />
          ),
          isExpanded: expandedDialogNodes.includes(code.id),
          isCheckboxDisplayed: true,
          isChecked: selectedFilterDialogItemIds.includes(code.id),
          dataId: code.id,
          level: parent.level + 1,
          parent,
        }
        node.children = this.generateActivityCodeTree(children, node)

        return node
      })
  }

  @computed
  private get projectMembersTree(): ITreeNode[] {
    const { store, projectMembersStore, userProjectsStore, companiesStore } =
      this.props
    const { selectedFilterDialogCategoryIds, expandedDialogNodes } = store

    const projectMembersByCompany = projectMembersStore.list.reduce(
      (companiesMap, user) => {
        const company =
          UserProject.getCompanyId(user, userProjectsStore) ||
          User.getDefaultCompanyName()

        if (!companiesMap[company]) {
          companiesMap[company] = []
        }

        companiesMap[company].push(user)

        return companiesMap
      },
      {},
    )

    const tree: ITreeNode[] = Object.keys(projectMembersByCompany).map(
      companyId => {
        const companyName = companiesStore.getCompanyNameById(companyId)
        const node: ITreeNode = {
          component: (
            <div className="text primary-blue large">{companyName}</div>
          ),
          isExpanded: expandedDialogNodes.includes(companyId),
          isCheckboxDisplayed: true,
          isChecked: selectedFilterDialogCategoryIds.includes(companyId),
          dataId: companyId,
          level: CATEGORY_LEVEL,
        }

        node.children = this.getUserNodes(
          projectMembersByCompany[companyId],
          node,
        )

        return node
      },
    )

    return tree
  }

  private getUserNodes(users: User[], companyNode: ITreeNode) {
    if (!users || !users.length) {
      return []
    }

    const { projectRolesStore, userProjectsStore, store } = this.props
    const { selectedFilterDialogItemIds, expandedDialogNodes } = store

    // convert flatten user list to hierarchical structure,
    // so the list will have only nodes of users reporting to nobody,
    // and children of a node are nodes of users reporting to the user
    const usersByReportsTo: ITreeNode[] = []

    // list for search convenience
    const flattenUserNodeList = []
    // start with all users
    let usersCopy = users.splice(0)
    // and go by levels from root node to leaf node
    while (usersCopy.length) {
      // find all parentless items
      const usersOfCurrentLevel = usersCopy.filter(
        user =>
          !usersCopy.some(
            parentCandidate => parentCandidate.id === user.reportsToId,
          ),
      )

      if (!usersOfCurrentLevel.length) {
        console.error('circle reportsTo dependency')
        break
      }

      // for each user create a node and add at a corresponding level of hierarchy
      usersOfCurrentLevel.forEach(user => {
        const { id, reportsToId } = user

        const parentNode = flattenUserNodeList.find(
          parentCandidate => parentCandidate.dataId === reportsToId,
        )

        const node = {
          component: (
            <div className="text large light">
              {User.getFullNameToDisplay(user, this.props.userProjectsStore)}
              {UserProject.getAllRolesAsString(
                user,
                userProjectsStore,
                projectRolesStore,
              )}
            </div>
          ),
          dataId: id,
          children: [],
          isExpanded: expandedDialogNodes.includes(id),
          isCheckboxDisplayed: true,
          isChecked: selectedFilterDialogItemIds.includes(id),
          level: (parentNode ? parentNode.level : companyNode.level) + 1,
          parent: parentNode || companyNode,
        }

        if (parentNode) {
          parentNode.children.push(node)
        } else {
          usersByReportsTo.push(node)
        }

        flattenUserNodeList.push(node)
      })

      // remove processed items from the list
      usersCopy = usersCopy.filter(user => !usersOfCurrentLevel.includes(user))
    }

    return usersByReportsTo
  }

  @computed
  private get content() {
    const {
      activityFilterSetupDialogType,
      activityFilterSetupDialogSubtype,
      isSingleSelectMode,
      getFilter,
    } = this.props.store

    let title = ''
    let subTitle = ''
    switch (activityFilterSetupDialogType) {
      case ActivityFilterSetupDialogType.ActivityCodes: {
        const filter = getFilter(activityFilterSetupDialogSubtype)
        title = Localization.translator.selectActivityCodesLinkedToX(
          filter.getCaption(),
          !isSingleSelectMode,
        )
        subTitle = isSingleSelectMode
          ? Localization.translator.selectionOf1ActivityCodeIsPermitted
          : Localization.translator.multipleSelectionsArePermitted

        break
      }

      case ActivityFilterSetupDialogType.ProjectMembers: {
        const filter = getFilter(activityFilterSetupDialogSubtype)
        title = Localization.translator.selectProjectMembersLinkedToX(
          filter.getCaption(),
          !isSingleSelectMode,
        )
        subTitle = isSingleSelectMode
          ? Localization.translator.selectionOf1ProjectMemberIsPermitted
          : Localization.translator.multipleSelectionsArePermitted

        break
      }

      case ActivityFilterSetupDialogType.HiddenActivityCodes: {
        title = Localization.translator.excludeSectionsOfTheScheduleOptional
        subTitle =
          Localization.translator.activityFilterDialogDescriptions.hiddenCodes
        break
      }
    }

    return {
      title: title.toUpperCase(),
      subTitle,
    }
  }
}
