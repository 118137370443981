import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import { Loader } from '~/client/src/shared/components/Loader'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import EventStore from '~/client/src/shared/stores/EventStore/Events.store'
import { GET_SCHEDULE } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import VerticalObjectsStore from '~/client/src/shared/stores/domain/VerticalObjects.store'
import WbsStore from '~/client/src/shared/stores/domain/WbsStore/Wbs.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivityGanttOrListViewStore from './ActivityGanttOrListView.store'
import TableOffsetsColumnCollapseStore from './TableOffsetsColumnCollapse.store'
import UnsafeActivityListView from './components/ActivityListView/ActivityListView'
import UnsafeSimpleGanttChart from './components/SimpleGantChart/GanttChartTable'

const SimpleGanttChart = withErrorBoundary(UnsafeSimpleGanttChart)
const ActivityListView = withErrorBoundary(UnsafeActivityListView)

interface IProps {
  activityListStore: DesktopActivityListStore
  eventsStore?: EventStore
  state?: DesktopInitialState
  activityFiltersStore?: ActivityFiltersStore
  locationStore?: LocationsStore
  activityCodeTypesStore?: ActivityCodeTypesStore
  common?: DesktopCommonStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  projectDateStore?: ProjectDateStore
  statusUpdatesStore?: StatusUpdatesStore
  graphExecutorStore?: GraphExecutorStore
  projectMembersStore?: ProjectMembersStore
  companiesStore?: CompaniesStore
  wbsStore?: WbsStore
  tagsStore?: TagsStore
  threadsStore?: ThreadsStore
  messagesStore?: MessagesStore
  constraintsStore?: ConstraintsStore
  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  verticalObjectsStore?: VerticalObjectsStore
  gatesStore?: GatesStore
  routesStore?: RoutesStore
}

@inject(
  'eventsStore',
  'state',
  'activityFiltersStore',
  'locationStore',
  'activityCodeTypesStore',
  'common',
  'activityAssignmentsStore',
  'projectDateStore',
  'statusUpdatesStore',
  'graphExecutorStore',
  'projectMembersStore',
  'companiesStore',
  'wbsStore',
  'tagsStore',
  'threadsStore',
  'messagesStore',
  'constraintsStore',
  'offloadingEquipmentsStore',
  'verticalObjectsStore',
  'gatesStore',
  'routesStore',
)
@observer
export default class ActivityGanttOrListView extends React.Component<IProps> {
  private readonly store: ActivityGanttOrListViewStore
  private readonly tableOffsetsColumnCollapseStore: TableOffsetsColumnCollapseStore
  private readonly clearPostEventCallback: () => void

  public constructor(props: IProps) {
    super(props)

    this.tableOffsetsColumnCollapseStore = new TableOffsetsColumnCollapseStore(
      props.activityFiltersStore,
      props.state,
    )

    this.store = new ActivityGanttOrListViewStore(
      props.activityListStore,
      props.eventsStore,
      props.state,
      props.activityFiltersStore,
      props.locationStore,
      props.activityCodeTypesStore,
      props.common,
      props.activityAssignmentsStore,
      props.projectDateStore,
      props.statusUpdatesStore,
      props.graphExecutorStore,
      props.wbsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.tagsStore,
      props.threadsStore,
      props.messagesStore,
      props.offloadingEquipmentsStore,
      props.verticalObjectsStore,
      props.gatesStore,
      props.routesStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.loadWbs,
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback?.()
  }

  public componentDidMount() {
    const { activityListStore, state } = this.props
    const { selectedActivity, moveToActivityStart } = activityListStore
    const { loadConstraints, listenToConstraints } = this.props.constraintsStore

    if (selectedActivity) {
      state.activityList.isActivityDetailsPanelDisplayed = true
      moveToActivityStart(selectedActivity)
    }

    loadConstraints()
    listenToConstraints()
  }

  public componentDidUpdate(prevProps: IProps) {
    // force re-listen if project has been switched
    if (
      prevProps.state.activeProject.id !== this.props.state.activeProject.id
    ) {
      const { loadConstraints, listenToConstraints } =
        this.props.constraintsStore
      loadConstraints()
      listenToConstraints()
    }
  }

  public render() {
    if (this.store.areActivitiesBeingUpdated) {
      return <Loader />
    }

    const { viewMode } = this.props.state.activityList
    const { selectedBandsOption } = this.props.state.filters

    return (
      <>
        {viewMode === ViewModes.Gantt && (
          <SimpleGanttChart
            rows={this.store.expandedOrderedTableRowsList}
            store={this.store}
            activityListStore={this.props.activityListStore}
            tableOffsetsColumnCollapseStore={
              this.tableOffsetsColumnCollapseStore
            }
            selectedBandsOption={selectedBandsOption}
          />
        )}
        {viewMode === ViewModes.List && (
          <ActivityListView
            rows={this.store.expandedOrderedTableRowsList}
            store={this.store}
            activityListStore={this.props.activityListStore}
            tableOffsetsColumnCollapseStore={
              this.tableOffsetsColumnCollapseStore
            }
          />
        )}
      </>
    )
  }

  private loadWbs = ({ event: [eventType] }: EventContext) => {
    if (eventType === GET_SCHEDULE) {
      this.store.loadWbs()
    }
  }
}
