import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import FilterResetter from '~/client/src/desktop/components/FilterResetter/FilterResetter'
import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import { NOOP } from '~/client/src/shared/utils/noop'

interface IProps {
  store: LogisticsFilterStore
  forceCloseMap: { [filterType: string]: boolean }
  eventsStore: DesktopEventStore
  filters: IFilters
  dynamicOverflowListStore: DynamicOverflowListStore
  viewMode: ViewModes

  onResetClick?(): void
}

const MORE_BTN_WIDTH = 55
const MIN_CONTAINER_WIDTH = 200

@observer
export default class LogisticsFilter extends React.Component<IProps> {
  @observable private isOverflowedModalShown: boolean = false
  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    props.store.resetAllFilters()

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handleDesktopPostEventCallback,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      forceCloseMap,
      filters: { fieldsMap },
      viewMode,
      dynamicOverflowListStore,
    } = this.props

    if (forceCloseMap) {
      Object.keys(fieldsMap).forEach(filterType => {
        if (forceCloseMap[filterType]) {
          fieldsMap[filterType].isFilterShown = false
        }
      })
    }

    if (viewMode !== prevProps.viewMode) {
      const { filterStoresByTypeMap } = prevProps.store
      Object.keys(filterStoresByTypeMap).forEach(filterType => {
        filterStoresByTypeMap[filterType].clickOnCancel()
      })

      dynamicOverflowListStore.enableRecalculation()
    }
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    return (
      <MenuCloser
        className="row full-height logistic-filter x-start relative flex-basis300"
        closeMenu={this.hideOverflowedFiltersModal}
        isOpen={this.isOverflowedModalShown}
      >
        {this.renderHandleButtons()}
        {this.renderPopups()}
      </MenuCloser>
    )
  }

  private renderPopups() {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return <SuperFilter key={filterType} filterStore={store} />
    })
  }

  private renderHandleButtons = (): JSX.Element => {
    return (
      <DynamicOverflowList
        items={Object.keys(this.storesMap).map(filterType => (
          <SuperFilterHandleButton
            className="no-grow"
            key={filterType}
            filterStore={this.storesMap[filterType]}
          />
        ))}
        store={this.props.dynamicOverflowListStore}
        elementsClassName="vertical-align-middle"
        moreButtonWidth={MORE_BTN_WIDTH}
        minContainerWidthConstraint={MIN_CONTAINER_WIDTH}
      >
        {({
          visibleElements,
          overflowElements,
          containerRefSetter,
          setAdditionalElementsRef,
        }) => (
          <div
            className={classList({
              'overflowed-filters-list': true,
              nowrap: visibleElements.length === 1,
            })}
            ref={containerRefSetter}
          >
            {visibleElements}
            <div className="inline-block vertical-align-middle">
              {this.renderOverflowedFiltersLabel(overflowElements)}
            </div>
            <div
              className="inline-block pl5 vertical-align-middle"
              ref={setAdditionalElementsRef}
            >
              <FilterResetter color="light" onReset={this.resetAllFilters} />
            </div>
          </div>
        )}
      </DynamicOverflowList>
    )
  }

  private renderOverflowedFiltersLabel = (
    hiddenElements: JSX.Element[],
  ): JSX.Element => {
    if (!hiddenElements?.length) {
      return null
    }

    return (
      <>
        <FilterHandleButton
          isSelected={this.isOverflowedModalShown}
          className="text large bold px10"
          onClick={this.toggleOverflowedFiltersModal}
        >
          ...
        </FilterHandleButton>
        {this.isOverflowedModalShown && (
          <div className="absolute col pa10 z-index-100 bg-white mt10 ba-grey">
            {hiddenElements.map(item => (
              <div key={item.key} className="pa5">
                {item}
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }

  private resetAllFilters = () => {
    this.props.store.resetAllFilters()
    this.props.onResetClick?.()
  }

  private handleDesktopPostEventCallback = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (RESET_ALL_FILTERS === eventType) {
      Object.keys(this.storesMap).forEach(filterType => {
        const filterStore = this.storesMap[filterType]

        filterStore.clickOnSelectAll()
        filterStore.clickOnApply()
      })

      this.hideOverflowedFiltersModal()
    }
  }

  @action.bound
  private toggleOverflowedFiltersModal() {
    this.isOverflowedModalShown = !this.isOverflowedModalShown
  }

  @action.bound
  private hideOverflowedFiltersModal() {
    this.isOverflowedModalShown = false
  }
}
