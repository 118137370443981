import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import { View } from '~/client/src/shared/components/Layout'
import SharedProjectsView from '~/client/src/shared/views/Projects/Projects'

import './Projects.scss'

@observer
export class ProjectsView extends React.Component {
  public render() {
    return (
      <div className="desktop-projects-view col x-center y-center full-height ma-x-auto w375">
        <View className="login-view-container full-width">
          <Icons.LogoWithTitle className="py20 logo" />
          <SharedProjectsView {...this.props} />
        </View>
      </div>
    )
  }
}
