import { IMutation, IQuery, ISubscription, InviteStatus } from '~/client/graph'
import { GetAnalyticsSettingByProjectIdDocument } from '~/client/graph/operations/generated/AnalyticsSettings.generated'
import { GetPresentationUserTokenDocument } from '~/client/graph/operations/generated/Auth.generated'
import { DeliveriesByProjectIdDocument } from '~/client/graph/operations/generated/Deliveries.generated'
import { UnreadNotificationsDocument } from '~/client/graph/operations/generated/Notifications.generated'
import {
  GetPresentationSettingsDocument,
  ListenToPresentationSettingsDocument,
} from '~/client/graph/operations/generated/PresentationSettings.generated'
import { DownloadUsersCsvByProjectDocument } from '~/client/graph/operations/generated/Users.generated'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopRootStore from '~/client/src/desktop/stores/DesktopRoot.store'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Config from '~/client/src/shared/Config'
import { isSignUpPageRequested } from '~/client/src/shared/constants/commonRoutes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Project from '~/client/src/shared/models/Project'
import { IEvent } from '~/client/src/shared/stores/EventStore/BaseEvents.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { shouldRedirectToAuthLinkingPage } from '~/client/src/shared/stores/EventStore/handlers'
import { FilterSourceType } from '~/client/src/shared/stores/substates/ActivityFilterInfo'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import getIntegrationsConfigName from '~/client/src/shared/utils/getIntegrationsConfigName'

import { generateAndSendDesktopNotifications } from '../desktopNotificationsUtils'
import registerDesktopChangeEvents from './desktopChangeEvents'

export default function (
  eventsStore: DesktopEventStore,
  rootStore: DesktopRootStore,
) {
  eventsStore.on(e.INIT_APP_SELECT_PROJECT, state => {
    const [firstProjectDto] = rootStore.projectsStore.list
    if (firstProjectDto) {
      state.activeProject = Project.fromDto(firstProjectDto)
    }

    return {
      dispatch: [e.GET_AUTH_USER_PROJECT],
    }
  })

  /**
   * Complete initialization if a project was located
   */
  eventsStore.on(e.INIT_APP_3, state => {
    state.loading.set(e.INIT_APP_2, false)

    if (shouldRedirectToAuthLinkingPage(rootStore)) {
      return {
        dispatchN: [
          [e.LOAD_AND_LISTEN_TO_COMPANIES],
          [e.DISPLAY_SAVE_PASSWORD],
        ],
      }
    }

    if (!state.activeProject.id) {
      return
    }

    state.delivery.compactView.activeDate =
      rootStore.projectDateStore.startOfDay(new Date())

    if (Config.TENANTLESS_MODE) {
      state.didInitialize = true
      return
    }

    const dispatchN: any[] = [
      [e.GET_PROJECT_CONFIG],
      [
        e.GET_CONFIGURATION,
        getIntegrationsConfigName(state.activeProject.id),
        e.PROJECT_INTEGRATIONS_CONFIGS_RECEIVED,
      ],
      [e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_ADDRESS],
      [e.LISTEN_TO_PROJECT],
      [e.LOAD_AND_LISTEN_TO_PROJECT_COLORING_OPTIONS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_SU_OPTIONS],
      [e.LOAD_AND_LISTEN_TO_ACTIVITIES_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_FORMS_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_ZONEMAP_THRESHOLDS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY],
      [e.LOAD_AND_LISTEN_TO_FORM_CATEGORIES],
      [e.LOAD_AND_LISTEN_TO_PERMIT_TYPES],
      [e.REGISTER_SERVICE_WORKER],
      [e.GET_PROJECT_MEMBERS],
      [e.LOAD_AND_LISTEN_TO_OPERATION_RULES],
      [e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_ROLES],
      [e.LOAD_AND_LISTEN_TO_PROJECT_TRADES],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMITS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTIONS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES],
      [e.LOAD_LOCATIONS_AND_LISTEN_CHANGES],
      [e.LOAD_AND_LISTEN_TO_COMPANIES],
      [e.LOAD_AND_LISTEN_TO_USER_PROJECTS],
      [e.LOAD_AND_LISTEN_TO_SITEMAPS],
      [e.LOAD_AND_LISTEN_TO_GLOBE_VIEWS],
      [e.LISTEN_TO_SITEMAP_ITEMS_DATA],
      [e.LISTEN_TO_GLOBE_VIEW_ITEMS_DATA],
      [e.LOAD_AND_LISTEN_TO_BASEMAPS],
      [e.LOAD_AND_LISTEN_TO_SITEMAP_ITEMS],
      [e.LOAD_ANNOUNCEMENTS_AND_LISTEN_CHANGES],
      [e.LOAD_ANNOUNCEMENT_ASSIGNMENTS_AND_LISTEN_CHANGES],
      [e.LOAD_AND_LISTEN_TO_MESSAGES],
      [e.LOAD_AND_LISTEN_TO_PHOTOS],
      [e.LOAD_AND_LISTEN_TO_SCANNERS],
      [e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES],
      [e.LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS],
      [e.LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS],
      [e.LOAD_CAST_ASSIGNMENTS],
      [e.LOAD_CLOSURES_AND_LISTEN_CHANGES],
      [e.LOAD_CLOSURE_ASSIGNMENTS_AND_LISTEN_CHANGES],
      [e.LOAD_CLOSURE_FOLLOWINGS_AND_LISTEN_CHANGES],
      [e.LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION],
      [e.LISTEN_TO_USERS],
      [e.UPDATE_USER_PREFERENCES],
      [e.LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS],
      [e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES],
      [e.LOAD_AND_LISTEN_TO_MATERIALS],
    ]

    const eventsForDesktopNotifications = []

    if (
      state.projectTypeOptions.isTrackerDisabled ||
      !state.userActiveProjectSettings?.isActivityViewAvailable
    ) {
      dispatchN.push([e.CHART_LOADED_AND_INITIALIZED])
    } else {
      dispatchN.push(
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS],
        [e.LOAD_AND_LISTEN_TO_THREADS],
        [e.LOAD_AND_LISTEN_TO_CONTENT_OBJECTS],
        state.activeScheduleId
          ? [e.GET_SCHEDULE]
          : [e.CHART_LOADED_AND_INITIALIZED],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_PRESETS],
        [e.GET_ACTIVITY_CUSTOM_FILTERS],
        [e.GET_HIERARCHY_CONFIGURATION],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS],
        [e.LOAD_AND_LISTEN_TO_STATUS_UPDATES],
      )
      eventsForDesktopNotifications.push(e.STATUS_UPDATES_RECEIVED)
    }

    if (!state.isDeliveriesDisabled) {
      dispatchN.push([e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS])
    }

    if (!state.isTilesetsDisabled) {
      dispatchN.push([e.LOAD_TILESETS], [e.LISTEN_TO_TILESETS])
    }

    if (!state.projectTypeOptions.isDeliveriesDisabled) {
      dispatchN.push([e.LOAD_AND_LISTEN_TO_ALL_DELIVERY_ATTRIBUTES])
    }

    if (!state.projectTypeOptions.isFormsDisabled) {
      dispatchN.push([e.GET_WORKFLOW_CUSTOM_FILTERS])
    }

    if (!state.projectTypeOptions.isDeliveriesDisabled) {
      dispatchN.push(
        [e.LISTEN_AND_LOAD_DELIVERIES],
        [e.GET_DELIVERY_CUSTOM_FILTERS],
        [e.LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS],
        [e.LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS],
        [e.LISTEN_TO_RECURRING_DELIVERIES_SETTING],
      )
      eventsForDesktopNotifications.push(
        e.LOAD_DELIVERIES_SUCCESS,
        e.LOAD_LOCATIONS_SUCCESS,
        e.COMPANIES_RECEIVED,
      )
    } else {
      rootStore.deliveriesStore.isDataReceived = true
    }

    if (!eventsForDesktopNotifications.length) {
      dispatchN.unshift([e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT])
    }

    if (!state.isAnalyticsDisabled) {
      dispatchN.push([e.LOAD_ANALYTICS_SETTING])
    }

    if (state.userActiveProjectSettings) {
      state.didInitialize = true

      if (state.userActiveProjectSettings.isPending) {
        state.userActiveProjectSettings.setInviteStatus(InviteStatus.Accepted)

        dispatchN.push([
          e.SAVE_USER_PROJECTS,
          [state.userActiveProjectSettings.toDto()],
        ])
      }
    } else {
      state.didInitialize = false
    }

    console.log(`active schedule id: ${state.activeScheduleId}`)

    return {
      dispatchN,
      flow: eventsForDesktopNotifications.length && {
        startWith: [],
        rules: [
          {
            when: 'all',
            events: eventsForDesktopNotifications,
            dispatch: [e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT],
            successArgs: [],
          },
        ],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_TYPE_OPTIONS_RECEIVED,
    (state, { projectTypeOptions }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS, false)

      state.projectTypeOptions =
        projectTypeOptions.data[0] || state.getDefaultProjectTypeOptions()

      return {
        dispatch: [e.LISTEN_TO_PROJECT_TYPE_OPTIONS],
      }
    },
  )

  eventsStore.on(
    e.GET_ACTIVITY_FILTERS_SETTINGS_SUCCESS,
    (state, { activityFiltersSettings }: IQuery) => {
      state.loading.set(e.GET_ACTIVITY_FILTERS_SETTINGS, false)

      rootStore.activityFiltersStore.receiveSettings(activityFiltersSettings)
      state.filters.locationsMap = {}
      const filterMap = state.activityFiltersSettings.filterInfoMap
      Object.keys(filterMap).forEach(filterType => {
        if (!filterMap[filterType].isDisabled) {
          state.filters.locationsMap[filterType] = new UIFilterInfo()
        }
      })
    },
  )

  eventsStore.on(e.GET_PROJECT_MEMBERS_SUCCESS, (state, { users }: IQuery) => {
    state.loading.set(e.GET_PROJECT_MEMBERS, false)

    rootStore.projectMembersStore.receiveList(users.data)

    // TODO: Should it be here?
    const map = state.activityFiltersSettings.filterInfoMap
    const projectMemberFilterTypes = Object.keys(map).filter(filterType => {
      return (
        !map[filterType].isDisabled &&
        map[filterType].sourceType === FilterSourceType.ProjectMember
      )
    })

    if (projectMemberFilterTypes.length) {
      const dispatchN: IEvent[] = projectMemberFilterTypes.map(type => [
        e.RESET_FILTER,
        type,
      ])
      return {
        dispatchN,
      }
    }
  })

  eventsStore.on(e.INIT_PROJECT_INTEGRATIONS, () => {
    rootStore.concreteDirectIntegrationStore.init()
    rootStore.maturixProjectsStore.init()
  })

  eventsStore.on(e.GET_SCHEDULE_SUCCESS, (state, { schedule }: IQuery) => {
    state.activeSchedule = {
      updatedAt: schedule.updatedAt,
      activityQuantity: schedule.activityQuantity,
      companyQuantity: schedule.companyQuantity,
      fileName: schedule.fileName,
      id: schedule.id,
      projectId: schedule.projectId,
    }

    // release event loop to avoid issue with incorrect computing props by MobX
    // after switching between project with large schedule
    // TODO: this needs to be double-checked
    setTimeout(() => {
      eventsStore.dispatch(e.CHART_LOADED_AND_INITIALIZED)
    }, 0)

    return {
      dispatchN: [
        [e.GET_ACTIVITY_FILTERS_SETTINGS],
        [e.LISTEN_TO_ACTIVITY_CODE_RELATIONSHIP],
        [e.LISTEN_TO_ACTIVITY],
        [e.LISTEN_TO_ACTIVITY_CODE_TYPE],
        [e.LISTEN_TO_ACTIVITY_CODE],
        [e.LISTEN_TO_RESOURCE],
        [e.LISTEN_TO_ACTIVITY_RESOURCE_RELATIONSHIP],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS],
      ],
    }
  })

  eventsStore.on(
    e.GET_USERS_FROM_PROCORE,
    (state, callbackSuccess, callbackError) => {
      return {
        cmd: {
          cmd: {
            execute: () => rootStore.procoreService.getPopulatedCompanies(),
          },
          onSuccess: [e.RUN_CALLBACK, callbackSuccess],
          onError: [e.RUN_CALLBACK, callbackError],
        },
      }
    },
  )

  eventsStore.on(e.CHART_LOADED_AND_INITIALIZED, state => {
    if (state.userActiveProjectSettings?.isPresentationUser) {
      return {
        dispatchN: [[e.RESET_ALL_FILTERS], [e.START_PRESENTATION]],
      }
    } else {
      return {
        dispatch: [e.RESET_ALL_FILTERS],
      }
    }
  })

  eventsStore.on(
    e.STATUS_UPDATES_RECEIVED,
    (state, { statusUpdates }: IQuery) => {
      const { statusUpdatesStore } = rootStore
      statusUpdatesStore.receiveStatusUpdates(statusUpdates?.data || [])

      const dispatchN = []
      if (statusUpdates?.data.length) {
        dispatchN.push([e.CALCULATE_ACTIVITY_ACTUAL_DATES])
      }

      state.loading.set(e.LOAD_AND_LISTEN_TO_STATUS_UPDATES, false)
      dispatchN.push([e.LISTEN_TO_STATUS_UPDATES])
      return {
        dispatchN,
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT, state => {
    return {
      graphQuery: {
        query: UnreadNotificationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SEND_DESKTOP_NOTIFICATIONS],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT],
      },
      dispatch: [e.LISTEN_TO_NOTIFICATIONS_COUNT],
    }
  })

  eventsStore.on(
    e.SEND_DESKTOP_NOTIFICATIONS,
    (state, { unreadNotifications }: IQuery) => {
      state.unreadNotificationsCount = unreadNotifications.length
      if (!state.pushNotificationEntityId) {
        generateAndSendDesktopNotifications(unreadNotifications, rootStore)
      }
    },
  )

  eventsStore.on(e.GET_PRESENTATION_USER_TOKEN, state => {
    state.loading.set(e.GET_PRESENTATION_USER_TOKEN, true)

    return {
      graphMutation: {
        mutation: GetPresentationUserTokenDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GET_PRESENTATION_USER_TOKEN_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_PRESENTATION_USER_TOKEN],
      },
    }
  })

  eventsStore.on(
    e.GET_PRESENTATION_USER_TOKEN_SUCCESS,
    (state, { getPresentationUserToken: inviteKey }: IMutation) => {
      state.loading.set(e.GET_PRESENTATION_USER_TOKEN, false)

      return {
        dispatch: [e.LOGIN_WITH_INVITE_KEY, inviteKey],
      }
    },
  )

  eventsStore.on(e.START_PRESENTATION, () => {
    return {
      flow: {
        startWith: [[e.LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS]],
        rules: [
          {
            when: 'on',
            event: e.PRESENTATION_SETTINGS_RECEIVED,
            dispatch: [e.PLAY_PRESENTATION],
            shouldTerminate: true,
          },
        ],
      },
    }
  })

  eventsStore.on(e.PLAY_PRESENTATION, () => {
    rootStore.presentationModeStore.playPresentation()
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS, true)
    return {
      graphQuery: {
        query: GetPresentationSettingsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PRESENTATION_SETTINGS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS],
      },
    }
  })

  eventsStore.on(
    e.PRESENTATION_SETTINGS_RECEIVED,
    (state, { presentationSettings }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS, false)
      state.updatePresentationSettings({ ...presentationSettings.data[0] })

      return {
        dispatch: [e.LISTEN_TO_PRESENTATION_SETTINGS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_PRESENTATION_SETTINGS, state => {
    return {
      graphSubscription: {
        query: ListenToPresentationSettingsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PRESENTATION_SETTINGS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PRESENTATION_SETTINGS],
      },
    }
  })

  eventsStore.on(
    e.PRESENTATION_SETTINGS_UPDATED,
    (state: DesktopInitialState, { presentationSettings }: ISubscription) => {
      state.updatePresentationSettings(presentationSettings.item)
    },
  )

  eventsStore.on(e.TRY_UNAUTHORIZED_MODE, state => {
    if (isSignUpPageRequested()) {
      state.isUnauthorizedMode = true
    }

    return { dispatch: [e.TRY_UNAUTHORIZED_MODE_RESULT] }
  })

  eventsStore.on(e.GO_TO_AVAILABLE_PROJECT, state => {
    const availableProjectCode =
      rootStore.projectsStore.getAvailableProjectCode()

    if (!availableProjectCode) {
      return alert(Localization.translator.thereIsNoProject)
    }

    state.initProjectCode = availableProjectCode
    rootStore.common.displaySameViewWithUpdatedProjectId()

    return { dispatch: [e.INIT_APP_2] }
  })

  eventsStore.on(e.DOWNLOAD_PROJECT_MEMBERS, (state, onSuccess, onError) => {
    state.loading.set(e.DOWNLOAD_PROJECT_MEMBERS, true)

    return {
      graphMutation: {
        mutation: DownloadUsersCsvByProjectDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [
          e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
          e.DOWNLOAD_PROJECT_MEMBERS,
          onSuccess,
        ],
        onError: [
          e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
          e.DOWNLOAD_PROJECT_MEMBERS,
          onError,
        ],
      },
    }
  })

  eventsStore.on(e.LOAD_DELIVERIES, state => {
    state.loading.set(e.LOAD_DELIVERIES, true)

    return {
      graphQuery: {
        query: DeliveriesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_DELIVERIES_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOAD_DELIVERIES],
      },
    }
  })

  eventsStore.on(e.RESET_MATERIALS_FILTERS, (state: DesktopInitialState) => {
    Object.values(state.materialFilters.fieldsMap || {}).forEach(field =>
      field.clear(),
    )
  })

  eventsStore.on(e.LOAD_ANALYTICS_SETTING, (state: DesktopInitialState) => {
    state.loading.set(e.LOAD_ANALYTICS_SETTING, true)

    return {
      graphQuery: {
        query: GetAnalyticsSettingByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.ANALYTICS_SETTING_RECEIVED],
        onError: [e.REQUEST_ERROR],
      },
    }
  })

  eventsStore.on(
    e.ANALYTICS_SETTING_RECEIVED,
    (state, { analyticsSettings }: IQuery) => {
      state.loading.set(e.LOAD_ANALYTICS_SETTING, false)

      rootStore.analyticsSettingsStore.receiveData(analyticsSettings)
    },
  )

  registerDesktopChangeEvents(eventsStore, rootStore)
}
