import * as React from 'react'

import { classList } from 'react-classlist-helper'

import ActivityCode from '~/client/src/shared/models/ActivityCode'

interface IProps {
  code: ActivityCode
  hasChildren: boolean
}

export default class ActivityCodeLabel extends React.Component<IProps> {
  public render() {
    const { code, hasChildren } = this.props
    return (
      <div
        className={classList({
          'row text large': true,
          'primary-blue': hasChildren,
          light: !hasChildren,
        })}
      >
        <div>{code.shortName}</div>
        <div className="no-grow" style={{ flexBasis: 250 }}>
          {code.name}
        </div>
      </div>
    )
  }
}
