import { action, computed, observable } from 'mobx'

import { IDeliveryMaterial } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Delivery from '~/client/src/shared/models/Delivery'
import { UPDATE_DELIVERY_MATERIALS } from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryUnitsStore from '~/client/src/shared/stores/domain/DeliveryUnits.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import { NO_DIGITS_AND_SPACES_IN_START_PATTERN } from '~/client/src/shared/utils/regExpPatterns'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

// localization: no display text to translate

export const ADDITIONAL_TAGS_WIDTH = 12

export default class DeliveryMaterialsCellStore {
  @observable private deliveryId: string = null

  public constructor(
    deliveryId: string,
    private readonly materialsStore: MaterialsStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly eventsStore: DesktopEventStore,
    private readonly deliveriesStore: DeliveriesStore,
    private readonly deliveryUnitsStore: DeliveryUnitsStore,
  ) {
    this.init(deliveryId)
  }

  public get isSubcategoryShown(): boolean {
    return !this.appState.projectMaterialOptions.isCSISubCategoriesDisabled
  }

  public get isCSIShown(): boolean {
    return !this.appState.projectMaterialOptions.isCSIDisabled
  }

  public get isAnyCategoryEnabled(): boolean {
    return this.isSubcategoryShown || this.isCSIShown
  }

  @computed
  public get isLoading(): boolean {
    const { loading } = this.appState
    return loading.get(UPDATE_DELIVERY_MATERIALS + this.deliveryId)
  }

  public set isLoading(value: boolean) {
    const { loading } = this.appState
    loading.set(UPDATE_DELIVERY_MATERIALS + this.deliveryId, value)
  }

  @computed
  public get deliveryMaterialsLabels(): string[] {
    if (this.isAnyCategoryEnabled) {
      return this.delivery.materialIds
        .map(id => this.getCategoryLabel(id))
        .filter(m => m)
    }

    return this.deliveryMaterials?.map(d => d.note).filter(m => m)
  }

  @computed
  public get deliveryMaterials(): IDeliveryMaterial[] {
    return this.delivery.materials
  }

  @computed
  public get isMaterialNoteHidden(): boolean {
    return this.appState.delivery.hiddenFields[FieldIds.MATERIAL_NOTE]
  }

  @computed
  public get isMaterialQuantityHidden(): boolean {
    return this.appState.delivery.hiddenFields[FieldIds.MATERIAL_QUANTITY]
  }

  @computed
  public get isAddBtnEnabled(): boolean {
    const { userActiveProjectSettings, user } = this.appState
    return (
      !this.delivery.isFromConcreteDirect &&
      (userActiveProjectSettings.isSuperUser ||
        user.isDeliveryRequester(this.delivery))
    )
  }

  @computed
  private get delivery(): Delivery {
    return this.deliveriesStore.byId.get(this.deliveryId)
  }

  @action.bound
  public init(deliveryId: string) {
    this.deliveryId = deliveryId
  }

  public getCategoryLabel = (materialId: string): string => {
    const material = this.materialsStore.getInstanceById(materialId)
    const categoryName = this.materialCategoryStore.getCategoryNameById(
      material?.categoryId,
    )

    return (
      this.isSubcategoryShown
        ? material?.productName || categoryName
        : categoryName
    )?.replace(NO_DIGITS_AND_SPACES_IN_START_PATTERN, EMPTY_STRING)
  }

  public getMaterialUnitName = (unitId: string): string => {
    return this.deliveryUnitsStore.getInstanceById(unitId, true)?.name
  }

  public getFieldName = (id: FieldIds): string => {
    return this.appState.getDeliveryFieldName(id)
  }

  private get appState(): DesktopInitialState {
    return this.eventsStore.appState
  }
}
