import * as React from 'react'

import { observer } from 'mobx-react'

import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'

import LocationFilterStore from './LocationFilter.store'

// localization: no display text to translate

interface IProps {
  store: LocationFilterStore
}

@observer
export default class LocationFilterPopup extends React.Component<IProps> {
  public render() {
    const storesMap = this.props.store.filterStoresByTypeMap
    return Object.keys(storesMap).map(filterType => {
      const store = storesMap[filterType]
      return <SuperFilter key={filterType} filterStore={store} />
    })
  }
}
