import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DeleteSavedViewConfirmDialog from '../SavedViews/components/DeleteSavedViewConfirmDialog'
import NewViewDialog from '../SavedViews/components/NewViewDialog'
import SavedViewsDialog from '../SavedViews/components/SavedViewsDialog'
import DesktopCustomWorkflowsFiltersStore from './DesktopCustomWorkflowsFilters.store'

import '../SavedFilters/SavedFilters.scss'

// localization: translated

interface IProps {
  store: DesktopCustomWorkflowsFiltersStore
  forceClose?: boolean
}

@observer
export default class CustomWorkflows extends React.Component<IProps> {
  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.props.store.isShown = false
      this.props.store.shouldSaveFilterDialogShow = false
    }
  }

  public render() {
    const {
      editableFilterName,
      editableFilterIsPublic,
      saveCustomFilter,
      isShown,
      shouldSaveFilterDialogShow,
      toggleAddSavedViewDialog,
      toggleSavedViewsDialog,
      closeSavedFilters,
      onFilterNameChange,
      onFilterIsPublicToggle,
      selectedFiltersCount,
      filtersMap,
      groupByCaption,
      unsetDeletableCustomFilterId,
      deleteCustomFilterById,
      deletableCustomFilter,
    } = this.props.store

    return (
      <div className="row saved-filters no-grow relative">
        <div
          className={classList({
            'text grey large pointer no-grow mx15': true,
            blue: shouldSaveFilterDialogShow && !isShown,
          })}
          onClick={toggleAddSavedViewDialog}
          title={Localization.translator.new}
        >
          <Icons.Add
            className={classList({
              'mw32 row no-grow pa4 br-rounded highlighted-hover': true,
              'bg-blue-light': shouldSaveFilterDialogShow && !isShown,
            })}
          />
        </div>
        <div
          className={classList({
            'text grey large pointer no-grow mr15': true,
            blue: isShown,
          })}
          onClick={toggleSavedViewsDialog}
          title={Localization.translator.savedFilters}
        >
          <Icons.ConfigureSavedViews
            className={classList({
              'row no-grow pa4 br-rounded highlighted-hover': true,
              'bg-blue-light': isShown,
            })}
          />
        </div>
        <DeleteSavedViewConfirmDialog
          onHide={unsetDeletableCustomFilterId}
          onApply={deleteCustomFilterById.bind(null, null)}
          name={deletableCustomFilter?.name}
          isShown={!!deletableCustomFilter}
        />
        {isShown && (
          <SavedViewsDialog
            desktopCustomWorkflowsFiltersStore={this.props.store}
          />
        )}
        {shouldSaveFilterDialogShow && (
          <NewViewDialog
            filterName={editableFilterName}
            filtersCount={selectedFiltersCount}
            filtersMap={filtersMap}
            groupByCaption={groupByCaption}
            onCreateClick={saveCustomFilter}
            onCancelClick={closeSavedFilters}
            onNameChange={onFilterNameChange}
            onIsPublicToggle={onFilterIsPublicToggle}
            isPublic={editableFilterIsPublic}
          />
        )}
      </div>
    )
  }
}
