import React from 'react'

import { Location } from 'history'
import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import FormTypeConfiguratorStore from './FormTypeConfigurator.store'

interface IProps extends RouteComponentProps {
  store: FormTypeConfiguratorStore
}

@observer
class UnsavedChangesDialog extends React.Component<IProps> {
  private unblock: () => void

  public render(): React.ReactNode {
    return (
      <ConfirmDialog
        isOpen={this.props.store.isUnsavedChangesDialogOpen}
        onCancelClicked={this.props.store.handleUnsavedDialogContinue}
        onDoneClicked={this.props.store.dismissUnsavedChangesDialog}
        title={Localization.translator.youHaveUnsavedChanges}
        question={Localization.translator.unsavedChangesDiscarded}
        cancelTitle={Localization.translator.continue}
        doneTitle={Localization.translator.stay}
      />
    )
  }

  public componentDidMount() {
    const { store } = this.props

    this.unblock = this.props.history.block((location: Location) => {
      store.displayUnsavedChangesDialog(() => {
        this.unblock()
        this.props.history.push(location)
      })

      return store.hasUndo ? false : undefined
    })
  }

  public componentWillUnmount(): void {
    this.props.store.dismissUnsavedChangesDialog()
  }
}

export default withRouter(UnsavedChangesDialog)
