import { action, observable } from 'mobx'

import { SaveConcreteDirectDataForProjectDocument } from '~/client/graph/operations/generated/ConcreteDirectConfiguration.generated'
import CDConfigurationFormFieldId from '~/client/src/desktop/enums/ConcreteDirectConfigurationFormFieldId'
import { FormFieldType } from '~/client/src/shared/enums/FormFieldType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ConcreteDirectIntegrationStore from '~/client/src/shared/stores/domain/ConcreteDirectStores/ConcreteDirectIntegration.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import FormStore from '~/client/src/shared/stores/ui/Form.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { Field } from '~/client/src/shared/types/CustomFieldType'
import { NOOP } from '~/client/src/shared/utils/noop'

const SITE_ID = 'SiteId'

interface IFormCDFields {
  [CDConfigurationFormFieldId.IsActive]: boolean
  [CDConfigurationFormFieldId.CDBaseURL]: string
  [CDConfigurationFormFieldId.CDLogin]: string
  [CDConfigurationFormFieldId.CDPassword]: string
  [CDConfigurationFormFieldId.SiteId]: string
  [CDConfigurationFormFieldId.SyncStartDate]: string
}

const concreteDirectFormName = 'concreteDirectForm'

export default class ConcreteDirectConfigurationFormStore {
  protected requiredConcreteDirectFieldIds = [
    CDConfigurationFormFieldId.CDBaseURL,
    CDConfigurationFormFieldId.CDLogin,
    CDConfigurationFormFieldId.CDPassword,
    CDConfigurationFormFieldId.SiteId,
    CDConfigurationFormFieldId.SyncStartDate,
  ]
  private resetErrorMessage: () => void = NOOP
  private lastSiteId: string

  @observable public isUpdating = false
  @observable public activeFormIndex = 0
  @observable
  public form: FormStore<IFormCDFields> = new FormStore(
    concreteDirectFormName,
    this.createFormItemFields,
    this.validateForm,
    this.resetErrorMessage,
    this.requiredConcreteDirectFieldIds,
  )

  public constructor(
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly initProjectId: string,
    private readonly projectDateStore: ProjectDateStore,
    private readonly concreteDirectIntegrationStore: ConcreteDirectIntegrationStore,
  ) {}

  protected get createFormItemFields(): IFormCDFields {
    const { isActive, cDBaseUrl, cDLogin, cDPassword, siteId, syncStartDate } =
      this.concreteDirectIntegrationStore.concreteDirectConfiguration
    return {
      [CDConfigurationFormFieldId.IsActive]: isActive,
      [CDConfigurationFormFieldId.CDBaseURL]: cDBaseUrl,
      [CDConfigurationFormFieldId.CDLogin]: cDLogin,
      [CDConfigurationFormFieldId.CDPassword]: cDPassword,
      [CDConfigurationFormFieldId.SiteId]: siteId,
      [CDConfigurationFormFieldId.SyncStartDate]: syncStartDate
        ? this.projectDateStore.getDashedFormattedDate(syncStartDate)
        : null,
    }
  }

  public get concreteDirectFields(): Field[] {
    const fields: Field[] = [
      {
        id: CDConfigurationFormFieldId.IsActive,
        label: Localization.translator.isActive,
        value: this.getFieldValueById(CDConfigurationFormFieldId.IsActive),
        type: FormFieldType.Switch,
        onChange: this.handleChange,
      },
      {
        id: CDConfigurationFormFieldId.CDBaseURL,
        label: `CD ${Localization.translator.baseUrl}`,
        value: this.getFieldValueById(CDConfigurationFormFieldId.CDBaseURL),
        type: FormFieldType.Text,
        onChange: this.handleChange,
      },
      {
        id: CDConfigurationFormFieldId.CDLogin,
        label: `CD ${Localization.translator.login}`,
        value: this.getFieldValueById(CDConfigurationFormFieldId.CDLogin),
        type: FormFieldType.Text,
        onChange: this.handleChange,
      },
      {
        id: CDConfigurationFormFieldId.CDPassword,
        label: `CD ${Localization.translator.password}`,
        value: this.getFieldValueById(CDConfigurationFormFieldId.CDPassword),
        type: FormFieldType.Text,
        onChange: this.handleChange,
      },
      {
        id: CDConfigurationFormFieldId.SiteId,
        label: `CD ${Localization.translator.siteId}`,
        value: this.getFieldValueById(CDConfigurationFormFieldId.SiteId),
        type: FormFieldType.Text,
        onChange: this.handleChange,
      },
      {
        id: CDConfigurationFormFieldId.SyncStartDate,
        label: Localization.translator.syncStartDate,
        value: this.getFieldValueById(CDConfigurationFormFieldId.SyncStartDate),
        type: FormFieldType.Date,
        onChange: this.handleChange,
      },
    ]

    fields.forEach(field => {
      field.isValid = this.isFieldValid(field)
      field.isRequired =
        this.requiredConcreteDirectFieldIds &&
        this.requiredConcreteDirectFieldIds.includes(field.id)
    })

    return fields
  }

  @action.bound
  private handleChange(
    e: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    this.form.handleChange(e)
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected validateForm(form: FormStore<IFormCDFields>) {
    this.form.invalidFields.clear()
    this.form.error = ''
    Object.keys(this.form.fields).forEach(fieldId => {
      const fieldValue = this.form.fields[fieldId]
      const isRequired = this.form.requiredFields.includes(fieldId)
      const isEmpty = FormStore.isFieldValueEmpty(fieldValue)
      if (isRequired && isEmpty) {
        return this.setError(
          fieldId,
          Localization.translator.requiredFieldCannotBeEmpty,
        )
      }
    })
    if (
      this.getFieldValueById(CDConfigurationFormFieldId.SiteId) ===
      this.lastSiteId
    ) {
      this.setError(
        CDConfigurationFormFieldId.SiteId,
        `CD ${Localization.translator.siteId}: ${Localization.translator.fieldShouldBeUnique}`,
      )
    }
  }

  public isFieldValid({ id }: Field): boolean {
    const { invalidFields } = this.form
    return invalidFields ? !invalidFields.get(id) : true
  }

  public getFieldValueById(fieldId: string): any {
    return this.form.fields[fieldId]
  }

  @action.bound
  protected setError(fieldId: string, message?: string) {
    this.form.invalidFields.set(fieldId, true)
    this.form.error = message ?? Localization.translator.pleaseCorrectErrors
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async submitCDConfig(e?: Event) {
    if (!this.isSubmitBtnDisabled) {
      const formatedDate = this.projectDateStore.combineISODateTime(
        this.getFieldValueById(CDConfigurationFormFieldId.SyncStartDate),
      )

      this.isUpdating = true
      const { error } = await this.graphExecutorStore.mutate(
        SaveConcreteDirectDataForProjectDocument,
        {
          concreteDirectIntegration: {
            ...this.form.fields,
            projectId: this.initProjectId,
            syncStartDate: formatedDate,
          },
        },
      )
      this.isUpdating = false
      if (error && error.message.startsWith(SITE_ID)) {
        this.lastSiteId = this.getFieldValueById(
          CDConfigurationFormFieldId.SiteId,
        )
        this.setError(
          CDConfigurationFormFieldId.SiteId,
          `CD ${Localization.translator.siteId}: ${Localization.translator.fieldShouldBeUnique}`,
        )
      } else {
        this.concreteDirectIntegrationStore.initItemFields()
      }
    }
  }

  public get isSubmitBtnDisabled() {
    return !this.form.hasChanges || !!this.form.invalidFields.size
  }
}
