import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CustomWorkflows from '~/client/src/desktop/components/Filters/CustomWorkflowsFilters/CustomWorkflows'
import DesktopCustomWorkflowsFiltersStore from '~/client/src/desktop/components/Filters/CustomWorkflowsFilters/DesktopCustomWorkflowsFilters.store'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import LogisticsGroupingOptionsStore from './LogisticsGroupingOptions/LogisticsGroupingOptions.store'

interface IProps {
  viewMode: ViewModes

  customFiltersStore: DesktopCustomWorkflowsFiltersStore
  eventsStore: DesktopEventStore
  logisticsGroupingOptionsStore: LogisticsGroupingOptionsStore

  userProjectsStore?: UserProjectsStore
  state?: DesktopInitialState
}

const MORE_BTN_WIDTH = 55
const MIN_CONTAINER_WIDTH = 200

@inject('userProjectsStore', 'state')
@observer
export default class SavedWorkflowViews extends React.Component<IProps> {
  @observable private isOverflowedModalShown: boolean = false
  private readonly dynamicOverflowListStore: DynamicOverflowListStore

  private readonly clearPostEventCallback: () => void

  public constructor(props: IProps) {
    super(props)

    this.dynamicOverflowListStore = new DynamicOverflowListStore()

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handleDesktopPostEventCallback,
    )
  }

  public componentDidMount() {
    this.props.customFiltersStore.dispatchSetSelectedView()
  }

  public componentDidUpdate(prevProps: IProps) {
    const { viewMode } = this.props

    if (viewMode !== prevProps.viewMode) {
      this.dynamicOverflowListStore.enableRecalculation()
    }
  }

  public componentWillUnmount() {
    this.clearPostEventCallback?.()
  }

  public render() {
    return (
      <MenuCloser
        className="row full-height x-start relative workflow-filter-tabs-container no-select"
        closeMenu={this.hideOverflowedModal}
        isOpen={this.isOverflowedModalShown}
      >
        <DynamicOverflowList
          items={this.tabsAsElements}
          store={this.dynamicOverflowListStore}
          elementsClassName="h50 vertical-align-middle bb-light-grey"
          moreButtonWidth={MORE_BTN_WIDTH}
          minContainerWidthConstraint={MIN_CONTAINER_WIDTH}
          allowRecalculationForItems
          shouldUseItemKey
        >
          {({
            visibleElements,
            overflowElements,
            containerRefSetter,
            setAdditionalElementsRef,
          }) => (
            <div
              className={classList({
                'overflowed-filters-list full-height': true,
                nowrap: visibleElements.length === 1,
              })}
              ref={containerRefSetter}
            >
              {visibleElements}
              <div className="inline-block vertical-align-middle">
                {this.renderOverflowedTabs(overflowElements)}
              </div>
              <div
                className="inline-block vertical-align-middle"
                ref={setAdditionalElementsRef}
              >
                <CustomWorkflows store={this.props.customFiltersStore} />
              </div>
            </div>
          )}
        </DynamicOverflowList>
      </MenuCloser>
    )
  }

  private renderOverflowedTabs = (
    hiddenElements: JSX.Element[],
  ): JSX.Element => {
    if (!hiddenElements?.length) {
      return null
    }

    const { appliedCustomFilterId } = this.props.customFiltersStore
    const isMoreBtnActive = hiddenElements.some(
      el => el.key === appliedCustomFilterId,
    )

    return (
      <>
        <FilterHandleButton
          isSelected={this.isOverflowedModalShown}
          className="px10"
          isActive={isMoreBtnActive}
          isBold
          onClick={this.toggleOverflowedModal}
        >
          ...
        </FilterHandleButton>
        {this.isOverflowedModalShown && (
          <div className="absolute col pa10 z-index-100 bg-white mt10 ba-grey max-height360 overflow-auto">
            {hiddenElements.map(item => (
              <div key={item.key} className="pa5">
                {item}
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  private get tabsAsElements(): JSX.Element[] {
    const { appliedCustomFilterId } = this.props.customFiltersStore

    return this.tabObjects
      .filter(tab => !tab.isHidden)
      .map(({ title, page }, idx) => (
        <div
          key={page || idx}
          className={classList({
            'row x-center y-center pointer no-flex workflow-filter-tab-item':
              true,
            active: page === appliedCustomFilterId,
          })}
          onClick={this.selectTab.bind(this, page)}
        >
          <span className="px20 nowrap text-ellipsis">{title}</span>
        </div>
      ))
  }

  private get tabObjects(): ITabObject<string>[] {
    const {
      state: { user },
      userProjectsStore: { getByUser },
      customFiltersStore: { savedViewsSettings, getSavedViewById },
    } = this.props

    return [
      {
        title: Localization.translator.all_items,
        page: null,
      },
      ...savedViewsSettings
        .map(item => getSavedViewById(item.workflowCustomFilterId))
        .filter(
          filter =>
            filter &&
            !getByUser(user)?.savedViewsSettings?.find(
              view => view.workflowCustomFilterId === filter.id,
            )?.isHidden,
        )
        .map(filter => ({
          title: filter.name,
          page: filter.id,
        })),
    ]
  }

  private selectTab = (tabId: string) => {
    const {
      resetAll,
      selectCustomFilterById,
      applyCustomFilter,
      closeSavedFilters,
    } = this.props.customFiltersStore

    resetAll(!!tabId)

    if (tabId) {
      selectCustomFilterById(tabId)
      applyCustomFilter()
      closeSavedFilters()
    }
  }

  private handleDesktopPostEventCallback = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (e.RESET_ALL_FILTERS === eventType) {
      this.hideOverflowedModal()
    }
    if (e.SET_FILTER_SELECTION === eventType) {
      this.props.customFiltersStore.setSelectedView()
    }
  }

  @action.bound
  private toggleOverflowedModal() {
    this.isOverflowedModalShown = !this.isOverflowedModalShown
  }

  @action.bound
  private hideOverflowedModal() {
    this.isOverflowedModalShown = false
  }
}
