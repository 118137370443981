import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'

import '~/client/src/desktop/components/Filters/SavedFilters/SavedFilters.scss'

// localization: no display text to translate

interface IProps {
  activityPreset?: ActivityPreset
  selectActivityPresetById: (id: string) => void
  editActivityPreset: () => void
  activitiesStore?: ActivitiesStore
}

@inject('activitiesStore')
@observer
export default class DesktopActivityPresetLabel extends React.Component<IProps> {
  public render() {
    const availableActivities = this.props.activitiesStore.list.filter(a =>
      this.props.activityPreset.activities.includes(a.code),
    )
    const activitiesAmount = availableActivities.length

    const { name } = this.props.activityPreset
    return (
      <div className="label">
        <div className="name-cell nowrap">{name}</div>
        <div className="row count-cell">{activitiesAmount}</div>
        <div className="row">
          <Icon
            className="arrow-icon"
            icon={IconNames.CHEVRON_RIGHT}
            onClick={this.editClicked}
          />
        </div>
      </div>
    )
  }

  @action.bound
  private editClicked() {
    this.props.selectActivityPresetById(this.props.activityPreset.id)
    this.props.editActivityPreset()
  }
}
