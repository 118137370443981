import KnownTranslatorKeys from '../localization/knownTranslatorKeys'
import { enumToList } from '../utils/converters'

export enum FormTypeGroupingOption {
  NONE = 'None',
  CATEGORY = 'Category',
  APP = 'App',
  CREATED_BY = 'CreatedBy',
}

export const formTypeGroupingOptionList = enumToList(FormTypeGroupingOption)

export const getFormTypeGroupingOptionTranslatorKey = (
  id: string,
): KnownTranslatorKeys => {
  switch (id) {
    case FormTypeGroupingOption.NONE:
      return KnownTranslatorKeys.none
    case FormTypeGroupingOption.CATEGORY:
      return KnownTranslatorKeys.category
    case FormTypeGroupingOption.APP:
      return KnownTranslatorKeys.app
    case FormTypeGroupingOption.CREATED_BY:
      return KnownTranslatorKeys.createdBy
    default:
      throw new Error(id + ' is unhandled')
  }
}
