import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import CalendarHoursLabels from '~/client/src/shared/components/CalendarDayView/components/CalendarHoursLabels'
import IEditableCalendarEvent from '~/client/src/shared/components/CalendarDayView/components/IEditableCalendarEvent'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import InitialState from '~/client/src/shared/stores/InitialState'
import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'
import {
  HOUR_HEIGHT_PX,
  ONE_MINUTE_HEIGHT_PX,
} from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { getElementHeightByRef } from '~/client/src/shared/utils/RefHelpers'

import DeliveryViewPeriodMode from '../../enums/DeliveryViewPeriodMode'
import { TwoMonthsDatePickerMode } from '../TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopCalendarStore from './DesktopCalendar.store'
import { NewEventData } from './DesktopCalendarColumn'
import CalendarBodyUnsafe from './components/CalendarBody'
import CalendarHeaderUnsafe from './components/CalendarHeader'
import CalendarWeatherBarUnsafe from './components/CalendarWeatherBar'
import HorizontalTotalBarUnsafe from './components/HorizontalTotalBar'
import MultiDayEventsBarUnsafe from './components/MultiDayEventsBar/MultiDayEventsBar'
import VerticalTotalBarUnsafe from './components/VerticalTotalBar'

import './DesktopCalendar.scss'

// localization: no display text to translate

const HorizontalTotalBar = withErrorBoundary(HorizontalTotalBarUnsafe)
const VerticalTotalBar = withErrorBoundary(VerticalTotalBarUnsafe)
const CalendarHeader = withErrorBoundary(CalendarHeaderUnsafe)
const CalendarWeatherBar = withErrorBoundary(CalendarWeatherBarUnsafe)
const MultiDayEventsBar = withErrorBoundary(MultiDayEventsBarUnsafe)
const CalendarBody = withErrorBoundary(CalendarBodyUnsafe)

interface IProps {
  store: DesktopCalendarStore
  isDayCalendarModeActive?: boolean
  isEventCreatingDisabled?: boolean
  onNewEventCreate?: (start: Date, end: Date, columnData: NewEventData) => void
  onEventDateUpdate?: (event: CalendarEvent, start: Date, end: Date) => void
  onEventClicked?: (event: CalendarEvent) => void
  setEditableEvent?: (event: IEditableCalendarEvent) => void

  startDate: Date
  calendarMode?: TwoMonthsDatePickerMode | DeliveryViewPeriodMode
  editableEvent?: IEditableCalendarEvent
  isEventMovable?: boolean
  multiDayEvents: CalendarEvent[]

  projectDateStore?: ProjectDateStore
  weatherForecastsStore?: WeatherForecastsStore
  state?: InitialState
}

const scrollSettings = {
  maxDistanceFromBorders: 30,
  step: 10,
  interval: 50,
  initialScrollTop: 6 * HOUR_HEIGHT_PX,
  rowWidth: 150,
}

@inject('projectDateStore', 'weatherForecastsStore', 'state')
@observer
export default class DesktopCalendar extends React.Component<IProps> {
  private hourLabelsContainer: HTMLDivElement
  private expandMultiDayContainer: HTMLDivElement
  private columnsLabelsContainer: HTMLDivElement
  private weatherLabelsContainer: HTMLDivElement

  private calendarContainer: HTMLDivElement
  private verticalTotalBarContainer: HTMLDivElement
  private horizontalTotalBarContainer: HTMLDivElement
  private multiDayEventsBarContainer: HTMLDivElement

  private scrollingXTimer: number
  private scrollingYTimer: number

  private currentPageX: number = 0
  private currentPageY: number = 0
  private currentScrollTop: number = 0
  private currentScrollLeft: number = 0

  public get store(): DesktopCalendarStore {
    return this.props.store
  }

  public componentDidUpdate(prevProps: IProps) {
    const { calendarMode } = this.props
    if (calendarMode === TwoMonthsDatePickerMode.MONTH) {
      this.calendarContainer.scrollLeft =
        scrollSettings.rowWidth * this.numberOfDayInCurrentMonth
    }

    const { startDate, weatherForecastsStore } = this.props
    if (
      (calendarMode === TwoMonthsDatePickerMode.WEEK ||
        calendarMode === TwoMonthsDatePickerMode.FIXED_WEEK) &&
      startDate !== prevProps.startDate
    ) {
      weatherForecastsStore.fetchWeeklyForecast(startDate)
    }

    if (
      calendarMode === TwoMonthsDatePickerMode.ONE_DAY &&
      startDate !== prevProps.startDate
    ) {
      this.store.setCollection()
    }

    if (
      this.props.isDayCalendarModeActive &&
      !prevProps.isDayCalendarModeActive
    ) {
      this.store.setSelectedType(LocationType.Zone)
    }

    if (
      this.props.multiDayEvents.length !== prevProps.multiDayEvents.length ||
      this.props.calendarMode !== prevProps.calendarMode ||
      this.props.startDate !== prevProps.startDate
    ) {
      this.adjustWorkingAreaHeights()
    }
  }

  public componentDidMount() {
    const { weatherForecastsStore, startDate } = this.props

    this.calendarContainer.scrollTop = scrollSettings.initialScrollTop

    document.addEventListener('mouseup', this.documentMouseUp)

    weatherForecastsStore.fetchWeeklyForecast(startDate)

    this.adjustWorkingAreaHeights()
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.documentMouseUp)
  }

  public render() {
    const {
      isDayCalendarModeActive,
      onEventClicked,
      isEventCreatingDisabled,
      weatherForecastsStore,
      editableEvent,
    } = this.props

    const {
      calendarColumns,
      dayHours,
      selectedType,
      collection,
      setShowingOfAllZoneColumns,
      updateSortedCollection,
      eventEntityType,
    } = this.store
    const { weatherByDaysList, weatherUnits } = weatherForecastsStore

    return (
      <div
        className={classList({
          'desktop-calendar-view col': true,
          'vertical-total-bar': isDayCalendarModeActive,
        })}
      >
        <div className="row y-start x-start full-height overflow-hidden">
          <CalendarHoursLabels
            dayHours={dayHours}
            setRef={this.setHourLabelsContainerRef}
            selectedType={selectedType}
            onTypeChange={this.onTypeChange}
            filteredAttributes={collection}
            setShowingOfAllZoneColumns={setShowingOfAllZoneColumns}
            updateSortedCollection={updateSortedCollection}
            isDayCalendarModeActive={isDayCalendarModeActive}
            entityType={eventEntityType}
          />
          <div className="days-container">
            <CalendarHeader
              calendarColumns={calendarColumns}
              setRef={this.setColumnsLabelsContainerRef}
            />
            {!isDayCalendarModeActive && (
              <CalendarWeatherBar
                calendarColumns={calendarColumns}
                weatherByDaysList={weatherByDaysList}
                projectWeatherUnits={weatherUnits}
                setRef={this.setWeatherLabelsContainerRef}
              />
            )}
            <MultiDayEventsBar
              events={this.props.multiDayEvents}
              calendarColumns={calendarColumns}
              onEventClicked={onEventClicked}
              setRef={this.setMultiDayEventsBarContainerRef}
              isExpanded={this.store.isMultiDayRowExpanded}
              toggleExpanded={this.toggleMultiDayRowExpansion}
              setExpandMultiDayContainerRef={this.setExpandMultiDayContainerRef}
            />
            <CalendarBody
              calendarColumns={calendarColumns}
              isEventCreatingDisabled={isEventCreatingDisabled}
              isDayCalendarModeActive={isDayCalendarModeActive}
              setRef={this.setCalendarContainerRef}
              store={this.store}
              onEventClicked={onEventClicked}
              onEventMoved={this.onEventMoved}
              onEventTimeChange={this.onEventTimeChange}
              onLongSelect={this.onLongSelect}
              editableEvent={editableEvent}
            />
          </div>
          {isDayCalendarModeActive && (
            <VerticalTotalBar
              store={this.store}
              setVerticalTotalBarRef={this.setVerticalTotalBarContainerRef}
              entityType={eventEntityType}
            />
          )}
        </div>
        {!isDayCalendarModeActive && (
          <HorizontalTotalBar
            store={this.store}
            setHorizontalTotalBarRef={this.setHorizontalTotalBarContainerRef}
          />
        )}
      </div>
    )
  }

  private get numberOfDayInCurrentMonth(): number {
    return this.props.projectDateStore.getDayOfMonth(new Date()) - 1
  }

  @action.bound
  private setCalendarContainerRef(ref: HTMLDivElement) {
    if (this.calendarContainer) {
      this.calendarContainer.removeEventListener(
        'scroll',
        this.calendarContainerScroll,
      )
      this.calendarContainer.removeEventListener(
        'mousemove',
        this.calendarContainerMouseMove,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.calendarContainerScroll)
      ref.addEventListener('mousemove', this.calendarContainerMouseMove)
    }

    this.calendarContainer = ref
  }

  @action.bound
  private setHourLabelsContainerRef(ref: HTMLDivElement) {
    if (this.hourLabelsContainer) {
      this.hourLabelsContainer.removeEventListener(
        'scroll',
        this.hourLabelsContainerScroll,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.hourLabelsContainerScroll)
    }

    this.hourLabelsContainer = ref
  }

  @action.bound
  private setExpandMultiDayContainerRef(ref: HTMLDivElement) {
    this.expandMultiDayContainer = ref
  }

  @action.bound
  private toggleMultiDayRowExpansion() {
    this.store.toggleMultiDayRowExpansion()

    Promise.resolve().then(() => this.adjustWorkingAreaHeights())
  }

  @action.bound
  private setColumnsLabelsContainerRef(ref: HTMLDivElement) {
    if (this.columnsLabelsContainer) {
      this.columnsLabelsContainer.removeEventListener(
        'scroll',
        this.columnsLabelsContainerScroll,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.columnsLabelsContainerScroll)
    }

    this.columnsLabelsContainer = ref
  }

  @action.bound
  private setWeatherLabelsContainerRef(ref: HTMLDivElement) {
    if (this.weatherLabelsContainer) {
      this.weatherLabelsContainer.removeEventListener(
        'scroll',
        this.weatherLabelsContainerScroll,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.weatherLabelsContainerScroll)
    }

    this.weatherLabelsContainer = ref
  }

  private setMultiDayEventsBarContainerRef = (ref: HTMLDivElement) => {
    if (this.multiDayEventsBarContainer) {
      this.multiDayEventsBarContainer.removeEventListener(
        'scroll',
        this.multiDayEventsBarContainerScroll,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.multiDayEventsBarContainerScroll)
    }

    this.multiDayEventsBarContainer = ref
  }

  @action.bound
  private setVerticalTotalBarContainerRef(ref: HTMLDivElement) {
    if (this.verticalTotalBarContainer) {
      this.verticalTotalBarContainer.removeEventListener(
        'scroll',
        this.verticalTotalBarContainerScroll,
      )
    }
    if (this.horizontalTotalBarContainer) {
      this.horizontalTotalBarContainer.removeEventListener(
        'scroll',
        this.horizontalTotalBarContainerScroll,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.verticalTotalBarContainerScroll)
      if (this.calendarContainer) {
        ref.scrollTop = this.calendarContainer.scrollTop
      }
    }

    this.verticalTotalBarContainer = ref
  }

  @action.bound
  private setHorizontalTotalBarContainerRef(ref: HTMLDivElement) {
    if (this.verticalTotalBarContainer) {
      this.verticalTotalBarContainer.removeEventListener(
        'scroll',
        this.verticalTotalBarContainerScroll,
      )
    }
    if (this.horizontalTotalBarContainer) {
      this.horizontalTotalBarContainer.removeEventListener(
        'scroll',
        this.horizontalTotalBarContainerScroll,
      )
    }

    if (ref) {
      ref.addEventListener('scroll', this.horizontalTotalBarContainerScroll)
      if (this.calendarContainer) {
        ref.scrollLeft = this.calendarContainer.scrollLeft
      }
    }

    this.horizontalTotalBarContainer = ref
  }

  @action.bound
  private calendarContainerScroll() {
    const { scrollTop, scrollLeft } = this.calendarContainer

    this.hourLabelsContainer.scrollTop = scrollTop
    this.columnsLabelsContainer.scrollLeft = scrollLeft

    if (this.weatherLabelsContainer) {
      this.weatherLabelsContainer.scrollLeft = scrollLeft
    }

    if (this.multiDayEventsBarContainer) {
      this.multiDayEventsBarContainer.scrollLeft = scrollLeft
    }

    if (this.verticalTotalBarContainer) {
      this.verticalTotalBarContainer.scrollTop = scrollTop
    }

    if (this.horizontalTotalBarContainer) {
      this.horizontalTotalBarContainer.scrollLeft = scrollLeft
    }

    this.currentScrollLeft = scrollLeft
    this.currentScrollTop = scrollTop

    if (this.store.isNewEventMode) {
      this.moveEditableEvent()
    }
  }

  private hourLabelsContainerScroll = () => {
    const { scrollTop } = this.calendarContainer
    this.hourLabelsContainer.scrollTop = scrollTop
  }

  private columnsLabelsContainerScroll = () => {
    const { scrollLeft } = this.calendarContainer
    this.columnsLabelsContainer.scrollLeft = scrollLeft
  }

  private weatherLabelsContainerScroll = () => {
    const { scrollLeft } = this.calendarContainer
    this.weatherLabelsContainer.scrollLeft = scrollLeft
  }

  private multiDayEventsBarContainerScroll = () => {
    this.multiDayEventsBarContainer.scrollLeft =
      this.calendarContainer.scrollLeft
  }

  private verticalTotalBarContainerScroll = () => {
    if (this.verticalTotalBarContainer) {
      const { scrollTop } = this.calendarContainer
      this.verticalTotalBarContainer.scrollTop = scrollTop
    }
  }

  private horizontalTotalBarContainerScroll = () => {
    if (this.horizontalTotalBarContainer) {
      const { scrollLeft } = this.calendarContainer
      this.horizontalTotalBarContainer.scrollLeft = scrollLeft
    }
  }

  @action.bound
  private documentMouseUp() {
    this.disableXScrolling()
    this.disableYScrolling()

    if (this.store.isNewEventMode) {
      this.store.setDefaultMode()
      const { newEventData } = this.store.editableEventColumn

      if (this.store.editableEventColumn.isAttributeType) {
        const zoneId = newEventData[FieldIds.ZONE]
        const areaId = newEventData[FieldIds.AREA]
        const buildingId = newEventData[FieldIds.BUILDING]
        const gateId = newEventData[FieldIds.GATE]
        const levelId = newEventData[FieldIds.LEVEL]
        const routeId = newEventData[FieldIds.ROUTE]

        this.store.setEventPlaceholder(
          zoneId,
          areaId,
          buildingId,
          gateId,
          levelId,
          routeId,
        )
      } else {
        this.store.setEventPlaceholder()
      }

      if (this.props.onNewEventCreate) {
        const startDate = this.store.editableEventStartDate
        const endDate =
          this.store.editableEventEndDate || this.store.getEndDate(startDate)
        const { newEventData = {} } = this.store.editableEventColumn
        this.props.onNewEventCreate(startDate, endDate, newEventData)
      }
    }
  }

  @action.bound
  private calendarContainerMouseMove(event) {
    const { pageY, pageX } = event

    if (!this.store.isNewEventMode) {
      return
    }

    const { top, left, height, width } =
      this.calendarContainer.getBoundingClientRect()
    const bottom = top + height
    const right = left + width

    if (pageY < top || bottom < pageY || pageX < left || right < pageX) {
      return
    }

    this.currentPageY = pageY
    this.currentPageX = pageX
    this.moveEditableEvent()

    switch (true) {
      case pageY - top < scrollSettings.maxDistanceFromBorders:
        this.activateYScrolling(true)
        break
      case bottom - pageY < scrollSettings.maxDistanceFromBorders:
        this.activateYScrolling(false)
        break
      default:
        this.disableYScrolling()
    }

    switch (true) {
      case pageX - left < scrollSettings.maxDistanceFromBorders:
        this.activateXScrolling(true)
        break
      case right - pageX < scrollSettings.maxDistanceFromBorders:
        this.activateXScrolling(false)
        break
      default:
        this.disableXScrolling()
    }
  }

  @action.bound
  private activateYScrolling(isUpDirection: boolean) {
    if (this.scrollingYTimer || !this.store.isNewEventMode) {
      return
    }
    const directionCoefficient = isUpDirection ? -1 : 1
    this.scrollingYTimer = window.setInterval(() => {
      const { scrollTop, scrollHeight, clientHeight } = this.calendarContainer

      let newScrollTop = scrollTop + scrollSettings.step * directionCoefficient

      if (newScrollTop <= 0) {
        newScrollTop = 0
        this.disableYScrolling()
      }

      const maxScrollTop = scrollHeight - clientHeight
      if (newScrollTop >= maxScrollTop) {
        newScrollTop = maxScrollTop
        this.disableYScrolling()
      }

      this.calendarContainer.scrollTop = newScrollTop
    }, scrollSettings.interval)
  }

  @action.bound
  private activateXScrolling(isLeftDirection: boolean) {
    if (this.scrollingXTimer || !this.store.isNewEventMode) {
      return
    }
    const directionCoefficient = isLeftDirection ? -1 : 1
    this.scrollingXTimer = window.setInterval(() => {
      const { scrollLeft, scrollWidth, clientWidth } = this.calendarContainer

      let newScrollLeft =
        scrollLeft + scrollSettings.step * directionCoefficient

      if (newScrollLeft <= 0) {
        newScrollLeft = 0
        this.disableXScrolling()
      }

      const maxScrollLeft = scrollWidth - clientWidth
      if (newScrollLeft >= maxScrollLeft) {
        newScrollLeft = maxScrollLeft
        this.disableXScrolling()
      }

      this.calendarContainer.scrollLeft = newScrollLeft
    }, scrollSettings.interval)
  }

  @action.bound
  private disableXScrolling() {
    if (this.scrollingXTimer) {
      clearInterval(this.scrollingXTimer)
      this.scrollingXTimer = null
    }
  }

  @action.bound
  private disableYScrolling() {
    if (this.scrollingYTimer) {
      clearInterval(this.scrollingYTimer)
      this.scrollingYTimer = null
    }
  }

  @action.bound
  private moveEditableEvent() {
    const { top, left } = this.calendarContainer.getBoundingClientRect()

    const offsetX = this.currentPageX - left + this.currentScrollLeft
    const offsetY = this.currentPageY - top + this.currentScrollTop

    const timeIntervalsOffset = Math.floor(
      offsetY / ONE_MINUTE_HEIGHT_PX / this.store.timeInterval,
    )

    const calendarWidth = this.calendarContainer.scrollWidth
    const columnsCount = this.store.calendarColumns.length
    const columnWidth = calendarWidth / columnsCount
    const newColumnIndex = Math.floor(offsetX / columnWidth)

    this.store.updateEditableEventValues(timeIntervalsOffset, newColumnIndex)
  }

  private onEventMoved = (e: CalendarEvent, x: number, y: number) => {
    const {
      projectDateStore: { addMinutes, addDays },
      onEventDateUpdate,
    } = this.props
    const startTime = addMinutes(e.dateInterval.startDate, y)
    const startDate = addDays(startTime, x)
    const endTime = addMinutes(e.dateInterval.endDate, y)
    const endDate = addDays(endTime, x)

    if (e.isNew) {
      this.store.editableEventStartDate = startDate
      this.store.editableEventEndDate = endDate
    }

    onEventDateUpdate(e, startDate, endDate)
  }

  private onEventTimeChange = (
    e: CalendarEvent,
    startDate: number,
    endDate: number,
  ) => {
    const {
      projectDateStore: { addMinutes },
      onEventDateUpdate,
    } = this.props
    const startTime = addMinutes(e.dateInterval.startDate, startDate)
    const endTime = addMinutes(e.dateInterval.endDate, endDate)

    if (e.isNew) {
      this.store.editableEventStartDate = startTime
      this.store.editableEventEndDate = endTime
    }
    onEventDateUpdate(e, startTime, endTime)
  }

  private onLongSelect = (event: CalendarEvent) => {
    const { state, isEventMovable, setEditableEvent } = this.props
    const { isSuperUser, userId } = state.userActiveProjectSettings

    if (
      isSuperUser ||
      !event.data ||
      (isEventMovable && event.data.userId === userId)
    ) {
      setEditableEvent({
        event,
        x: 0,
        y: 0,
      })
    }
  }

  private onTypeChange = (type: LocationType) => {
    this.store.setSelectedType(type)
  }

  // It is required for vertical scrolling to work correctly with dynamically changing height of "multiDayEventsBarContainer"
  // See DesktopCalendar.scss : .$working-area-height calculation
  private adjustWorkingAreaHeights() {
    const weatherLabelsContainerHeight = getElementHeightByRef(
      this.weatherLabelsContainer,
    )
    const columnsLabelsContainerHeight = getElementHeightByRef(
      this.columnsLabelsContainer,
    )
    const multiDayEventsBarContainerHeight = getElementHeightByRef(
      this.multiDayEventsBarContainer,
    )
    const expandMultiDayContainerHeight = getElementHeightByRef(
      this.expandMultiDayContainer,
    )

    const delta =
      columnsLabelsContainerHeight +
      weatherLabelsContainerHeight +
      multiDayEventsBarContainerHeight

    const hValue = `calc(100% - ${delta}px)`

    this.calendarContainer.style.setProperty('height', hValue)
    this.hourLabelsContainer.style.setProperty('height', hValue)

    const hlMarginTopOffset = 5
    this.hourLabelsContainer.style.marginTop = `${delta - hlMarginTopOffset}px`

    if (this.expandMultiDayContainer) {
      this.expandMultiDayContainer.style.top = `${
        delta - expandMultiDayContainerHeight - hlMarginTopOffset
      }px`

      this.expandMultiDayContainer.style.left = `${
        this.hourLabelsContainer.clientWidth / 2
      }px`
    }
  }
}
