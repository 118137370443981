import * as React from 'react'

import { inject } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

// localization: translated

interface IProps {
  date: Date
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
export default class DateTitle extends React.Component<IProps> {
  public render() {
    const { date, projectDateStore } = this.props

    return (
      <>
        <div
          className={classList({
            'text bold uppercase center relative': true,
            orange: this.isToday,
          })}
        >
          {projectDateStore.getWeekdayToDisplay(date)}
        </div>
        <div className="row x-center y-center pt5 relative">
          <div
            className={classList({
              'text large bold date-label uppercase center': true,
              white: this.isToday,
            })}
          >
            {projectDateStore.getDayOfMonthToDisplay(date)}
          </div>
          {this.isToday && <div className="today-dot" />}
        </div>
      </>
    )
  }

  private get isToday() {
    const { date, projectDateStore } = this.props
    return projectDateStore.isToday(date)
  }
}
