import React from 'react'

import convert, { Distance, Mass } from 'convert-units'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import LocationBaseWithDimensions from '~/client/src/shared/models/LocationObjects/LocationBaseWithDimensions'

const maxWidth = 'Max Width'
const maxHeight = 'Max Height'
const maxWeight = 'Max Weight'
const dimensions = 'Dimensions'
const dimensionUnit = 'Dimension unit'
const weightUnit = 'Weight unit'
const weight = 'Weight'
const UNKNOWN = 'Unknown'

enum WeightUnitsType {
  lb = 'lb',
  kg = 'kg',
}

enum DimensionUnitType {
  ft = 'ft',
  m = 'm',
}

const getDimensionUnitTypeCaption = (u: DimensionUnitType) => {
  switch (u) {
    case DimensionUnitType.ft:
      return 'feet'
    case DimensionUnitType.m:
      return 'meter'
    default:
      return UNKNOWN
  }
}

const getWeightUnitsTypeCaption = (u: WeightUnitsType) => {
  switch (u) {
    case WeightUnitsType.kg:
      return 'kilogram'
    case WeightUnitsType.lb:
      return 'pound'
    default:
      return UNKNOWN
  }
}

const DEFAULT_DIMENSION_UNIT = DimensionUnitType.ft
const DEFAULT_WEIGHT_UNIT = WeightUnitsType.lb

interface IProps {
  dataObject: LocationBaseWithDimensions
  selectedItemId?: string
}

@observer
export default class DimensionProperties extends React.Component<IProps> {
  @observable private selectedWeightUnitType: Mass = DEFAULT_WEIGHT_UNIT
  @observable private selectedDimensionsUnitType: Distance =
    DEFAULT_DIMENSION_UNIT
  @observable private maxWidth: number = 0
  @observable private maxHeight: number = 0
  @observable private maxWeight: number = 0

  public componentDidMount(): void {
    this.setDimensionValues()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.selectedItemId !== prevProps.selectedItemId) {
      this.setDimensionValues()
    }
  }

  public render() {
    return (
      <>
        <div className="properties-section pa12">
          <div className="text uppercase title pb12">{dimensions}</div>
          <StruxhubSelect
            label={dimensionUnit}
            value={this.selectedDimensionsUnitType.toString()}
            onChange={this.setDimensionUnitType}
          >
            {Object.keys(DimensionUnitType).map(option => (
              <option key={option} value={option}>
                {getDimensionUnitTypeCaption(option as DimensionUnitType)}
              </option>
            ))}
          </StruxhubSelect>
          <StruxhubInput
            label={maxHeight}
            type="number"
            onChange={this.onMaxHeightChange}
            value={this.maxHeight?.toString()}
            negativeOrDecimal={true}
            customRightIcon={getDimensionUnitTypeCaption(
              this.selectedDimensionsUnitType as DimensionUnitType,
            )}
          />
          <StruxhubInput
            label={maxWidth}
            type="number"
            onChange={this.onMaxWidthChange}
            value={this.maxWidth?.toString()}
            negativeOrDecimal={true}
            customRightIcon={getDimensionUnitTypeCaption(
              this.selectedDimensionsUnitType as DimensionUnitType,
            )}
          />
        </div>
        <div className="properties-section pa12">
          <div className="text uppercase title pb12">{weight}</div>
          <StruxhubSelect
            label={weightUnit}
            value={this.selectedWeightUnitType.toString()}
            onChange={this.setWeightUnitType}
          >
            {Object.values(WeightUnitsType).map(option => (
              <option key={option} value={option}>
                {getWeightUnitsTypeCaption(option as WeightUnitsType)}
              </option>
            ))}
          </StruxhubSelect>
          <StruxhubInput
            label={maxWeight}
            type="number"
            onChange={this.onMaxWeightChange}
            value={this.maxWeight?.toString()}
            negativeOrDecimal={true}
            customRightIcon={getWeightUnitsTypeCaption(
              this.selectedWeightUnitType as WeightUnitsType,
            )}
          />
        </div>
      </>
    )
  }

  private setDimensionValues = (): void => {
    const { dataObject } = this.props
    this.maxHeight =
      DEFAULT_DIMENSION_UNIT === this.selectedDimensionsUnitType
        ? dataObject?.maxHeight || 0
        : convert(dataObject?.maxHeight || 0)
            .from(DEFAULT_DIMENSION_UNIT)
            .to(this.selectedDimensionsUnitType)

    this.maxWidth =
      DEFAULT_DIMENSION_UNIT === this.selectedDimensionsUnitType
        ? dataObject?.maxWidth || 0
        : convert(dataObject?.maxWidth || 0)
            .from(DEFAULT_DIMENSION_UNIT)
            .to(this.selectedDimensionsUnitType)

    this.maxWeight =
      DEFAULT_WEIGHT_UNIT === this.selectedWeightUnitType
        ? dataObject?.maxWeight || 0
        : convert(dataObject?.maxWeight || 0)
            .from(DEFAULT_WEIGHT_UNIT)
            .to(this.selectedWeightUnitType)
  }

  @action.bound
  private onMaxWidthChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.dataObject.maxWidth = convert(+event.target.value)
      .from(this.selectedDimensionsUnitType)
      .to(DEFAULT_DIMENSION_UNIT)
    this.setDimensionValues()
  }

  @action.bound
  private onMaxHeightChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.dataObject.maxHeight = convert(+event.target.value)
      .from(this.selectedDimensionsUnitType)
      .to(DEFAULT_DIMENSION_UNIT)
    this.setDimensionValues()
  }

  @action.bound
  private onMaxWeightChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.dataObject.maxWeight = convert(+event.target.value)
      .from(this.selectedWeightUnitType)
      .to(DEFAULT_WEIGHT_UNIT)
    this.setDimensionValues()
  }

  @action.bound
  private setDimensionUnitType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.selectedDimensionsUnitType = DimensionUnitType[event.target.value]
    this.setDimensionValues()
  }

  @action.bound
  private setWeightUnitType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.selectedWeightUnitType = WeightUnitsType[event.target.value]
    this.setDimensionValues()
  }
}
