import { action, computed, observable } from 'mobx'

import { DefaultCompanyType, ICompany } from '~/client/graph'
import CompanyFieldId from '~/client/src/desktop/enums/CompanyFieldId'
import { AvatarInputData } from '~/client/src/shared/components/AvatarInput/AvatarInput'
import { FormFieldType } from '~/client/src/shared/enums/FormFieldType'
import InfoSectionId from '~/client/src/shared/enums/InfoSectionId'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import User from '~/client/src/shared/models/User'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'
import AddEditItemDialogStore, {
  IAddEditDialogField,
} from '~/client/src/shared/stores/ui/AddEditItemDialog.store'
import FormStore from '~/client/src/shared/stores/ui/Form.store'
import { VALID_EMAIL_PATTERN } from '~/client/src/shared/utils/regExpPatterns'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

interface IFormCompanyFields {
  [CompanyFieldId.Name]: string
  [CompanyFieldId.Code]: string
  [CompanyFieldId.LogoUrl]: string
  [CompanyFieldId.AvatarUrl]: string
  [CompanyFieldId.BusinessPhone]: string
  [CompanyFieldId.BusinessEmail]: string
  [CompanyFieldId.TypeTags]: string[]
  [CompanyFieldId.ReportsTo]: string
  [CompanyFieldId.Roles]: string[]
  [CompanyFieldId.Trades]: string[]
  [CompanyFieldId.DBA]: string
  [CompanyFieldId.LicenseNumber]: string

  [CompanyFieldId.Address]: string
  [CompanyFieldId.Country]: string
  [CompanyFieldId.City]: string
  [CompanyFieldId.State]: string
  [CompanyFieldId.ZipCode]: string
}

const idPropName = 'id'
const minCodeLength = 3
const maxCodeLength = 5

export default class AddEditCompanyDialogStore extends AddEditItemDialogStore<
  ICompany,
  IFormCompanyFields
> {
  @observable public areWhiteListItemsBeingLoaded: boolean = false

  protected requiredFieldIds = [
    CompanyFieldId.Name,
    CompanyFieldId.TypeTags,
    CompanyFieldId.Code,
  ]

  protected avatarFieldId: string = CompanyFieldId.AvatarUrl
  private logoFieldId: string = CompanyFieldId.LogoUrl

  public constructor(
    itemsToEdit: ICompany[],
    items: ICompany[],
    resetErrorMessage: () => void,
    protected readonly updateItems: (
      items: ICompany[],
      lastUpdatedCompanyId: string,
    ) => void,
    protected readonly closeDialog: () => void,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly whiteListItemsStore: WhiteListItemsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly readonlyFieldIds: CompanyFieldId[] = [],
  ) {
    super(
      itemsToEdit,
      items,
      KnownTranslatorKeys.addCompany,
      KnownTranslatorKeys.editCompany,
      resetErrorMessage,
    )

    this.idPropName = idPropName

    this.init()
  }

  @action.bound
  public async requestAdditionalData() {
    if (this.isAddMode) {
      return
    }

    this.areWhiteListItemsBeingLoaded = true
    await this.whiteListItemsStore.requestItemsByCompany(this.activeItemId)
    this.areWhiteListItemsBeingLoaded = false
  }

  @action.bound
  public init() {
    this.recreateFormStores()
    this.updateFormStores()
  }

  @action.bound
  public handleResponsibleContactIdsChange(newResponsibleContactIds: string[]) {
    this.handleTagsChange(
      CompanyFieldId.ResponsibleContacts,
      newResponsibleContactIds,
    )
  }

  @action.bound
  public handleLogoChange(logoUrl: string) {
    const event = {
      currentTarget: {
        name: this.logoFieldId,
        type: FormFieldType.Image,
        value: logoUrl,
      },
    }
    this.handleChange(event)
  }

  @computed
  public get fields(): IAddEditDialogField[] {
    const fields: IAddEditDialogField[] = [
      {
        id: CompanyFieldId.Name,
        label: Localization.translator.company,
        value: this.getFieldValueById(CompanyFieldId.Name),
        sectionId: InfoSectionId.CompanyContactInfo,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.Name),
      },
      {
        id: CompanyFieldId.Code,
        label: Localization.translator.code,
        value: this.getFieldValueById(CompanyFieldId.Code),
        sectionId: InfoSectionId.CompanyContactInfo,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.Code),
        maxlength: maxCodeLength,
        minlength: minCodeLength,
        inline: 2,
      },
      {
        id: CompanyFieldId.AvatarUrl,
        label: Localization.translator.avatar,
        value: this.avatarUrlFieldValue,
        sectionId: InfoSectionId.CompanyContactInfo,
        type: FormFieldType.Avatar,
        onChange: this.handleAvatarChange,
        inline: 2,
      },
      {
        id: CompanyFieldId.LogoUrl,
        label: Localization.translator.logo,
        value: this.logoUrlFieldValue,
        sectionId: InfoSectionId.CompanyContactInfo,
        type: FormFieldType.Image,
        onChange: this.handleLogoChange,
      },
      {
        id: CompanyFieldId.BusinessPhone,
        label: Localization.translator.businessPhone,
        value: this.getFieldValueById(CompanyFieldId.BusinessPhone),
        sectionId: InfoSectionId.CompanyContactInfo,
        type: FormFieldType.Phone,
        onChange: this.handlePhoneNumberChange.bind(
          null,
          CompanyFieldId.BusinessPhone,
        ),
      },
      {
        id: CompanyFieldId.BusinessEmail,
        label: Localization.translator.businessEmail,
        value: this.getFieldValueById(CompanyFieldId.BusinessEmail),
        sectionId: InfoSectionId.CompanyContactInfo,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(
          this,
          CompanyFieldId.BusinessEmail,
        ),
      },
      {
        id: CompanyFieldId.TypeTags,
        label: Localization.translator.companyType,
        value: this.getFieldValueById(CompanyFieldId.TypeTags),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.CompanyType,
        onChange: this.handleTagChange.bind(null, CompanyFieldId.TypeTags),
        isItemCreationAllowed: false,
      },
      {
        id: CompanyFieldId.ReportsTo,
        label: Localization.translator.reportsTo,
        value: this.getFieldValueById(CompanyFieldId.ReportsTo),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tag,
        serviceInfo: TagType.Company,
        onChange: this.handleTagChange.bind(null, CompanyFieldId.ReportsTo),
        options: this.reportsToFieldOptions,
        isHidden: !this.shouldShowReportsToField,
      },
      {
        id: CompanyFieldId.Roles,
        label: Localization.translator.projectRole,
        value: this.getFieldValueById(CompanyFieldId.Roles),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.Role,
        onChange: this.handleTagsChange.bind(null, CompanyFieldId.Roles),
      },
      {
        id: CompanyFieldId.Trades,
        label: Localization.translator.projectTrades,
        value: this.getFieldValueById(CompanyFieldId.Trades),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.Trade,
        onChange: this.handleTagsChange.bind(null, CompanyFieldId.Trades),
      },
      {
        id: CompanyFieldId.Address,
        label: Localization.translator.address,
        value: this.getFieldValueById(CompanyFieldId.Address),
        sectionId: InfoSectionId.CompanyAddress,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.Address),
      },
      {
        id: CompanyFieldId.Country,
        label: Localization.translator.country,
        value: this.getFieldValueById(CompanyFieldId.Country),
        sectionId: InfoSectionId.CompanyAddress,
        type: FormFieldType.SelectOne,
        onChange: this.handleChange,
        options: this.countryFieldOptions,
      },
      {
        id: CompanyFieldId.City,
        label: Localization.translator.city,
        value: this.getFieldValueById(CompanyFieldId.City),
        sectionId: InfoSectionId.CompanyAddress,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.City),
      },
      {
        id: CompanyFieldId.State,
        label: Localization.translator.state_region,
        value: this.getFieldValueById(CompanyFieldId.State),
        sectionId: InfoSectionId.CompanyAddress,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.State),
        inline: 2,
      },
      {
        id: CompanyFieldId.ZipCode,
        label: Localization.translator.zipCode,
        value: this.getFieldValueById(CompanyFieldId.ZipCode),
        sectionId: InfoSectionId.CompanyAddress,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.ZipCode),
        inline: 2,
      },
      {
        id: CompanyFieldId.DBA,
        label: Localization.translator.dba,
        value: this.getFieldValueById(CompanyFieldId.DBA),
        sectionId: InfoSectionId.LegalInfo,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, CompanyFieldId.DBA),
      },
      {
        id: CompanyFieldId.LicenseNumber,
        label: Localization.translator.licenseNumber,
        value: this.getFieldValueById(CompanyFieldId.LicenseNumber),
        sectionId: InfoSectionId.LegalInfo,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(
          this,
          CompanyFieldId.LicenseNumber,
        ),
      },
    ]

    const { requiredFields } = this.activeFormStore

    fields.forEach(field => {
      field.isReadonly = this.isFieldReadonly(field.id)
      field.isValid = this.isFieldValid(field)
      field.isRequired = requiredFields && requiredFields.includes(field.id)
    })

    return fields
  }

  @action.bound
  public handleChange(event: any) {
    super.handleChange(event)

    // Try to update code field according to new name
    if (
      event.currentTarget.name === CompanyFieldId.Name &&
      !this.isFieldReadonly(CompanyFieldId.Code)
    ) {
      this.setCodeFieldValue(event.target.value)
      this.activeFormStore.validate()
    }
  }

  public get reportsToFieldOptions() {
    const defaultOption = {
      value: '',
      name: User.getDefaultCompanyName(),
    }

    const options = this.allItems
      .filter(({ id }) => id !== this.activeItemId)
      .map(({ id, name }) => ({ value: id, name }))

    return [defaultOption, ...options]
  }

  public get countryFieldOptions() {
    const defaultOption = {
      value: '',
      name: NO_VALUE,
    }

    return [
      defaultOption,
      ...Object.entries(Localization.translator.countryValues).map(
        ([value, name]) => ({
          value,
          name,
        }),
      ),
    ]
  }

  public get shouldShowReportsToField(): boolean {
    const selectedCompanyTypeIds: string[] =
      this.getFieldValueById(CompanyFieldId.TypeTags) || []

    const tieredSubContractorTag = this.companiesStore.getCompanyTypeTagByType(
      DefaultCompanyType.TieredSubContractor,
    )

    return selectedCompanyTypeIds.includes(tieredSubContractorTag?.id)
  }

  @computed
  public get companyMembers(): User[] {
    return this.projectMembersStore.getCompanyMembers(this.activeItemId)
  }

  @computed
  public get responsibleContactIds(): string[] {
    return this.getFieldValueById(CompanyFieldId.ResponsibleContacts)
  }

  public get logoUrlFieldValue(): string {
    return this.getFieldValueById(CompanyFieldId.LogoUrl)
  }

  public get avatarUrlFieldValue(): AvatarInputData {
    return {
      url: this.getFieldValueById(CompanyFieldId.AvatarUrl),
    }
  }

  public setFieldsToItem(company: ICompany, fields) {
    Object.assign(company, fields)

    company.address = {
      [CompanyFieldId.Address]: fields[CompanyFieldId.Address],
      [CompanyFieldId.Country]: fields[CompanyFieldId.Country],
      [CompanyFieldId.City]: fields[CompanyFieldId.City],
      [CompanyFieldId.State]: fields[CompanyFieldId.State],
      [CompanyFieldId.ZipCode]: fields[CompanyFieldId.ZipCode],
    }

    delete company[CompanyFieldId.Country]
    delete company[CompanyFieldId.City]
    delete company[CompanyFieldId.State]
    delete company[CompanyFieldId.ZipCode]
  }

  protected createFormItemFields(): IFormCompanyFields {
    return {
      [CompanyFieldId.Name]: '',
      [CompanyFieldId.Code]: '',
      [CompanyFieldId.LogoUrl]: '',
      [CompanyFieldId.AvatarUrl]: '',
      [CompanyFieldId.BusinessPhone]: '',
      [CompanyFieldId.BusinessEmail]: '',
      [CompanyFieldId.TypeTags]: [],
      [CompanyFieldId.ReportsTo]: '',
      [CompanyFieldId.Roles]: [],
      [CompanyFieldId.Trades]: [],
      [CompanyFieldId.DBA]: '',
      [CompanyFieldId.LicenseNumber]: '',

      [CompanyFieldId.Address]: '',
      [CompanyFieldId.Country]: '',
      [CompanyFieldId.City]: '',
      [CompanyFieldId.State]: '',
      [CompanyFieldId.ZipCode]: '',
    }
  }

  protected updateFormStoreItem(item: ICompany) {
    const { address } = item

    Object.assign(item, {
      [CompanyFieldId.Address]: address?.address || '',
      [CompanyFieldId.Country]: address?.country || '',
      [CompanyFieldId.City]: address?.city || '',
      [CompanyFieldId.State]: address?.state || '',
      [CompanyFieldId.ZipCode]: address?.zipCode || '',
    })
  }

  private isFieldReadonly(fieldId: CompanyFieldId): boolean {
    return this.readonlyFieldIds.includes(fieldId)
  }

  private isCodeLengthTooSmall(code: string): boolean {
    return code.length < minCodeLength
  }

  private isCodeExists(code: string): boolean {
    const formattedCode = code.toUpperCase()

    return this.allItems.some(
      company =>
        company.id !== this.activeItemId &&
        company.code.toUpperCase() === formattedCode,
    )
  }

  private isCodeValid(code: string): boolean {
    return !this.isCodeLengthTooSmall(code) && !this.isCodeExists(code)
  }

  protected validateField(
    form: FormStore<IFormCompanyFields>,
    fieldId: string,
    fieldValue: any,
  ): boolean {
    switch (fieldId) {
      case CompanyFieldId.BusinessEmail:
        return VALID_EMAIL_PATTERN.test(fieldValue)
      case CompanyFieldId.BusinessPhone:
        return form.isPhoneValid
      case CompanyFieldId.Code:
        return this.isCodeValid(fieldValue)
      default:
        return true
    }
  }

  protected setError(form: FormStore<IFormCompanyFields>, fieldId: string) {
    form.invalidFields.set(fieldId, true)

    const {
      invalidEmail,
      invalidPhone,
      nameRequired,
      somethingFilledIncorrectly,
      codeRequired,
      codeIncorrectLength,
      codeIsNotUnique,
      companyTypeIsRequired,
    } = Localization.translator.companyValidationErrors

    let error = somethingFilledIncorrectly
    switch (fieldId) {
      case CompanyFieldId.BusinessEmail:
        error = invalidEmail
        break
      case CompanyFieldId.BusinessPhone:
        error = invalidPhone
        break
      case CompanyFieldId.Name:
        error = nameRequired
        break
      case CompanyFieldId.Code:
        const code = form.getFieldValue(CompanyFieldId.Code)
        if (this.isCodeLengthTooSmall(code)) {
          error = codeIncorrectLength
        } else if (this.isCodeExists(code)) {
          error = codeIsNotUnique
        } else {
          error = codeRequired
        }
        break
      case CompanyFieldId.TypeTags:
        error = companyTypeIsRequired
    }

    form.error = error
  }

  private getAvailableCodeBasedOnCompanyName(
    formattedCompanyName: string, // without whitespace, in uppercase
    codeLength: number,
    companies: ICompany[],
  ): string {
    const code = formattedCompanyName.substring(0, codeLength)

    if (codeLength >= maxCodeLength) {
      return code
    }

    const matchedCompanies = companies.filter(
      c => c.id !== this.activeItemId && c.code.toUpperCase().startsWith(code),
    )

    if (!matchedCompanies.length) {
      return code
    }

    return this.getAvailableCodeBasedOnCompanyName(
      formattedCompanyName,
      codeLength + 1,
      matchedCompanies,
    )
  }

  private setCodeFieldValue(companyName: string) {
    const companyCode = this.getAvailableCodeBasedOnCompanyName(
      companyName.replace(/\s/g, '').toUpperCase(),
      minCodeLength,
      this.allItems,
    )

    this.activeFormStore.setFieldValue(CompanyFieldId.Code, companyCode)
  }
}
