import * as React from 'react'

import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import OpacityBackground from '~/client/src/desktop/views/SimpleGanttView/components/OpacityBackground/OpacityBackground'

import MapViewSetUpStore from '../../MapViewSetUp.store'
import PdfToImageConverter from '../PdfToImageConverter'
import UploadSitemapDialog from '../dialogs/UploadSitemapDialog/UploadSitemapDialog'

import './Ribbons.scss'

interface IProps {
  store: MapViewSetUpStore
  shouldHideAdditionMenu?: boolean
  shouldFixTopPosition?: boolean
  isCreateButtonHidden?: boolean

  shouldHideButton?: boolean
  shouldHideModals?: boolean
  shouldAvoidSitemapSelection?: boolean
  allowUploadOnlyWhiteboard?: boolean
}

@observer
export default class SitemapRibbon extends React.Component<IProps> {
  public render() {
    const {
      isCreateButtonHidden,
      store: { currentStep, sitemapControlStore },
      shouldHideButton,
      allowUploadOnlyWhiteboard,
      shouldHideModals,
    } = this.props
    const {
      toggleCreateMenu,
      shouldShowCreateMenu,
      setConvertedSitemap,
      setConvertedBasemap,
      sitemapPdfFile,
      pdfFile,
    } = sitemapControlStore

    return (
      <>
        {!shouldHideButton && (
          <div className="sitemap-ribbon no-grow row">
            {!currentStep && !isCreateButtonHidden && (
              <>
                <div
                  className="text white bg-white brada24 py5 large title-row pointer row x-center ba-light-cool-grey"
                  onClick={() => toggleCreateMenu()}
                >
                  <Icon icon={IconNames.PLUS} intent={Intent.PRIMARY} />
                </div>
              </>
            )}
          </div>
        )}
        {!shouldHideModals && shouldShowCreateMenu && (
          <>
            <OpacityBackground isAnyPopupOpened={true} />
            <UploadSitemapDialog
              store={sitemapControlStore}
              shouldFixTopPosition={this.props.shouldFixTopPosition}
              mapViewItemsSetupStore={this.props.store.mapViewItemsSetupStore}
              allowUploadOnlyWhiteboard={
                !this.props.store.isGlobeMode || allowUploadOnlyWhiteboard
              }
            />
          </>
        )}
        {pdfFile && (
          <PdfToImageConverter
            pdfFile={pdfFile}
            uploadImage={setConvertedBasemap}
          />
        )}
        {sitemapPdfFile && (
          <PdfToImageConverter
            pdfFile={sitemapPdfFile}
            uploadImage={setConvertedSitemap}
          />
        )}
      </>
    )
  }
}
