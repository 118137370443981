import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BarState from '~/client/src/desktop/enums/SideBarState'
import UnsafeNotificationActivityDetails from '~/client/src/desktop/views/NotificationDetails/NotificationActivityDetails'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import BaseNotification from '~/client/src/shared/models/Notification'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'
import {
  isActivityType,
  isDeliveryType,
  isPermitType,
  isWorkflowMessageType,
} from '~/client/src/shared/types/NotificationTypes'

import DesktopInitialState from '../../stores/DesktopInitialState'
import NotificationDetailsViewStore from './NotificationDetailsView.store'
import NotificationDetailsDelivery from './components/NotificationDetailsDelivery'
import NotificationDetailsPermit from './components/NotificationDetailsPermit'

import './NotificationDetailsView.scss'

interface INotificationDetailsStoreProps {
  displayedNotification: BaseNotification
  notificationsStore: NotificationsStore
  activitiesStore?: ActivitiesStore
  state?: DesktopInitialState
}

const NotificationActivityDetails = withErrorBoundary(
  UnsafeNotificationActivityDetails,
)

@inject('activitiesStore', 'state')
@observer
export default class NotificationDetailsView extends React.Component<INotificationDetailsStoreProps> {
  private readonly store: NotificationDetailsViewStore

  public constructor(props: INotificationDetailsStoreProps) {
    super(props)

    const { activitiesStore, state } = this.props

    this.store = new NotificationDetailsViewStore(activitiesStore, state)
    this.setNotification(this.props.displayedNotification)
  }

  public UNSAFE_componentWillReceiveProps(
    newProps: INotificationDetailsStoreProps,
  ) {
    if (
      this.props.displayedNotification?.id !==
      newProps.displayedNotification?.id
    ) {
      this.setNotification(newProps.displayedNotification)
    }
  }

  public render() {
    if (!this.props.displayedNotification) {
      return <div />
    }
    const { type, entityId } = this.props.displayedNotification
    if (
      !isDeliveryType(type) &&
      !isActivityType(type) &&
      !isPermitType(type) &&
      !isWorkflowMessageType(type)
    ) {
      return <div />
    }

    return (
      <div className="notification-details-content-area full-height overflow-hidden">
        {isDeliveryType(type) ? (
          <NotificationDetailsDelivery
            backClicked={this.backClicked}
            deliveryId={entityId}
            store={this.store}
          />
        ) : isActivityType(type) ? (
          <NotificationActivityDetails backClicked={this.backClicked} />
        ) : (
          <NotificationDetailsPermit
            backClicked={this.backClicked}
            notificationType={type}
            permitId={entityId}
          />
        )}
      </div>
    )
  }

  private setBarStateMode() {
    const { state } = this.props
    const {
      selectedActivity,
      rfiId,
      flagId,
      statusUpdateId,
      scheduleCommentId,
    } = this.store

    switch (true) {
      case !!flagId:
        state.sideBarState = BarState.Flag
        return
      case !!rfiId:
        state.sideBarState = BarState.Rfi
        return
      case !!scheduleCommentId:
        state.sideBarState = BarState.ScheduleComment
        return
      case !!statusUpdateId:
        state.sideBarState = BarState.StatusUpdateMessages
        return
      case !!selectedActivity:
        state.sideBarState = BarState.Default
        return
      default:
        state.sideBarState = BarState.Default
        return
    }
  }

  private setActivity(activityId: string) {
    if (activityId) {
      const activity = this.props.activitiesStore.getByDbId(activityId)
      this.props.activitiesStore.select(activity?.code)
      this.setBarStateMode()
    }
  }

  private backClicked = () => {
    const { notificationsStore } = this.props
    notificationsStore.displayedNotification = null
  }

  private setNotification = (displayedNotification: BaseNotification) => {
    this.store.setNotification(displayedNotification)
    this.setActivity(displayedNotification.activityObjectId)
  }
}
