import { action, computed } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import {
  FormTypeCreatedByEnum,
  formTypeCreatedByList,
  getFormTypeCreatedByCaption,
} from '~/client/src/shared/enums/FormTypeCreatedBy'
import {
  FormTypeFilterType,
  formTypeFilterTypes,
} from '~/client/src/shared/enums/FormTypeFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import { enumToList } from '~/client/src/shared/utils/converters'

import FormTypesListStore from '../../../../FormTypesList.store'
import BaseFormTypesFilterStore, {
  ISourceMap,
} from './BaseFormTypesFilter.store'

enum ShowInApp {
  HOME = 'Home',
  FORMS = 'Forms',
}

const showInAppList = enumToList(ShowInApp)

export default class FormTypesFilterStore {
  public constructor(
    protected readonly eventsStore: DesktopEventStore,
    protected readonly instancesStore: PermitTypesStore,
    protected readonly instancesListStore: FormTypesListStore,
    protected readonly formCategoriesStore: FormCategoriesStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    private readonly onFilterClickHandler?: () => void,
  ) {}

  protected get availableInstances(): PermitType[] {
    return this.instancesStore.actualTypes
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseFormTypesFilterStore
  } {
    const map: { [filterType: string]: BaseFormTypesFilterStore } = {}

    formTypeFilterTypes.forEach(filterType => {
      const { appState } = this.eventsStore

      map[filterType] = new BaseFormTypesFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.instancesListStore,
        this.onShowChanged,
        appState.formTypesFilters.fieldsMap,
        this.getOptionName,
        this.onFilterClickHandler,
      )
    })
    return map
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = formTypeFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(formType => {
      const { id, isEnabled, createdBy, categoryId } = formType

      formTypeFilterTypes.forEach(filterType => {
        const map = maps[filterType]
        let optionIds: string[] = []

        if (filterType === FormTypeFilterType.FORM_CATEGORY) {
          optionIds = [categoryId || UNASSIGNED_FILTER_OPTION]
        } else if (filterType === FormTypeFilterType.APP) {
          optionIds = isEnabled ? showInAppList : [UNASSIGNED_FILTER_OPTION]
        } else if (filterType === FormTypeFilterType.CREATED_BY) {
          optionIds = [createdBy]
        }

        optionIds.forEach(optionId => {
          if (!map[optionId]) {
            optionId = UNASSIGNED_FILTER_OPTION
          }

          map[optionId].push(id)
        })
      })
    })
    return maps
  }

  protected getDefaultSourceMapByType(type: FormTypeFilterType) {
    let sourceList: string[] = []

    if (type === FormTypeFilterType.FORM_CATEGORY) {
      sourceList = [
        ...this.formCategoriesStore.activeCategories.map(c => c.id),
        UNASSIGNED_FILTER_OPTION,
      ]
    } else if (type === FormTypeFilterType.APP) {
      sourceList = [...showInAppList, UNASSIGNED_FILTER_OPTION]
    } else if (type === FormTypeFilterType.CREATED_BY) {
      sourceList = formTypeCreatedByList
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    return this.eventsStore.appState.materialFilters.fieldsMap
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  private getOptionName = (option: string, filterType: string): string => {
    switch (filterType) {
      case FormTypeFilterType.FORM_CATEGORY:
        return this.formCategoriesStore.getNameById(option)
      case FormTypeFilterType.APP:
        return this.getAppLabel(option)
      case FormTypeFilterType.CREATED_BY:
        return getFormTypeCreatedByCaption(option as FormTypeCreatedByEnum)
      default:
        return option
    }
  }

  private getAppLabel = (appName: string): string => {
    switch (appName) {
      case ShowInApp.HOME:
        return Localization.translator.home
      case ShowInApp.FORMS:
        return Localization.translator.forms
      case UNASSIGNED_FILTER_OPTION:
        return Localization.translator.unassigned
    }
  }
}
