import * as React from 'react'

import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  SortEnd,
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import { IMapboxTileset } from '~/client/graph'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import ProjectSetUpPageStore from '../../../ProjectSetUpPage.store'
import MapViewSetUpStore, { SetUpSteps } from '../MapViewSetUp.store'
import { ViewType } from '../stores/MapBoxEditor.store'
import { ItemsCollapseState } from '../stores/MapViewItemsSetup.store'
import HierarchyTreeNode from './HierarchyTree/HierarchyTreeNode'
import { C_KEY_CODE, V_KEY_CODE } from './SitemapEditor/MapEditor'

import './LeftPanel.scss'

interface IProps {
  globes?: GlobeView[]

  store: MapViewSetUpStore
  projectSetUpPageStore?: ProjectSetUpPageStore
  step: SetUpSteps
  state?: DesktopInitialState

  isRubberMode?: boolean
  sitemapsStore?: SitemapsStore
  tilesetsStore?: TilesetsStore
  isDisabled?: boolean

  selectedMapViewItem?: MapViewItemBase
  selectedSitemap?: Sitemap
}

interface ISortableListItems {
  hiddenItems: JSX.Element[]
  shownItems: JSX.Element[]
}

interface ISortableListProps {
  isRubberMode?: boolean
  items: ISortableListItems
}

const SEARCH_DEFAULT = 'Search'
const OVERSCAN_ROW_COUNT = 6
const objectsVisible = 'objects visible'

const SortableItem = SortableElement(({ item }) => (
  <div
    className={classList({
      'sortable-item full': true,
      hidden: !item?.props?.children,
    })}
  >
    {item}
  </div>
))

const SortableList = SortableContainer<ISortableListProps>(
  ({ items, isRubberMode }) => {
    const { hiddenItems, shownItems } = items
    return (
      <div className="col">
        {/* Shown items */}
        {shownItems?.length > 0 && (
          <div>
            <div className="row pb10 px16">
              <div className="text large medium-bold lpMinus01 line-extra-large">
                {Localization.translator.shownInView}
              </div>
            </div>
            {shownItems.map((item, index) => {
              return isRubberMode ? (
                <div key={`hidden-item-${index}`}>{item}</div>
              ) : (
                <SortableItem key={`item-${index}`} index={index} item={item} />
              )
            })}
          </div>
        )}

        {/* Hidden items */}
        {hiddenItems?.length > 0 && (
          <div>
            <div className="row py10 px16">
              <div className="text large medium-bold lpMinus01 line-extra-large">
                {Localization.translator.hiddenInView}
              </div>
            </div>
            {hiddenItems.map((item, index) => (
              <div key={`hidden-item-${index}`}>{item}</div>
            ))}
          </div>
        )}
      </div>
    )
  },
)

const doneTypingInterval = 300

@inject('state', 'sitemapsStore', 'tilesetsStore')
@observer
export default class GlobeLeftPanel extends React.Component<IProps> {
  private readonly cellMeasurerCache: CellMeasurerCache = null
  @observable private isCollapsed: boolean = false

  public constructor(props: IProps) {
    super(props)

    this.cellMeasurerCache = new CellMeasurerCache({
      fixedWidth: true,
    })
  }

  public componentDidMount(): void {
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  public render(): JSX.Element {
    const {
      store: { mapBoxEditorStore },
      isDisabled,
    } = this.props
    const { selectedViewType } = mapBoxEditorStore

    if (this.isCollapsed) {
      return (
        <div
          className={classList({
            'sitemaps-left-bar br-light-grey full-height col no-outline-container collapsed-panel':
              true,
            'inactive-element': isDisabled,
          })}
        >
          <Icon
            className="no-grow"
            icon={IconNames.DOUBLE_CHEVRON_RIGHT}
            onClick={this.toggleCollapseState}
          />
        </div>
      )
    }

    return (
      <div
        className={classList({
          'sitemaps-left-bar br-light-grey full-height col no-outline-container':
            true,
          'inactive-element': isDisabled,
        })}
      >
        {this.renderViewSelect()}
        {selectedViewType === ViewType.Objects && (
          <>
            {this.renderSearchBar()}
            <div className="sitemaps-left-bar-header no-grow row y-center bb-light-input-border">
              <div
                className="row text light large ml10 pointer"
                onClick={this.toggleCollapsing}
              >
                {this.navigationIcon}
                {`${this.getVisibleObjectsCount(
                  selectedViewType,
                )} ${objectsVisible}`}
              </div>
              <div className="no-grow"></div>
            </div>
          </>
        )}
        {this.renderHierarchyPanel()}
      </div>
    )
  }

  private getVisibleObjectsCount = (selectedViewType: ViewType): number => {
    const { mapViewItems } = this.props.store.mapViewItemsSetupStore

    switch (selectedViewType) {
      case ViewType.Objects:
        return mapViewItems.length
      case ViewType.Plans:
        return (
          this.props.store.globeViewSetupStore.selectedGlobeView?.sitemaps
            ?.length || 0
        )
      default:
        return 0
    }
  }

  private toggleCollapseState = (): void => {
    this.isCollapsed = !this.isCollapsed
  }

  private onViewTypeSelect = async (viewType: ViewType) => {
    const { onViewTypeSelect, currentStep, setStep } = this.props.store

    onViewTypeSelect(viewType)
    if (currentStep && !this.props.isRubberMode) {
      setStep(null)
    }
  }

  private renderViewSelect(): JSX.Element {
    const { selectedViewType } = this.props.store.mapBoxEditorStore

    return (
      <div className="row left-panel-items bb-light-cool-grey">
        {Object.values(ViewType).map((viewType, index) => {
          const isSelected = selectedViewType === viewType
          if (!this.props.store.isGlobeMode && viewType === ViewType.Plans) {
            return null
          }

          return (
            <div
              key={viewType}
              className={classList({
                'row x-center full-height bg-white left-panel-item': true,
                'bl-white br-white': index === 1,
                selected: isSelected,
                inactive: !isSelected,
              })}
              onClick={this.onViewTypeSelect.bind(null, viewType)}
            >
              <div
                className={classList({
                  'text large row y-center x-center no-grow full-height': true,
                  'blue-brand selected-item': isSelected,
                  'primary pointer': !isSelected,
                })}
              >
                {viewType}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  private changeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.props.store.mapViewItemsSetupStore.setSearchKey(event.target.value)
    this.recomputeGridSize()
  }

  private renderSearchBar(): JSX.Element {
    return (
      <div className="search-bar relative bg-white px10">
        <Icon
          icon={IconNames.SEARCH}
          className="search-bar-icon absolute"
          iconSize={Icon.SIZE_LARGE}
        />
        <StruxhubInput
          onChange={this.changeSearchText}
          value={this.props.store.mapViewItemsSetupStore.searchKey}
          placeholder={SEARCH_DEFAULT}
          isMinimalisticMode={true}
        />
      </div>
    )
  }

  private renderMapFilesSearchBar(): JSX.Element {
    return (
      <div className="search-bar relative bg-white px10">
        <Icon
          icon={IconNames.SEARCH}
          className="search-bar-icon absolute"
          iconSize={Icon.SIZE_LARGE}
        />
        <StruxhubInput
          onChange={this.changeSearchText}
          value={this.props.store.mapViewItemsSetupStore.searchKey}
          placeholder={SEARCH_DEFAULT}
          isMinimalisticMode={true}
          withDelay={true}
          inputDelay={doneTypingInterval}
        />
      </div>
    )
  }

  private renderItemsPanel(): JSX.Element {
    const { hierarchyList } = this.props.store.mapViewItemsSetupStore
    const { isRubberMode } = this.props

    return (
      <div className="relative full-height">
        <AutoSizer>
          {({ width, height }) => (
            <List
              deferredMeasurementCache={this.cellMeasurerCache}
              data={hierarchyList}
              width={width}
              height={height}
              rowCount={hierarchyList.length}
              overscanRowCount={OVERSCAN_ROW_COUNT}
              scrollToAlignment="start"
              rowHeight={this.getRowHeight}
              rowRenderer={this.renderRow.bind(null, isRubberMode)}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  private getRowHeight = ({ index }): number => {
    const { searchKey } = this.props.store.mapViewItemsSetupStore
    const node = this.props.store.mapViewItemsSetupStore.hierarchyList[index]

    return node.isHidden ||
      (searchKey &&
        node.name &&
        !node.name?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()))
      ? 0
      : 35
  }

  public renderRow = (
    isRubberMode: boolean,
    { key, style, index, parent }: any,
  ): JSX.Element => {
    const {
      mapViewItemsSetupStore,
      sitemapViewsSetupStore,
      mapBoxViewerStore,
      globeViewSetupStore,
      mapBoxEditorStore,
    } = this.props.store
    const node = mapViewItemsSetupStore.hierarchyList[index]

    if (!node) {
      return null
    }

    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            style={style}
            ref={registerChild}
            className="col bb-palette-brand-lighter"
          >
            <HierarchyTreeNode
              node={node}
              key={index}
              mapViewItemsSetupStore={mapViewItemsSetupStore}
              sitemapViewsSetupStore={sitemapViewsSetupStore}
              level={node.level}
              getUpdatedItem={this.getUpdatedItemCoords}
              globeViewSetupStore={globeViewSetupStore}
              setViewportFromItem={mapBoxViewerStore.setViewportFromItem}
              recomputeGridSize={this.recomputeGridSize}
              mapBoxEditorStore={isRubberMode && mapBoxEditorStore}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }

  private getUpdatedItemCoords = (item: MapViewItemBase): MapViewItemBase => {
    const {
      isGlobeMode,
      mapBoxViewerStore: { displayedGlobeViewItems },
    } = this.props.store
    return isGlobeMode
      ? displayedGlobeViewItems.find(i => item.id == i.id)
      : item
  }

  private renderHierarchyPanel(): JSX.Element {
    const { selectedViewType } = this.props.store.mapBoxEditorStore

    switch (selectedViewType) {
      case ViewType.Objects:
        return this.renderItemsPanel()
      case ViewType.Plans:
        return this.renderMapFiles()
    }
  }

  private get navigationIcon(): JSX.Element {
    const { itemsCollapseState } = this.props.store.mapViewItemsSetupStore
    const className = 'no-grow navigation-arrows'

    switch (itemsCollapseState) {
      case ItemsCollapseState.collapsed:
        return <Icons.NavigationArrowsUp className={className} />
      case ItemsCollapseState.notCollapsed:
        return <Icons.NavigationArrowsDown className={className} />
      default:
        return <Icons.NavigationArrows className={className} />
    }
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    if ((event.metaKey || event.ctrlKey) && event.code === C_KEY_CODE) {
      this.props.store.mapViewItemsSetupStore.copyLevel()
    }
    if ((event.metaKey || event.ctrlKey) && event.code === V_KEY_CODE) {
      this.props.store.mapViewItemsSetupStore.pasteLevel()
    }
  }

  @computed
  private get sortablePlans(): ISortableListItems {
    return {
      hiddenItems: this.filteredPlans
        .filter(p => !this.isPlanDisplayed(p.id))
        .map(p => this.renderSinglePlan(p, true)),
      shownItems: this.filteredGlobePlans
        .map(sitemapId => this.filteredPlansToSitemaps[sitemapId])
        .filter(p => this.isPlanDisplayed(p.id))
        .map(p => this.renderSinglePlan(p, this.props.isRubberMode)),
    }
  }

  @computed
  private get sortableTilesets(): ISortableListItems {
    return {
      hiddenItems: this.filteredTilesets
        .filter(t => !this.isTilesetDisplayed(t.id))
        .map(t => this.renderSingleTile(t, true)),
      shownItems: this.filteredGlobeTilesets
        .map(tId => this.filteredTilesetDataToTileset[tId])
        .filter(t => this.isTilesetDisplayed(t.id))
        .map(t => this.renderSingleTile(t, this.props.isRubberMode)),
    }
  }

  @computed
  private get filteredGlobePlans(): string[] {
    if (!this.props.store.globeViewSetupStore.selectedGlobeView) {
      return []
    }
    return this.props.store.globeViewSetupStore.selectedGlobeView.sitemaps
      .filter(sitemapId => !!this.filteredPlansToSitemaps[sitemapId])
      .reverse()
  }

  @computed
  private get filteredGlobeTilesets(): string[] {
    if (!this.props.store.globeViewSetupStore.selectedGlobeView) {
      return []
    }
    return this.props.store.globeViewSetupStore.selectedGlobeView.tilesets
      .filter(tId => !!this.filteredTilesetDataToTileset[tId])
      .reverse()
  }

  @computed
  private get filteredPlansToSitemaps(): { [planId: string]: Sitemap } {
    return this.filteredPlans.reduce((acc, sitemap) => {
      acc[sitemap.id] = sitemap
      return acc
    }, {})
  }

  @computed
  private get filteredTilesetDataToTileset(): {
    [planId: string]: IMapboxTileset
  } {
    return this.filteredTilesets.reduce((acc, tileset) => {
      acc[tileset.id] = tileset
      return acc
    }, {})
  }

  private isPlanDisplayed(planId: string): boolean {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store

    return this.props.isRubberMode
      ? mapBoxEditorStore.leftPanelVisibility.plans[planId]
      : globeViewSetupStore.selectedGlobeView?.sitemapsMap?.[planId]
  }

  private isTilesetDisplayed(tilesetId: string): boolean {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store

    return this.props.isRubberMode
      ? mapBoxEditorStore.leftPanelVisibility.tilesets[tilesetId]
      : globeViewSetupStore.selectedGlobeView?.tilesetsMap?.[tilesetId]
  }

  private renderMapFiles(): JSX.Element {
    return (
      <>
        {this.renderMapFilesSearchBar()}
        <div className="scrollable full-height">
          {this.renderPlans()}
          {!this.props.state.isTilesetsDisabled && this.renderTiles()}
        </div>
      </>
    )
  }

  private renderPlans(): JSX.Element {
    const { toggleCreateMenu } = this.props.store.sitemapControlStore
    return (
      <>
        <div className="row pa10">
          <div className="text header">
            {Localization.translator.xPlans(this.filteredPlans.length)}
          </div>
          <div className="sitemap-ribbon no-grow row">
            <div
              className="text white bg-white brada24 py5 large title-row pointer row x-center ba-light-cool-grey"
              onClick={() => toggleCreateMenu()}
            >
              <Icon icon={IconNames.PLUS} intent={Intent.PRIMARY} />
            </div>
          </div>
        </div>
        <div className="col pb12 px12 columns-list no-select">
          <div className="col full-height sortable-list-holder">
            <SortableList
              items={this.sortablePlans}
              onSortEnd={this.onPlansSortEnd}
              axis="y"
              distance={2}
              isRubberMode={this.props.isRubberMode}
            />
          </div>
        </div>
      </>
    )
  }

  @action.bound
  private onPlansSortEnd(sort: SortEnd) {
    const newSitemaps = arrayMove(
      this.filteredGlobePlans,
      sort.oldIndex,
      sort.newIndex,
    ).reverse()
    this.props.store.globeViewSetupStore.updateGlobeView(
      null,
      null,
      null,
      newSitemaps,
    )
  }

  @action.bound
  private onTilesetsSortEnd(sort: SortEnd) {
    const newTilesets = arrayMove(
      this.filteredGlobeTilesets,
      sort.oldIndex,
      sort.newIndex,
    ).reverse()
    this.props.store.globeViewSetupStore.updateGlobeView(
      null,
      null,
      null,
      null,
      newTilesets,
    )
  }

  private renderTiles(): JSX.Element {
    const { toggleTileCreateMenu } = this.props.store.sitemapControlStore
    return (
      <>
        <div className="row pa10">
          <div className="text header">
            {this.filteredTilesets?.length + ' Tilesets'}
          </div>
          <div className="sitemap-ribbon no-grow row">
            <div
              className="text white bg-white brada24 py5 large title-row pointer row x-center ba-light-cool-grey"
              onClick={toggleTileCreateMenu}
            >
              <Icon icon={IconNames.PLUS} intent={Intent.PRIMARY} />
            </div>
          </div>
        </div>
        <div className="col pb12 px12 columns-list no-select">
          <div className="col full-height sortable-list-holder">
            <SortableList
              items={this.sortableTilesets}
              onSortEnd={this.onTilesetsSortEnd}
              axis="y"
              distance={2}
              isRubberMode={this.props.isRubberMode}
            />
          </div>
        </div>
      </>
    )
  }

  private selectSitemap = async (sitemapId: string): Promise<void> => {
    this.props.store.tilesetsSetupStore.deselectTileset()
    this.props.store.mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
    await this.props.store.sitemapsSetupStore.selectSitemapById(
      sitemapId,
      false,
    )
    this.props.store.mapBoxViewerStore.setViewportFromPlan(
      this.props.store.sitemapsSetupStore.selectedSitemap,
    )
  }

  private selectTileset = async (tilesetId: string): Promise<void> => {
    this.props.store.sitemapsSetupStore.deselectSitemap()
    this.props.store.mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
    await this.props.store.tilesetsSetupStore.selectTilesetById(
      tilesetId,
      false,
    )
    this.props.store.mapBoxViewerStore.setViewportFromTileset(
      this.props.store.tilesetsSetupStore.selectedTileset,
    )
  }

  private renderSingleTile = (
    tileset: IMapboxTileset,
    isHidden?: boolean,
  ): JSX.Element => {
    const { setViewportFromTileset } = this.props.store.mapBoxViewerStore

    const shouldHighlightAllBorders =
      this.props.store.tilesetsSetupStore.selectedTilesetId === tileset?.id

    return (
      <div
        key={tileset.id}
        className={classList({
          'globe-plan-node row pr12 relative pl12 h70 pointer': true,
          selected: shouldHighlightAllBorders,
        })}
        onClick={
          this.props.isRubberMode
            ? NOOP
            : this.selectTileset.bind(this, tileset.id)
        }
      >
        {!isHidden && (
          <Icon
            className="dragging-trigger"
            icon={IconNames.DRAG_HANDLE_VERTICAL}
          />
        )}
        <div className="row">
          <div
            className="row px10 brada4 bg-unset y-center "
            onClick={setViewportFromTileset.bind(this, tileset)}
            style={{ minHeight: 70 }}
          >
            <Tooltip
              className="bp3-dark no-grow"
              content={tileset.name}
              position={Position.BOTTOM}
            >
              <div className="text bold large mw180 ellipsis">
                {tileset.name}
              </div>
            </Tooltip>
          </div>
          {this.selectedGlobeView &&
            this.renderTilesetObjectVisibilityToggle(tileset.id)}
        </div>
      </div>
    )
  }

  private renderSinglePlan = (
    plan: Sitemap,
    isHidden?: boolean,
  ): JSX.Element => {
    const isDisplayed =
      this.props.store.globeViewSetupStore.selectedGlobeView?.sitemapsMap?.[
        plan.id
      ]
    const shouldHighlightAllBorders =
      this.props.store.sitemapsSetupStore.selectedSitemap?.id === plan.id

    return (
      <div
        key={plan.id}
        className={classList({
          'globe-plan-node row pr12 relative pl12': true,
          'globe-icon-holder': isDisplayed,
          selected: shouldHighlightAllBorders,
        })}
        onClick={
          this.props.isRubberMode
            ? NOOP
            : this.selectSitemap.bind(this, plan.id)
        }
      >
        <div className="row pointer">
          {!isHidden && (
            <Icon
              className="dragging-trigger"
              icon={IconNames.DRAG_HANDLE_VERTICAL}
            />
          )}
          <div className="col full-height sitemap-image-holder x-center no-grow unclickable-element">
            <DesktopFileInput
              id={plan.id}
              name=""
              value={plan.filledImage}
              isReadonly={true}
              textClassName="hint"
              shouldHideIconAndOutline={true}
            />
          </div>
          <div className="row full-height y-center sitemap-item-name">
            <div className="text large row full-width relative">
              <div
                className="text bold large px10 brada4 text-ellipsis bg-unset"
                style={{ maxWidth: 130 }}
              >
                {plan.name}
              </div>
            </div>
          </div>
        </div>
        {this.selectedGlobeView &&
          this.renderPlanObjectVisibilityToggle(plan.id)}
      </div>
    )
  }

  private renderPlanObjectVisibilityToggle = (
    sitemapId: string,
  ): JSX.Element => {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store
    const isDisplayed = this.props.isRubberMode
      ? mapBoxEditorStore.leftPanelVisibility.plans[sitemapId]
      : globeViewSetupStore.selectedGlobeView?.sitemapsMap?.[sitemapId]

    return (
      <div
        className="icon-wrapper no-grow pointer"
        onClick={this.onPlanVisibilityClick.bind(null, sitemapId)}
      >
        {isDisplayed ? (
          <Icons.EyeView className="globe-icon" />
        ) : (
          <Icons.EyeHide className="globe-icon" />
        )}
      </div>
    )
  }

  private renderTilesetObjectVisibilityToggle = (
    tilesetId: string,
  ): JSX.Element => {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store
    const isDisplayed = this.props.isRubberMode
      ? mapBoxEditorStore.leftPanelVisibility.tilesets[tilesetId]
      : globeViewSetupStore.selectedGlobeView?.tilesetsMap?.[tilesetId]

    return (
      <div
        className="icon-wrapper no-grow pointer flex-pull-right"
        onClick={this.onTilesetVisibilityClick.bind(null, tilesetId)}
      >
        {isDisplayed ? (
          <Icons.EyeView className="globe-icon" />
        ) : (
          <Icons.EyeHide className="globe-icon" />
        )}
      </div>
    )
  }

  @action.bound
  private onPlanVisibilityClick(sitemapId: string) {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store
    if (this.props.isRubberMode) {
      mapBoxEditorStore.togglePlanVisibility(sitemapId)
    } else {
      globeViewSetupStore.setSitemapToGlobeView(sitemapId)
    }
  }

  @action.bound
  private onTilesetVisibilityClick(tilesetId: string) {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store
    if (this.props.isRubberMode) {
      mapBoxEditorStore.toggleTilesetVisibility(tilesetId)
    } else {
      globeViewSetupStore.setTilesetToGlobeView(tilesetId)
    }
  }

  private get selectedGlobeView(): GlobeView {
    return this.props.store.globeViewSetupStore.selectedGlobeView
  }

  @action.bound
  private toggleCollapsing(): void {
    this.props.store.mapViewItemsSetupStore.toggleItemsCollapsingState()
    this.recomputeGridSize()
  }

  @action.bound
  private recomputeGridSize(): void {
    this.cellMeasurerCache?.clearAll()
  }

  @computed
  private get referencedPlans(): Sitemap[] {
    return this.props.sitemapsStore.list.filter(x => x.isReferenced)
  }

  @computed
  private get tilesetsList(): IMapboxTileset[] {
    return this.props.tilesetsStore.list
  }

  private get filteredTilesets(): IMapboxTileset[] {
    const { searchKey } = this.props.store.mapViewItemsSetupStore
    if (!searchKey) {
      return this.tilesetsList
    }
    return this.tilesetsList.filter(x =>
      x.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()),
    )
  }

  private get filteredPlans(): Sitemap[] {
    const { searchKey } = this.props.store.mapViewItemsSetupStore
    if (!searchKey) {
      return this.referencedPlans
    }
    return this.referencedPlans.filter(x =>
      x.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()),
    )
  }
}
