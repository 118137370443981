import { action, computed, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import {
  formTypeCreatedByList,
  getFormTypeCreatedByCaption,
} from '~/client/src/shared/enums/FormTypeCreatedBy'
import { FormTypeGroupingOption } from '~/client/src/shared/enums/FormTypeGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import { ITreeNodeObj } from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  EMPTY_STRING,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

enum SpecificDataKeys {
  ICON = 'icon',
  NAME = 'name',
  CATEGORY = 'category',
  SHOW_IN_APP = 'show-in-app',
  CREATED_BY = 'created-by',
  LAST_UPDATED = 'last-updated',
  HIDDEN_UPDATED_AT_VALUE = 'hidden-updated-at',
}
export const WorkflowDataKeys = { ...BasicDataKeys, ...SpecificDataKeys }

const unassignedValues = [UNASSIGNED, NO_SPECIFIED, EMPTY_STRING, NO_VALUE]

export default class FormTypesListStore extends BaseMultiBandListStore<PermitType> {
  @observable public isRemoveDialogShown: boolean = false

  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public constructor(
    state: DesktopInitialState,
    permitTypesStore: PermitTypesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly formCategoriesStore: FormCategoriesStore,
  ) {
    super(state.formTypesFilters, () => permitTypesStore.actualTypes)
  }

  public get columns(): ILWFCColumn[] {
    return [
      {
        dataKey: WorkflowDataKeys.CHECKBOX,
        width: 40,
      },
      {
        dataKey: WorkflowDataKeys.ICON,
        label: Localization.translator.icon,
        width: 55,
      },
      {
        dataKey: WorkflowDataKeys.NAME,
        label: Localization.translator.formName,
        width: 220,
        flexGrow: 4,
      },
      {
        dataKey: WorkflowDataKeys.CATEGORY,
        label: Localization.translator.formCategory,
        width: 200,
        flexGrow: 1,
      },
      {
        dataKey: WorkflowDataKeys.SHOW_IN_APP,
        label: Localization.translator.showInApp,
        width: 265,
        flexGrow: 1,
      },
      {
        dataKey: WorkflowDataKeys.CREATED_BY,
        label: Localization.translator.createdBy,
        width: 160,
      },
      {
        dataKey: WorkflowDataKeys.LAST_UPDATED,
        label: Localization.translator.lastUpdated,
        width: 220,
        flexGrow: 1,
      },
    ]
  }

  public get minTableWidth(): number {
    return this.columns.reduce(
      (overallWidth, column) => (overallWidth += column.width),
      0,
    )
  }

  @computed
  public get rows(): ILWFCRow[] {
    return this.toBandTreeNodeRows(
      [this.groupingKey],
      null,
      EMPTY_STRING,
      0,
      false,
      this.sortTreeObjs,
    )
  }

  public get isTransferOrDefaultSelected(): boolean {
    return this.selectedInstances.some(
      ({ isMaterialTransfer, isCustom }) => isMaterialTransfer || !isCustom,
    )
  }

  public get deletionConfirmMessage(): string {
    return Localization.translator.logisticsListDescriptions.deletionConfirmMsg(
      this.selectedInstancesCount,
    )
  }

  @computed
  protected get categoryToInstancesMap() {
    const map = {}

    this.filteredCollection.forEach(formType => {
      const categoryIds = this.getCategoryIds(formType, this.groupingKey) || []
      if (!categoryIds.length) {
        categoryIds.push(UNASSIGNED)
      }

      categoryIds.forEach(id => {
        const formattedCategoryId = id || UNASSIGNED

        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(formType)
        } else {
          map[formattedCategoryId] = [formType]
        }
      })
    })
    return map
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    switch (currentBand) {
      case FormTypeGroupingOption.NONE:
        return [
          {
            id: FormTypeGroupingOption.NONE,
            name: Localization.translator.none,
          },
        ]
      case FormTypeGroupingOption.CATEGORY:
        return [
          ...this.formCategoriesStore.activeCategories.map(ct => ({
            id: ct.id,
            name: ct.name,
            object: !ct.projectId,
          })),
          { id: UNASSIGNED, name: Localization.translator.unassigned },
        ]
      case FormTypeGroupingOption.APP:
        return [
          {
            id: FormTypeGroupingOption.APP,
            name: `${Localization.translator.home}, ${Localization.translator.forms}`,
          },
          {
            id: UNASSIGNED,
            name: Localization.translator.unassigned,
          },
        ]
      case FormTypeGroupingOption.CREATED_BY:
        return formTypeCreatedByList.map(v => ({
          id: v,
          name: getFormTypeCreatedByCaption(v),
        }))

      default:
        return []
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: PermitType[] }
  } {
    const map = {}

    Object.values(FormTypeGroupingOption).forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(formType => {
        const groupNames: string[] = this.getCategoryIds(formType, band) || []
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(group => {
          const groupName = group || UNASSIGNED

          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }
          bandMap[groupName].push(formType)
        })
      })
    })

    return map
  }

  protected toRows(
    formTypes: PermitType[],
    _company?: string,
    level?: number,
  ): ILWFCRow[] {
    return formTypes.map(formType => ({
      data: {
        [WorkflowDataKeys.ID]: formType.id,
        [WorkflowDataKeys.CHECKBOX]: this.selection.get(formType.id),
        [WorkflowDataKeys.ICON]: formType.type,
        [WorkflowDataKeys.NAME]: formType.name,
        [WorkflowDataKeys.CATEGORY]: formType.getCategoryName(
          this.formCategoriesStore,
        ),
        [WorkflowDataKeys.SHOW_IN_APP]: formType.isEnabled,
        [WorkflowDataKeys.CREATED_BY]: getFormTypeCreatedByCaption(
          formType.createdBy,
        ),
        [WorkflowDataKeys.LAST_UPDATED]: formType.getFormattedUpdatedAt(
          this.projectDateStore.getMonthDayYearAndTimeToDisplay,
        ),
        [WorkflowDataKeys.HIDDEN_UPDATED_AT_VALUE]:
          !formType.isDefault && formType.updatedAt,
      },
      level,
    }))
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: count.toString(),
      categoryLabel: this.getCategoryLabelById(categoryId),
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
    }

    return { category, data: {}, level: 0 }
  }

  protected createTreeNodeCategoryRow(
    categoryId: string,
    categoryName: string,
    categoryObject: any,
    level: number,
    objects: PermitType[],
  ): ILWFCRow {
    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: objects.length.toString(),
      categoryLabel: categoryName,
      isChecked: objects.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      idsToSelect: objects.map(d => d[this.idKey]),
    }

    return {
      category,
      data: categoryObject,
      level,
    }
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getSortingValue(aRowData, columnKey)
    const v2 = this.getSortingValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      if (unassignedValues.includes(v1)) {
        return 1
      }
      if (unassignedValues.includes(v2)) {
        return -1
      }
      return this.collator.compare(v1, v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  protected searchFiltering = (formType: PermitType) => {
    const key = this.filter.searchKey.toLowerCase()
    const entityValues = [
      formType.name,
      formType.getCategoryName(this.formCategoriesStore),
    ]
    return entityValues.some(value => value?.toLowerCase()?.includes(key))
  }

  @action.bound
  public showDeleteFormsDialog() {
    this.isRemoveDialogShown = true
  }

  @action.bound
  public hideDeleteFormsDialog() {
    this.isRemoveDialogShown = false
  }

  public selectOrResetAll = () => {
    if (this.filteredCollection.length !== this.selectedInstancesCount) {
      this.resetSelection()
      this.selectAll()
    } else {
      this.resetSelection()
    }
  }

  private getCategoryIds(formType: PermitType, band: string): string[] {
    switch (band) {
      case FormTypeGroupingOption.CATEGORY:
        return [
          this.formCategoriesStore.getById(formType.categoryId)?.id ||
            UNASSIGNED,
        ]
      case FormTypeGroupingOption.APP:
        return [formType.isEnabled ? FormTypeGroupingOption.APP : UNASSIGNED]
      case FormTypeGroupingOption.CREATED_BY:
        return [formType.createdBy]
      case FormTypeGroupingOption.NONE:
        return [FormTypeGroupingOption.NONE]
      default:
        break
    }
  }

  private sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) return 1
      if (unassignedValues.includes(b.id)) return -1
      return this.collator.compare(a.name, b.name)
    })
  }

  private getSortingValue(rowData: LWFCRowData, columnKey: string) {
    switch (columnKey) {
      case WorkflowDataKeys.SHOW_IN_APP:
        return rowData[columnKey] ? 1 : 0
      case WorkflowDataKeys.LAST_UPDATED:
        return rowData[WorkflowDataKeys.HIDDEN_UPDATED_AT_VALUE] || 0

      default:
        const value = rowData[columnKey]
        return value === NO_SPECIFIED ? EMPTY_STRING : value
    }
  }
}
