import { action, computed, observable } from 'mobx'

import {
  IPermitTypeField,
  ISummaryField,
  PermitFieldType,
  WorkflowStepType,
} from '~/client/graph'
import { FORM_MATERIAL_FIELDS_KEY } from '~/client/src/shared/constants/permitTypeFieldsConstants'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import { showErrorToast } from '~/client/src/shared/utils/toaster'

import AnalyticsSetupStore from '../../AnalyticsSetup.store'

interface IConfigureColumn {
  label: string
  type: PermitFieldType
  id: string
  checkListItemId?: string
  isEnabled: boolean
}

const visibleFieldTypes = [
  PermitFieldType.Assignee,
  PermitFieldType.Subscriber,
  PermitFieldType.User,
  PermitFieldType.CompanyInformational,
  PermitFieldType.Select,
  PermitFieldType.InputText,
  PermitFieldType.InputTextArea,
  PermitFieldType.InputNumber,
  PermitFieldType.Email,
  PermitFieldType.PhoneNumber,
  PermitFieldType.Time,
  PermitFieldType.AdditionalDate,
  PermitFieldType.Text,
  PermitFieldType.Section,
  PermitFieldType.Measure,
  PermitFieldType.File,
  PermitFieldType.Question,
  PermitFieldType.Checklist,
]

enum SummarizedFormFieldId {
  NAME = 'name',
  FIELD_ID = 'fieldId',
}

export enum SummarizedFormFieldType {
  TEXT = 'text',
  SELECT = 'select',
}

interface ISummarizeFormField {
  id: string
  required?: boolean
  type: SummarizedFormFieldType
  getCaption: () => string
  isValid?: () => boolean
  value: any
  onChange: () => void
  options?: Array<{ value: any; name: string; disabled?: boolean }>
}

export default class ConfigureColumnModalStore {
  @observable public isSummarizedFormOpened = false
  @observable public editedSummarizedFieldIndex = null
  @observable public summaryField: ISummaryField = {}

  public constructor(
    private readonly permitTypesStore: PermitTypesStore,
    private readonly analyticsSetupStore: AnalyticsSetupStore,
  ) {}

  @computed
  public get fields(): IConfigureColumn[] {
    const _fields: IConfigureColumn[] = []

    this.activePermitType.workflowSteps.forEach(workflowStep => {
      // I'm adding it in order to separate RecurringInspect fields from others
      if (workflowStep.type === WorkflowStepType.RecurringInspection) {
        _fields.push({
          id: `${PermitFieldType.Section}_${workflowStep.type}`,
          type: PermitFieldType.Section,
          label: Localization.translator.inspection,
          isEnabled: false,
        })
      }

      workflowStep.fields.forEach(field =>
        _fields.push(...this.permitTypeFieldToConfigureField(field)),
      )

      // rework all of these in a separate story STX-2039
      workflowStep.conditionalFields
        .filter(cf => cf.key !== FORM_MATERIAL_FIELDS_KEY)
        .flatMap(cFields => cFields.values)
        .forEach(field =>
          _fields.push(...this.permitTypeFieldToConfigureField(field)),
        )
    })

    // Filtering only visible type of fields
    const filteredFields = _fields.filter(field =>
      visibleFieldTypes.includes(field.type),
    )

    // If our section have only invisible fields, I'm skipping this sections too
    return filteredFields.filter((field, i) => {
      const nextField = filteredFields[i + 1]
      const isNoSectionFields =
        field.type === PermitFieldType.Section &&
        nextField?.type === PermitFieldType.Section
      const isLastSectionField =
        field.type === PermitFieldType.Section &&
        filteredFields.length === i + 1

      return !isNoSectionFields && !isLastSectionField
    })
  }

  private permitTypeFieldToConfigureField(field: IPermitTypeField) {
    const selectedReportTypeFields =
      this.analyticsSetupStore.selectedReportTypeFields

    if (
      [PermitFieldType.Question, PermitFieldType.Checklist].includes(field.type)
    ) {
      return field.checklist.list.map(checkListItem => ({
        id: field.id,
        checkListItemId: checkListItem.id,
        label: checkListItem.text,
        type: field.type,
        isEnabled: selectedReportTypeFields.permitTypeFields?.some(
          permitTypeField =>
            permitTypeField.checkListItemId === checkListItem.id,
        ),
      }))
    }

    return [
      {
        id: field.id,
        label: field.caption,
        type: field.type,
        isEnabled: selectedReportTypeFields.permitTypeFields?.some(
          permitTypeField => permitTypeField.permitTypeFieldId === field.id,
        ),
      },
    ]
  }

  public get activePermitType(): PermitType {
    return this.permitTypesStore.getPermitTypeById(
      this.analyticsSetupStore.selectedReportType.id,
    )
  }

  public get numberFields(): IConfigureColumn[] {
    return this.fields.filter(
      field => field.type === PermitFieldType.InputNumber,
    )
  }

  public get selectedReportTypeFields() {
    return this.analyticsSetupStore.selectedReportTypeFields
  }

  public get summarizedFormFields(): ISummarizeFormField[] {
    return [
      {
        id: SummarizedFormFieldId.NAME,
        getCaption: () => Localization.translator.name,
        type: SummarizedFormFieldType.TEXT,
        required: true,
        isValid: () => !!this.summaryField.name,
        value: this.summaryField.name,
        onChange: this.changeSummaryField.bind(
          null,
          SummarizedFormFieldId.NAME,
        ),
      },
      {
        id: SummarizedFormFieldId.FIELD_ID,
        getCaption: () => Localization.translator.formField,
        type: SummarizedFormFieldType.SELECT,
        required: true,
        isValid: () => !!this.summaryField.fieldId,
        value: this.summaryField.fieldId,
        onChange: this.changeSummaryField.bind(
          null,
          SummarizedFormFieldId.FIELD_ID,
        ),
        options: this.numberFields.map(numberField => ({
          value: numberField.id,
          name: numberField.label,
        })),
      },
    ]
  }

  public get isFormValid() {
    return this.summarizedFormFields.every(field => field.isValid())
  }

  @action.bound
  public editSummaryField(summaryField: ISummaryField, index: number) {
    this.summaryField = Object.assign({}, summaryField)
    this.isSummarizedFormOpened = true
    this.editedSummarizedFieldIndex = index
  }

  @action.bound
  public deleteSummaryField(index: number) {
    this.selectedReportTypeFields.summaryFields.splice(index, 1)
    this.analyticsSetupStore.save()
  }

  @action.bound
  public changeSummaryField(
    summaryFieldKey: keyof ISummaryField,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    this.summaryField[summaryFieldKey] = e.target.value
  }

  @action.bound
  public addEditSummaryField() {
    if (!this.isFormValid) {
      showErrorToast(Localization.translator.pleaseFillAllFields)
      return
    }

    if (this.editedSummarizedFieldIndex === null) {
      this.selectedReportTypeFields.summaryFields.push(this.summaryField)
    } else {
      this.selectedReportTypeFields.summaryFields.splice(
        this.editedSummarizedFieldIndex,
        1,
        this.summaryField,
      )
    }
    this.analyticsSetupStore.save()
    this.closeSummarizedForm()
  }

  public getFieldLabelById = (id: string) => {
    return this.fields.find(field => field.id === id)?.label
  }
  public isPermitTypeFieldAvailable = (
    fieldId: string,
    checkListItemId?: string,
  ) => {
    return this.selectedReportTypeFields?.permitTypeFields?.some(
      ptf =>
        ptf.permitTypeFieldId === fieldId &&
        ptf.checkListItemId == checkListItemId,
    )
  }

  @action.bound
  public togglePermitTypeField(fieldId: string, checkListItemId: string) {
    if (this.isPermitTypeFieldAvailable(fieldId, checkListItemId)) {
      const removeIndex =
        this.selectedReportTypeFields.permitTypeFields.findIndex(
          ptf =>
            ptf.permitTypeFieldId === fieldId &&
            ptf.checkListItemId == checkListItemId,
        )
      this.selectedReportTypeFields.permitTypeFields.splice(removeIndex, 1)
    } else {
      this.selectedReportTypeFields?.permitTypeFields.push({
        permitTypeFieldId: fieldId,
        checkListItemId,
      })
    }

    this.analyticsSetupStore.save()
  }

  @action.bound
  public openSummarizedForm() {
    this.isSummarizedFormOpened = true
  }

  @action.bound
  public closeSummarizedForm() {
    this.isSummarizedFormOpened = false
    this.editedSummarizedFieldIndex = null
    this.summaryField = {}
  }
}
