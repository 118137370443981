import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { ViewState } from 'react-map-gl'

import StruxhubNumberWithControlsInput from '~/client/src/desktop/components/StruxhubDesktopInputs/StruxhubNumberWithControlsInput/StruxhubNumberWithControlsInput'
import MapViewSetUpStore, {
  MapViewConfigType,
  SetUpSteps,
} from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/MapViewSetUp.store'
import PropertiesPanel from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/components/PropertiesPanel/PropertiesPanel'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import MapBoxViewerStore, {
  MAX_PITCH_LEVEL,
} from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import IGeoPosition from '~/client/src/shared/models/IGeoPosition'
import Sitemap from '~/client/src/shared/models/Sitemap'
import InitialState from '~/client/src/shared/stores/InitialState'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import GlobeAssociationControl from '../../views/ProjectSetUp/components/AppsSitemap/GlobeAssociationControl'
import SitemapAssociationControl from '../../views/ProjectSetUp/components/AppsSitemap/components/SitemapAssociationControl'
import MapBoxEditorStore from '../../views/ProjectSetUp/components/AppsSitemap/stores/MapBoxEditor.store'
import TilesetProperties from './TilesetProperties'

import './GlobeProperties.scss'

const maxDegrees = 180
const maxZoomValue = 21
const minZoomValue = 0
const zoomStep = 0.01
const bearingStep = 1
const pitchStep = 1
export const maxLat = 90
export const minLat = -90

export const maxLng = 180
export const minLng = -180

const latitude = 'Latitude'
const longitude = 'Longitude'
const whiteboardProperties = 'Whiteboard properties'
const planProperties = 'Plan properties'
const viewProperties = 'View properties'
const plan = 'Plan'
const parameters = 'parameters'
const zoomText = 'Zoom'
const rotationText = 'Rotation'
const pitch = 'Pitch'
const showViewIn = 'Show view in'
const updateGeoreferencing = 'Update Georeferencing'
const updatePlanImage = 'Update Plan Image'
const lbsTag = 'LBS Tag'
const copyValues = 'Copy values'
const pasteValues = 'Paste values'

interface IProps {
  viewport: IGeoPosition
  store: MapViewSetUpStore

  globe: GlobeView
  updateGlobeView: (
    geoposition?: IGeoPosition,
    bounds?,
    items?,
    sitemaps?,
  ) => void

  mapBoxViewerStore: MapBoxViewerStore
  mapBoxEditorStore: MapBoxEditorStore
  isItemSelected: boolean
  selectedSitemap?: Sitemap

  saveAlignment: () => void
  exitRubber: () => void

  isGlobeMode?: boolean
  state?: InitialState
  sitemapsStore?: SitemapsStore
  isDisabled?: boolean
}

// TODO: extract all the text to the top
@inject('state', 'sitemapsStore')
@observer
export default class GlobeProperties extends React.Component<IProps> {
  public render(): JSX.Element {
    const { isGlobeMode, isDisabled } = this.props

    return (
      <div
        className={classList({
          'globe-properties-panel full-height relative': true,
          'globe-panel': isGlobeMode,
          'inactive-element': isDisabled,
        })}
      >
        {this.renderPanel()}
      </div>
    )
  }

  private renderPanel(): JSX.Element {
    const {
      isGlobeMode,
      isItemSelected,
      selectedSitemap,
      saveAlignment,
      exitRubber,
      mapBoxEditorStore,
    } = this.props

    switch (true) {
      case isItemSelected ||
        (selectedSitemap?.id && mapBoxEditorStore.isRubberMode):
        return (
          <PropertiesPanel
            store={this.props.store}
            step={SetUpSteps.alignPlan}
            saveAlignment={saveAlignment}
            exitRubber={exitRubber}
            viewport={this.props.viewport}
            isGlobeMode={isGlobeMode}
          />
        )
      case !this.props.state.isTilesetsDisabled &&
        (!!this.props.store.mapBoxViewerStore.tilesetsControlStore
          .selectedTilesetFeature ||
          !!this.props.store.tilesetsSetupStore.selectedTileset):
        return <TilesetProperties store={this.props.store} />
      default:
        return (
          <div className="full-height scrollable">{this.renderContent()}</div>
        )
    }
  }

  private renderContent(): JSX.Element {
    const { store, selectedSitemap } = this.props

    if (selectedSitemap?.id) {
      return (
        <div className="col bb-light-cool-grey">
          <div className="col py10">
            <div className="px10 row pb12 bb-light-input-border">
              <div className="text header bold center">
                {selectedSitemap?.isReferenced
                  ? planProperties
                  : whiteboardProperties}
              </div>
            </div>
            {this.renderHeader()}
            <div className="px10">
              <StruxhubInput
                label={Localization.translator.name}
                value={store.sitemapsSetupStore.editableSitemapName}
                isRequired={true}
                isRequiredTextHidden={true}
                isMinimalisticMode={false}
                onChange={this.changeSitemapName}
                withDelay={true}
              />
            </div>
          </div>
          {this.renderLBSTag()}
          {selectedSitemap?.isReferenced
            ? this.renderPlanProperties()
            : this.renderShowView()}
        </div>
      )
    }

    return (
      <div className="col">
        <div className="col py10">
          <div className="row pb12 bb-light-input-border">
            <div className="text header bold center">{viewProperties}</div>
          </div>
          <div className="px10">
            <StruxhubInput
              label={Localization.translator.name}
              value={store.globeViewSetupStore.editableGlobeName}
              onChange={this.changeGlobeName}
              withDelay={true}
              isRequired={true}
              isRequiredTextHidden={true}
              isMinimalisticMode={false}
            />
          </div>
          {this.renderLBSTag()}
        </div>
        {this.renderViewOrientation()}
        {this.renderShowView()}
      </div>
    )
  }

  private renderPlanProperties(): JSX.Element {
    const { sitemapsStore, selectedSitemap } = this.props

    return (
      <>
        {this.renderPlanOrientation()}
        <>
          <div
            className="pa10 text large blue center pointer"
            onClick={this.onSitemapGeoEditClick.bind(
              null,
              sitemapsStore.byId.get(selectedSitemap.id),
            )}
          >
            {updateGeoreferencing}
          </div>
          <div
            className="pa10 text large blue center pointer bb-light-cool-grey"
            onClick={this.props.store.sitemapControlStore.toggleEditMenu}
          >
            {updatePlanImage}
          </div>
        </>
        {this.renderViewOrientation()}
      </>
    )
  }

  private renderPlanOrientation(): JSX.Element {
    const { selectedSitemap } = this.props
    const label = `${plan} ${parameters}`
    return (
      <div className="col pa10 bb-light-cool-grey">
        <div className="row">
          <div className="text large pb12 bold">{label}</div>
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={latitude}
            type="number"
            isReadOnly={true}
            value={selectedSitemap.center.lat.toFixed(2)}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            negativeOrDecimal={true}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={longitude}
            type="number"
            isReadOnly={true}
            value={selectedSitemap.center.lng.toFixed(2)}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            negativeOrDecimal={true}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={zoomText}
            type="number"
            isReadOnly={true}
            value={selectedSitemap.zoom?.toFixed(2)}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            negativeOrDecimal={true}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={rotationText}
            type="number"
            isReadOnly={true}
            value={selectedSitemap.bearing?.toFixed(2)}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            negativeOrDecimal={true}
          />
        </div>
      </div>
    )
  }

  private renderViewOrientation(): JSX.Element {
    const { viewport } = this.props
    const label = `${Localization.translator.view_noun} ${parameters}`
    return (
      <div className="col pa10 bb-light-cool-grey">
        <div className="row">
          <div className="text large pb12 bold">{label}</div>
          {this.renderDuplicate()}
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={latitude}
            type="number"
            isRequired={true}
            value={viewport.latitude.toString()}
            onChange={this.changeLat}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            withDelay={true}
            min={minLat}
            max={maxLat}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={longitude}
            type="number"
            isRequired={true}
            value={viewport.longitude.toString()}
            onChange={this.changeLng}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            withDelay={true}
            min={minLng}
            max={maxLng}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubNumberWithControlsInput
            label={zoomText}
            type="number"
            isRequired={true}
            max={maxZoomValue}
            min={minZoomValue}
            step={zoomStep}
            value={viewport.zoom.toFixed(3)}
            onChange={this.changeZoom}
            onValueChange={this.changeZoomValue}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            withDelay={true}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubNumberWithControlsInput
            label={rotationText}
            type="number"
            isRequired={true}
            max={maxDegrees}
            min={-maxDegrees}
            step={bearingStep}
            value={viewport.bearing.toFixed(2)}
            onChange={this.changeBearing}
            onValueChange={this.changeBearingValue}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            withDelay={true}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubNumberWithControlsInput
            label={pitch}
            type="number"
            step={pitchStep}
            isRequired={true}
            value={viewport.pitch.toFixed(2)}
            onChange={this.changePitch}
            onValueChange={this.changePitchValue}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            withDelay={true}
            min={0}
            max={MAX_PITCH_LEVEL}
          />
        </div>
      </div>
    )
  }

  private renderShowView(): JSX.Element {
    const { selectedSitemap, state, store } = this.props
    const {
      isDeliveriesDisabled,
      isFormsDisabled,
      isLogisticsDisabled,
      isTrackerDisabled,
    } = state
    const {
      logisticsAssignedGlobes,
      formsAssignedGlobes,
      activitiesAssignedGlobes,
      deliveriesAssignedGlobes,
      logisticsAssignedSitemaps,
      formsAssignedSitemaps,
      activitiesAssignedSitemaps,
      deliveriesAssignedSitemaps,
      globeViewSetupStore: { selectedGlobeViewId, selectedGlobeView },
      onGlobeSectionClick,
      onWhiteboardSectionClick,
      isGlobeMode,
    } = store

    return (
      <div className="col view-select-globe-props pa10">
        <div className="text large">{showViewIn}</div>
        <div className="pa10">
          {!isLogisticsDisabled && (
            <div className="row mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? logisticsAssignedGlobes[selectedGlobeViewId]
                    : logisticsAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.logistics,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.logistics,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.HomeAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.home}</div>
              </div>
            </div>
          )}
          {!isFormsDisabled && (
            <div className="row mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? formsAssignedGlobes[selectedGlobeViewId]
                    : formsAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.forms,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.forms,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.FormAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.forms}</div>
              </div>
            </div>
          )}
          {!isDeliveriesDisabled && (
            <div className="row text mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? deliveriesAssignedGlobes[selectedGlobeViewId]
                    : deliveriesAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.deliveries,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.deliveries,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.DeliveryAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.deliveries}</div>
              </div>
            </div>
          )}
          {!isTrackerDisabled && (
            <div className="row text mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? activitiesAssignedGlobes[selectedGlobeViewId]
                    : activitiesAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.activities,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.activities,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.ScheduleAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.schedule}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  private renderLBSTag(): JSX.Element {
    const { store, selectedSitemap } = this.props
    if (selectedSitemap?.id) {
      return (
        <div className="pa10">
          <div className="row text uppercase small light lp15 font-w-600">
            {lbsTag}
          </div>
          <div className="row">
            <SitemapAssociationControl
              mapViewItemsSetupStore={store.mapViewItemsSetupStore}
              sitemapsSetupStore={store.sitemapsSetupStore}
              shouldHideModal={
                !store.sitemapsSetupStore.isAssignSitemapDialogShown ||
                store.isGlobeListShown
              }
              sitemap={selectedSitemap}
              onToggle={store.sitemapsSetupStore.toggleAssignSitemapDialog}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="px10">
        <div className="row text uppercase small light lp15 font-w-600">
          {lbsTag}
        </div>
        <div className="row">
          <GlobeAssociationControl
            mapViewItemsSetupStore={store.mapViewItemsSetupStore}
            globeViewSetupStore={store.globeViewSetupStore}
            shouldHideModal={
              !store.globeViewSetupStore.isAssignGlobeDialogShown ||
              store.isGlobeListShown
            }
            globe={store.globeViewSetupStore.selectedGlobeView}
          />
        </div>
      </div>
    )
  }

  private renderHeader(): JSX.Element {
    return (
      <div className="row pa10 x-end">
        <Icons.Delete
          className="no-grow pointer"
          onClick={this.deleteSitePlan}
        />
      </div>
    )
  }

  private renderDuplicate = (): JSX.Element => {
    return (
      <>
        <Tooltip
          className="bp3-dark no-grow"
          content={copyValues}
          position={Position.BOTTOM}
        >
          <Icons.CopyOrientation
            className="pointer no-grow mx10"
            onClick={this.copyText}
          />
        </Tooltip>
        <Tooltip
          className="bp3-dark no-grow"
          content={pasteValues}
          position={Position.BOTTOM}
        >
          <Icons.PasteOrientation
            className="pointer no-grow mx10"
            onClick={this.pasteText}
          />
        </Tooltip>
      </>
    )
  }

  private deleteSitePlan = (): void => {
    this.props.store.sitemapControlStore.showDeleteDialog()
  }

  private onSitemapGeoEditClick = (sitemap: Sitemap): void => {
    const { store } = this.props
    store.sitemapsSetupStore.selectSitemap(sitemap, false)
    store.setStep(SetUpSteps.alignPlan)
    store.mapBoxViewerStore.setViewportFromAddress(sitemap, true)
    store.mapBoxEditorStore.setLeftVisibility()
  }

  private copyText = async (): Promise<void> => {
    const { longitude, latitude, zoom, bearing, pitch } = this.props.viewport
    const text = `${longitude};${latitude};${zoom};${bearing};${pitch}`
    await navigator.clipboard
      .writeText(text)
      .then(() =>
        showToast(
          Localization.translator.copiedToClipboard,
          ToastTheme.SUCCESS,
          IconNames.TICK,
        ),
      )
  }

  private pasteText = async (): Promise<void> => {
    await navigator.clipboard.readText().then(result => {
      const [lng, lat, zoom, bearing, pitch] = result.split(';')
      if (
        !lng ||
        isNaN(Number(lng)) ||
        !lat ||
        isNaN(Number(lat)) ||
        !zoom ||
        isNaN(Number(zoom)) ||
        !bearing ||
        isNaN(Number(bearing)) ||
        !pitch ||
        isNaN(Number(pitch))
      ) {
        showToast('Wrong input', ToastTheme.ERROR, IconNames.ERROR)
      } else {
        const {
          store: { mapBoxViewerStore },
        } = this.props
        mapBoxViewerStore.setViewport({
          latitude: Number(lat),
          longitude: Number(lng),
          zoom: Number(zoom),
          bearing: Number(bearing),
          pitch: Number(pitch),
        } as ViewState)
        showToast(
          Localization.translator.successfullyUpdated,
          ToastTheme.SUCCESS,
          IconNames.TICK,
        )
      }
    })
  }

  private changeGlobeName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.props.store.globeViewSetupStore.changeName(event)
  }

  private changeSitemapName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.props.store.sitemapsSetupStore.updateEditableSitemapName(
      event.target.value,
    )
  }

  private changeLng = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.mapBoxViewerStore.setViewportProp(
      Number(event.target.value),
      'longitude',
    )
  }

  private changeLat = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.mapBoxViewerStore.setViewportProp(
      Number(event.target.value),
      'latitude',
    )
  }

  private changePitchValue = (value: number): void => {
    this.props.mapBoxViewerStore.setViewportProp(value, 'pitch')
  }

  private changePitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.changePitchValue(Number(event.target.value))
  }

  private changeZoom = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.changeZoomValue(Number(event.target.value))
  }

  private changeZoomValue = (value: number): void => {
    this.props.mapBoxViewerStore.setViewportProp(value, 'zoom')
  }

  private changeBearing = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.changeBearingValue(Number(event.target.value))
  }

  private changeBearingValue = (value: number): void => {
    this.props.mapBoxViewerStore.setViewportProp(value, 'bearing')
  }
}
