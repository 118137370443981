import * as React from 'react'

import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { FilterType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ActivityFiltersViewStore from '../ActivityFiltersView.store'
import CompanyObjectsSelector from './CompanyObjectsSelector'
import LocationObjectsSelector from './LocationObjectsSelector'

interface IProps {
  filterName: FilterType
  store: ActivityFiltersViewStore
  content?
}

@observer
export default class MappableFilterTabContent extends React.Component<IProps> {
  @computed
  private get filter() {
    const { filterName, store } = this.props
    return store.getFilter(filterName)
  }

  public renderTabContent() {
    const isResourceMappings = this.props.filterName === FilterType.Company

    return (
      <div className="pt24 mappable-filter-tab-content">
        <div className="row y-center bb-brand-dark">
          <div className="py8 px12 text bold brand-dark uppercase">
            {Localization.translator.scheduleData}
          </div>
          <div className="row y-center py2 px12 text bold brand-dark uppercase">
            <Icons.HorizontalArrows className="no-grow pr24" />
            {Localization.translator.struxhubObject}
          </div>
        </div>
        {isResourceMappings
          ? this.renderResourceMappings()
          : this.renderActivityCodeMappings()}
      </div>
    )
  }

  public renderActivityCodeMappings() {
    const { filterName, store } = this.props
    const isSetupCompleted = store.isFilterSetupCompleted(filterName)

    return (
      <div>
        {isSetupCompleted && this.renderActivityCodes()}
        <div
          onClick={this.showFilterConfiguration}
          className="text large blue-highlight pa12 pointer"
        >
          {isSetupCompleted
            ? Localization.translator.editScheduleData
            : Localization.translator.addScheduleData}
        </div>
      </div>
    )
  }

  public renderResourceMappings() {
    const resources = this.props.store.getActivityResources()

    return resources.map(resource => (
      <div key={resource.id} className="row bb-light-grey y-start">
        <div className="pa12 text extra-large brand-dark">
          {resource.shortName || resource.name}
        </div>
        <div>
          <CompanyObjectsSelector resource={resource} />
        </div>
      </div>
    ))
  }

  public render() {
    const { content } = this.props
    return (
      <div
        className={classList({
          'col full-height scrollable': true,
          'inactive-element': this.filter.isDisabled,
        })}
      >
        {content || this.renderTabContent()}
      </div>
    )
  }

  private renderActivityCodes() {
    const { filterName, store } = this.props
    const { getActivityCodesByFilter } = store
    const codes = getActivityCodesByFilter(filterName)

    return codes.map(code => (
      <div key={code.id} className="row bb-light-grey y-start">
        <div className="pa12 text extra-large brand-dark">
          {code.shortName || code.name}
        </div>
        <div>
          <LocationObjectsSelector code={code} filterType={filterName} />
        </div>
      </div>
    ))
  }

  @action.bound
  private showFilterConfiguration() {
    const { store, filterName } = this.props
    store.showFilterConfiguration(filterName)
  }
}
